const BackCard = () => <svg xmlns="http://www.w3.org/2000/svg" width="144" height="89" viewBox="0 0 144 89" fill="none">
  <g filter="url(#filter0_d_701_3169)">
    <rect x="16" y="12" width="112" height="66" rx="4" fill="#006884" />
    <rect x="16" y="22" width="112" height="18" fill="#2590B6" />
    <rect opacity="0.5" x="22" y="51" width="0.7" height="15" fill="#2590B6" />
    <rect opacity="0.5" x="47" y="51" width="0.7" height="15" fill="#2590B6" />
    <rect opacity="0.5" x="39.7002" y="51" width="1.7" height="15" fill="#2590B6" />
    <rect opacity="0.5" x="64.7002" y="51" width="1.7" height="15" fill="#2590B6" />
    <rect opacity="0.5" x="23.7002" y="51" width="1" height="15" fill="#2590B6" />
    <rect opacity="0.5" x="48.7002" y="51" width="1" height="15" fill="#2590B6" />
    <rect opacity="0.5" x="42.4004" y="51" width="1" height="15" fill="#2590B6" />
    <rect opacity="0.5" x="67.4004" y="51" width="1" height="15" fill="#2590B6" />
    <rect opacity="0.5" x="25.7002" y="51" width="1" height="15" fill="#2590B6" />
    <rect opacity="0.5" x="50.7002" y="51" width="1" height="15" fill="#2590B6" />
    <rect opacity="0.5" x="44.4004" y="51" width="0.3" height="15" fill="#2590B6" />
    <rect opacity="0.5" x="69.4004" y="51" width="0.3" height="15" fill="#2590B6" />
    <rect opacity="0.5" x="27.7002" y="51" width="0.5" height="15" fill="#2590B6" />
    <rect opacity="0.5" x="52.7002" y="51" width="0.5" height="15" fill="#2590B6" />
    <rect opacity="0.5" x="45.7002" y="51" width="1" height="15" fill="#2590B6" />
    <rect opacity="0.5" x="70.7002" y="51" width="1" height="15" fill="#2590B6" />
    <rect opacity="0.5" x="29.2002" y="51" width="1" height="15" fill="#2590B6" />
    <rect opacity="0.5" x="54.2002" y="51" width="1" height="15" fill="#2590B6" />
    <rect opacity="0.5" x="31.2002" y="51" width="0.5" height="15" fill="#2590B6" />
    <rect opacity="0.5" x="56.2002" y="51" width="0.5" height="15" fill="#2590B6" />
    <rect opacity="0.5" x="32.7002" y="51" width="1" height="15" fill="#2590B6" />
    <rect opacity="0.5" x="57.7002" y="51" width="1" height="15" fill="#2590B6" />
    <rect opacity="0.5" x="34.7002" y="51" width="0.5" height="15" fill="#2590B6" />
    <rect opacity="0.5" x="59.7002" y="51" width="0.5" height="15" fill="#2590B6" />
    <rect opacity="0.5" x="36.2002" y="51" width="0.5" height="15" fill="#2590B6" />
    <rect opacity="0.5" x="61.2002" y="51" width="0.5" height="15" fill="#2590B6" />
    <rect opacity="0.5" x="37.7002" y="51" width="1" height="15" fill="#2590B6" />
    <rect opacity="0.5" x="62.7002" y="51" width="1" height="15" fill="#2590B6" />
  </g>
  <rect x="1" y="1" width="142" height="87" rx="6" stroke="#006884" stroke-width="2" stroke-dasharray="54 54" />
  <defs>
    <filter id="filter0_d_701_3169" x="16" y="12" width="112" height="69" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset dy="3" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.854167 0 0 0 0 0.84349 0 0 0 0 0.84349 0 0 0 1 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_701_3169" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_701_3169" result="shape" />
    </filter>
  </defs>
</svg>
export default BackCard