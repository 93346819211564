import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { API, setApiAuthorizationHeader } from '../services/API'



const token = localStorage?.getItem('rbc-access-token')
const initialState: any = {
    unseenCount: null,
}

export const unseenActivitysCount = createAsyncThunk('activity/unseen-activitys-count', async (id:string | undefined) => {
    const token = localStorage?.getItem('rbc-access-token');
    setApiAuthorizationHeader(token)
    const response = await API.get(`/unseen-activities-count/${id}/`)
    return response.data
})
export const changeLastSeen = createAsyncThunk('activity/change-last-seen/', async ({id, activity}:{ id:string | undefined, activity:number}) => {
    const token = localStorage?.getItem('rbc-access-token');
    setApiAuthorizationHeader(token)
    const response = await API.post(`/seen-activity/${id}/`,{activity})
    return response.data
})


const activitySlice = createSlice({
    name: 'activitySlice',
    initialState,
    reducers: {
        setUnseenActivitysCount(state) {
            state.unseenCount = 0
          },
    },
    extraReducers: (builder) => {

        builder.addCase(unseenActivitysCount.fulfilled, (state, action) => {
            state.unseenCount = { ...action.payload }
        })
        builder.addCase(changeLastSeen.fulfilled, (state, action) => {
            state.changeLastSeen = { ...action.payload }
            state.unseenCount = 0
        })
       

    },
})

export const { setUnseenActivitysCount} = activitySlice.actions

export default activitySlice.reducer