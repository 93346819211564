import React from "react";
import { Route } from "react-router-dom";
import { Dashboard, Lawyer, Menu, PrivatePage, Profile } from "../Pages";

export default function LawyerRoutes() {
  return (
    <React.Fragment>
      <Route
        path="/lawyer"
        element={
          <PrivatePage>
            <Menu>
              <Lawyer />
            </Menu>
          </PrivatePage>
        }
      ></Route>
      <Route path='/profile' element={<PrivatePage><Menu><Profile /></Menu></PrivatePage>}></Route>
      <Route path='/profile/dashboard' element={<PrivatePage><Menu><Dashboard /></Menu></PrivatePage>}></Route>
    </React.Fragment>
  );
};
