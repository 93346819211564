import { useState, useCallback, useMemo, useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import * as Yup from "yup";
import { Form, Formik, FormikHelpers } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import {
  AddInput,
  CustomButtosWithIcon,
  CustomInput,
  CustomSelect,
  ProfileFileUpload,
} from "../../../../Components";
import Close from "../../../../Components/SvgCompoents/Close";
import { useGetManagersQuery } from "../../../../store/services/managers";
import { useAddCompaniesMutation } from "../../../../store/services/companies";
import { useAddImagesMutation } from "../../../../store/services/photo";
import styleProfile from "./AddCompany.module.scss";
import { AddressItem, IMe } from "../../../../types";
import TarifItem from "../../../../Components/TarifItem";
import { useGetTasksTypeQuery } from "../../../../store/services/tasks";
import Help from "../../../../Components/SvgCompoents/Help";
import styles from "react-day-picker/dist/style.css";

export default function AddCompany() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams<Record<string, string | undefined>>();
  const [addressList, setAddressList] = useState<AddressItem[]>([]);
  const [managerList, setManagerList] = useState<string[]>([]);
  const [help, setHelp] = useState<boolean>(false);
  const [accountantList, setAccountantList] = useState<string[]>([]);
  const [submited, setSubmit] = useState<boolean>(false);
  const [avatarImg, setAvatarImg] = useState<string>()
  const [selectedTarif, setSelectedTarif] = useState<number>(0)
  const [addImages, { data }] = useAddImagesMutation();
  const { data: managers } = useGetManagersQuery({ limit: 100000, offset: 0 });
  const { data: task_types } = useGetTasksTypeQuery({
    limit: 100000,
    offset: 0,
  });
  const { data: clinetsManagers } = useGetManagersQuery({
    limit: 100000,
    offset: 0,
    client_id: params?.id,
  });
  const [addCompaniesRequest, { data: companies, isSuccess }] =
    useAddCompaniesMutation();

  useEffect(() => {
    if (isSuccess) toast.success(t("createCompanySuccess"));
  }, [isSuccess]);

  const SignupSchema = Yup.object().shape({
    name: Yup.string().required(t("required")),
    hvhh: Yup.string().required(t("required")),
    // addresses: Yup.array().required(t("required")),
  });
  interface Values {
    name: string;
    hvhh: string;
    managers?: number[];
    accountant_worker?: number | null;
  }
  const handleAddAddress = useCallback(
    (address: string) => {
      const newArray = address
        ? [...addressList, { name: address }]
        : [...addressList];
      setAddressList(newArray);
    },
    [addressList]
  );

  const handleDeleteAddress = useCallback(
    (ind: number) => {
      const newArray = [...addressList];
      newArray.splice(ind, 1);
      setAddressList(newArray);
    },
    [addressList]
  );

  const handleAddManager = useCallback(
    (managerId: any) => {
      setManagerList(managerId);
    },
    [managerList]
  );

  const handleDeleteManager = useCallback(
    (ind: number) => {
      const newArray = [...managerList];
      newArray.splice(ind, 1);
      setManagerList(newArray);
    },
    [managerList]
  );

  const selectedManagers = useMemo(() => {
    let newArray: string[] = [];
    for (let index = 0; index < managerList?.length; index++) {
      const element = managerList[index];
      for (let ind = 0; ind < clinetsManagers?.length; ind++) {
        const el = clinetsManagers[ind];
        if (el.id === +element) {
          newArray.push(el);
        }
      }
    }
    return newArray;
  }, [managerList]);


  const handleAddAccountant = useCallback(
    (managerId: any) => {
      setAccountantList(managerId);
    },
    [accountantList]
  );

  const handleSelectTarif = useCallback((event: React.SyntheticEvent, newValue: number) => { setSelectedTarif(newValue) }, [])

  const handleDeleteAccountant = useCallback(
    (ind: number) => {
      const newArray = [...accountantList];
      newArray.splice(ind, 1);
      setAccountantList(newArray);
    },
    [accountantList]
  );

  const selectedAccountant = useMemo(() => {
    let newArray: string[] = [];
    for (let index = 0; index < accountantList?.length; index++) {
      const element = accountantList[index];
      for (let ind = 0; ind < managers?.length; ind++) {
        const el = managers[ind];
        if (el.id === +element) {
          newArray.push(el);
        }
      }
    }
    return newArray;
  }, [accountantList]);


  const handleSetImages = useCallback((img: string) => {
    setAvatarImg(img)
  }, [])

  const handleSetHelp = useCallback(() => {
    setHelp(!help)
  }, [help])

  const handleSubmit = useCallback(() => {
    setSubmit(true);
  },
    [managerList, accountantList]
  );

  return (
    <div className={`w-100`}>
      <Formik
        initialValues={{
          name: "",
          hvhh: "",
          accountant_worker: null,
        }}
        validationSchema={SignupSchema}
        onSubmit={(
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {
          if (addressList.length > 0) {
            addCompaniesRequest({
              ...values,
              managers: managerList.length > 0 ? managerList : [],
              accountant_worker: accountantList.length > 0 ? accountantList : [],
              addresses: addressList,
              image: avatarImg ? avatarImg : '',
              client_id: params.id ? +params.id : '',
              tarif: selectedTarif ? 2 : 1
            }).then((res: any) => {
              if (res?.data?.id) {
                navigate(`/profile/clients/${params.id}/companies`, {
                  replace: true,
                });
              }
            });
          }

        }}
      >
        <Form>
          <div className={styleProfile.profilePhotos}>
            <div
              className={`w-100 d-flex justify-content-between ${styleProfile.nameImages}`}
            >
              <div className="d-flex flex-wrap justify-content-between align-items-center w-100 gap-2">
                <ProfileFileUpload
                  forCompany={true}
                  avatar_image={avatarImg ? `${process.env.REACT_APP_API_KEY}${avatarImg}` : ''} request={handleSetImages}
                />
                <CustomButtosWithIcon
                  title={t("save")}
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z"
                        fill="#FEFEFE"
                      />
                    </svg>
                  }
                  type="submit"
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
          <div className={`d-flex ${styleProfile.tarifContainer}`}>
            <div className={styleProfile.selectTarif}>{t('selectTarif')}</div>
            <div className='d-flex align-items-center'>
              <div className="tabs-cont">
                <Tabs
                  textColor="secondary"
                  value={selectedTarif}
                  onChange={handleSelectTarif}
                  aria-label="basic tabs example"
                >
                  <Tab label="Basic" className="basic" />
                  <Tab label="Premium" className="premium" />
                </Tabs>
              </div>
              <div onClick={handleSetHelp} className={styleProfile.helpStyle}>
                <Help />
              </div>
            </div>
            <Dialog
              open={help}
              onClose={handleSetHelp}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={true}
              maxWidth='md'
              className="tarifModal"
            >
              <div className="d-flex flex-column flex-md-row justify-content-center">
                <TarifItem
                  price='5,000'
                  type='basic'
                  title="Basic"
                  array={task_types}
                />
                <TarifItem
                  price='7,000'
                  type='premium'
                  title="Premium"
                  array={task_types}
                />
              </div>
            </Dialog>

          </div>
          <div className={styleProfile.formsCont}>
            <div
              className={`d-flex flex-wrap justify-content-start ${styleProfile.inputs}`}
            >
              <CustomInput name="name" title={t("companyName")} />
              <CustomInput name="hvhh" title={t("HVHH")} />
              {managers?.length > 0 && <div className={`${styleProfile.withSelected} ${styleProfile.withSelectedAccountant}`}>
                <CustomSelect
                  label={t("accountants")}
                  list={managers?.map((el: IMe) => ({
                    name: `${el.user.first_name} ${el.user.last_name}`,
                    id: el.id,
                  }))}
                  value={accountantList}
                  multiple={true}
                  onChange={handleAddAccountant}
                />
                {selectedAccountant?.map((el: any, ind: number) => {
                  return (
                    <div key={ind} className={styleProfile.selected}>
                      {el?.avatar_image ? (
                        <img
                          src={`${process.env.REACT_APP_API_KEY}${el?.avatar_image}`}
                          className={styleProfile.image}
                        />
                      ) : (
                        <div>
                          <img
                            src={"/managerDefaultImg.svg"}
                            className={styleProfile.imageDefault}
                          />
                        </div>
                      )}
                      <span className={styleProfile.title}>
                        {el?.user?.first_name} {el?.user?.last_name}
                      </span>
                      <span onClick={() => handleDeleteAccountant(ind)}>
                        <Close />
                      </span>
                    </div>
                  );
                })}
              </div>}
              {/* {managers?.length > 0 && <CustomSelectFormik
                  label={t("accountantCompanies")}
                  list={managers?.map((el: any) => ({
                    name: `${el.user.first_name} ${el.user.last_name}`,
                    id: el.id,
                  }))}
                  name="accountant_worker"
                />} */}
              <div className={styleProfile.withSelected}>
                <AddInput
                  label={t("addresses")}
                  addAddress={(val: string) => handleAddAddress(val)}
                />
                {submited && addressList.length === 0 && <div className='error'>{t('required')}</div>}
                {addressList?.map((el: any, ind: number) => (
                  <div
                    key={ind}
                    className={`${styleProfile.selected} ${styleProfile.selectedAddress}`}
                  >
                    <span className={styleProfile.title}>{el.name}</span>
                    <span onClick={() => handleDeleteAddress(ind)}>
                      <Close />
                    </span>
                  </div>
                ))}
              </div>
              {clinetsManagers?.length > 0 && <div className={styleProfile.withSelected}>
                <CustomSelect
                  label={t("managers")}
                  list={clinetsManagers?.map((el: any) => ({
                    name: `${el.user.first_name} ${el.user.last_name}`,
                    id: el.id,
                  }))}
                  value={managerList}
                  multiple={true}
                  onChange={handleAddManager}
                />
                {selectedManagers?.map((el: any, ind: number) => {
                  return (
                    <div key={ind} className={styleProfile.selected}>
                      {el?.avatar_image ? (
                        <img
                          src={`${process.env.REACT_APP_API_KEY}${el?.avatar_image}`}
                          className={styleProfile.image}
                        />
                      ) : (
                        <div>
                          <img
                            src={"/managerDefaultImg.svg"}
                            className={styleProfile.imageDefault}
                          />
                        </div>
                      )}
                      <span className={styleProfile.title}>
                        {el?.user?.first_name} {el?.user?.last_name}
                      </span>
                      <span onClick={() => handleDeleteManager(ind)}>
                        <Close />
                      </span>
                    </div>
                  );
                })}
              </div>}

            </div>
          </div>

        </Form>
      </Formik>
    </div>
  );
}
