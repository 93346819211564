import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  CustomAutocomplete,
  CustomButton,
  CustomButtosWithIcon,
  CustomDayPicker,
  DashbordSelect,
  SearchInput,
  Status,
} from "../../../Components";
import { useGetCompaniesQuery } from "../../../store/services/companies";
import { useAppDispatch, useAppSelector } from "../../../store";
import { createTask } from "../../../store/slice/createtask";
import { CreateTask } from "../../../Dialogs";
import styles from "./dashboard.module.scss";
import DashboardCard from "../../../Components/DashboardCard";
import Filter from "../../../Components/SvgCompoents/Filter";
import { useGetTasksQuery, useGetTasksTypeQuery, usePrefetch } from "../../../store/services/tasks";
import moment from "moment";
import { Pagination, Stack } from '@mui/material';
import { toast } from "react-toastify";
import { ICompanyItem } from "../../../types";
import { onMessageListener } from "../../../firebaseNotifications/firebase";

export default function Dashboard() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [filter, setFilter] = useState<any>({});
  const [open, setOpen] = useState(false);
  const [selectCompany, setSelecteCompany] = useState<string>("");
  const [taskType, setTaskType] = useState<string>("");
  const [activePage, setActivePage] = useState<number>(1);
  const notification = useAppSelector((state) => state?.notificationsContainer?.pushNotification);
  const { data, isLoading } = useGetTasksQuery(filter?.from_date ? {
    limit: 12,
    offset: (activePage - 1) * 12,
    page: activePage,
    ...filter,
    from_date: filter?.from_date ? filter?.from_date?.toISOString() : '',
    to_date: filter?.to_date ? filter?.to_date?.toISOString() : ''
  } : {
    limit: 12,
    offset: (activePage - 1) * 12,
    page: activePage,
    ...filter
  });
  const { data: companies } = useGetCompaniesQuery({ limit: 100000 });
  const { data: task_types } = useGetTasksTypeQuery({
    limit: 100000,
    offset: 0,
  });
  const taskData = useAppSelector((state: any) => state.createTask.taskData);
  const prefetchPage = usePrefetch('getTasks');
  const dispatch = useAppDispatch();
  const handleClickOpen = () => {
    // if (auth?.me?.accountant) {
    setOpen(true);
    // } else {
    //   toast.info(t("youCantCreateTask"))
    // }

  };
  const handleClose = () => {
    dispatch(createTask({ task_type_id: taskType, company_id: selectCompany }));
    const tarif = companies?.filter((el: ICompanyItem) => el.id === taskData.company_id)[0]?.tarif
    if (tarif === 1 && taskData.task_type_id === 11) {
      toast.info(t("youCantCreateTaskForThisCompany"))
    } else {
      switch (taskData.task_type_id.toString()) {
        case "1":
          return navigate("/profile/companies/create-task");
        case "2":
          return navigate("/profile/companies/create-vacation");
          break;
        case "3":
          navigate("/profile/companies/create-provision-allowance");
          break;
        case "4":
          navigate("/profile/companies/create-dismissal");
          break;
        case "5":
          navigate("/profile/companies/change-salary-position");
          break;
        case "6":
          navigate("/profile/companies/create-bonus");
          break;
        case "7":
          navigate("/profile/companies/landing-bill");
          break;
        case "8":
          navigate("/profile/companies/estimate-task");
          break;
        case "9":
          navigate("/profile/companies/create-invoice");
          break;
        case "10":
          navigate("/profile/companies/free");
          break;
        default:
          break;
      }
    }
    setOpen(false);
  };

  const userRole = localStorage.getItem("rbcRole");
  const handleSelectDateRange = useCallback((value: any) => {
    setFilter({ ...filter, from_date: value?.from, to_date: value?.to })
  }, [filter]);
  const handleChangeFilter = useCallback((value: any) => {
    const keys = Object.keys(value)
    if (value[keys[0]]) {
      setFilter({ ...filter, ...value })
    } else {
      const filterClone = { ...filter };
      delete filterClone[keys[0]]
      setFilter({ ...filterClone })
    }
  }, [filter]);
  useEffect(() => {
    if (notification?.data?.push_type !== 'TASK_COMMENT') {
      prefetchPage({ limit: 12, offset: (activePage - 1) * 12, page: activePage }, { force: true })
    }
  }, [notification])
  useEffect(() => {
    const myParams: any = {}
    const queryParams: any = new URLSearchParams(window.location.search)
    for (const [key, value] of queryParams) {
      myParams[key] = value
    }
    setFilter({ ...myParams })
  }, [window.location.search])
  useEffect(() => {
    prefetchPage({ limit: 12, offset: (activePage - 1) * 12, page: activePage }, { force: true })
    onMessageListener()
      .then((payload) => {
        if (payload?.data?.type === 'task' && Object.keys(filter).length === 0) {
          prefetchPage({ limit: 12, offset: (activePage - 1) * 12, page: activePage }, { force: true })
        }
      })
      .catch((err) => console.log('failed: ', err));
  }, [])
  return (
    <div className={`w-100 dashboard`}>
      <div className="d-flex justify-content-between">
        <CreateTask
          open={open}
          handleClose={handleClose}
          handleOpen={handleClickOpen}
        />
        {userRole !== "2" && (
          <div className={styles.createTask} onClick={() => handleClickOpen()}>
            <CustomButton onClick={handleClickOpen} title={t("createTasks")} type="button" />
            {/* {t('createTasks')} */}
          </div>
        )}
      </div>
      <div className={`d-flex flex-wrap align-items-end gap-2 w-100 ${styles.filterRightSideCont}`}>
        {/* <SearchInput onClick={() => console.log("dddddd")} /> */}
        <DashbordSelect
          showValue={true}
          label={t('status')}
          value={filter.status}
          list={[{ name: `${t('created')}`, id: 'created' },
          { name: `${t('answeredByAccountant')}`, id: 'answeredByAccountant' },
          { name: `${t('dispute')}`, id: 'dispute' },
          { name: `${t('done')}`, id: 'done' },
          { name: `${t('deleted')}`, id: 'deleted' },
          ]}
          onChange={(e) => handleChangeFilter({ status: e })}
        />
        <DashbordSelect
          showValue={true}
          label={t('companies')}
          value={filter.company_id}
          list={companies?.filter((item: any) => item?.is_active)?.map((el: any) => ({ name: el?.name, id: el?.id }))}
          onChange={(e) => handleChangeFilter({ company_id: e })}
        />
        <DashbordSelect
          showValue={true}
          label={t('taskTypes')}
          value={filter.type_id}
          list={task_types?.map((el: any) => ({ name: t(`${el?.name}`), id: el?.id }))}
          onChange={(e) => handleChangeFilter({ type_id: e })}
        />

        <CustomDayPicker
          className='dashboardPicker'
          mode="range"
          title={t('date')}
          selected={{ from: filter.from_date, to: filter.to_date }}
          submited={false}
          setSelected={handleSelectDateRange}
        />
      </div>

      <div className={`${styles.DashboardCardCont}`}>
        {data?.results?.map((el: any, ind: number) => (
          <DashboardCard
            key={ind}
            task_name={el.task_name}
            task_type={t(`${el.task_type.name}`)}
            id={el.id}
            status={el.status}
            company_name={el.company.name}
            created_at={moment(el.created_at).format("DD-MMMM-YYYY HH:mm")}
            manager=""
          />
        ))}
      </div>
      {data?.count > 12 && (
        <Stack spacing={2} marginTop={10}>
          <Pagination
            count={Math.ceil(data?.count / 12)}
            page={activePage}
            showFirstButton
            showLastButton
            onChange={(_, page) => {
              setActivePage(page);

            }}
          />
        </Stack>
      )}
      {!isLoading && data?.results?.length === 0 && <div className={styles.noDataAvailableCont}>
        <img src={"/noDataAvailable.png"} />
      </div>}
    </div>
  );
}
