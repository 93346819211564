import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IAuth, IMe } from '../../types'
import { API, setApiAuthorizationHeader } from '../services/API'
import { toast } from 'react-toastify'
import i18n from '../../i18n'


const token = localStorage?.getItem('rbc-access-token')
const initialState: IAuth = {
  isLogged: token ? true : false,
  token: token ? token : null,
  me: null
}
export const deleteUser = createAsyncThunk('user/deleteUser', async () => {
  const token =localStorage?.getItem('rbc-access-token');
  setApiAuthorizationHeader(token)
  const response = await API.get('/delete-user/')
  return response.data
})

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setLogIn(state, action: PayloadAction<string>) {
      state.isLogged = true
      state.token = action.payload
      localStorage.setItem('rbc-access-token', action.payload)
    },
    setLogOut(state) {
      state.isLogged = false
      state.token = null
      localStorage.removeItem('rbc-access-token')
      state.me = null
    },
    setMe(state, action: PayloadAction<IMe>) {
      state.me = action.payload
      state.isLogged=true
    }
  },
  extraReducers: (builder) => {
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      toast.success( i18n.t('deleteAfterInfoDialogTitle') )
    })
    builder.addCase(deleteUser.rejected, (state, action) => {
      toast.error( i18n.t('sometingWentWrong') )
    })
  },
})

export const {
  setLogIn,
  setLogOut,
  setMe
} = authSlice.actions

export default authSlice.reducer