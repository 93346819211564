import { useAddImagesMutation } from '../../store/services/photo';
import Camera from '../SvgCompoents/Camera';
import style from './ProfileFileUpload.module.scss'

interface Props {
  forCompany?: boolean,
  avatar_image: string,
  edit?:boolean
  request: (arg: any) => void,
};
const ProfileFileUpload = ({ avatar_image, forCompany = false, edit=true, request }: Props) => {
  const [addImages, { data }] = useAddImagesMutation();
  return (<div className={style.profilePhotoEdit}>
    {avatar_image
      ? <img src={avatar_image} className={style.myProfilePhoto} />
      : <img src={forCompany ? `/companyDefaultImg.svg` : '/managerDefaultImg.svg'} className={style.profilePhoto} />}
    <label>
     {edit && <div className={style.editAvatar}>
        <Camera />
      </div>}
      <input
        type='file'
        style={{ display: 'none' }}
        onChange={(e: any) => {
          const file: any = e?.target?.files[0];
          if (file) {
            const formdata = new FormData();
            formdata.append('file_url', file)
            addImages(formdata)
              .then((res: any) => {
                request(res.data)
              });
          }
        }}
      />
    </label>
  </div>)
}
export default ProfileFileUpload;