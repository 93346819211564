import { useTranslation } from 'react-i18next';
import styles from './DetailItem.module.scss';
export default function DetailItem({ name, value }: { name: string, value: string | undefined }) {
  const { t } = useTranslation();
  return (
    <div className={`d-flex justify-content-between ${styles.detailItem}`}>
      <span className={styles.detailName}>{name}</span>
      <span className={styles.detailValue}>{value}</span>
    </div>
  );
}


