import React from 'react';
import style from './NotificationItem.module.scss'

type Props = {
  title: string,
  avatar: string,
  name: string,
  date: string,
  is_reed: boolean,
  onClick: () => void
};
const NotificationItem = ({ avatar, is_reed, name, date, title, onClick }: Props) => {  
  return (<div className={`d-flex ${style.notificationItem}`} onClick={onClick}>
    <div className={is_reed ? `${style.color} ${style.colorTransparent}` : `${style.color}`} />
    <div className={`d-flex flex-column ${style.titleNameDate}`}>
      <span className={style.title}>{title}</span>
      <div className={style.name}>{name}</div>
      <span className={style.date}>{date}</span>
    </div>
    {avatar
      ? <img className={style.avatar} src={avatar} />
      : <div className={style.avatarCont}><img src={'/managerDefaultImg.svg'} /></div>}
  </div>)
}
export default NotificationItem;