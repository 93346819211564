import { useEffect, useState } from 'react';
import styles from './Applications.module.scss';
import { useTranslation } from 'react-i18next';
import { ApplicationItem, CustomButtosWithIcon, NotificationMenu, SearchInput } from '../../../Components';
import EmployeeItem from '../../../Components/EmployeeItem';
import { useNavigate } from 'react-router-dom';
import { usePrefetch } from '../../../store/services/managers';
import { useGetAccountantsQuery, useGetApplicationQuery } from '../../../store/services/accountants';
import { Pagination, Stack } from '@mui/material';

export default function Applications() {
  const [page, setPage] = useState<number>(1);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data, isLoading } = useGetApplicationQuery({ limit: 10, page });
  const prefetchPage = usePrefetch('getManagers');
  useEffect(() => {
    prefetchPage({ limit: 10, page }, { force: true })
  }, []); 
  return (
    <div className={`w-100`}>
      <div className={`w-100 d-none d-lg-flex ${styles.searchNotification}`}>
        <SearchInput onClick={() => console.log('dskfj')} />
        {/* <NotificationMenu /> */}
      </div>
      <div className='d-flex flex-wrap w-100 justify-content-between'>
        <h1 className={styles.pageHeading}>{t("applications")}</h1>
      </div>
      <div className={`${styles.applicationArray}`}>
        {data?.results?.map((el: any, ind: number) => <ApplicationItem
          id={el?.id}
          key={ind}
          img={el?.accountant?.avatar_image}
          user_comment={el?.user_comment}
          company_name={el?.accountant?.company_name }
          accountant_comment={el?.accountant_comment}
          accountant_accept={el?.accountant_accept}
          user_accept={el?.user_accept}
        />)}
      </div>
      {data?.count > 10 && (
          <Stack spacing={2} marginTop={10}>
            <Pagination
              count={Math.ceil(data?.count / 10)}
              page={page}
              showFirstButton
              showLastButton
              onChange={(_, page) => {
                setPage(page);
              }}
            />
          </Stack>
        )}
      {!isLoading && data?.results?.length === 0 && <div className={styles.noDataAvailableCont}>
        <img src={"/noDataAvailable.png"} />
      </div>}
    </div>
  );
}


