const ChangePhoto = () => <svg xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 58 58" fill="none">
  <g filter="url(#filter0_d_363_4640)">
    <rect x="4" width="50" height="50" rx="25" fill="white" shape-rendering="crispEdges" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M38.4736 12.6867V18.1253C38.4736 18.3958 38.5811 18.6552 38.7723 18.8464C38.9635 19.0376 39.2229 19.1451 39.4934 19.1451C39.7638 19.1451 40.0232 19.0376 40.2144 18.8464C40.4057 18.6552 40.5131 18.3958 40.5131 18.1253V12.6867C40.5131 12.4163 40.4057 12.1569 40.2144 11.9657C40.0232 11.7744 39.7638 11.667 39.4934 11.667C39.2229 11.667 38.9635 11.7744 38.7723 11.9657C38.5811 12.1569 38.4736 12.4163 38.4736 12.6867Z" fill="#006884" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M36.7741 16.4262H42.2127C42.4832 16.4262 42.7426 16.3188 42.9338 16.1275C43.125 15.9363 43.2325 15.6769 43.2325 15.4065C43.2325 15.136 43.125 14.8766 42.9338 14.6854C42.7426 14.4942 42.4832 14.3867 42.2127 14.3867H36.7741C36.5037 14.3867 36.2443 14.4942 36.0531 14.6854C35.8618 14.8766 35.7544 15.136 35.7544 15.4065C35.7544 15.6769 35.8618 15.9363 36.0531 16.1275C36.2443 16.3188 36.5037 16.4262 36.7741 16.4262Z" fill="#006884" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M42.3732 17.7798C42.3198 17.7837 42.2663 17.7855 42.2127 17.7853H41.8728V18.1252C41.8728 18.7562 41.6221 19.3614 41.1759 19.8077C40.7297 20.2539 40.1245 20.5046 39.4934 20.5046C38.8624 20.5046 38.2572 20.2539 37.8109 19.8077C37.3647 19.3614 37.114 18.7562 37.114 18.1252V17.7853H36.7741C36.3365 17.7853 35.9074 17.6646 35.534 17.4365C35.1605 17.2085 34.8572 16.8818 34.6574 16.4925C34.4575 16.1033 34.3689 15.6664 34.4013 15.23C34.4336 14.7936 34.5857 14.3746 34.8407 14.019L34.6626 13.6615C34.4649 13.2664 34.1612 12.9341 33.7854 12.7019C33.4096 12.4697 32.9765 12.3467 32.5347 12.3467H24.6977C24.256 12.3467 23.8229 12.4697 23.4471 12.7019C23.0713 12.9341 22.7675 13.2664 22.5699 13.6615L21.2823 16.238C21.2537 16.2943 21.2102 16.3416 21.1564 16.3747C21.1027 16.4078 21.0408 16.4254 20.9777 16.4256H17.739C16.7474 16.4256 15.7963 16.8196 15.0951 17.5208C14.3939 18.222 14 19.173 14 20.1647V33.7612C14 34.7523 14.3943 35.7041 15.0945 36.4057C15.7964 37.1062 16.7474 37.4998 17.739 37.5002H39.4934C40.4846 37.5002 41.4364 37.1059 42.1379 36.4057C42.8384 35.7038 43.232 34.7528 43.2325 33.7612V20.1647C43.2325 19.2578 42.9102 18.427 42.3732 17.7798ZM28.6162 19.8247C25.0512 19.8247 22.1579 22.7181 22.1579 26.2831C22.1579 29.8481 25.0512 32.7414 28.6162 32.7414C32.1812 32.7414 35.0746 29.8481 35.0746 26.2831C35.0746 22.7181 32.1812 19.8247 28.6162 19.8247ZM28.6162 21.8642C29.7692 21.8928 30.8653 22.3709 31.6707 23.1965C32.476 24.0221 32.9268 25.1298 32.9268 26.2831C32.9268 27.4364 32.476 28.5441 31.6707 29.3696C30.8653 30.1952 29.7692 30.6733 28.6162 30.7019C27.4633 30.6733 26.3671 30.1952 25.5618 29.3696C24.7564 28.5441 24.3056 27.4364 24.3056 26.2831C24.3056 25.1298 24.7564 24.0221 25.5618 23.1965C26.3671 22.3709 27.4633 21.8928 28.6162 21.8642Z" fill="#006884" />
  </g>
  <defs>
    <filter id="filter0_d_363_4640" x="0" y="0" width="58" height="58" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset dy="4" />
      <feGaussianBlur stdDeviation="2" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_363_4640" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_363_4640" result="shape" />
    </filter>
  </defs>
</svg>
export default ChangePhoto