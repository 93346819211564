import styles from './ProfessionCard.module.scss';
interface IProps {
  icon?: any,
  title?: string,
  active?: boolean,
  onClick: () => void
}
const ProfessionCard = (props: IProps) => {
  return <div
    className={`${styles.profession} ${props.active ? styles.active : ""} d-flex flex-column justify-content-center align-items-center`}
    onClick={props.onClick}>
    {props?.icon}
    <span className={styles.title}>{props?.title}</span>
  </div>
}
export default ProfessionCard;