import { Rating } from '@mui/material';
import style from './OurTime.module.scss';
export default function OurTimeItem({img, name, city, description}:{img:string, name:string, city:string, description:string}) {
    return (
        <div className={style.item}>
            <img className={style.img} src={img} />
            <span className={style.name}>{name}</span>
            <span className={style.city}>{city}</span>
            <Rating name="half-rating" defaultValue={2.5} precision={0.5} />
            <p className={style.description}>{description}</p>
        </div>
    )
}