import React from 'react';
import styles from '../../App.module.scss';
import { useTranslation } from 'react-i18next';

export default function Terms() {

  const { t } = useTranslation();
  return (
    <div className={`d-flex justify-content-center align-items-center ${styles.cont}`}>Terms  </div>

  );
}


