import { createApi } from '@reduxjs/toolkit/query/react'
import { setMe } from '../slice/authSlice'
import axiosBaseQuery from './API'
export const authApiSignIn: any = createApi({
  reducerPath: 'authApi',
  tagTypes:['Auth'],
  baseQuery: axiosBaseQuery({ baseUrl: process.env.REACT_APP_API_KEY }),
  endpoints: (builder) => ({
    signIn: builder.mutation({
      query: (form) => {        
        return {
        url: `/login/`,
        method: 'POST',
        data: form,
        headers: {
          'content-type': 'application/json'
        }
      }},
      async onQueryStarted(_, {dispatch, queryFulfilled}) {
        try {
          const {data} = await queryFulfilled 
          const {access} = data
          if(access){
            localStorage.setItem('rbc-access-token', access)
            localStorage.setItem('rbcRole', data.user_detail.user_role )
            localStorage.setItem('rbcId', data.user_detail.id)
            dispatch(setMe({...data.user_detail, token:data.access}))
          }
        } catch (err) {}
      }
    }),
    getMe: builder.query<any, {}>({
      query: (data:any) => {
        return  {url:`/get-me/`, method:'GET'}
      },
      async onQueryStarted(_, {dispatch, queryFulfilled}) {
        try {
          const {data} = await queryFulfilled;          
          dispatch(setMe(data))
        } catch (err) {}
      },
      providesTags: [{ type: 'Auth' }],
    }),
    editMe: builder.mutation<any, {}>({
      query: (form:any) => { 
        const {id, ...rest}=form      
        return {
          url: `/edit-user/${id}/`,
          method: 'PUT',
          data: rest,
        }
      },
      async onQueryStarted(_, {dispatch, queryFulfilled}) {
        try {
          const {data} = await queryFulfilled
          dispatch(setMe(data.user_detail))
        } catch (err) {}
      },
      invalidatesTags: [{ type: 'Auth'}],
    }),  
    checkEmail: builder.mutation({
      query: (form) => {        
        return {
        url: `/check-email/`,
        method: 'POST',
        data: form,
      }},
     
    }),
    signUp: builder.mutation({
      query: (form) => {        
        return {
        url: `/register/`,
        method: 'POST',
        data: form,
      }},      
      async onQueryStarted(_, {dispatch, queryFulfilled}) {
        try {
          const {data} = await queryFulfilled
          localStorage.setItem('rbc-access-token', data.access)
          localStorage.setItem('rbcRole', data.user_detail.user_role)
          localStorage.setItem('rbcId', data.user_detail.id)
          dispatch(setMe({...data.user_detail, token:data.access}))
        } catch (err) {}
      }
    }),
    sendForgetCode: builder.mutation({
      query: (email: string) => {        
        return {
        url: `/send-forget-code/`,
        method: 'POST',
        data: email,
      }},      
    }),
    changePassword: builder.mutation({
      query: (form: any) => {        
        return {
        url: `/check-forget-code/`,
        method: 'POST',
        data: form,
      }},      
    }),
    getUserDetails: builder.query({
      query: (id: number) => {
        return  {url:`/user-detail/${id}/`, method:'GET'}
      }
    })
  }),
})

export const {
  useSignInMutation,
  useSignUpMutation,
  useCheckEmailMutation,
  useForgetPasswordMutation,
  useSendForgetCodeMutation,
  useChangePasswordMutation,
  useEditMeMutation,
  useGetMeQuery,
  useGetUserDetailsQuery
} = authApiSignIn