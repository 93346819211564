import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import styles from './TaskPage.module.scss';
import { CustomButtosWithIcon, DetailItem, FileDownload } from '../../../Components';
import DocumentCard from '../../../Components/SvgCompoents/DocumentCard';
import ComponentWithBorder from '../../../Components/ComponentWithBorder';
import Recording from '../../../Components/Recording';
import { useEditTasksByIdMutation, useGetTasksByIdQuery } from '../../../store/services/tasks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAddImagesMutation } from '../../../store/services/photo';
import { toast } from 'react-toastify';
import { IFiles, ITaskPage } from '../../../types';
import { getFileName } from '../../../Utils';

interface DataTask{
  data:ITaskPage
}
export default function Registration( {data} : DataTask) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [submit, setSubmit] = useState<boolean>(false);
  const [files, setFiles] = useState<IFiles>({});
  const [savedFiles, setSavedFiles] = useState<IFiles>({});
  const [addImages] = useAddImagesMutation();
  const [editTasksById] = useEditTasksByIdMutation();
  
  useEffect(() => {
    if (data?.employee_registration) {
      const { employment_contract, employment_order, application_from_tax, aplication_file } = data?.employee_registration
      setSavedFiles({ employment_contract, employment_order, application_from_tax, aplication_file })
    }
  }, [data])
  const handleSetFile = useCallback((data: IFiles) => {
    setFiles({ ...files, ...data })
  }, [files])

  const handleDeleteFile = useCallback((name: keyof typeof savedFiles) => {
    if (savedFiles[name]) {
      setSavedFiles((prev: IFiles) => {
        const prevFiles = { ...prev };
        delete prevFiles[name];
        return prevFiles
      })
    } else {
      setFiles((prev: IFiles) => {
        const prevFiles = { ...prev };
        delete prevFiles[name];
        return prevFiles
      })
    }

  }, [savedFiles])

  const deleteFile = (file: any) => {
    editTasksById(file)
  }
  // const getFileName = (string: string | null | undefined) => {
  //   const stringList = string ? string?.split("/") : '';
  //   return stringList ? stringList[stringList?.length - 1] : ''
  // }
  const employment_contract = useMemo(() => {
    return getFileName(savedFiles?.employment_contract)
  }, [savedFiles?.employment_contract]);

  const employment_order = useMemo(() => {
    return getFileName(savedFiles?.employment_order)
  }, [savedFiles?.employment_order]);

  const application_from_tax = useMemo(() => {
    return getFileName(savedFiles?.application_from_tax)
  }, [savedFiles?.application_from_tax]);

  const aplication_file = useMemo(() => {
    return getFileName(savedFiles?.aplication_file)
  }, [savedFiles?.aplication_file]);

  const passport_back_name = useMemo(() => {
    return getFileName(data?.employee_registration?.passport_back)
  }, [data?.employee_registration?.passport_back]);

  const passport_front_name = useMemo(() => {
    return getFileName(data?.employee_registration?.passport_front)
  }, [data?.employee_registration?.passport_front]);

  const id_back_name = useMemo(() => {
    return getFileName(data?.employee_registration?.id_back)
  }, [data?.employee_registration?.passport_back]);

  const id_front_name = useMemo(() => {
    return getFileName(data?.employee_registration?.id_front)
  }, [data?.employee_registration?.passport_front]);

  const application = useMemo(() => {
    return getFileName(data?.employee_registration?.application)
  }, [data?.employee_registration?.application]);

  const passport_other_name = useMemo(() => {
    return getFileName(data?.employee_registration?.passport_other)
  }, [data?.employee_registration?.passport_other]);

  const address_other_name = useMemo(() => {
    return getFileName(data?.employee_registration?.address_other)
  }, [data?.employee_registration?.address_other]);

  const residence_other_name = useMemo(() => {
    return getFileName(data?.employee_registration?.residence_other)
  }, [data?.employee_registration?.residence_other]);
  const social_other_name = useMemo(() => {
    return getFileName(data?.employee_registration?.social_other)
  }, [data?.employee_registration?.social_other]);
  
  return (
    <div className={`w-100`}>
      <div className='d-flex justify-content-between flex-nowrap'>
        <div className={`${styles.taskTypeHeading}`}>{t(`${data?.task_type?.name}`)} </div>
        <div className={`${styles.taskStatus} ${styles[data?.status]}`}>{t(`${data?.status}`)}</div>
      </div>
      <div className='d-flex justify-content-between flex-wrap'>
        <div>
          <div className={styles.heading}>{t('detail')} </div>
          <div className={styles.detailCont}>
            {data?.task_name && <DetailItem name={t('taskName')} value={data?.task_name} />}
            {/* {data?.status && <DetailItem name={t('status')} value={data?.status} />} */}
            {data?.employee_registration?.social && <DetailItem name={t('socialOther')} value={data?.employee_registration?.social} />}
            {data?.employee_registration?.salary && <DetailItem name={t('salary')}
              value={new Intl.NumberFormat(
                'en-US',
              ).format(data?.employee_registration?.salary)}
            />}
            {data?.employee_registration?.role && <DetailItem name={t('role')} value={data?.employee_registration?.role} />}
            {/* {data?.created_at && <DetailItem name={t('createdAt')} value={moment(data?.created_at).format('DD-MM-YY')} />} */}
            {data?.employee_registration?.date_contract && <DetailItem
              name={t('dataContract')}
              value={moment(data?.employee_registration?.date_contract).format('DD MMMM YYYY')} />}
            {data?.employee_registration?.date_starting && <DetailItem
              name={t('dataStarting')}
              value={moment(data?.employee_registration?.date_starting).format('DD MMMM YYYY')} />}
            {data?.employee_registration?.bank_score && <DetailItem name={t('bankScore')}
              value={`${data?.employee_registration?.bank_score} / ${data?.employee_registration?.bank?.name}`} />}
          </div>
          {data?.employee_registration?.comment && <><div className={styles.heading}>{t('description')} </div>
            <p className={styles.detailCont}>
              {data?.employee_registration?.comment}
            </p></>}
        </div>
        <div className={styles.filesCont}>
          <div className={styles.heading}> {t('attachedFiles')} </div>
          <div className={styles.passport}>
            <h4 className={styles.subHeading}> {!data?.employee_registration?.passport_other
              ? (data?.employee_registration?.id_back && data?.employee_registration.id_front
                ? t('IdCard') : t('passport'))
              : t('citizenAnotherCountry')}</h4>
            <div className={`d-flex flex-wrap ${styles.cardCont}`}>
              {data?.employee_registration?.passport_back && <FileDownload
                url={`${process.env.REACT_APP_API_KEY}/${data?.employee_registration.passport_back}`}
                status={data?.status}
                title={passport_back_name}
              // deleteItem={() => deleteFile({ passport_back_name: '' })}
              />}
              {data?.employee_registration?.passport_front && <FileDownload
                url={`${process.env.REACT_APP_API_KEY}/${data?.employee_registration.passport_front}`}
                status={data?.status}
                title={passport_front_name}
              // deleteItem={() => { console.log('dgf') }}
              />}
              {data?.employee_registration?.passport_other && <FileDownload
                url={`${process.env.REACT_APP_API_KEY}/${data?.employee_registration.passport_other}`}
                status={data?.status}
                title={passport_other_name}
              // deleteItem={() => { console.log('dgf') }}
              />}
               {data?.employee_registration?.id_back && <FileDownload
                url={`${process.env.REACT_APP_API_KEY}/${data?.employee_registration.id_back}`}
                status={data?.status}
                title={id_back_name}
              // deleteItem={() => { console.log('dgf') }}
              />}
              {data?.employee_registration?.id_back && <FileDownload
                url={`${process.env.REACT_APP_API_KEY}/${data?.employee_registration.id_back}`}
                status={data?.status}
                title={id_front_name}
              // deleteItem={() => { console.log('dgf') }}
              />}
              {data?.employee_registration?.address_other && <FileDownload
                url={`${process.env.REACT_APP_API_KEY}/${data?.employee_registration.address_other}`}
                status={data?.status}
                title={address_other_name}
              // deleteItem={() => { console.log('dgf') }}
              />}
              {data?.employee_registration?.residence_other && <FileDownload
                url={`${process.env.REACT_APP_API_KEY}/${data?.employee_registration.residence_other}`}
                status={data?.status}
                title={residence_other_name}
              // deleteItem={() => { console.log('dgf') }}
              />}
              {data?.employee_registration?.social_other && <FileDownload
                url={`${process.env.REACT_APP_API_KEY}/${data?.employee_registration.social_other}`}
                status={data?.status}
                title={social_other_name}
              // deleteItem={() => { console.log('dgf') }}
              />}
            </div>
          </div>
          {data?.employee_registration?.application && <div className={styles.application}>
            <h4 className={styles.subHeading}>{t('accost')}</h4>
            <div className={`d-flex ${styles.cardCont}`}>
              <FileDownload
                url={`${process.env.REACT_APP_API_KEY}/${data?.employee_registration.application}`}
                status={data?.status}
                title={application}
              // deleteItem={() => { console.log('dgf') }}
              />
            </div>
          </div>}
          {data?.any_document?.length > 0 && <div className={styles.application}>
            <h4 className={styles.subHeading}>{t('anyDocuments')}</h4>
            <div className={`d-flex flex-wrap ${styles.cardCont}`}>
              {data?.any_document?.map((el: any, ind: number) => <FileDownload
                  url={`${process.env.REACT_APP_API_KEY}/${el.document}`}
                  status={data?.status}
                  title={getFileName(el.document)}
                  // deleteItem={() => handleDeleteAnyFile('any_document', ind)}
                  key={ind}
                />)}
            </div>
          </div>}
          {data?.employee_registration?.recording && <div className={styles.application}>
            <h4 className={styles.subHeading}>{t('voiceMessage')}</h4>
            <Recording src={data?.employee_registration?.recording} />
          </div>}
          {/* <ComponentWithBorder img='/passport.png' /> */}
        </div>
      </div>
      <hr />
      <div className={`d-flex justify-content-between align-items-center ${styles.heading}`}>
        <span> {t('accountantAttachedFiles')} </span>
        {(data?.status === 'created' || data?.status === 'dispute') && <CustomButtosWithIcon
          title={t("answer")}
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z"
                fill="#FEFEFE"
              />
            </svg>
          }
          type="submit"
          onClick={() => {
            setSubmit(true)
            const savedData = { ...savedFiles, ...files }
            if ((savedData && savedData.aplication_file && savedData.application_from_tax && savedData.employment_contract && savedData.employment_order)
              || (savedData && savedData.application_from_tax && savedData.employment_contract && savedData.employment_order && data?.employee_registration?.application)) {
              editTasksById({ ...savedData, id: data?.employee_registration?.id, status: 'answeredByAccountant' })
                .then((res: any) => {
                  if (res?.data?.id) {
                    toast.success('Գործընթացը հաջողությամբ կատարվել է');
                    navigate('/profile/dashboard')
                  }
                })
            }
          }}
        />}
      </div>
      <div className={`d-flex flex-wrap ${styles.cardsCont}`}>

        <label className={`${styles.cardUploadItem} d-flex flex-column position-relative`}>
          <input type='file' style={{ display: 'none' }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const file:File | null = (e?.target?.files &&  e?.target?.files?.length>0) ? e?.target?.files[0]:null;
              if (file) {
                const formdata = new FormData();
                formdata.append('file_url', file)
                addImages(formdata)
                  .then((res: any) => {                    
                    if (res.data) {
                      handleSetFile({ employment_contract: res.data })
                    }
                  })
              }
            }} />
          <span className={styles.cardLabel}>{t('employmentContract')}</span>
          {files?.employment_contract
            ? <ComponentWithBorder onClick={() => handleDeleteFile('employment_contract')}
              img={`${process.env.REACT_APP_API_KEY}${files.employment_contract}`} />
            : (employment_contract
              ? <FileDownload url={`${process.env.REACT_APP_API_KEY}/${savedFiles.employment_contract}`}
                status={data?.status}
                title={employment_contract}
                deleteItem={() => handleDeleteFile('employment_contract')}
              />
              : <div className='d-flex flex-column'>
                <DocumentCard />
                <span className={styles.cardTitle}>{t('attachFilesHere')}</span>
                {submit && !files?.employment_contract && <div className='error error-text'>{t('required')}</div>}
              </div>)}
        </label>
        <label className={`${styles.cardUploadItem} d-flex flex-column position-relative`}>
          <input type='file' style={{ display: 'none' }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const file:File | null = (e?.target?.files &&  e?.target?.files?.length>0) ? e?.target?.files[0]:null;
              if (file) {
                const formdata = new FormData();
                formdata.append('file_url', file)
                addImages(formdata)
                  .then((res: any) => {
                    if (res.data) {
                      handleSetFile({ employment_order: res.data })
                    }
                  })
              }
            }} />
          <span className={styles.cardLabel}>{t('employmentOrder')}</span>
          {files?.employment_order
            ? <ComponentWithBorder
              onClick={() => handleDeleteFile('employment_order')}
              img={`${process.env.REACT_APP_API_KEY}${files.employment_order}`} />
            : (employment_order
              ? <FileDownload url={`${process.env.REACT_APP_API_KEY}/${savedFiles.employment_order}`}
                status={data?.status}
                title={employment_order}
                deleteItem={() => handleDeleteFile('employment_order')}
              />
              : <div className='d-flex flex-column'>
                <DocumentCard />
                <span className={styles.cardTitle}>{t('attachFilesHere')}</span>
                {submit && !files?.employment_order && <div className='error error-text'>{t('required')}</div>}
              </div>)}
        </label>
        <label className={`${styles.cardUploadItem} d-flex flex-column position-relative`}>
          <input type='file' style={{ display: 'none' }} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const file:File | null = (e?.target?.files &&  e?.target?.files?.length>0) ? e?.target?.files[0]:null;
            if (file) {
              const formdata = new FormData();
              formdata.append('file_url', file)
              addImages(formdata)
                .then((res: any) => {
                  if (res.data) {
                    handleSetFile({ application_from_tax: res.data })
                  }
                })
            }
          }} />
          <span className={styles.cardLabel}>{t('applicationFromTax')}</span>
          {files?.application_from_tax
            ? <ComponentWithBorder
              onClick={() => handleDeleteFile('application_from_tax')}
              img={`${process.env.REACT_APP_API_KEY}${files.application_from_tax}`} />
            : (application_from_tax
              ? <FileDownload url={`${process.env.REACT_APP_API_KEY}/${savedFiles.application_from_tax}`}
                status={data?.status}
                title={application_from_tax}
                deleteItem={() => handleDeleteFile('application_from_tax')}
              />
              : <div className='d-flex flex-column'>
                <DocumentCard />
                <span className={styles.cardTitle}>{t('attachFilesHere')}</span> 
                {submit && !files?.application_from_tax && <div className='error error-text'>{t('required')}</div>}
              </div>)}
        </label>
        <label className={`${styles.cardUploadItem} d-flex flex-column position-relative`}>
          <input type='file' style={{ display: 'none' }} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const file:File | null = (e?.target?.files &&  e?.target?.files?.length>0) ? e?.target?.files[0]:null;
            if (file) {
              const formdata = new FormData();
              formdata.append('file_url', file)
              addImages(formdata)
                .then((res: any) => {
                  if (res.data) {
                    handleSetFile({ aplication_file: res.data })
                  }
                })
            }
          }} />
          {!data?.employee_registration?.application && <><span className={styles.cardLabel}>{t('accost')}</span>
            {files?.aplication_file
              ? <ComponentWithBorder
                onClick={() => handleDeleteFile('aplication_file')}
                img={`${process.env.REACT_APP_API_KEY}${files.aplication_file}`} />
              : (aplication_file
                ? <FileDownload
                  url={`${process.env.REACT_APP_API_KEY}/${savedFiles.aplication_file}`}
                  status={data?.status}
                  title={aplication_file}
                  deleteItem={() => handleDeleteFile('aplication_file')}
                />
                : <div className='d-flex flex-column'>
                  <DocumentCard />
                  <span className={styles.cardTitle}>{t('attachFilesHere')}</span>
                  {submit && !files?.aplication_file && <div className='error error-text'>{t('required')}</div>}
                </div>)}
          </>}
        </label>
      </div>
      <hr />
      {data?.status === "dispute" && <div className='d-flex flex-column mt-3'>
        <div className={styles.heading}>{t('userComplain')} </div>
        <span className={styles.complain}>{data?.employee_registration?.user_complain}</span>
      </div>}
    </div>
  );
}


