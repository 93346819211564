import React from 'react';
import { Route } from 'react-router-dom';
import {
  PrivatePage,
  Menu,
  AccountantWorkerCompany,
  AccountantWorkerDashboard,
  AccountantWorkerProfile,
  TaskPageAccount

} from '../Pages';
import { EditAccountantWorkerCompany } from '../Pages/AccountantWorker/EditCompany';
import ActivityPage from '../Pages/ActivityPage';
import NotificationPage from '../Pages/NotificationPage';

export const AccountantWorkerRoutes = () => {
  return (
    <React.Fragment>
      <Route path='/profile' element={<PrivatePage><Menu><AccountantWorkerProfile /></Menu></PrivatePage>}></Route>
      <Route path='/profile/dashboard/:id' element={<PrivatePage><Menu><TaskPageAccount /></Menu></PrivatePage>}></Route>
      <Route path='/profile/dashboard' element={<PrivatePage><Menu><AccountantWorkerDashboard /></Menu></PrivatePage>}></Route>
      <Route path='/profile/companies/:id' element={<PrivatePage><Menu><EditAccountantWorkerCompany /></Menu></PrivatePage>}></Route>
      <Route path='/profile/companies' element={<PrivatePage><Menu><AccountantWorkerCompany /></Menu></PrivatePage>}></Route>
      <Route path='/profile/activity/:id' element={<PrivatePage><Menu><ActivityPage /></Menu></PrivatePage>}></Route>
      <Route path='/profile/notification' element={<PrivatePage><Menu><NotificationPage /></Menu></PrivatePage>}></Route>
    </React.Fragment>
  )
}