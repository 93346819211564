import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetTasksByIdQuery, usePrefetch } from '../../../store/services/tasks';
import ChangeSalaryPosition from './ChangeSalaryPosition';
import Dismissal from './Dismissal';
import Holiday from './Holiday';
import Provision from './Provision';
import Registration from './Registration';
import InvoicePage from './InvoicePage';
import Estimate from './Estimate';
import LadingBill from './LadingBill';
import EmployeeBonus from './EmployeeBonus';
import Free from './Free';
import { useAppSelector } from '../../../store';


export default function TaskPage() {
  const { t } = useTranslation();
  const params = useParams();
  const notification = useAppSelector((state) => state?.notificationsContainer?.pushNotification);
  const { data, isLoading } = useGetTasksByIdQuery({ id: params.id });
  const prefetchPage = usePrefetch('getTasksById');
  useEffect(() => {
    prefetchPage({ id: params.id }, { force: true })
  }, []);
  useEffect(() => {    
    if (notification?.data?.type === 'task') {
        prefetchPage({ id: params.id }, { force: true })
    }
}, [notification]);
  const renderTask = () => {
    let type = data?.task_type?.id;
    switch (type) {
      case 1:
        return <Registration data={data} />;
      case 2:
        return <Holiday data={data} />;
      case 3:
        return <Provision data={data} />;
      case 4:
        return <Dismissal data={data} />;
      case 5:
        return <ChangeSalaryPosition data={data} />;
      case 6:
        return <EmployeeBonus data={data} />;
      case 7:
        return <LadingBill data={data} />;
      case 8:
        return <Estimate data={data} />;
      case 9:
        return <InvoicePage data={data} />;
      case 10:
        return <Free data={data} />;
      default:
        break;
    }
  }
  return (
    !isLoading ? <div className={`w-100`}>
      {renderTask()}
    </div> : <></>
  );
}


