import { createApi } from '@reduxjs/toolkit/query/react'
import queryString from 'query-string';
import axiosBaseQuery from './API'
import { ICompaniesData } from '../../types';

export const companiesApi = createApi({
  reducerPath: 'companiesApi',
  tagTypes: ['CompaniesApi'],
  baseQuery: axiosBaseQuery({ baseUrl: process.env.REACT_APP_API_KEY }),
  endpoints: (builder) => ({
    getCompanies: builder.query<any, {}>({
      query: (data:any) => {
        const stringified = queryString.stringify(data);        
        return  {url:`/company/?${stringified}`, method:'GET'}
      },
      providesTags: [{ type: 'CompaniesApi' }],
    }),
    getCompaniesForAccountant: builder.query<any, {}>({
      query: (data:ICompaniesData) => {
        const stringified = queryString.stringify(data);
        return  {url:`/get-companies-for-accountant/?${stringified}`, method:'GET'}
      },
      providesTags: [{ type: 'CompaniesApi' }],
    }),
    getCompanyById: builder.query<any, {}>({
      query: (id:number) => {
        return  {url:`/company/${id}/`, method:'GET'}
      },
      providesTags: [{ type: 'CompaniesApi' }],
    }),
    addCompanies: builder.mutation<any, Partial<any>>({
      query(body) {   
        const {client_id, ...rest} =body 
        const stringified = queryString.stringify({client_id});
        return {
          url: `/company/?${stringified}`,
          method: 'POST',
          data:rest,
        }
      },
      invalidatesTags: [{ type: 'CompaniesApi'}],
    }),   
    editCompanies: builder.mutation<any, Partial<any>>({
      query(data) {        
        return {
          url: `/company/${data.id}/`,
          method: 'PUT',
          data: data,
        }
      },
      invalidatesTags: [{ type: 'CompaniesApi'}],
    }),
    deleteCompanies: builder.mutation<any, {id:number | null}>({
      query({id}) {        
        return {
          url: `/company/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: [{ type: 'CompaniesApi'}],
    }), 
    getMyClients: builder.query<any, {}>({
      query: () => {
        return  {url:`/get-my-clients/`, method:'GET'}
      },
      providesTags: [{ type: 'CompaniesApi' }],
    }),
  }),
  
})

export const { 
  useGetCompaniesQuery, 
  useGetCompanyByIdQuery,
  useDeleteCompaniesMutation, 
  useAddCompaniesMutation, 
  useEditCompaniesMutation,
   useGetMyClientsQuery,
   useGetCompaniesForAccountantQuery,
   usePrefetch
   } = companiesApi
