// import { usersApi } from "../../../entities/users"
// import { setLogIn, setMe } from "../../../entities/auth"
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setMe } from '../slice/authSlice'
import axiosBaseQuery from './API'
const id=localStorage.getItem('rbcId');
const token=localStorage.getItem('rbc-access-token');
export const userApi = createApi({
  reducerPath: '/userApi',
  tagTypes:['user'],
  baseQuery: axiosBaseQuery({ baseUrl: process.env.REACT_APP_API_KEY }),
  endpoints: (builder) => ({
    edit: builder.mutation({
      query: (form) => {        
        return {
        url: `/edit-user/${id}/`,
        method: 'PUT',
        data: form,
       
      }},
      
    }),
    resetPassword: builder.mutation({
      query: (form) => {        
        return {
        url: `/change-password/`,
        method: 'POST',
        data: form,
      }},
    }),
  }),
  // }),
  
  // overrideExisting: false
})

export const {
  useEditMutation,
  useResetPasswordMutation
} = userApi