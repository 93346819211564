import { createApi } from '@reduxjs/toolkit/query/react'
import queryString from 'query-string';
import axiosBaseQuery from './API'


// Define a service using a base URL and expected endpoints
export const notificationsApi = createApi({
  reducerPath: 'notificationsApi',
  tagTypes: ['NotificationsApi'],
  baseQuery: axiosBaseQuery({ baseUrl: process.env.REACT_APP_API_KEY }),
  endpoints: (builder) => ({
    getNotifications: builder.query<any, {}>({
      query: (data:any) => {
        const stringified = queryString.stringify(data);
        return  {url:`/get-my-notifications/?${stringified}`, method:'GET'}
      },
      providesTags: [{ type: 'NotificationsApi' }],
    }),
    changeLastSeen: builder.query<any, {}>({
      query: (data:any) => {
        return  {url:`/change-last-seen/${data.id}/`, method:'GET'}
      },
      providesTags: [{ type: 'NotificationsApi' }],
    }),
    unseenNotificationsCount: builder.query<any, {}>({
        query: (data:any) => {
          return  {url:`/unseen-notifications-count/`, method:'GET'}
        },
        providesTags: [{ type: 'NotificationsApi' }],
      }),
      readNotifications: builder.mutation<any, any>({
      query(body:any) {  
        return {
          url: `/read-notifications/`,
          method: 'POST',
          data: body,
        }
      },
      invalidatesTags: [{ type: 'NotificationsApi'}],
    }),

  }),
})

export const { 
  useGetNotificationsQuery,  
  useChangeLastSeenQuery,  
  useReadNotificationsMutation,
  useUnseenNotificationsCountQuery,
  usePrefetch
} = notificationsApi
