import { useCallback, useEffect, useState } from 'react';
import { Pagination, Stack, Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import styles from './Applications.module.scss';
import { ApplicationItem, NotificationMenu, SearchInput } from '../../../Components';
import { usePrefetch } from '../../../store/services/managers';
import { useGetApplicationQuery } from '../../../store/services/accountants';
import { IApplicationItem } from '../../../types';
interface IFilter {
    user_accept?: boolean,
    accountant_accept?: boolean,
    accountant_comment?: boolean
}
export default function Applications() {
    const [page, setPage] = useState<number>(1);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { pathname, search } = useLocation();
    const [filter, setFilter] = useState<IFilter>({ user_accept: false, accountant_accept: false, accountant_comment: false });
    const { data, isLoading } = useGetApplicationQuery({
        limit: 10,
        page,
        user_accept: filter.user_accept,
        acc_accept: filter.accountant_accept,
        acc_comment: !filter.accountant_comment ,
    });
    const prefetchPage = usePrefetch('getManagers');
    const handleChangeFilter = useCallback((data: IFilter) => {
        const newFilters = { ...filter, ...data };
        const queryStr = queryString.stringify(newFilters);
        setFilter(newFilters);
        navigate((window.location.pathname + "?" + queryStr) as any);
    }, [filter])

    useEffect(() => {
        prefetchPage({ limit: 10, page }, { force: true });
    }, []);
    useEffect(() => {
        const myParams: IFilter = {}
        const queryParams: any = new URLSearchParams(search)
        for (const [key, value] of queryParams) {
            myParams[key as keyof typeof filter] = value === 'true' ? true : false
        }
        setFilter({ ...myParams })
    }, [])
    return (
        <div className={`w-100`}>
            <div className={`w-100 d-none d-lg-flex ${styles.searchNotification}`}>
                <SearchInput onClick={() => console.log('dskfj')} />
                {/* <NotificationMenu /> */}
            </div>
            <div className='d-flex flex-wrap w-100 justify-content-between'>
                <h1 className={styles.pageHeading}>{t("applications")}</h1>
            </div>
            <div className='d-flex flex-wrap flex-md-row gap-3 mb-3 switch-filter'>
                <div className='d-flex gap-2 align-item-center'>
                    <div className={`d-flex align-items-center ${styles.switchLabel}`}>{t('accountantAccept')}</div>
                    <Switch
                        checked={filter.accountant_accept}
                        onChange={() => handleChangeFilter({ accountant_accept: !filter.accountant_accept })}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                    {/* <input
                        type="checkbox"
                        checked={filter.accountant_accept}
                        onChange={() => handleChangeFilter({ accountant_accept: !filter.accountant_accept })}
                    /> */}
                </div>
                <div className='d-flex gap-2 align-item-center'>
                    <div className={`d-flex align-items-center ${styles.switchLabel}`}>{t('userAccept')}</div>
                    <Switch
                        checked={filter.user_accept}
                        onChange={() => handleChangeFilter({ user_accept: !filter.user_accept })}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </div>
                <div className='d-flex gap-2 align-item-center'>
                    <div className={`d-flex align-items-center ${styles.switchLabel}`}>{t('userComment')} </div>
                    <Switch
                        checked={filter.accountant_comment}
                        onChange={() => handleChangeFilter({ accountant_comment: !filter.accountant_comment })}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                    </div>
            </div>
            <div className={`${styles.applicationArray}`}>
                {data?.results?.map((el: IApplicationItem, ind: number) => {
                    return <ApplicationItem
                        accountant_accept={el?.accountant_accept}
                        user_accept={el?.user_accept}
                        id={el?.id}
                        key={ind}
                        img={el?.user?.avatar_image}
                        user_comment={el?.user_comment}
                        company_name={`${el?.user?.user?.first_name} ${el?.user?.user?.last_name}`}
                        accountant_comment={el?.accountant_comment}
                    />
                })}
            </div>
            {data?.count > 10 && (
                <Stack spacing={2} marginTop={10}>
                    <Pagination
                        count={Math.ceil(data?.count / 10)}
                        page={page}
                        showFirstButton
                        showLastButton
                        onChange={(_, page) => {
                            setPage(page);
                        }}
                    />
                </Stack>
            )}
            {!isLoading && data?.length === 0 && <div className={styles.noDataAvailableCont}>
                <img src={"/noDataAvailable.png"} />
            </div>}
        </div>
    );
}


