import { Form, Formik, FormikHelpers } from "formik";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import style from "./AccountantItem.module.scss";
import CustomInput from "../CustomInput";
import CustomButtosWithIcon from "../CustomButtosWithIcon";
import { useAddAccountantsMutation } from "../../store/services/accountants";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Collapse from "@mui/material/Collapse"; 
import { useCallback, useState } from "react";

type Props = {
    img: string;
    cover_image:string,
    name: string;
    profession: string;
    is_write?:boolean;
    id?: number;
};

const AccountantItem = ({ img,cover_image, name,is_write, profession, id }: Props) => {
    const [addAccountantsRequest, { isSuccess }] = useAddAccountantsMutation();
    const { t } = useTranslation();
    const [open, setOpen] = useState(false); 
    const navigate = useNavigate();
    interface Values {
        user_comment: string;
    }
    const SignupSchema = Yup.object().shape({
        user_comment: Yup.string()
            .required(t('required')),
    });
    const handleOpenClose=useCallback(()=>{
        setOpen(!open)
    },[open])
    
    return (
        <div
            className={style.employeeItem}
        >
            <div className="w-100 position-relative">
                <div className={style.imageCover} style={{backgroundImage:`url(${process.env.REACT_APP_API_KEY}${cover_image})`}}></div>
                {img ? (
                    <img
                        className={style.image}
                        src={`${process.env.REACT_APP_API_KEY}${img}`}
                    />
                ) : (
                    <div className={style.imgContainer}>
                        <img className={style.defaultImage} src={"/managerDefaultImg.svg"} />
                    </div>
                )}
            </div>
            <div className={`d-flex flex-column w-100 ${style.nameProfession}`}>
                <div className={`w-100 ${style.name}`}>{name}</div>
                <div className={style.profession}><LocalPhoneIcon/>{profession}</div>
            </div>
            <div className="w-100">
               {!is_write && <div data-toggle="collapse" onClick={handleOpenClose} className={style.messageIcon}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 60 60" data-name="add comment" id="add_comment"><defs><style>
                    </style></defs><path className="cls-1"
                        d="M257.875,246a12,12,0,1,1-12,12A12,12,0,0,1,257.875,246ZM252,260h4v4a2,2,0,0,0,4,0v-4h4a2,2,0,0,0,0-4h-4v-4a2,2,0,0,0-4,0v4h-4A2,2,0,0,0,252,260Z"
                        id="add"
                        transform="translate(-210 -210.031)" />
                        <path
                            className="cls-2"
                            d="M268.109,243.107A18,18,0,0,0,240,258c0,0.388.034,0.768,0.058,1.151l-0.058,0c-1.16,0-4.375-.536-5.358-0.166a5.046,5.046,0,0,0-.847.546c-0.912.91-8.24,10.53-13.295,10.49-0.31,0-2.485.251-2.5-2,0.006-1.352,1.122-1.8,2.433-6.909a20.624,20.624,0,0,0,.532-6.341,2.958,2.958,0,0,0-1.059-1.948c-6.082-4.495-9.906-11-9.906-18.236,0-13.568,13.431-24.566,30-24.566s30,11,30,24.566A20.571,20.571,0,0,1,268.109,243.107Z"
                            id="comment"
                            transform="translate(-210 -210.031)"
                        /></svg>
                </div>}
                <Collapse in={open} > 
                    <Formik
                        initialValues={{
                            user_comment: ''
                        }}
                        validationSchema={SignupSchema}
                        onSubmit={(
                            values: Values,
                            { setSubmitting, resetForm }: FormikHelpers<Values>
                        ) => {
                            addAccountantsRequest({
                                ...values,
                                user: localStorage.getItem('rbcId'),
                                accountant: id,
                                // accountant_comment :''
                            })
                                .then((res: any) => {
                                    if (res?.data?.id) {
                                        toast.success(t('Գործընթացը հաջողությամբ կատարվել է'))
                                    }
                                    handleOpenClose()
                                    resetForm()
                                })
                        }}
                    >
                        {({ errors, touched }: any) => (
                            <Form >
                                <div className={`d-flex flex-wrap justify-content-between ${style.inputs}`}>
                                    <CustomInput name='user_comment' title={t('userComment')} />
                                </div>
                                <div className='d-flex flex-wrap justify-content-between align-items-center w-100'>
                                    <CustomButtosWithIcon
                                        title={t('save')}
                                        icon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z" fill="#FEFEFE" />
                                        </svg>}
                                        type='submit'
                                        onClick={() => console.log()}
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                    </Collapse>
                
            </div>
        </div>
    );
};
export default AccountantItem;
