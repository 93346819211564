import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from '../DeleteDialog.module.scss';
import CustomButtosWithIcon from '../../Components/CustomButtosWithIcon';
interface IProps {
    open?: any,
    handleClose?: () => void,
    handleDelete?: () => void
}
const DeleteTaskDialog = ({ open, handleClose, handleDelete }: IProps) => {
    const { t } = useTranslation();
    return <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <div className={styles["dialogCont"]}>
            <DialogContent>
                <div className={`d-flex flex-column ${styles.heading}`}>
                    {t("deleteTaskDialogTitle")}
                </div>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'center', gap: '2' }}>
                <CustomButtosWithIcon
                    title={t('deleteInfoDialogCancelButtonTitle')}
                    type={'button'}
                    onClick={handleClose}
                    icon=''
                />
                <CustomButtosWithIcon
                    title={t('deleteInfoDialogAcceptButtonTitle')}
                    type={'button'}
                    onClick={handleDelete}
                    icon=''
                />
            </DialogActions>
        </div>
    </Dialog>
}
export default DeleteTaskDialog;