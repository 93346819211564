import { Link } from "react-router-dom";
import style from "./CompanyItem.module.scss";
import { useTranslation } from "react-i18next";

type Props = {
  img: string;
  id?: number;
  name?: string;
  profession: string;
  onClick?: () => void;
  managers?: any;
  to: string;
  accountant_worker?: any;
  tarif?:number,
  is_active?:boolean
};

const CompanyItem = ({
  img,
  name,
  onClick,
  managers,
  tarif,
  id,
  to,
  accountant_worker,
  is_active
}: Props) => {
  const filteredArr = localStorage.getItem("rbcRole") === "1"? accountant_worker?.slice(0, 3): managers?.slice(0, 3);
  const array=localStorage.getItem("rbcRole") === "1"?accountant_worker:managers
  const {t} =useTranslation()
  return (
    <Link onClick={onClick} className={style.companyItem} to={to}>
      {!is_active && <div className={style.deactivated}>{t('deactivated')}</div>}
      <div className={`${style.tarif} ${tarif===2?style.premiumTarif:''}`}>{tarif===1?"Basic":"Premium"}</div>
      {img ? (
        <img className={style.image} src={`${img}`} />
      ) : (
        <div className={style.imgContainer}>
          <img className={style.defaultImage} src={"/managerDefaultImg.svg"} />
        </div>
      )}
      <div className={`d-flex flex-column ${style.nameProfession}`}>
        <div className={style.name}>
          <span>{name}</span>
        </div>

          <div>
            {array.length === 1 ? (
              <div className={style.managersCont}>
                {array?.map((elem: any, ind: number) => {
                  return (
                    <div className={`${style.oneManagerCont} w-100`}>
                      {elem.avatar_image ? (
                        <img
                          src={`${process.env.REACT_APP_API_KEY}${elem.avatar_image}`}
                        />
                      ) : (
                        <div className={style.managerDefault}>
                          <img src={"/managerDefaultImg.svg"} />
                        </div>
                      )}
                      <div className={`${style.managerName}`}>
                        {elem?.user?.first_name} {elem?.user?.last_name}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : array.length > 1 && array.length < 4 ? (
              <div className={style.managersCont}>
                {array?.map((elem: any, ind: number) => {
                  return (
                    <div
                      className={`${style.managerAvatar}`}
                      style={{
                        zIndex: ind,
                        // position: "absolute",
                        marginLeft: ind !== 0 ? `-10px` : "",
                      }}
                    >
                      {elem.avatar_image ? (
                        <img
                          src={`${process.env.REACT_APP_API_KEY}${elem.avatar_image}`}
                        />
                      ) : (
                        <div className={style.managerDefault}>
                          <img src={"/managerDefaultImg.svg"} />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className={style.managersCont}>
                {filteredArr?.map((elem: any, ind: number) => {
                  return (
                    <div
                      className={`${style.managerAvatar}`}
                      style={{
                        zIndex: ind,
                        // position: "absolute",
                        marginLeft: ind !== 0 ? `-10px` : "",
                      }}
                    >
                      <img
                        src={
                          elem.avatar_image
                            ? `${process.env.REACT_APP_API_KEY}${elem.avatar_image}`
                            : "/managerDefaultImg.svg"
                        }
                      />
                    </div>
                  );
                })}
                {array.length > 3 && (
                  <div className={style.managersCount}>
                    +{array.length - 3}
                  </div>
                )}
              </div>
            )}
          </div>
      </div>
    </Link>
  );
};
export default CompanyItem;
