import { useState, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { onMessageListener } from './firebase';
import { useAppDispatch, useAppSelector } from '../store';
import { setNotification } from '../store/slice/notificationSlice';
import { useTranslation } from 'react-i18next';

const Notification = () => {
  // const [notification, setNotification] = useState({ title: '', body: '' });
  const { t } = useTranslation();
  const auth = useAppSelector((state) => state.auth);
  const notification = useAppSelector((state) => state?.notificationsContainer?.pushNotification);
  const notify = () => toast(<ToastDisplay />, {
    className: 'toaster-cont',
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  console.log(notification?.notification, 'notification?.notificationnotification?.notificationnotification?.notification');
  function ToastDisplay() {
    const splitedBody = notification?.notification?.body.includes('->') ? notification?.notification?.body.split('->') : notification?.notification?.body
    const statusFrom = splitedBody[0].includes('answeredByAccountant')
      ? 'answeredByAccountant'
      : (notification?.notification?.body.includes('created')
        ? 'created'
        : (notification?.notification?.body.includes('dispute') ? 'dispute' : 'done'));
    const statusTo = splitedBody[1].includes('answeredByAccountant')
      ? 'answeredByAccountant'
      : (notification?.notification?.body.includes('created')
        ? 'created'
        : (notification?.notification?.body.includes('dispute') ? 'dispute' : 'done'))
    const newBody = notification?.notification?.body.includes('->') ? notification?.notification?.body?.replace(statusFrom, t(`${statusFrom}`)) : notification?.notification?.body
    // description.replace('<<X<<', new Intl.NumberFormat(
    //   'en-US',
    // ).format(data?.employee_provision?.salary))
    return (
      <a href={`/profile/dashboard/${notification?.data?.task_id}`}>
        <p><b>{notification?.notification?.title}</b></p>
        <p>{newBody?.replace(statusTo, t(`${statusTo}`))}</p>
      </a>
    );
  };
  useEffect(() => {
    const hrefSplit = window.location.href.split('/');
    console.log(hrefSplit.length, 'hrefSplit.lengthhrefSplit.length');
    if (+notification?.data?.user_id === auth?.me?.id) {
      if ((!window.location.href.includes('profile/dashboard/') && hrefSplit.length !== 6)
        || (window.location.href.includes('profile/dashboard/') && hrefSplit.length !== 6)) {
        notify()
      }
    }
  }, [notification?.data?.user_id, auth?.me?.id])

  onMessageListener()
    .then((payload) => {
      dispatch(setNotification(payload))
    })
    .catch((err) => console.log('failed: ', err));

  return (
    <Toaster
      toastOptions={{
        success: {
          style: {
            background: 'green',
          },
        },
        style: {
          color: '#FFFFFF',
          backgroundColor: '#02BC7D',
          background: '#02BC7D',
          zIndex: '9999999999999999999999'
        },
      }}
    />
  )
}

export default Notification
