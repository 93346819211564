const Changepassword = () => <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
  <g clip-path="url(#clip0_369_5179)">
    <path d="M17.3606 2.48305C16.9739 2.44779 16.6319 2.73273 16.5966 3.11945L16.5034 4.14141C14.9228 1.65916 12.1791 0 8.99996 0C5.57993 0 2.56595 1.95195 1.06977 4.88159C0.893141 5.22742 1.03032 5.65095 1.37615 5.82757C1.72206 6.0042 2.14551 5.86705 2.32214 5.52118C3.58836 3.04182 6.13111 1.40625 8.99996 1.40625C11.6148 1.40625 13.9745 2.76789 15.3264 4.90106L14.3239 4.18504C14.0079 3.95937 13.5687 4.03256 13.3431 4.34855C13.1174 4.66457 13.1906 5.10367 13.5066 5.32937L16.5467 7.50066C16.9923 7.81829 17.6068 7.52752 17.6556 6.99233L17.997 3.24714C18.0323 2.86038 17.7474 2.51831 17.3606 2.48305ZM16.6238 12.1724C16.2779 11.9958 15.8544 12.133 15.6778 12.4788C14.4116 14.9582 11.8688 16.5938 8.99996 16.5938C6.38515 16.5938 4.02539 15.2321 2.67349 13.0989L3.67604 13.815C3.99206 14.0406 4.43116 13.9674 4.65686 13.6515C4.88253 13.3354 4.80934 12.8963 4.49335 12.6706L1.45322 10.4993C1.01309 10.1849 0.393571 10.467 0.344352 11.0077L0.00294988 14.7529C-0.0323118 15.1396 0.25263 15.4817 0.639313 15.5169C1.02652 15.5521 1.36814 15.2669 1.40336 14.8805L1.49653 13.8586C3.07708 16.3408 5.82082 18 8.99996 18C12.42 18 15.434 16.0481 16.9302 13.1184C17.1068 12.7726 16.9696 12.3491 16.6238 12.1724Z" fill="#FEFEFE" />
  </g>
  <defs>
    <clipPath id="clip0_369_5179">
      <rect width="18" height="18" fill="white" />
    </clipPath>
  </defs>
</svg>
export default Changepassword