import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../../App.module.scss';
import { useTranslation } from 'react-i18next';
import Finished from '../../Components/SvgCompoents/Finished';
import { CustomButton } from '../../Components';
import RegisSvg from '../../Components/SvgCompoents/RegisSvg';


export default function Congratulations() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  return (
    <div
      className={`d-flex justify-content-center align-items-center ${styles.cont}`}
    >
      <div className={styles.RegisSvg}>
        <RegisSvg />
      </div>
      <div className={styles.formCont}><div
        className={`d-flex flex-column align-items-center ${styles.congratulations}`}
      >
        <Finished />
        <div className={`d-flex flex-column ${styles.text}`}>
          {t("congratulations")}
        </div>
        <p className={`d-flex flex-column ${styles.desciption}`}>
          {t("profileSuccessfullyCreated")}
        </p>
        <CustomButton
          title={t("goToHome")}
          type="button"
          onClick={() => {
            navigate("/dashboard");
          }}
        />
      </div>
      </div>
    </div>
  );
}


