import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomInput, CustomButton } from '../../Components';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from "yup";
import styles from '../../App.module.scss';
import RegisSvg from '../../Components/SvgCompoents/RegisSvg';
import { useTranslation } from 'react-i18next';
import { useCheckEmailMutation, useSignUpMutation } from '../../store/services/auth';
import Finished from '../../Components/SvgCompoents/Finished';
import { requestForToken } from '../../firebaseNotifications/firebase';

export default function AsAccountant() {
  const navigate = useNavigate();
  const [signUpRequest, { isSuccess }] = useSignUpMutation();
  const [checkEmail] = useCheckEmailMutation();
  const { i18n } = useTranslation();
  const [showCode, setShowCode] = useState(false);
  const [showCongratulations, setShowCongratulations] = useState(false);

  interface Values {
    company_name: string;
    tin: string;
    phone_number: string;
    email: string;
    password: string;
    address: string;
  }
  const { t } = useTranslation();
  const SignupSchema = Yup.object().shape({
    company_name: Yup.string()
      .required(t('required')),
    tin: Yup.string()
      .required(t('required')),
      phone_number: Yup.string()
      .required(t("required"))
      .test(
        'unique',
        t("phoneNumberError").toString(),
        (value:any) => /^[0-9\b]+$/.test(value)
      ),
    email: Yup.string().trim().email(t('emailError'))
      .required(t('required')),
    password: Yup.string().trim()
      .required(t('required')),
      address: Yup.string().trim()
      .required(t('required'))
  });
  const handleshowCode = useCallback(() => setShowCode(true), []);
  const handleShowCongratulations = useCallback(() => setShowCongratulations(true), [])
  const onSubmit = async (
    values: Values,
    { setSubmitting }: FormikHelpers<Values>
  ) => {
    await checkEmail({ email: values.email })
      .then((res: any) => {
        if (res?.error) return
        if (showCode) {
          signUpRequest({
            ...values,
            email: values.email.trim(),
            password: values.password.trim(),
            role: 1,
            first_name: '',
            last_name: '',
            birth_date: new Date(),
            legal_type: 0,
            language: i18n.language==='am'?'hy':i18n.language,
            avatar_image: "",
            cover_image: ""
          })
            .then((res: any) => {
              if (res.data && res.data.access) {
                requestForToken();
                navigate('/profile')
              }
            });
        } else {
          handleshowCode()
        }
      })
  }

  return (
    <div className={`d-flex justify-content-center align-items-center ${styles.cont}`}>
      <div className={styles.RegisSvg}>
        <RegisSvg />
      </div>
      <div className={styles.formCont}>
        <div className={styles.heading}>{t('signUpAsAccountant')}</div>
        <Formik
          initialValues={{
            company_name: '',
            tin: '',
            phone_number: '',
            email: '',
            password: '',
            address: ''
          }}
          validationSchema={SignupSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched }: any) => (
            <Form >
              {showCode ? <>
                <CustomInput name="email_code" title={t("code")} />
                <CustomButton
                  title={t("signUp")}
                  type="submit"
                  onClick={() => console.log('log')}
                />
              </>
                : <>
                  <div className={`d-md-flex justify-content-between flex-wrap ${styles.inLine}`}>
                    <CustomInput name='company_name' title={t("companyName")} />
                    <CustomInput name='tin' title={t("HVHH")} />
                  </div>
                  <CustomInput name='address' type="text" title={t("address")} />
                  <CustomInput name='phone_number' title={t("phoneNumber")} />
                  <CustomInput name='email' title={t("emailAdress")} />
                  <CustomInput name='password' type='password' title={t("password")} />
                  <CustomButton title={t('signIn')} type='submit' />
                </>}
            </Form>
          )}
        </Formik>
      </div>

    </div>

  );
}


