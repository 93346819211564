
import { useCallback, useEffect, useState } from 'react';
import moment from "moment";
import { DayPicker } from 'react-day-picker';
import en from 'date-fns/locale/en-US';
import am from 'date-fns/locale/hy';
import ru from 'date-fns/locale/ru';
import 'react-day-picker/dist/style.css';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import styles from './CustomDayPicker.module.scss';

function CustomDayPicker({ mode, selected, setSelected, withLabel = true, className,disabled, title, submited, ...props }: any) {
  const [showPicker, setShowPicker] = useState(false);
  const { t, i18n } = useTranslation();
  if (i18n.language === 'am') {
    require('moment/locale/hy-am')
  }
  if (i18n.language === 'ru') {
    require('moment/locale/ru')
  }
  const handleOpenPicker = useCallback(() => {
    setShowPicker(true);
  }, [])

  return (
    <>
      <div className={`${styles.cont} d-flex flex-column customDayPicker ${className}`}>
        <div className='d-flex justify-content-between align-items-center'>
          {withLabel && <div className='d-flex'>
            <span className={styles.title}>{title} </span>
            {submited && !selected && <span className='error'>*</span>}
          </div>}
        </div>
        <div className={styles.custom_picker}>
          {/* {showPicker && <div onClick={() => setShowPicker(false)} className={styles.close}>x</div>} */}
          <div className='d-flex gap-2' onClick={handleOpenPicker}>
            <input
              // placeholder={t("date")}
              type='text'
              value={selected?.from
                ? `${moment(selected?.from).format('DD/MM/YYYY')} - ${moment(selected?.to).format('DD/MM/YYYY')}`
                : (selected ? `${moment(selected).format('DD/MM/YYYY')}` : '')
              }

              readOnly={true}
              autoComplete='off'
            />
          </div>
          {showPicker && <div className={styles.day_picker_cont}>
            <DayPicker
              disabled={disabled}
              mode={mode}
              selected={selected}
              onSelect={(v: any) => {
                setSelected(v);
                if (mode === 'single') {
                  setShowPicker(false)
                }
              }}
              weekStartsOn={1}
              locale={i18n.language === 'ru' ? ru : i18n.language === 'am' ? am : en}
            />
          </div>}
        </div>
        {submited && !selected && <div className='error error-text'>{t('required')}</div>}
      </div>
      {showPicker && <div onClick={() => setShowPicker(false)} className={styles.custom_picker_layer}></div>}

    </>
  );
}
export default CustomDayPicker;