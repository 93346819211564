import BackgroundSection from "../../Components/LadigPageCopoents/BackgroundSection";
import BackgroundSectionAvailable from "../../Components/LadigPageCopoents/BackgroundSectionAvailable";
import Footer from "../../Components/LadigPageCopoents/Footer";
import ImageText from "../../Components/LadigPageCopoents/ImageText";
import Interface from "../../Components/LadigPageCopoents/Interface";
import LandingPageHeader from "../../Components/LadigPageCopoents/LandingPageHeader";
import OurTime from "../../Components/LadigPageCopoents/OurTime";
import Questions from "../../Components/LadigPageCopoents/Questions";
import SectionFeatures from "../../Components/LadigPageCopoents/SectionFeatures";
import SectionOne from "../../Components/LadigPageCopoents/SectionOne";
import Subscribe from "../../Components/LadigPageCopoents/Subscribe";
import TextImage from "../../Components/LadigPageCopoents/TextImage";

export default function LandingPage() {
    return(
        <div>
            <LandingPageHeader/>
            <SectionOne/>
            <SectionFeatures/>
            <ImageText/>
            <TextImage/>
            <BackgroundSection/>
            <Interface/>
            <OurTime/>
            <Questions/>
            <BackgroundSectionAvailable/>
            <Subscribe/>
            <Footer/>
        </div>
    )
}