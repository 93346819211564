import { useParams } from "react-router-dom";
import * as Yup from "yup";
import Collapse from "@mui/material/Collapse";
import { useTranslation } from "react-i18next";
import SendIcon from '@mui/icons-material/Send';
import ShowMore from 'react-show-more-button';
import style from "./ApplicationItem.module.scss";
import { useEditApplicationMutation } from "../../store/services/accountants";
import { Form, Formik, FormikHelpers } from "formik";
import { toast } from "react-toastify";
import CustomInput from "../CustomInput";
import CustomButtosWithIcon from "../CustomButtosWithIcon";
import { RootState, useAppSelector } from "../../store";
import { useCallback, useState } from "react";

type Props = {
    id: number,
    img: string;
    user_comment: string;
    company_name?: string;
    accountant_comment?: string;
    accountant_accept?: boolean;
    user_accept?: boolean
};

const ApplicationItem = ({ id,
    img,
    user_comment,
    company_name,
    accountant_comment,
    accountant_accept,
    user_accept
}: Props) => {
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const [seeMoreCommentUser, setSeemoreCommentUser] = useState<boolean>(true);
    const [seeMoreCommentAccountant, setSeemoreCommentAccountant] = useState<boolean>(true);
    const auth: any = useAppSelector((state) => state.auth);
    const SignupSchema = Yup.object().shape({
        accountant_comment: Yup.string()
            .required(t('required')),
    });
    interface Values {
        accountant_comment: string;
    }
    const [editAccountantsRequest, { isSuccess: isEditSuccess }] = useEditApplicationMutation();
    const handleSeemoreForUser = useCallback((val: boolean) => {
        setSeemoreCommentUser(val)
    }, [])
    const handleSeemoreForAccountant = useCallback((val: boolean) => {
        setSeemoreCommentAccountant(val)
    }, [])
    const handleOpenClose = useCallback(() => {
        setOpen(!open)
    }, [open])
    
    return (
        <div
            className={`${style.applicationItem} d-flex flex-column`}
        >
            <div className={`d-flex align-items-center flex ${style.nameImg}`}>
                {img ? (
                    <img className={style.image} src={`${process.env.REACT_APP_API_KEY}${img}`} />
                ) : (
                    <div className={style.imgContainer}>
                        <img className={style.defaultImage} src={"/companyDefaultImg.svg"} />
                    </div>
                )}
                <span className={style.name}>{company_name}</span>
            </div>
            <div className={`d-flex flex-column gap-2 ${style.commentCont}`}>
                <div className="d-flex flex-column mb-2">
                    <span className={style.commentLabel}>{t('userComment')}</span>
                    {user_comment && <div className={style.userComment}>
                        <ShowMore
                            onChange={handleSeemoreForUser}
                            button={<span className={style.seeMore}>{seeMoreCommentUser ? t('seeMore') : t('seeLess')}</span>}
                            maxHeight={103}>
                            <p>{user_comment}</p>
                        </ShowMore>
                    </div>}

                </div>
                <div className="d-flex flex-column mb-2">
                    <span className={style.commentLabel}>{t('accountantComment')} </span>
                    {accountant_comment && <div className={style.userComment}>
                        <ShowMore
                            onChange={handleSeemoreForAccountant}
                            button={<span className={style.seeMore}>{seeMoreCommentAccountant ? t('seeMore') : t('seeLess')}</span>}
                            maxHeight={103}>
                            <p>{accountant_comment}</p>
                        </ShowMore>
                    </div>}
                </div>

                {accountant_accept && auth?.me?.user_role === 3 && <span className={style.accept}>{t('accountantAccept')}</span>}
                {user_accept && auth?.me?.user_role === 1 && <span className={style.accept}>{t('userAccept')}</span>}
                <div className="w-100">
                    <div className={`d-flex gap-4 ${style.buttonCont}`}>
                        {auth?.me?.user_role === 1
                            ? (!accountant_accept
                                ? <CustomButtosWithIcon
                                    title={t('userAcceptApplication')}
                                    icon={''}
                                    type='button'
                                    onClick={() => editAccountantsRequest({
                                        accountant_accept: true,
                                        id
                                    })}
                                />
                                : <div className={style.accepted}>{t('accepted')}</div>)
                            : null}
                        {auth?.me?.user_role === 3
                            ? (!user_accept
                                ? <CustomButtosWithIcon
                                    title={t('accountantsAcceptApplication')}
                                    icon={''}
                                    type='button'
                                    onClick={() => editAccountantsRequest({
                                        user_accept: true,
                                        id
                                    })}
                                />
                                : <div className={style.accepted}>{t('accepted')}</div>)
                            : null}
                        {!accountant_comment && auth?.me?.user_role === 1 && <div onClick={handleOpenClose} className={style.messageIcon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 60 60" data-name="add comment" id="add_comment"><defs><style>
                            </style></defs><path className="cls-1"
                                d="M257.875,246a12,12,0,1,1-12,12A12,12,0,0,1,257.875,246ZM252,260h4v4a2,2,0,0,0,4,0v-4h4a2,2,0,0,0,0-4h-4v-4a2,2,0,0,0-4,0v4h-4A2,2,0,0,0,252,260Z"
                                id="add"
                                transform="translate(-210 -210.031)" />
                                <path
                                    className="cls-2"
                                    d="M268.109,243.107A18,18,0,0,0,240,258c0,0.388.034,0.768,0.058,1.151l-0.058,0c-1.16,0-4.375-.536-5.358-0.166a5.046,5.046,0,0,0-.847.546c-0.912.91-8.24,10.53-13.295,10.49-0.31,0-2.485.251-2.5-2,0.006-1.352,1.122-1.8,2.433-6.909a20.624,20.624,0,0,0,.532-6.341,2.958,2.958,0,0,0-1.059-1.948c-6.082-4.495-9.906-11-9.906-18.236,0-13.568,13.431-24.566,30-24.566s30,11,30,24.566A20.571,20.571,0,0,1,268.109,243.107Z"
                                    id="comment"
                                    transform="translate(-210 -210.031)"
                                /></svg>
                        </div>}
                    </div>
                    <Collapse in={open} timeout="auto"
                        unmountOnExit>
                        {!accountant_comment && <Formik
                            initialValues={{
                                accountant_comment: ''
                            }}
                            validationSchema={SignupSchema}
                            onSubmit={(
                                values: Values,
                                { setSubmitting }: FormikHelpers<Values>
                            ) => {
                                editAccountantsRequest({
                                    ...values,
                                    id
                                })
                                    .then((res: any) => {
                                        if (res?.data?.id) {
                                            toast.success(t('Գործընթացը հաջողությամբ կատարվել է'))
                                            handleOpenClose()
                                        }
                                    })
                            }}
                        >
                            {({ errors, touched }: any) => (
                                <Form >
                                    <div className={`d-flex justify-content-between align-items-end ${style.inputs}`}>
                                        <CustomInput name='accountant_comment' title={t('userComment')} />
                                        <CustomButtosWithIcon
                                            title={''}
                                            icon={<SendIcon />}
                                            type='submit'
                                            onClick={() => console.log()}
                                        />
                                        {/* <button className={style.approveBtn} type='submit'><SendIcon /></button> */}
                                    </div>
                                </Form>
                            )}
                        </Formik>}
                    </Collapse>

                </div>
            </div>
        </div>
    );
};
export default ApplicationItem;
