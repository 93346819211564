import { useTranslation } from 'react-i18next';
import { Route, useNavigate, useParams, useRoutes } from 'react-router-dom';
import moment from 'moment';
import * as Yup from "yup";
import DeleteIcon from '@mui/icons-material/Delete';
import { Form, Formik, FormikHelpers } from 'formik';
import styles from './TaskPage.module.scss';
import { CustomButtosWithIcon, CustomInput, CustomizedTables, DetailItem, FileDownload } from '../../../Components';
import DocumentCard from '../../../Components/SvgCompoents/DocumentCard';
import ComponentWithBorder from '../../../Components/ComponentWithBorder';
import Recording from '../../../Components/Recording';
import { useEditEmployeeBonusByIdMutation, } from '../../../store/services/tasks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAddImagesMutation } from '../../../store/services/photo';
import { Collapse } from '@mui/material';
import { getFileName } from '../../../Utils';
import { IFiles, ITaskPage } from '../../../types';
import DeleteTaskDialog from '../../../Dialogs/DeleteTaskDialog';
import { toast } from 'react-toastify';
interface DataTask {
    data: ITaskPage
}
export default function EmployeeBonus({ data }: DataTask) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const params = useParams();
    const [files, setFiles] = useState<IFiles>({});
    const [submit, setSubmit] = useState<boolean>(false);
    const [status, setStatus] = useState<string>('done');
    const [complain, setComplain] = useState<string>('');
    const [savedFiles, setSavedFiles] = useState<IFiles>({});
    const navigate = useNavigate()
    const [editEmployeeBonusById, { isSuccess }] = useEditEmployeeBonusByIdMutation();
    // const { data, isLoading } = useGetTasksByIdQuery({ id: params.id });
    const property = useMemo(() => {
        return ["name", "count", "price", "vat", "sum"]
    }, [])
    const file = useMemo(() => {
        return getFileName(data?.employee_bonus?.file)
    }, [data?.employee_bonus?.file]);
    const acc_file = useMemo(() => {
        return getFileName(data?.employee_bonus?.acc_file)
    }, [data?.employee_bonus?.acc_file]);
    const [addImages] = useAddImagesMutation();
    const handleSetFile = useCallback((data: IFiles) => {
        setFiles({ ...files, ...data })
    }, [files])
    const handleChangeStatus = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setStatus(e.target.value)
    }, [status])
    const handleComplain = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComplain(e.target.value)
    }, [])

    const handleDeleteAnyFile = useCallback((name: keyof typeof savedFiles, ind?: number) => {
        if (savedFiles[name]) {
            setSavedFiles((prev: any) => {
                const prevFiles = { ...prev };
                const newArray = prevFiles[name].filter((el: any, index: number) => {
                    return index !== ind
                })
                return { ...prevFiles, [name]: newArray }
            })
        }
    }, [savedFiles])
    const handleDeleteAnyFileSelected = useCallback((name: string, ind?: number) => {
        setFiles((prev: any) => {
            const prevFiles = { ...prev };
            const newArray = prevFiles[name].splice(ind, 1);
            return newArray
        })
    }, [])
    const handleDeleteFile = useCallback((name: string, id?: number) => {
        setFiles((prev: any) => {
            const prevFiles = { ...prev };
            delete prevFiles[name];
            return prevFiles
        })
    }, [])

    // useEffect(() => {
    //     if (data?.employee_bonus) {
    //       const { files } = data?.employee_bonus
    //       setSavedFiles({ files: files.map((el: any) => el.url) })
    //     }
    //   }, [data])
    const SignupSchema = Yup.object().shape({
        comment: Yup.string(),
    });
    interface Values {
        comment: string
    }
    const handleOpenDeleteTask = useCallback(() => { setOpen(true) }, []);
    const handleCloseDeleteTask = useCallback(() => { setOpen(false) }, []);
    const deleteTask = () => {
        editEmployeeBonusById({ id: data?.employee_bonus?.id, status: 'deleted' })
    }
    useEffect(() => {
        if (isSuccess) {
            toast.success(t('taskHasBeenDeleted'))
            handleCloseDeleteTask()
            navigate('/profile/dashboard')
        }
    }, [isSuccess])
    return (
        <div className={`w-100`}>
            <DeleteTaskDialog
                open={open}
                handleClose={handleCloseDeleteTask}
                handleDelete={deleteTask}
            />
            <div className='d-flex justify-content-between flex-nowrap'>
                <div className={`${styles.taskTypeHeading}`}>{t(`${data?.task_type?.name}`)} </div>
                <div className='d-flex align-items-center gap-2'>
                    <div className={`${styles.taskStatus} ${styles[data?.status]}`}>{t(`${data?.status}`)}</div>
                    {data?.status === 'created' && <div onClick={handleOpenDeleteTask} className={`${styles.taskStatus} ${styles.deleteTask}`}>{t('delete')} <DeleteIcon /></div>}
                </div>
            </div>
            <div className='d-flex justify-content-between flex-wrap'>
                <div className='d-flex justify-content-between flex-wrap'>
                    <div>
                        <div className={styles.heading}>{t('detail')} </div>
                        <div className={styles.detailCont}>
                            {data?.task_name && <DetailItem name={t('taskName')} value={data?.task_name} />}
                            <DetailItem name={t('taskName')} value={data?.employee_bonus?.type === 1 ? t('keep') : t("bonus")} />
                            {data?.employee_bonus?.employee_name && <DetailItem name={t('employeeName')} value={data?.employee_bonus?.employee_name} />}
                            {data?.employee_bonus?.price && <DetailItem name={t('price')} value={new Intl.NumberFormat(
                                'en-US',
                            ).format(data?.employee_bonus?.price)} />}

                            {data?.recording && <div className={styles.application}>
                                <h4 className={styles.subHeading}>{t('voiceMessage')}</h4>
                                <Recording src={data?.recording} />
                            </div>}
                        </div>
                    </div>
                </div>
                <div className={styles.filesCont}>
                    {data?.employee_bonus?.file && <div className={styles.application}>
                        <h4 className={styles.subHeading}>{t('document')}</h4>
                        <div className={`d-flex ${styles.cardCont}`}>
                            <FileDownload
                                url={`${process.env.REACT_APP_API_KEY}/${data?.employee_bonus.file}`}
                                status={data?.status}
                                title={file}
                            />
                        </div>
                    </div>}
                </div>
            </div>
            {/* 
            {(data?.status === 'created' || data?.status === 'dispute') && <><hr />
                <Formik
                    initialValues={{
                        comment: data.employee_registration?.comment ? data.employee_registration?.comment : ''
                    }}
                    validationSchema={SignupSchema}
                    onSubmit={(
                        values: Values,
                        { setSubmitting }: FormikHelpers<Values>
                    ) => {
                        const saved = savedFiles?.files ? savedFiles?.files.map((el) => ({ url: el })) : [];
                        const newFiles = files?.files ? files?.files.map((el) => ({ url: el })) : []
                        const sendFiles = (saved && newFiles) ? [...saved, ...newFiles] : [];
                        const { comment, ...rest } = values;
                        editEmployeeBonusById({ ...data?.employee_bonus, ...values, id: data?.employee_bonus?.id, files: sendFiles })
                            .then((res: any) => {
                                if (res?.data?.id) {
                                    toast.success('Գործընթացը հաջողությամբ կատարվել է');
                                    setFiles({})
                                }
                            })
                    }}
                >
                    {({ errors, touched, values }: any) => (
                        <Form >
                            <div className='d-flex justify-content-between'>
                                <div className={styles.heading}> {t('makeChanges')} </div>
                                {(data?.status === 'created' || data?.status === 'dispute') && <CustomButtosWithIcon
                                    title={t("save")}
                                    icon={
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                        >
                                            <path
                                                d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z"
                                                fill="#FEFEFE"
                                            />
                                        </svg>
                                    }
                                    type="submit"
                                />}
                            </div>
                            <CustomInput readonly={data?.status === 'answeredByAccountant' || data?.status === 'done'}
                                name='comment'
                                title={t('description')}
                                type='string'
                                className='taskInput'
                            />
                            <div>
                                <div className={`${styles.sectionHeading} ${styles.cardContHeading}`}>{t('attachYourDocument')}</div>
                                <div className={`d-flex align-items-center ${styles.documentLabel}`}>
                                    <label className={`${styles.cardUploadItem} d-flex flex-column position-relative`}>
                                        <input type='file' style={{ display: 'none' }}
                                            disabled={data?.status === 'answeredByAccountant' || data?.status === 'done'}
                                            onChange={(e: any) => {
                                                const file: any = e?.target?.files[0];
                                                if (file) {
                                                    const formdata = new FormData();
                                                    formdata.append('file_url', file)
                                                    addImages(formdata)
                                                        .then((res: any) => {
                                                            if (res.data) {
                                                                let any_document = files && files.files ? [...files.files] : [];
                                                                any_document.push(res.data)
                                                                handleSetFile({ files: any_document })
                                                            }
                                                        })
                                                }
                                            }}
                                        />
                                        <DocumentCard />
                                    </label>
                                </div>
                                {savedFiles?.files?.map((el: any, ind: number) => <FileDownload
                                    url={`${process.env.REACT_APP_API_KEY}/${el}`}
                                    status={data?.status}
                                    title={getFileName(el)}
                                    deleteItem={() => handleDeleteAnyFile('files', ind)}
                                    key={ind}
                                />
                                )}
                                {files?.files && files?.files?.length > 0 && <div className='d-flex gap-2'>
                                    {files?.files.map((el: any, ind: number) => {
                                        return <ComponentWithBorder
                                            key={ind}
                                            img={`${process.env.REACT_APP_API_KEY}${el}`}
                                            onClick={() => handleDeleteAnyFileSelected('files', el.id)} />
                                    })}
                                </div>}
                            </div>
                        </Form>)}
                </Formik>
            </>} */}
            {data.status !== "created" && <>
                <hr />
                <div className={styles.heading}> {t('accountantAttachedFiles')} </div>
                <div className={`d-flex flex-wrap ${styles.cardsCont}`}>
                    <label className={`${styles.cardUploadItem} d-flex flex-column position-relative`}>
                        <input type='file' style={{ display: 'none' }}
                            onChange={(e: any) => {
                                const file: any = e?.target?.files[0];
                                if (file) {
                                    const formdata = new FormData();
                                    formdata.append('file_url', file)
                                    addImages(formdata)
                                        .then((res: any) => {
                                            if (res.data) {
                                                handleSetFile({ acc_file: res.data })
                                            }
                                        })
                                }
                            }} />
                        <span className={styles.cardLabel}>{t('document')}</span>
                        {files?.acc_file
                            ? <ComponentWithBorder
                                onClick={() => handleDeleteFile('acc_file')}
                                img={`${process.env.REACT_APP_API_KEY}${files.acc_file}`}
                            />
                            : (acc_file
                                ? <FileDownload url={`${process.env.REACT_APP_API_KEY}/${data?.employee_bonus?.acc_file}`}
                                    status={data?.status}
                                    title={acc_file}
                                    deleteItem={() => { console.log('dgf') }}
                                />
                                : <div className='d-flex flex-column align-items-center'>
                                    <DocumentCard />
                                    <span className={styles.cardTitle}>{t('attachFilesHere')}</span>
                                </div>)}
                    </label>

                </div>
            </>}
            <hr />
            {data.status === "answeredByAccountant" && <div className='d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <div className={styles.heading}> {t('acceptTask')} </div>
                    {(data?.status === 'answeredByAccountant') && <CustomButtosWithIcon
                        title={t("answer")}
                        icon={
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                            >
                                <path
                                    d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z"
                                    fill="#FEFEFE"
                                />
                            </svg>
                        }
                        type="submit"
                        onClick={() => {
                            setSubmit(true)
                            const edtData = data?.status === 'created'
                                ? { ...files?.files?.map((el) => ({ url: el })), status: 'answeredByAccountant', id: data?.employee_bonus?.id, }
                                : { status, user_complain: complain, id: data?.employee_bonus?.id, }
                            if (!complain && status === 'dispute') return
                            editEmployeeBonusById(edtData)
                        }}
                    />}
                </div>
                <div className='d-flex flex-column align-items-start gap-2'>
                    <label className={styles.customRadioItem}>
                        <input className="form-check-input"
                            type="radio"
                            id="contactChoice1"
                            name="status"
                            value="done"
                            onChange={handleChangeStatus}
                            checked={status === 'done'}
                        />
                        <div className={styles.customRadioItemLabel}>{t('finishTask')}</div></label>
                    <label className={styles.customRadioItem}>
                        <input className="form-check-input"
                            type="radio"
                            id="contactChoice2"
                            name="status"
                            value="dispute"
                            checked={status === 'dispute'}
                            onChange={handleChangeStatus}
                        />
                        <div className={styles.customRadioItemLabel}> {t('disputeTask')} </div></label>
                    <Collapse in={status === 'dispute'} timeout="auto">
                        <div className='mt-2'>
                            <textarea value={complain} className={styles['complain-textarea']} onChange={handleComplain} />
                            {submit && !complain && <div className='error'>{t("required")}</div>}
                        </div>
                    </Collapse>
                </div>
            </div>}
            {data.status === "dispute" && <div className='d-flex flex-column'>
                <div className={styles.heading}> {t('userComplain')} </div>
                <span className={styles.complain}>{data?.employee_bonus?.user_complain}</span>
            </div>}
        </div>
    );
}


