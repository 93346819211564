import { useTranslation } from 'react-i18next';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import styles from './Recording.module.scss';
import { useAddImagesMutation } from '../../store/services/photo';
import { useEffect, useState } from 'react'
import RecordingIcon from '../SvgCompoents/RecordingIcon';
const MicRecorder = require('mic-recorder-to-mp3');
interface IProps {
  handleRecording?: (arg: string) => void,
  src?: string
}
const Recording = ({ handleRecording, src }: IProps) => {
  const { t } = useTranslation();
  const [addImages, { data }] = useAddImagesMutation();
  const button: any = document.querySelector('.recordingButton');
  const [start, setStart] = useState<boolean>(false);
  const [recorder, setRecorder] = useState(new MicRecorder({
    bitRate: 128
  }));

  // button.addEventListener('click', startRecording);
  console.log(button, 'buttonnnnnnnnnnnnnnnnnnnnnn');

  function startRecording() {
    recorder.start().then(() => {
      setStart(true);
      // button.textContent = 'Stop recording';
      // button.classList.toggle('btn-danger');
      // button.removeEventListener('click', startRecording);
      // button.addEventListener('click', stopRecording);
    }).catch((e: any) => {
      console.error(e);
    });
  }

  function stopRecording() {
    recorder.stop().getMp3().then(([buffer, blob]: any) => {
      console.log(buffer, blob, 'buffer, blob');
      const file = new File(buffer, 'music.mp3', {
        type: blob.type,
        lastModified: Date.now()
      });
      const element = document.createElement('div');
      const player = new Audio(URL.createObjectURL(file));
      player.controls = true;
      // if (element?.childNodes[0]) {
      //   element.replaceChild(player, element?.childNodes[0]);
      // } else {
      //   element.appendChild(player);
      // }
      const formdata: any = new FormData();
      console.log(file, 'fileeeeeeeeeeeeeeeeeeeeeee');
      if (file) {
        formdata.append('file_url', file)
        for (var pair of formdata.entries()) {
          console.log(pair[0] + ', ' + pair[1]);
        }
        addImages(formdata)
          .then((res: any) => {
            handleRecording?.(res.data);
            const playlist: any = document.querySelector('#playlist');
            if (playlist?.childNodes[0]) {
              playlist.replaceChild(player, playlist?.childNodes[0]);
            } else {
              playlist.appendChild(player);
            }
            setStart(false);
            // playlist.appendChild(player);
            // button.textContent = 'Start recording';
            // button.classList.toggle('btn-danger');
            // button.removeEventListener('click', stopRecording);
            // button.addEventListener('click', startRecording);
          })
      }

    }).catch((e: any) => {
      console.error(e);
    });
  }
  useEffect(() => {
    const audiotag = document.getElementsByTagName("audio");

    if (src && audiotag.length === 0) {
      const audio = document.createElement('audio');
      const element = document.getElementById('audioCont');
      audio.src = `${process.env.REACT_APP_API_KEY}${src}`;
      audio.controls = true;
      element?.appendChild(audio);
    }
  }, [src])
  return <>
    {src
      ? <div id='audioCont'></div>
      : <div className="container text-center">
        <button className={`${styles.recordingButton} recordingButton`} onClick={start ? stopRecording : startRecording}>
          <RecordingIcon />
          <div className={styles.recordingButtonTitle}>{start ? t('stopRecording') : t('recording')}</div>
        </button>
        <div className={styles.playlist} id="playlist"></div>
      </div>}
  </>
}
export default Recording;