import { Link } from "react-router-dom";
import style from "./EmployeeItem.module.scss";

type Props = {
  img: string;
  name: string;
  profession: string;
  onClick?: () => void;
  id?: number;
  to: string
};

const EmployeeItem = ({ img, name, profession, onClick, id, to }: Props) => {

  return (
    <Link
      onClick={onClick}
      className={style.employeeItem}
      to={to}
    >
      {img ? (
        <img
          className={style.image}
          src={`${process.env.REACT_APP_API_KEY}${img}`}
        />
      ) : (
        <div className={style.imgContainer}>
          <img className={style.defaultImage} src={"/managerDefaultImg.svg"} />
        </div>
      )}
      <div className={`d-flex flex-column w-100 ${style.nameProfession}`}>
        <div className={`w-100 ${style.name}`}>{name}</div>
        <div className={style.profession}>{profession}</div>
      </div>
    </Link>
  );
};
export default EmployeeItem;
