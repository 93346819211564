import React from 'react';
import style from './FileDownload.module.scss'
import { fileIcon } from '../../Utils';

type Props = {
  status: string,
  title: string,
  url?: string,
  deleteItem?: () => void
};
const FileDownload = ({ title, status, deleteItem, url }: Props) => {
  console.log(url,'urlurlurlurlurlurlurl');
  
  const rbcRole = localStorage.getItem('rbcRole');
  return (<a className={style.fileDownload} href={url} target='_blank' download>
    {fileIcon(title, style.icon)}
    <span className={style.title}>
      {title}
    </span>
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
      <path d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z" /></svg>
    {(status === "created" || (status === "dispute" && (rbcRole === '1' || rbcRole === '2'))) && deleteItem && <span
      className={style.close}
      onClick={(e) => {
        e.preventDefault()
        deleteItem()
      }}>x</span>}
  </a>)
}

export default FileDownload;