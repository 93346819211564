import style from './BackgroundSectionAvailable.module.scss';
export default function BackgroundSectionAvailable() {
    return (
        <div className={style.container}>
            <div className='container'>
                <div className={style.headingCont}>
                    <h2 className={style.heading}>Scandapay is available for all devices</h2>
                    <div className={style.headingNext}></div>
                </div>
                <p className={style.sectionTitle}>Scandapay is available for all devices, you can download it from Google Playstore if you have an android phone, or download it on Appstore if you own an Ios device. We can’t wait to have you on board!</p>
                <div className={style.sectionItems}>
                   <img src='/googleplay.png'/>
                   <img src='/appstore.png'/>
                </div>
            </div>
        </div>
    )
}