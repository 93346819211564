import * as React from 'react';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import Cookies from 'js-cookie'

import styles from './App.module.scss';
import './index.scss'
import './i18n'
import i18n from './i18n';
import { UserRoutes } from './Routes/User';
import Login from './Pages/Login';
import {
  AsAccountant,
  AsBroker,
  AsLawyer,
  AsUser,
  Conditions,
  ForgetPassword,
  PrivatePage,
  Registration,
  Terms
} from './Pages';
import Layout from './Layout';
import { AccountantRoutes } from './Routes/Accountant';
import LawyerRoutes from './Routes/Lawyer';
import BrokerRoutes from './Routes/Broker';
import { AccountantWorkerRoutes } from './Routes/AccountantWorkerRoutes';
import { UserWorkerRoutes } from './Routes/UserWorker';
import LandingPage from './Pages/LandingPage';
import Congratulations from './Pages/Registration/Congratulations';
import { useAppSelector } from './store';
import { IAuth } from './types';
import Notification from './firebaseNotifications/Notification';

interface IRoleContext {
  role: number | null,
  setRole: React.Dispatch<React.SetStateAction<number | null>>,
  setAccess: React.Dispatch<React.SetStateAction<string | null>>
}

export const roleContext = React.createContext<IRoleContext | null>(null)

const App: React.FC = () => {

  const [access, setAccess] = React.useState(() => localStorage.getItem('rbc-access-token'))
  const auth: IAuth = useAppSelector((state: any) => state.auth)
  const [role, setRole] = React.useState<number | null>(() => {
    const r = localStorage.getItem('rbcRole')
    setAccess(localStorage.getItem('rbc-access-token'))
    return r ? +r : 3
  })

  React.useEffect(() => {
    if (auth?.me) {
      setRole(auth?.me?.user_role);
      setAccess(localStorage.getItem('rbc-access-token'));
      i18n.changeLanguage(auth?.me?.language === 'hy' ? 'am' : auth?.me?.language)
      if (auth?.me?.language === 'hy') {
        require('moment/locale/hy-am')
      }
      if (i18n.language === 'ru') {
        require('moment/locale/ru')
      }
    } else {
      const rbcLocale=localStorage.getItem('rbc-locale')
      if (rbcLocale) {
        i18n.changeLanguage(rbcLocale)
      }
      if (auth?.me?.language === 'am') {
        require('moment/locale/hy-am')
      }
      if (i18n.language === 'ru') {
        require('moment/locale/ru')
      }
    }
  }, [auth])

  const RouteSteck = React.useMemo(() => {
    switch (role?.toString()) {
      case "1": return AccountantRoutes()
      case "2": return AccountantWorkerRoutes()
      case '3': return UserRoutes()
      case '4': return UserWorkerRoutes()
      case '5': return LawyerRoutes()
      case '6': return BrokerRoutes()
      default:
        break
    }
  }, [role])

  const [data, setData] = React.useState('')

  const handleChangeData = React.useCallback((data: string) => {
    setData(data)
  }, [])

  return (
    <roleContext.Provider value={{ role, setRole, setAccess }}>
      <div className='App'>
        <Router>
          <Routes>
            <Route path="/" element={<Layout><Login /></Layout>} />
            <Route path="/landing" element={<Layout><LandingPage /></Layout>} />
            <Route path='/registration/broker' element={<Layout><AsBroker /></Layout>} />
            <Route path='/registration/accountant' element={<Layout><AsAccountant /></Layout>} />
            <Route path='/registration/lawyer' element={<Layout><AsLawyer /></Layout>} />
            <Route path='/registration/user' element={<Layout><AsUser /></Layout>} />
            <Route path='/registration' element={<Layout><Registration /></Layout>} />
            <Route path='/terms' element={<Layout><Terms /></Layout>} />
            <Route path='/conditions' element={<Layout><Conditions /></Layout>} />
            <Route path='/forget-password' element={<Layout><ForgetPassword /></Layout>} />
            <Route path="/*" element={<Layout><Login /></Layout>} />
            <Route path='/profile/congratulations' element={<PrivatePage><Congratulations /></PrivatePage>} />
            {RouteSteck}
          </Routes>
        </Router>
        <ToastContainer
          pauseOnHover={true}
          hideProgressBar={false}
          closeOnClick={true}
          draggable={true}
          autoClose={3000}
        />
      </div>
    </roleContext.Provider>
  )
}

export default App
