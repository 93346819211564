import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import * as Yup from "yup";
import { Formik, FormikHelpers, Form } from 'formik';
import styles from './TaskPage.module.scss';
import { CustomButtosWithIcon, CustomInput, DetailItem, FileDownload } from '../../../Components';
import Recording from '../../../Components/Recording';
import { getFileName } from '../../../Utils';
import { useEditProvisionTasksByIdMutation } from '../../../store/services/tasks';
import { ITaskPage } from '../../../types';
interface DataTask{
  data:ITaskPage
}
export default function Provision({ data }: DataTask) {
  const { t } = useTranslation();
  const [editProvisionTasksById] = useEditProvisionTasksByIdMutation();
  const navigate = useNavigate();
  const description = t('salaryDescription');
  const first_file = useMemo(() => {
    return getFileName(data?.employee_provision?.first_file)
  }, [data?.employee_provision?.first_file]);
  const second_file = useMemo(() => {
    return getFileName(data?.employee_provision?.second_file)
  }, [data?.employee_provision?.second_file]);
  const SignupSchema = Yup.object().shape({
    salary: Yup.number()
      .required(t('required'))
      .min(0, t('required')),
  });
  interface Values {
    salary?: number
  }
  return (
    <div className={`w-100`}>
      <div className='d-flex justify-content-between flex-nowrap'>
        <div className={`${styles.taskTypeHeading}`}>{t(`${data?.task_type?.name}`)} </div>
        <div className={`${styles.taskStatus} ${styles[data?.status]}`}>{t(`${data?.status}`)}</div>
      </div>
      <div className='d-flex justify-content-between flex-wrap'>
        <div>
          <div className={styles.heading}>{t('detail')} </div>
          <div className={styles.detailCont}>
            {data?.task_name && <DetailItem name={t('taskName')} value={data?.task_name} />}
            <DetailItem
              name={t('provisionType')}
              value={data?.employee_provision?.type === 0 ? t('disease') : t('pregnancy')}
            />
            {data?.employee_provision?.recording && <div className={styles.application}>
              <h4 className={styles.subHeading}>{t('voiceMessage')}</h4>
              <Recording src={data?.employee_provision?.recording} />
            </div>}
          </div>
        </div>
        {data?.employee_provision?.first_file && data?.employee_provision.second_file && <div className={styles.application}>
          <h4 className={styles.subHeading}>{t('accost')}</h4>
          <div className={`d-flex ${styles.cardCont}`}>
            <FileDownload
              url={`${process.env.REACT_APP_API_KEY}/${data?.employee_provision.first_file}`}
              status={data?.status}
              title={first_file}
            />
            <FileDownload
              url={`${process.env.REACT_APP_API_KEY}/${data?.employee_provision.second_file}`}
              status={data?.status}
              title={second_file}
            />
          </div>
        </div>}

      </div>
      {data.status === "created" && <>
        <hr />
        <Formik
          initialValues={{
          }}
          validationSchema={SignupSchema}
          onSubmit={(
            values: Values,
            { setSubmitting }: FormikHelpers<Values>
          ) => {
            editProvisionTasksById({ ...values, id: data?.employee_provision?.id, status: 'done' })
              .then((res: any) => {
                if (res?.data?.id) {
                  toast.success('Գործընթացը հաջողությամբ կատարվել է');
                  navigate('/profile/dashboard')
                }
              })
          }}
        >
          {({ errors, touched, values }: any) => (
            <Form >
              <div className='d-flex justify-content-between'>
                <div className={styles.heading}> {t('accountantSalary')} </div>
                {(data?.status === 'created' || data?.status === 'dispute') && <CustomButtosWithIcon
                  title={t("answer")}
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z"
                        fill="#FEFEFE"
                      />
                    </svg>
                  }
                  type="submit"
                />}
              </div>
              <CustomInput name='salary' title={t('salary')} type='number' className='taskInput' />
            </Form>)}
        </Formik>
      </>}
      {data.status === "done" && <>
        <hr />
        <div className={styles.heading}> {t('accountantSalary')} </div>
        {data?.employee_provision && <div>{description.replace('<<X<<', new Intl.NumberFormat(
          'en-US',
        ).format(data?.employee_provision?.salary))}</div>}
      </>}
    </div>
  );
}


