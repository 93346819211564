import Logo from '../../SvgCompoents/Logo';
import style from './LandingPageHeader.module.scss';
export default function LandingPageHeader() {
    return (
        <div className={style.header}>
            <div className='container d-flex justify-content-between align-items-center'>
                <div className={style.logo}><Logo /></div>
                <div className='d-flex align-items-center'>
                    <nav className={style.linkCont}>
                        <a className={style.link} href='/#'> Features</a>
                        <a className={style.link} href='/#'> Download </a>
                        <a className={style.link} href='/#'>FAQs </a>
                    </nav>
                    <button className={style.orangeButton}>
                        Partner
                    </button>
                </div>
            </div>
        </div>
    )
}