
const RegisSvg = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 653 550" fill="none">
  <path d="M567.474 492.875L566.42 488.763L554.493 491.821L555.548 495.933L567.474 492.875Z" fill="#5793CE" />
  <path d="M561.098 498.807L560.044 494.695L548.117 497.75L549.171 501.862L561.098 498.807Z" fill="#5793CE" />
  <path d="M554.95 504.742L553.897 500.63L541.97 503.686L543.023 507.798L554.95 504.742Z" fill="#5793CE" />
  <path d="M549.228 510.697L548.173 506.585L536.247 509.643L537.301 513.755L549.228 510.697Z" fill="#5793CE" />
  <path d="M303.249 122.374L191.239 188.091C162.837 179.357 126.655 182.069 102.787 196.07C73.7396 213.116 73.8952 240.407 103.209 257.053C110.142 260.895 117.556 263.795 125.255 265.676C131.006 267.135 136.861 268.145 142.768 268.699C166.059 270.921 190.906 266.832 208.841 256.297C230.554 243.563 235.91 225.184 225.02 209.627L240.577 200.559L240.132 190.736H256.401L255.978 181.557H272.246L271.846 172.357H288.092L287.692 163.178L303.96 163.067L303.538 153.866L320.917 153.755L326.207 150.643L332.052 126.508L303.249 122.374ZM145.635 248.097C141.392 250.292 136.69 251.455 131.913 251.49C127.136 251.524 122.418 250.43 118.144 248.297C110.521 243.852 110.454 236.851 118.033 232.429C122.275 230.233 126.977 229.07 131.754 229.036C136.532 229.001 141.249 230.095 145.524 232.229C153.147 236.562 153.191 243.674 145.635 248.097Z" fill="#C4D9E8" />
  <path d="M364.5 538.034L213.375 451.204C203.196 445.314 204.485 435.069 216.22 428.291L362.899 343.638C374.634 336.86 392.391 336.127 402.57 341.994L553.695 428.846C563.873 434.713 562.584 444.981 550.85 451.759L404.17 536.389C392.436 543.19 374.678 543.923 364.5 538.034Z" fill="#C4D9E8" />
  <path d="M541.604 341.172L515.491 326.082L611.766 270.521C616.211 267.899 616.211 263.609 611.766 260.987L513.402 204.204C510.846 202.911 508.021 202.237 505.157 202.237C502.292 202.237 499.468 202.911 496.911 204.204L400.614 259.764L374.5 244.696L470.798 189.136C489.733 178.179 520.558 178.179 539.515 189.136L637.88 245.919C656.815 256.853 656.815 274.655 637.88 285.589L541.604 341.172Z" fill="#5793CE" />
  <path d="M583.741 347.839C583.741 333.882 575.252 317.658 564.784 311.613L390.324 210.893C385.635 208.204 381.434 208.004 378.034 209.849L169.748 330.882L371.167 482.651L576.363 363.396C577.008 363.118 577.619 362.768 578.185 362.351C581.608 359.84 583.741 354.751 583.741 347.839Z" fill="#D4ECFF" />
  <path d="M371.167 482.585L576.363 363.396C577.008 363.118 577.619 362.768 578.185 362.351C581.607 359.751 583.741 354.662 583.741 347.683C583.578 340.658 581.831 333.76 578.63 327.504L344.697 462.56L371.167 482.585Z" fill="#053D57" />
  <path d="M358.054 482.051L183.594 381.331C173.126 375.286 164.637 359.107 164.637 345.105C164.637 331.149 173.126 324.748 183.594 330.793L358.054 431.491C368.522 437.536 377.012 453.715 377.012 467.717C377.012 481.696 368.522 488.096 358.054 482.051Z" fill="#5793CE" />
  <path d="M331.919 448.093L310.939 435.98C309.788 435.161 308.847 434.082 308.19 432.831C307.534 431.58 307.181 430.192 307.161 428.78C307.161 425.979 308.85 424.713 310.939 425.913L331.919 438.003C333.067 438.83 334.007 439.913 334.663 441.167C335.319 442.421 335.673 443.811 335.697 445.226C335.697 448.026 334.008 449.293 331.919 448.093Z" fill="white" />
  <path d="M174.438 93.4158L210.263 113.773L246.844 92.3046L246.733 82.9704L246.444 83.1704L246.4 82.5037L262.668 82.3926L262.579 73.1917L278.514 73.0806L278.425 63.902L294.382 63.7909L294.271 54.5901L310.228 54.479L310.139 45.3003L327.185 45.167L332.474 42.0556L338.342 17.9423L338.23 8.60815L335.408 17.5423L309.606 14.0975L174.438 93.4158Z" fill="#396CAA" />
  <path d="M210.174 104.439L246.733 82.9705L246.311 73.1696L262.579 73.0585L262.157 63.8576L278.425 63.7465L278.025 54.5679L294.271 54.4568L293.871 45.256L310.139 45.1448L309.717 35.9662L327.096 35.8329L332.385 32.7215L338.23 8.60822L309.494 4.76343L174.327 84.0817L210.174 104.439Z" fill="#D4ECFF" />
  <path d="M234.532 90.1493C230.115 84.219 224.447 79.3327 217.931 75.8369C212.482 72.8088 206.722 70.3796 200.751 68.5918L174.349 84.1488L210.174 104.506L234.532 90.1493Z" fill="#396CAA" />
  <path d="M337.252 12.6534L338.23 8.60861L333.408 7.96411L219.197 74.9702L225.22 78.3927L337.252 12.6534Z" fill="#5793CE" />
  <path d="M87.8075 122.863L87.4075 109.595L91.6523 118.552L87.8075 122.863Z" fill="#D3D0CF" />
  <path d="M109.876 152.71C139.168 169.356 186.461 169.023 215.508 151.977C230.065 143.443 237.266 132.308 237.155 121.241L236.754 107.173L234.377 109.84C230.998 102.906 224.576 96.3718 215.086 90.9713C185.794 74.3476 138.501 74.681 109.454 91.727C80.4071 108.773 80.6071 136.087 109.876 152.71ZM152.102 127.908C159.725 132.219 159.77 139.331 152.214 143.776C147.967 145.96 143.267 147.116 138.492 147.15C133.717 147.185 129 146.098 124.722 143.976C117.099 139.531 117.033 132.531 124.611 128.086C128.862 125.87 133.576 124.691 138.369 124.645C143.162 124.599 147.899 125.686 152.191 127.819L152.102 127.908Z" fill="#396CAA" />
  <path d="M109.499 139.465C138.768 156.088 186.061 155.755 215.108 138.709C244.155 121.663 244 94.2606 214.686 77.748C185.372 61.2354 138.101 61.4355 109.054 78.4814C80.0071 95.5274 80.2072 122.819 109.499 139.465ZM151.725 114.64C159.348 119.085 159.392 126.064 151.725 130.508C147.482 132.704 142.78 133.867 138.003 133.901C133.226 133.936 128.508 132.842 124.233 130.708C116.61 126.264 116.566 119.263 124.233 114.818C128.488 112.62 133.202 111.458 137.991 111.427C142.78 111.396 147.509 112.497 151.791 114.64H151.725Z" fill="#F2F2F2" />
  <path d="M143.435 146.71C137.094 147.825 130.563 146.866 124.811 143.976C119.344 140.865 117.788 136.331 120.122 132.375L106.787 121.863L92.9858 106.306C82.3181 121.863 87.9409 140.309 109.81 152.733C120.026 158.227 131.183 161.755 142.701 163.133L143.435 146.71Z" fill="#5793CE" />
  <path d="M109.499 139.465C116.422 143.31 123.829 146.21 131.523 148.088C137.274 149.544 143.129 150.555 149.036 151.11C172.327 153.333 197.196 149.221 215.108 138.709C244.155 121.663 244 94.2606 214.686 77.748C185.372 61.2354 138.101 61.4355 109.054 78.4814C80.0071 95.5274 80.2072 122.819 109.499 139.465ZM151.725 114.64C159.348 119.085 159.392 126.064 151.725 130.508C147.482 132.704 142.78 133.867 138.003 133.901C133.226 133.936 128.508 132.842 124.233 130.708C116.61 126.264 116.566 119.263 124.233 114.818C128.488 112.62 133.202 111.458 137.991 111.427C142.78 111.396 147.509 112.497 151.791 114.64H151.725Z" fill="#D4ECFF" />
  <path d="M95.6531 109.24C97.0755 100.75 103.387 92.4384 114.61 85.86C140.435 70.7031 182.505 70.303 208.574 85.1933C221.509 92.5495 228.065 102.261 228.198 112.018C230.065 100.906 223.62 89.527 208.886 81.1485C182.817 66.3471 140.746 66.636 114.922 81.793C101.92 89.4159 95.4976 99.3723 95.6531 109.24Z" fill="#396CAA" />
  <path d="M78.029 230.051C72.9015 230.087 67.8532 228.785 63.3831 226.273C52.8933 220.316 47.0483 208.338 46.9372 192.558C46.7371 163.8 66.7612 128.53 91.5857 113.974C105.187 105.995 118.455 104.862 128.945 110.84C133.47 113.431 137.222 117.182 139.812 121.708C143.652 128.691 145.577 136.565 145.391 144.532C145.591 173.312 125.567 208.56 100.742 223.139C93.9149 227.41 86.0778 229.795 78.029 230.051ZM114.277 117.618C108.121 117.881 102.142 119.759 96.9417 123.063C75.0063 135.931 57.3159 167.067 57.4937 192.492C57.4937 204.337 61.5162 213.071 68.6058 217.094C75.6953 221.117 85.185 220.028 95.4082 214.027C117.321 201.159 135.012 170.023 134.834 144.599C135.002 138.487 133.578 132.436 130.7 127.041C129.077 124.127 126.691 121.709 123.797 120.049C120.903 118.389 117.612 117.548 114.277 117.618Z" fill="#396CAA" />
  <path d="M128.056 140.865L148.902 145.976L151.902 130.419C147.66 132.615 142.958 133.777 138.181 133.812C133.403 133.847 128.686 132.753 124.411 130.619L128.056 140.865Z" fill="#D4ECFF" />
  <path d="M109.499 139.465C116.422 143.31 123.829 146.21 131.523 148.088C137.274 149.544 143.129 150.555 149.036 151.11C172.327 153.333 197.196 149.221 215.108 138.709L130.212 136.82L109.499 139.465Z" fill="#D4ECFF" />
  <path d="M140.857 150.177C134.601 149.071 128.442 147.481 122.433 145.421V158.444C128.337 160.578 134.432 162.141 140.635 163.111C160.081 165.6 140.857 150.177 140.857 150.177Z" fill="#396CAA" />
  <path d="M127.323 146.998C123.216 145.822 119.204 144.336 115.322 142.553C103.965 137.242 118.322 156.933 118.322 156.933C118.322 156.933 122.189 158.488 127.323 160.066V146.998Z" fill="#5793CE" />
  <path d="M340.32 375.841L303.161 399.799L285.092 396.866L329.252 375.508L340.32 375.841Z" fill="#5793CE" />
  <path d="M295.404 496.741C295.404 496.741 284.714 491.341 278.136 499.364C271.557 507.387 262.179 526.366 261.89 539.189C261.601 552.013 272.024 551.146 278.225 548.079C284.425 545.012 302.538 518.765 303.649 513.609C304.76 508.453 302.338 501.497 300.604 500.141C298.871 498.786 295.404 496.741 295.404 496.741Z" fill="#173D7A" />
  <path d="M297.738 494.252L298.227 505.364C298.288 506.52 298.03 507.67 297.481 508.689C296.933 509.708 296.114 510.557 295.115 511.142C292.591 512.513 289.67 512.969 286.848 512.431C278.781 511.12 278.07 508.831 277.492 497.741C276.914 486.651 297.738 494.252 297.738 494.252Z" fill="#5793CE" />
  <path d="M256.245 475.495C256.245 475.495 245.533 470.116 238.977 478.139C232.421 486.162 222.998 505.142 222.709 517.965C222.42 530.789 232.865 529.922 239.066 526.855C245.266 523.788 263.379 497.541 264.468 492.385C265.557 487.229 263.179 480.273 261.446 478.917C259.712 477.562 256.245 475.495 256.245 475.495Z" fill="#173D7A" />
  <path d="M258.579 472.917L259.068 484.029C259.124 485.187 258.862 486.338 258.309 487.356C257.757 488.375 256.935 489.223 255.934 489.807C253.416 491.173 250.501 491.62 247.689 491.074C239.622 489.785 238.799 487.496 238.333 476.406C237.866 465.316 258.579 472.917 258.579 472.917Z" fill="#5793CE" />
  <path d="M354.054 269.41C350.876 217.872 298.249 276.988 298.249 276.988L262.224 312.28L229.776 347.839C226.645 351.277 224.302 355.357 222.912 359.795C221.522 364.233 221.119 368.92 221.731 373.53L234.843 473.539C235.154 475.921 236.328 478.106 238.143 479.679C239.958 481.252 242.287 482.104 244.689 482.073C246.32 482.058 247.947 481.916 249.556 481.651C252.686 481.308 255.563 479.769 257.584 477.354C259.606 474.939 260.615 471.836 260.401 468.694L258.001 375.353L326.118 334.282C326.118 334.282 357.21 320.925 354.054 269.41Z" fill="#D4ECFF" />
  <path d="M284.025 357.573L274.336 354.017C274.113 354.017 257.868 369.73 258.001 375.241L265.291 377.464L283.603 359.685L284.025 357.573Z" fill="#5793CE" />
  <path d="M340.319 375.864C340.319 375.864 380.812 352.75 385.656 343.038C387.546 339.26 390.502 335.282 378.678 329.548L324.696 368.041L326.918 381.375L340.319 375.864Z" fill="#5793CE" />
  <path d="M393.213 289.278C389.835 237.762 337.408 296.856 337.408 296.856L301.382 332.282L268.957 367.84C265.822 371.276 263.475 375.356 262.081 379.794C260.688 384.231 260.281 388.92 260.89 393.532L274.002 493.541C274.322 495.922 275.502 498.105 277.319 499.677C279.137 501.249 281.467 502.102 283.87 502.075C285.493 502.059 287.113 501.918 288.714 501.653C291.845 501.31 294.722 499.77 296.743 497.355C298.764 494.941 299.774 491.838 299.56 488.696L297.16 395.243L365.299 354.173C365.299 354.173 396.302 336.393 393.213 289.278Z" fill="#D4ECFF" />
  <path d="M362.077 123.619C402.081 140.176 401.192 163.756 401.192 163.756C401.192 163.756 393.369 250.23 396.036 307.991C397.147 332.149 267.758 333.993 287.137 269.565C309.895 193.625 301.361 132.13 312.028 122.885C322.696 113.64 351.765 119.352 362.077 123.619Z" fill="#5793CE" />
  <path d="M374.922 144.532C372.833 152.644 362.21 156.911 351.209 154.066C340.208 151.221 332.963 142.376 335.052 134.264C337.141 126.152 347.764 121.907 358.765 124.73C369.766 127.552 377.011 136.442 374.922 144.532Z" fill="#FFC2B0" />
  <path d="M357.944 156.844C355.515 156.841 353.096 156.535 350.743 155.933C345.29 154.617 340.391 151.615 336.742 147.354C335.115 145.539 333.946 143.362 333.331 141.004C332.716 138.646 332.674 136.175 333.208 133.797C335.43 124.685 347.232 119.796 359.233 122.885C364.692 124.185 369.596 127.19 373.234 131.464C374.862 133.277 376.033 135.454 376.648 137.813C377.262 140.171 377.303 142.643 376.767 145.02C374.901 152.266 367.167 156.844 357.944 156.844ZM351.943 125.73C344.52 125.73 338.32 129.175 336.897 134.731C336.518 136.528 336.575 138.389 337.062 140.16C337.549 141.931 338.453 143.559 339.698 144.909C342.79 148.56 346.961 151.136 351.61 152.266C361.588 154.821 371.189 151.177 373.012 144.109C373.383 142.309 373.323 140.446 372.837 138.672C372.35 136.899 371.45 135.267 370.211 133.908C367.086 130.277 362.894 127.726 358.233 126.619C356.18 126.071 354.067 125.78 351.943 125.752V125.73Z" fill="white" />
  <path d="M346.075 121.818C346.317 126.569 346.206 131.33 345.742 136.064C345.52 140.042 359.432 143.754 363.032 140.153C366.633 136.553 365.255 132.819 366.055 119.285C366.855 105.75 346.075 121.818 346.075 121.818Z" fill="#FFC2B0" />
  <path d="M366.055 119.285C366.055 119.285 362.632 131.842 345.72 136.642L345.986 125.241L366.055 119.285Z" fill="#EA9C92" />
  <path d="M376.767 87.4153C378.288 93.2964 378.031 99.4965 376.028 105.231C374.025 110.966 370.366 115.979 365.515 119.634C360.663 123.29 354.837 125.425 348.772 125.769C342.707 126.113 336.676 124.651 331.442 121.568C326.208 118.485 322.006 113.919 319.367 108.447C316.728 102.976 315.771 96.8449 316.617 90.8294C317.462 84.814 320.073 79.1843 324.118 74.6524C328.163 70.1204 333.461 66.8897 339.342 65.3688C343.247 64.359 347.312 64.1282 351.306 64.6896C355.301 65.251 359.145 66.5937 362.62 68.6409C366.096 70.6881 369.134 73.3997 371.561 76.6211C373.988 79.8424 375.757 83.5103 376.767 87.4153Z" fill="#FFC2B0" />
  <path d="M354.076 96.2383C354.076 96.2383 337.163 103.483 325.451 103.55C313.739 103.617 316.317 93.8381 316.317 93.8381L351.076 88.8154L354.076 96.2383Z" fill="#EA9C92" />
  <path d="M321.962 98.1498C321.962 98.1498 336.697 91.8826 349.631 94.4828C352.686 95.0953 355.438 96.7344 357.432 99.1277C358.003 99.9464 358.833 100.549 359.788 100.839C362.011 101.372 365.833 91.9492 370.3 91.6381C374.767 91.327 377.723 94.5717 374.078 100.528C370.433 106.484 371.656 112.929 373.323 113.862C374.989 114.796 392.835 101.15 377.123 69.9917C361.41 38.8334 321.562 55.6349 315.228 61.9465C315.228 61.9465 308.695 67.6359 303.361 62.5688C303.361 62.4577 297.405 107.151 321.962 98.1498Z" fill="#965353" />
  <path d="M378.79 226.939V250.319C378.792 251.64 378.518 252.947 377.986 254.157C377.454 255.366 376.676 256.451 375.7 257.342L327.696 301.168L333.141 309.524L396.547 263.387C398.009 262.324 399.233 260.966 400.136 259.4C401.04 257.834 401.604 256.096 401.792 254.297L406.081 213.538L378.79 226.939Z" fill="#FFC2B0" />
  <path d="M397.814 152.066C397.814 152.066 412.371 173.979 417.816 231.628C417.816 231.628 392.947 239.674 378.812 235.095C364.677 230.517 397.814 152.066 397.814 152.066Z" fill="#5793CE" />
  <path d="M376.056 179.757C376.056 179.757 370.345 210.271 370.033 224.628C369.97 226.555 370.518 228.454 371.6 230.051C372.682 231.648 374.242 232.861 376.056 233.517V179.757Z" fill="#396CAA" />
  <path d="M363.677 331.504L353.076 330.104L284.581 290.545C283.225 289.934 281.746 289.649 280.261 289.71C278.776 289.772 277.325 290.18 276.025 290.9L227.487 318.947L221.064 318.103V324.481C221.08 324.954 221.228 325.412 221.492 325.805C221.755 326.198 222.123 326.509 222.553 326.703L300.16 371.53C301.518 372.145 303.002 372.434 304.492 372.372C305.982 372.31 307.436 371.9 308.739 371.174L361.588 340.66C362.165 340.413 362.664 340.014 363.032 339.505C363.4 338.996 363.623 338.397 363.677 337.771V331.504Z" fill="#173D7A" />
  <path d="M312.228 306.502L307.361 303.701H253.823L227.487 318.947L221.064 318.103V324.481C221.08 324.954 221.228 325.412 221.492 325.805C221.755 326.198 222.123 326.509 222.553 326.704L300.16 371.53C301.533 372.222 303.075 372.507 304.605 372.352L312.228 306.502Z" fill="#396CAA" />
  <path d="M223.909 318.458L221.064 318.08V324.459C221.08 324.931 221.228 325.39 221.492 325.782C221.755 326.175 222.123 326.486 222.553 326.681L223.909 327.481V318.458Z" fill="#5793CE" />
  <path d="M300.96 371.886C303.351 372.669 305.95 372.51 308.228 371.441V360.573H300.96V371.886Z" fill="#274F89" />
  <path d="M300.161 365.152L222.554 320.326C220.331 319.059 220.62 316.836 223.154 315.37L276.047 284.878C277.346 284.152 278.797 283.742 280.283 283.68C281.769 283.618 283.249 283.907 284.604 284.522L362.233 329.326C364.455 330.593 364.144 332.816 361.611 334.282L308.761 364.796C307.454 365.521 305.996 365.93 304.503 365.992C303.01 366.054 301.523 365.766 300.161 365.152Z" fill="white" />
  <path d="M298.849 358.351L234.398 321.081C232.576 320.036 232.798 318.192 234.91 316.969L263.245 301.035C264.328 300.439 265.533 300.103 266.767 300.053C268.001 300.003 269.23 300.24 270.357 300.746L334.807 338.016C336.63 339.06 336.407 340.905 334.296 342.127L305.983 358.062C304.896 358.658 303.687 358.994 302.449 359.044C301.212 359.094 299.979 358.857 298.849 358.351Z" fill="#D4ECFF" />
  <path d="M279.025 283.388L278.225 288.744C277.536 293.307 277.649 297.955 278.559 302.479L279.514 307.191C279.781 308.494 280.431 309.688 281.382 310.618C282.332 311.549 283.539 312.174 284.848 312.413C285.342 312.501 285.848 312.487 286.337 312.372C286.825 312.257 287.284 312.042 287.686 311.742C288.088 311.443 288.424 311.063 288.674 310.628C288.924 310.193 289.081 309.711 289.137 309.213L290.404 297.767C290.404 297.767 293.338 301.59 294.849 301.59C296.36 301.59 293.271 290.122 290.071 283.633C286.871 277.143 279.025 283.388 279.025 283.388Z" fill="#FFC2B0" />
  <path d="M305.339 198.292L297.004 214.76C295.033 218.658 293.869 222.914 293.582 227.272L289.982 283.655L279.025 283.411L274.047 220.094C273.887 218.134 273.984 216.162 274.336 214.227L280.003 182.113L305.339 198.292Z" fill="#FFC2B0" />
  <path d="M297.538 369.441L291.026 360.951L222.709 321.325C221.497 320.459 220.505 319.319 219.814 317.999C219.123 316.679 218.752 315.215 218.73 313.725V257.675L214.797 252.541L218.486 250.319C218.904 250.089 219.377 249.982 219.853 250.01C220.329 250.037 220.787 250.198 221.175 250.475L298.738 295.39C299.95 296.257 300.941 297.396 301.632 298.716C302.323 300.036 302.695 301.5 302.716 302.99V364.018C302.79 364.641 302.693 365.273 302.434 365.845C302.176 366.417 301.766 366.907 301.249 367.263L297.538 369.441Z" fill="#173D7A" />
  <path d="M250.2 337.305L245.355 334.504L218.686 288.145V257.675L214.752 252.541L218.442 250.319C218.859 250.089 219.333 249.982 219.809 250.01C220.284 250.037 220.743 250.198 221.131 250.475L298.693 295.39C299.977 296.228 300.995 297.415 301.627 298.812L250.2 337.305Z" fill="#396CAA" />
  <path d="M216.486 254.831L214.752 252.608L218.442 250.386C218.859 250.156 219.333 250.049 219.809 250.077C220.284 250.104 220.743 250.265 221.131 250.541L222.509 251.342L216.486 254.831Z" fill="#5793CE" />
  <path d="M299.405 295.967C301.271 297.659 302.429 299.992 302.65 302.501L295.071 306.946L291.427 300.634L299.405 295.967Z" fill="#274F89" />
  <path d="M295.004 297.612L217.442 252.697C215.219 251.43 213.464 252.697 213.464 255.697V316.725C213.486 318.212 213.855 319.674 214.542 320.993C215.229 322.313 216.214 323.454 217.42 324.326L294.982 369.241C297.205 370.507 298.96 369.241 298.96 366.24V305.213C298.949 303.723 298.584 302.258 297.896 300.937C297.208 299.615 296.218 298.476 295.004 297.612Z" fill="#5793CE" />
  <path d="M333.141 309.458L331.897 312.036C330.468 315.002 328.215 317.494 325.407 319.214L317.718 323.97C316.544 324.706 315.193 325.11 313.808 325.142C312.423 325.173 311.056 324.83 309.85 324.148L309.583 323.992C309.019 323.606 308.546 323.099 308.2 322.509C307.854 321.918 307.642 321.259 307.581 320.577C307.519 319.895 307.609 319.209 307.844 318.566C308.079 317.923 308.453 317.34 308.939 316.858L316.984 308.591C316.984 308.591 312.539 312.058 311.184 310.658C310.25 309.635 315.628 304.635 319.651 304.479C322.593 304.118 325.378 302.949 327.696 301.101L333.141 309.458Z" fill="#FFC2B0" />
  <path d="M312.717 122.73C312.717 122.73 283.203 154.844 271.313 184.313C279.395 196.745 291.78 205.758 306.094 209.626C330.807 216.716 312.717 122.73 312.717 122.73Z" fill="#5793CE" />
  <path d="M300.493 207.826L305.294 161.6L294.004 204.893C296.048 205.848 298.271 206.826 300.493 207.826Z" fill="#396CAA" />
  <path d="M334.83 116.263L326.896 114.04C329.127 117.456 332.389 120.072 336.208 121.508C341.742 123.019 342.609 118.352 342.609 118.352L334.83 116.263Z" fill="white" />
  <path d="M184.305 45.2558C179.91 45.2558 175.613 43.9524 171.958 41.5104C168.303 39.0683 165.455 35.5974 163.773 31.5365C162.091 27.4755 161.651 23.007 162.508 18.6959C163.366 14.3848 165.482 10.4248 168.59 7.31671C171.698 4.20859 175.658 2.09194 179.97 1.23441C184.281 0.376887 188.749 0.817009 192.81 2.49911C196.871 4.18121 200.342 7.02973 202.784 10.6845C205.226 14.3392 206.529 18.6361 206.529 23.0316C206.529 28.9258 204.188 34.5787 200.02 38.7465C195.852 42.9144 190.199 45.2558 184.305 45.2558ZM184.305 5.67449C180.866 5.6701 177.503 6.68619 174.642 8.59417C171.78 10.5021 169.549 13.2162 168.231 16.3928C166.913 19.5693 166.567 23.0656 167.237 26.4389C167.906 29.8122 169.562 32.911 171.994 35.3429C174.426 37.7748 177.525 39.4304 180.898 40.1003C184.271 40.7702 187.768 40.4241 190.944 39.106C194.121 37.7878 196.835 35.5568 198.743 32.6954C200.651 29.834 201.667 26.4708 201.662 23.0316C201.668 20.7464 201.224 18.4824 200.355 16.3689C199.486 14.2554 198.209 12.3338 196.597 10.7138C194.985 9.09379 193.07 7.8071 190.961 6.9272C188.852 6.0473 186.59 5.59142 184.305 5.58559V5.67449Z" fill="#D4ECFF" />
  <path d="M633.413 205.337C630.295 205.337 627.248 204.412 624.656 202.68C622.064 200.948 620.045 198.486 618.853 195.605C617.661 192.725 617.35 189.555 617.96 186.498C618.57 183.441 620.073 180.633 622.279 178.43C624.484 176.228 627.294 174.729 630.352 174.123C633.41 173.518 636.579 173.833 639.458 175.029C642.337 176.225 644.796 178.248 646.525 180.842C648.253 183.436 649.174 186.485 649.17 189.602C649.164 193.778 647.501 197.78 644.546 200.73C641.592 203.68 637.588 205.337 633.413 205.337ZM633.413 178.668C631.254 178.668 629.145 179.308 627.35 180.507C625.556 181.706 624.157 183.41 623.331 185.404C622.505 187.398 622.289 189.592 622.71 191.709C623.131 193.826 624.17 195.77 625.697 197.296C627.223 198.822 629.167 199.862 631.284 200.283C633.401 200.704 635.594 200.488 637.588 199.662C639.582 198.836 641.287 197.437 642.486 195.643C643.685 193.848 644.325 191.738 644.325 189.58C644.319 186.69 643.166 183.92 641.121 181.879C639.075 179.837 636.303 178.69 633.413 178.69V178.668Z" fill="#5793CE" />
  <path d="M116.9 489.941L106.21 468.205L127.945 457.516L138.635 479.251L116.9 489.941ZM112.699 470.406L119.1 483.451L132.145 477.051L125.745 464.005L112.699 470.406Z" fill="#5793CE" />
  <path d="M432.55 152.866L421.838 131.108L443.595 120.418L454.285 142.176L432.55 152.866ZM428.327 133.33L434.75 146.376L447.796 139.953L441.373 126.908L428.327 133.33Z" fill="#5793CE" />
  <path d="M87.6841 357.338L77.692 362.25L82.6044 372.243L92.5965 367.33L87.6841 357.338Z" fill="#5793CE" />
  <path d="M479.532 34.8552L420.104 0.540991C419.659 0.235828 419.141 0.0526603 418.603 0.00976744C418.065 -0.0331254 417.525 0.0657523 417.037 0.296523L411.281 3.60793L415.326 5.09695V48.3675C415.353 50.1576 415.799 51.9166 416.629 53.503C417.458 55.0894 418.649 56.459 420.104 57.5017L476.909 90.3046V95.3717L482.732 92.0159C483.303 91.5686 483.75 90.9823 484.03 90.3131C484.31 89.6439 484.414 88.9141 484.332 88.1933V43.9893C484.301 42.1976 483.851 40.4381 483.017 38.8518C482.184 37.2656 480.99 35.8968 479.532 34.8552Z" fill="#D3008D" />
  <path d="M473.665 95.172L414.237 60.88C412.783 59.836 411.594 58.4662 410.764 56.8801C409.934 55.294 409.487 53.5356 409.459 51.7458V7.54185C409.459 4.03042 411.682 2.40806 414.237 3.94153L473.665 38.2335C475.119 39.2775 476.308 40.6473 477.138 42.2334C477.968 43.8195 478.415 45.5779 478.443 47.3677V91.5716C478.443 95.0831 476.31 96.7054 473.665 95.172Z" fill="#FF2C9C" />
  <path d="M434.217 45.5896C434.217 48.1453 432.439 49.1899 430.217 47.812C429.051 47.0473 428.082 46.0193 427.387 44.8109C426.692 43.6026 426.291 42.2477 426.216 40.8558C426.216 38.3 427.994 37.2555 430.217 38.6334C431.382 39.398 432.352 40.426 433.047 41.6344C433.742 42.8428 434.143 44.1976 434.217 45.5896Z" fill="white" />
  <path d="M446.373 52.6567C446.373 55.2125 444.573 56.257 442.373 54.8791C441.206 54.1191 440.235 53.0957 439.536 51.8913C438.837 50.6869 438.431 49.3352 438.351 47.9452C438.351 45.3894 440.151 44.3448 442.373 45.7227C443.536 46.4852 444.503 47.5097 445.198 48.7139C445.893 49.9182 446.295 51.2686 446.373 52.6567Z" fill="white" />
  <path d="M458.53 59.6798C458.53 62.2356 456.73 63.2579 454.508 61.9023C453.342 61.1376 452.373 60.1096 451.678 58.9012C450.983 57.6929 450.582 56.338 450.507 54.9461C450.507 52.3903 452.307 51.368 454.508 52.7237C455.677 53.4859 456.651 54.5128 457.35 55.7213C458.049 56.9298 458.453 58.2859 458.53 59.6798Z" fill="white" />
  <path d="M68.7395 410.8L10.4454 377.131C10.0121 376.827 9.50575 376.644 8.97843 376.601C8.45111 376.558 7.92196 376.657 7.44523 376.886L1.77808 380.153L5.75616 381.598V424.046C5.79305 425.79 6.23608 427.502 7.05009 429.045C7.8641 430.588 9.02663 431.92 10.4454 432.936L66.1616 465.116V470.072L71.8732 466.783C72.4365 466.345 72.8776 465.77 73.154 465.112C73.4304 464.454 73.5327 463.736 73.4512 463.027V419.69C73.4205 417.942 72.9777 416.226 72.159 414.681C71.3404 413.137 70.1688 411.807 68.7395 410.8Z" fill="#5793CE" />
  <path d="M62.9835 470.072L4.68941 436.313C3.27558 435.293 2.1166 433.96 1.30315 432.418C0.489704 430.876 0.043713 429.167 0 427.424V384.086C0 380.642 2.22253 379.041 4.68941 380.531L62.9835 414.2C64.4087 415.209 65.5759 416.54 66.3907 418.085C67.2054 419.629 67.6446 421.344 67.6728 423.09V466.449C67.6728 469.872 65.5837 471.472 62.9835 470.072Z" fill="#D4ECFF" />
  <path d="M24.2908 421.379C24.2908 423.868 22.5352 424.89 20.3572 423.601C19.2118 422.853 18.2588 421.845 17.5754 420.66C16.892 419.474 16.4973 418.145 16.4235 416.778C16.4235 414.267 18.1792 413.267 20.3572 414.556C21.5051 415.302 22.4599 416.309 23.1436 417.495C23.8274 418.681 24.2205 420.012 24.2908 421.379Z" fill="white" />
  <path d="M36.2033 428.246C36.2033 430.758 34.4476 431.78 32.2696 430.469C31.1259 429.724 30.1738 428.72 29.4904 427.538C28.8069 426.357 28.4114 425.031 28.3359 423.668C28.3359 421.157 30.1139 420.134 32.2696 421.446C33.4158 422.188 34.3699 423.191 35.0536 424.373C35.7373 425.555 36.1314 426.883 36.2033 428.246Z" fill="white" />
  <path d="M48.1375 435.135C48.1375 437.647 46.3595 438.647 44.2038 437.358C43.0583 436.609 42.1053 435.602 41.4219 434.416C40.7385 433.231 40.3439 431.901 40.27 430.535C40.27 428.024 42.0258 427.024 44.2038 428.313C45.3395 429.071 46.2852 430.081 46.9674 431.265C47.6496 432.448 48.05 433.772 48.1375 435.135Z" fill="white" />
</svg>

export default RegisSvg

