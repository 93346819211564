import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Notification from '../SvgCompoents/Notification';
import styles from './DashboardCard.module.scss';
interface IProps {
  task_type?: string,
  task_name?: string,
  company_name?: string,
  created_at?: string,
  manager?: string,
  status?: string,
  id: number
}
export default function DashboardCard({ task_type, task_name, company_name, created_at, status, id, manager }: IProps) {
  const { t } = useTranslation();

  return (
    <Link to={`/profile/dashboard/${id}`} className={`${status} ${styles.dashboardCard}`}>
      <div className={`d-flex justify-content-between ${styles.dashboardCardTop}`}>
        <span className={`${styles.taskName}`}>{task_name}</span>
        <span className={styles.taskType}>{task_type}</span>
        {/* <div className={`${styles.notificationCont}`}>
          <Notification />
          <div className={`${styles.round}`}></div>
        </div> */}
      </div>
      <div className={`d-flex w-100 align-items-center justify-content-between ${styles.dashboardCardCont}`}>
        <div className={`w-100 ${styles.dashboardCardMain}`}>
          <div className={styles.companyName}>{company_name}</div>
         {manager && <div className='d-flex align-items-center mb-1'>
            <div className={`d-flex justify-content-center align-items-center ${styles.managerSrc}`}>
              <img src='/managerDefaultImg.svg' />
            </div>
            <span className={styles.managerName}>{manager}</span>
          </div>}
          <div className={styles.createdAt}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8C16 12.4183 12.4183 16 8 16C3.576 16 0 12.4 0 8C0 3.58172 3.58172 0 8 0ZM8.0001 14.3996C11.5347 14.3996 14.4001 11.5342 14.4001 7.99961C14.4001 4.46499 11.5347 1.59961 8.0001 1.59961C4.46548 1.59961 1.6001 4.46499 1.6001 7.99961C1.6001 11.5342 4.46548 14.3996 8.0001 14.3996ZM8.4 8.2V4H7.2V8.8L11.4 11.32L12 10.336L8.4 8.2Z" fill="#006884" />
            </svg>
            {created_at}</div>
        </div>


      </div>
    </Link>
  );
}


