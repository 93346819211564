// Firebase Cloud Messaging Configuration File. 
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { API, setApiAuthorizationHeader } from '../store/services/API';

const firebaseConfig = {
    apiKey: "AIzaSyAzz3ttHWkoCKqjGAPV8CXLk8-UXcmJvY8",
    authDomain: "rb-controller.firebaseapp.com",
    projectId: "rb-controller",
    storageBucket: "rb-controller.appspot.com",
    messagingSenderId: "829008506816",
    appId: "1:829008506816:web:b723f13bd6a8be5ffde225",
    measurementId: "G-ZEE0X82L62"
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = () => {
    try {

    } catch (error) {

    }
    return getToken(messaging, { vapidKey: `BB8pRePl30UPio5sRA8E02hwUNYSF2Fky2bhDUtXThxyc1Ka8CqnkQ2_VNjY_ZDJ4ZllEyr25Ix9w6b3MAkNOqY` })
        .then((currentToken) => {
            if (currentToken) {
                localStorage.setItem('firebaseToken', currentToken)
                try {
                    const token = localStorage?.getItem('rbc-access-token')
                    setApiAuthorizationHeader(token)
                    API.post('/push-token/', { 
                        token: currentToken,
                        type: "web"
                    })
                } catch (error) {
                    console.log(error);
                }
                // Perform any other neccessary action with the token
            } else {
                // Show permission request UI
                console.log('No registration token available. Request permission to generate one.');
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        });
};
export const deleteToken = () => {
    try {
        const token = localStorage?.getItem('rbc-access-token');
        const pushToken = localStorage?.getItem('firebaseToken')
        setApiAuthorizationHeader(token)
        API.post('/delete-push-token/', { 
            type: "web",
            token:pushToken
        })
    } catch (error) {
        console.log(error);
    }
    
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });


