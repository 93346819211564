const Notification = () => <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
  <g clip-path="url(#clip0_542_2663)">
    <path d="M33.8501 26.7789C32.6689 25.7825 31.7198 24.5399 31.0692 23.1383C30.4185 21.7366 30.082 20.2097 30.0833 18.6643V14.25C30.0833 8.67825 25.9445 4.066 20.5833 3.29333V1.58333C20.5833 1.16341 20.4165 0.76068 20.1196 0.463748C19.8226 0.166815 19.4199 0 19 0C18.5801 0 18.1773 0.166815 17.8804 0.463748C17.5835 0.76068 17.4166 1.16341 17.4166 1.58333V3.29333C12.0539 4.066 7.91665 8.67825 7.91665 14.25V18.6643C7.91726 20.2128 7.57913 21.7426 6.92598 23.1466C6.27282 24.5505 5.32045 25.7946 4.13565 26.7916C3.70745 27.1607 3.40225 27.6518 3.26093 28.1992C3.1196 28.7466 3.1489 29.3241 3.34489 29.8544C3.54087 30.3846 3.8942 30.8424 4.35755 31.1663C4.8209 31.4902 5.37215 31.6648 5.93748 31.6667H32.0625C33.5904 31.6667 34.8333 30.4237 34.8333 28.8958C34.8333 28.0852 34.4802 27.3188 33.8501 26.7789ZM19 38C20.368 37.9981 21.6936 37.5247 22.7532 36.6594C23.8129 35.7942 24.5418 34.59 24.8171 33.25H13.1828C13.4582 34.59 14.1871 35.7942 15.2467 36.6594C16.3064 37.5247 17.632 37.9981 19 38Z" fill="#006884" />
  </g>
  <defs>
    <clipPath id="clip0_542_2663">
      <rect width="38" height="38" fill="white" />
    </clipPath>
  </defs>
</svg>
export default Notification