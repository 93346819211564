import style from './AddApplication.module.scss'
import { useTranslation } from 'react-i18next';
import * as Yup from "yup";
import { Form, Formik, FormikHelpers } from 'formik';
import { CustomButtosWithIcon, CustomInput } from '../../../Components';
import { useAddImagesMutation } from '../../../store/services/photo';
import { useAddAccountantsMutation } from '../../../store/services/accountants';
import { useParams } from 'react-router-dom';


export default function AddApplication() {
  const { t, i18n } = useTranslation();
    const params =useParams();
  interface Values {
    user_comment: string;
  }
  const SignupSchema = Yup.object().shape({
    user_comment: Yup.string()
      .required(t('required')),
  });

  const [addImages, { data }] = useAddImagesMutation();
  const [addAccountantsRequest, { isSuccess }] = useAddAccountantsMutation();

  return (
    <div className={`w-100`}>
      <div className={style.profilePhotos}>
        {/* <h1 className={style.myProfile}>{t('addEmployees')}</h1> */}
        <div className={`w-100 d-flex justify-content-between ${style.nameImages}`}>
         
        </div>
      </div>
      <div className={style.formsCont}>
        <Formik
          initialValues={{
            user_comment :''
          }}
          validationSchema={SignupSchema}
          onSubmit={(
            values: Values,
            { setSubmitting }: FormikHelpers<Values>
          ) => {
            addAccountantsRequest({
                ...values,
                user :localStorage.getItem('rbcId') ,
                accountant :params.id,
                // accountant_comment :''
            });
          }}
        >
          {({ errors, touched }: any) => (
            <Form >
              <div className={`d-flex flex-wrap justify-content-between ${style.inputs}`}>
                <CustomInput name='user_comment' title={t('userComment')} />
              </div>
              <div className='d-flex flex-wrap justify-content-between align-items-center w-100'>
            <CustomButtosWithIcon
              title={'save'}
              icon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z" fill="#FEFEFE" />
              </svg>}
              type='submit'
              onClick={() => console.log()}
            />
          </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}


