import { useState, useCallback, useMemo, useEffect } from "react";
import styleProfile from "./editCompany.module.scss";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Form, Formik, FormikHelpers } from "formik";
import { Params, useNavigate, useParams } from "react-router-dom";
import {
  AddInput,
  CustomButtosWithIcon,
  CustomInput,
  CustomSelect,
  CustomSelectFormik,
  ProfileFileUpload,
} from "../../../Components";
import Close from "../../../Components/SvgCompoents/Close";
import { useGetManagersQuery } from "../../../store/services/managers";
import { useAddCompaniesMutation, useEditCompaniesMutation, useGetCompaniesQuery, useGetCompanyByIdQuery } from "../../../store/services/companies";
import { useAddImagesMutation } from "../../../store/services/photo";
import ChangePhoto from "../../../Components/SvgCompoents/ChangePhoto";
import { toast } from "react-toastify";
import EditUser from "../../../Components/SvgCompoents/EditUser";
import Camera from "../../../Components/SvgCompoents/Camera";

export const EditAccountantWorkerCompany = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params: any = useParams();
  const { data: companyData, isLoading } = useGetCompanyByIdQuery(+params?.id);
  const [disable, setDisable] = useState(true);
  const [addressList, setAddressList] = useState<any>(companyData?.address);
  const [managerList, setManagerList] = useState<string[]>(companyData?.managers ? companyData?.managers.map((el: any) => el.id) : []);
  const { data: managers } = useGetManagersQuery({ limit: 100000, offset: 0 });
  const { data: clinetsManagers } = useGetManagersQuery({ limit: 100000, offset: 0, client_id: companyData?.creator });

  useEffect(() => {
    setAddressList(companyData?.address)
  }, [companyData?.address])

  useEffect(() => {
    setManagerList(companyData?.managers.map((el: any) => el.id))
  }, [companyData?.managers])

  const SignupSchema = Yup.object().shape({
    name: Yup.string().required(t("required")),
    hvhh: Yup.string().required(t("required")),
    // addresses: Yup.array().required(t("required")),
  });
  interface Values {
    name: string;
    hvhh: string;
    managers: number[];
  }
  const handleEdit = useCallback(
    () =>
      setTimeout(() => {
        setDisable(false);
      }, 1000),
    []
  );
  const handleAddAddress = useCallback(
    (address: string) => {
      const newArray = address
        ? [...addressList, { name: address }]
        : [...addressList];
      setAddressList(newArray);
    },
    [addressList]
  );

  const handleDeleteAddress = useCallback(
    (ind: number) => {
      const newArray = [...addressList];
      newArray.splice(ind, 1);
      setAddressList(newArray);
    },
    [addressList]
  );

  const handleAddManager = useCallback(
    (managerId: any) => {
      // const selected = managerList.find((element) => element === managerId)
      // const newArray = selected ? managerList.filter((el) => el !== selected) : [...managerList, managerId];
      setManagerList(managerId);
    },
    [managerList]
  );

  const handleDeleteManager = useCallback(
    (ind: number) => {
      const newArray = [...managerList];
      newArray.splice(ind, 1);
      setManagerList(newArray);
    },
    [managerList]
  );

  const selectedManagers = useMemo(() => {
    let newArray: any = [];
    for (let index = 0; index < managerList?.length; index++) {
      const element = managerList[index];
      for (let ind = 0; ind < clinetsManagers?.length; ind++) {
        const el = clinetsManagers[ind];
        if (el.id === +element) {
          newArray.push(el);
        }
      }
    }
    return newArray;
  }, [managerList, clinetsManagers]);

  const [addImages, { data }] = useAddImagesMutation();
  const [editCompany, { data: editedCompanyData }] = useEditCompaniesMutation()

  return (isLoading ? <div></div> : <div className={`w-100`}>
    <Formik
      initialValues={{
        name: companyData?.name,
        hvhh: companyData?.hvhh,
        managers: [],
        // accountant_worker: companyData?.accountant_worker
      }}
      validationSchema={SignupSchema}
      onSubmit={(
        values: Values,
        { setSubmitting }: FormikHelpers<Values>
      ) => {
        editCompany({
          ...values,
          id: params?.id,
          managers: managerList.length > 0 ? managerList : [],
          address: addressList,
          image: data ? data : companyData?.image,

        })
          .then((res: any) => {
            if (res.data) {
              toast.success(t('editProfileSuccess'));
              setDisable(true)
            }
          })
      }}
    >
      {({ errors, touched }: any) => (
        <Form>
          <div className={styleProfile.profilePhotos}>
            {/* <h1 className={styleProfile.myProfile}>Edit company data</h1> */}
            <div
              className={`w-100 d-flex justify-content-between ${styleProfile.nameImages}`}
            >
              <div className="d-flex flex-wrap justify-content-between align-items-center w-100">
                {/* <div className={styleProfile.profilePhotoEdit}>
                  <img
                    src={
                      data ? `${process.env.REACT_APP_API_KEY}${data}` :
                        companyData?.image
                          ? `${process.env.REACT_APP_API_KEY}${companyData?.image}`
                          : `/companyDefaultImg.svg`
                    }
                    className={styleProfile.profilePhoto}
                  />
                  <label>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e: any) => {
                        const file: any = e?.target?.files[0];
                        if (file) {
                          const formdata = new FormData();
                          formdata.append("file_url", file);
                          addImages(formdata).then((res: any) => {
                            // editRequest(values)
                          });
                        }
                      }}
                    />
                    <div className={`d-flex justify-content-center align-items-center ${styleProfile.editAvatar}`}>
                      <Camera />
                    </div>
                  </label>
                </div> */}
                <ProfileFileUpload
                  forCompany={true}
                  avatar_image={companyData?.image
                    ? `${process.env.REACT_APP_API_KEY}${companyData?.image}`
                    : ''}
                  request={(img: string) => {
                    editCompany({
                      image: img,
                      id: +params?.id
                    });
                  }} />
                {disable ? (
                  <div>
                    <CustomButtosWithIcon
                      title={t("editProfile")}
                      type={"button"}
                      onClick={handleEdit}
                      icon={<EditUser />}
                    />
                  </div>
                ) : (
                  <div>
                    <CustomButtosWithIcon
                      title={t("save")}
                      type="submit"
                      onClick={console.log("ggg")}
                      icon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z"
                            fill="#FEFEFE"
                          />
                        </svg>
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styleProfile.formsCont}>
            <div
              className={`d-flex flex-wrap justify-content-between ${styleProfile.inputs}`}
            >
              <CustomInput readonly={disable} name="name" title={t("companyName")} />
              <CustomInput readonly={disable} name="hvhh" title={t("HVHH")} />
              {/* <CustomSelectFormik
                label={t("accountantCompanies")}
                list={managers?.map((el: any) => ({
                  name: `${el.user.first_name} ${el.user.last_name}`,
                  id: el.id,
                }))}
                name='accountant_worker'
              /> */}
              <div className={styleProfile.withSelected}>
                <AddInput
                  readonly={disable}
                  label={t("addresses")}
                  addAddress={(val: string) => handleAddAddress(val)}
                />
                {addressList?.map((el: any, ind: number) => (
                  <div key={ind} className={`${styleProfile.selected} ${styleProfile.selectedAddress}`}>
                    <span className={styleProfile.title}>{el.name}</span>
                    {!disable && <span onClick={() => handleDeleteAddress(ind)}>
                      <Close />
                    </span>}
                  </div>
                ))}
              </div>
              {clinetsManagers?.length > 0 && <div className={styleProfile.withSelected}>
                <CustomSelect
                  label={t("managers")}
                  list={clinetsManagers?.map((el: any) => ({
                    name: `${el.user.first_name} ${el.user.last_name}`,
                    id: el.id,
                  }))}
                  value={managerList}
                  multiple={true}
                  readonly={disable}
                  onChange={handleAddManager}
                />
                {selectedManagers?.map((el: any, ind: number) => {
                  return (
                    <div key={ind} className={styleProfile.selected}>
                      {el?.avatar_image
                        ? <img
                          src={`${process.env.REACT_APP_API_KEY}${el?.avatar_image}`}
                          className={styleProfile.image}
                        />
                        : <div><img
                          src={"/managerDefaultImg.svg"}
                          className={styleProfile.imageDefault}
                        /></div>}
                      <span className={styleProfile.title}>
                        {el?.user?.first_name} {el?.user?.last_name}
                      </span>
                      {!disable && <span onClick={() => handleDeleteManager(ind)}>
                        <Close />
                      </span>}
                    </div>
                  );
                })}
              </div>}

            </div>
          </div>
        </Form>
      )}
    </Formik>
  </div>
  );
};

export default EditAccountantWorkerCompany;
