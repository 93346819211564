
const Broker = () => <svg xmlns="http://www.w3.org/2000/svg" width="74" height="74" viewBox="0 0 74 74" fill="none">
  <path d="M50.1667 45.5912L36.9997 41.9331H22.1998L9.03286 45.5912C6.43548 46.3125 4.14598 47.865 2.51467 50.0111C0.883353 52.1572 8.11215e-05 54.7786 0 57.4744V73.9998H59.1995V57.4744C59.1994 54.7786 58.3162 52.1572 56.6849 50.0111C55.0535 47.865 52.764 46.3125 50.1667 45.5912Z" fill="#EDEFF1" />
  <path d="M43.1866 43.6511L37.0003 41.9331H22.2004L16.0141 43.6511L24.5832 56.3459L29.6004 50.0731L34.6175 56.3459L43.1866 43.6511ZM9.86719 66.5998H12.3338V73.9998H9.86719V66.5998ZM46.8669 66.5998H49.3335V73.9998H46.8669V66.5998Z" fill="#D3D3D3" />
  <path d="M37.0003 33.3V41.9334L29.6004 48.1001L22.2004 41.9334V33.3H37.0003Z" fill="#FCB98B" />
  <path d="M43.1668 16.0332V23.4332C43.1668 30.9257 37.0927 36.9999 29.6002 36.9999C22.1078 36.9999 16.0337 30.9257 16.0337 23.4332V16.0332C16.0337 14.7248 16.5534 13.47 17.4786 12.5448C18.4038 11.6196 19.6586 11.0999 20.967 11.0999H38.2335C39.5419 11.0999 40.7967 11.6196 41.7219 12.5448C42.647 13.47 43.1668 14.7248 43.1668 16.0332Z" fill="#F0C7B7" />
  <path d="M38.2335 3.7H38.0596C37.7887 2.64295 37.1741 1.7059 36.3126 1.03615C35.4511 0.366395 34.3914 0.00191595 33.3002 0H28.3669C21.5553 0 16.0337 5.52164 16.0337 12.3333V23.4334L18.5003 16.0333H33.3002C35.5967 16.0333 37.5083 14.4571 38.0596 12.3333H38.7219C39.283 12.3334 39.8273 12.5249 40.265 12.876C40.7027 13.2271 41.0076 13.717 41.1294 14.2647L43.1668 23.4334V8.63334C43.1668 7.32494 42.647 6.07013 41.7219 5.14494C40.7967 4.21976 39.5419 3.7 38.2335 3.7Z" fill="#5F5555" />
  <path d="M43.167 16.0332H43.9551C44.3133 16.0332 44.6673 16.1112 44.9924 16.2618C45.3175 16.4124 45.6059 16.6321 45.8374 16.9055C46.069 17.1788 46.2383 17.4994 46.3334 17.8448C46.4286 18.1902 46.4473 18.5522 46.3884 18.9056L45.9777 21.3723C45.8814 21.9482 45.5841 22.4712 45.1386 22.8485C44.6931 23.2259 44.1282 23.433 43.5444 23.4332H43.167V16.0332ZM16.0339 16.0332H15.2458C14.8875 16.0332 14.5335 16.1112 14.2084 16.2618C13.8833 16.4124 13.595 16.6321 13.3634 16.9055C13.1318 17.1788 12.9625 17.4994 12.8674 17.8448C12.7722 18.1902 12.7535 18.5522 12.8124 18.9056L13.2231 21.3723C13.3194 21.9482 13.6167 22.4712 14.0622 22.8485C14.5078 23.2259 15.0726 23.433 15.6565 23.4332H16.0339V16.0332Z" fill="#F0C7B7" />
  <path d="M22.1999 39.4668L17.2666 43.3037L24.6665 54.2668L29.5998 48.1001L22.1999 39.4668ZM36.9998 39.4668L41.9331 43.3037L34.5331 54.2668L29.5998 48.1001L36.9998 39.4668Z" fill="#EDEFF1" />
  <path d="M34.5331 54.2665L29.5998 48.0999L24.6665 54.2665L27.1332 57.9665L24.6665 73.9999H34.5331L32.0664 57.9665L34.5331 54.2665Z" fill="#FFB0AA" />
  <path d="M60.4335 13.5667C52.9411 13.5667 46.8669 19.6408 46.8669 27.1333C46.8669 29.658 47.5687 32.0136 48.77 34.0375L46.8669 40.7L53.5294 38.797C55.6171 40.0422 58.0026 40.6998 60.4335 40.7C67.9259 40.7 74.0001 34.6258 74.0001 27.1333C74.0001 19.6408 67.9259 13.5667 60.4335 13.5667Z" fill="#80D6FB" />
  <path d="M69.0656 25.9004L61.6657 29.6004L56.7324 27.1337L51.7991 29.6004V35.7671H69.0656V25.9004Z" fill="#006884" />
  <path d="M61.6662 27.1333C64.3908 27.1333 66.5995 24.9245 66.5995 22.1999C66.5995 19.4753 64.3908 17.2666 61.6662 17.2666C58.9416 17.2666 56.7329 19.4753 56.7329 22.1999C56.7329 24.9245 58.9416 27.1333 61.6662 27.1333Z" fill="#FFE352" />
  <path d="M62.3197 21.5832H61.0124C60.919 21.5872 60.8258 21.5722 60.7383 21.5391C60.6508 21.5061 60.571 21.4557 60.5035 21.391C60.436 21.3264 60.3823 21.2487 60.3456 21.1627C60.3089 21.0767 60.29 20.9842 60.29 20.8907C60.29 20.7972 60.3089 20.7047 60.3456 20.6187C60.3823 20.5327 60.436 20.455 60.5035 20.3904C60.571 20.3257 60.6508 20.2753 60.7383 20.2423C60.8258 20.2092 60.919 20.1942 61.0124 20.1982H62.3209C62.7033 20.1982 63.0141 20.509 63.0141 20.8913H64.2474C64.2474 19.8294 63.3841 18.9649 62.3209 18.9649H62.2827V18.2729H61.0494V18.9649H61.0124C60.5162 18.9866 60.0476 19.199 59.7042 19.5578C59.3608 19.9166 59.1691 20.3941 59.1691 20.8907C59.1691 21.3873 59.3608 21.8648 59.7042 22.2236C60.0476 22.5824 60.5162 22.7948 61.0124 22.8166H62.3209C62.4143 22.8126 62.5076 22.8276 62.595 22.8606C62.6825 22.8937 62.7624 22.944 62.8298 23.0087C62.8973 23.0734 62.951 23.1511 62.9877 23.2371C63.0244 23.3231 63.0433 23.4156 63.0433 23.5091C63.0433 23.6026 63.0244 23.6951 62.9877 23.7811C62.951 23.867 62.8973 23.9447 62.8298 24.0094C62.7624 24.0741 62.6825 24.1245 62.595 24.1575C62.5076 24.1905 62.4143 24.2055 62.3209 24.2016H61.0124C60.9214 24.2016 60.8312 24.1837 60.7471 24.1488C60.663 24.114 60.5866 24.0629 60.5223 23.9986C60.4579 23.9342 60.4069 23.8578 60.372 23.7737C60.3372 23.6896 60.3193 23.5995 60.3193 23.5085H59.0859C59.0859 24.5704 59.9493 25.4349 61.0124 25.4349H61.0494V26.1268H62.2827V25.4349H62.3197C62.8159 25.4132 63.2845 25.2008 63.6279 24.842C63.9713 24.4832 64.163 24.0057 64.163 23.5091C64.163 23.0124 63.9713 22.535 63.6279 22.1762C63.2845 21.8174 62.8159 21.605 62.3197 21.5832Z" fill="black" />
</svg>

export default Broker

