const AddIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <g clip-path="url(#clip0_417_2898)">
    <path d="M9.99968 0.833984C8.18668 0.833984 6.4144 1.3716 4.90695 2.37885C3.3995 3.38609 2.22459 4.81773 1.53078 6.49272C0.83698 8.16771 0.655449 10.0108 1.00915 11.789C1.36284 13.5671 2.23589 15.2005 3.51787 16.4825C4.79985 17.7644 6.43319 18.6375 8.21135 18.9912C9.98951 19.3449 11.8326 19.1634 13.5076 18.4695C15.1826 17.7757 16.6142 16.6008 17.6215 15.0934C18.6287 13.5859 19.1663 11.8136 19.1663 10.0007C19.1635 7.57038 18.1968 5.24047 16.4783 3.52201C14.7599 1.80354 12.4299 0.836851 9.99968 0.833984ZM14.1663 10.834H10.833V14.1673C10.833 14.3883 10.7452 14.6003 10.5889 14.7566C10.4327 14.9129 10.2207 15.0007 9.99968 15.0007C9.77866 15.0007 9.5667 14.9129 9.41042 14.7566C9.25414 14.6003 9.16634 14.3883 9.16634 14.1673V10.834H5.83301C5.612 10.834 5.40004 10.7462 5.24376 10.5899C5.08748 10.4336 4.99968 10.2217 4.99968 10.0007C4.99968 9.77964 5.08748 9.56768 5.24376 9.4114C5.40004 9.25512 5.612 9.16732 5.83301 9.16732H9.16634V5.83398C9.16634 5.61297 9.25414 5.40101 9.41042 5.24473C9.5667 5.08845 9.77866 5.00065 9.99968 5.00065C10.2207 5.00065 10.4327 5.08845 10.5889 5.24473C10.7452 5.40101 10.833 5.61297 10.833 5.83398V9.16732H14.1663C14.3874 9.16732 14.5993 9.25512 14.7556 9.4114C14.9119 9.56768 14.9997 9.77964 14.9997 10.0007C14.9997 10.2217 14.9119 10.4336 14.7556 10.5899C14.5993 10.7462 14.3874 10.834 14.1663 10.834Z" fill="#006884" />
  </g>
  <defs>
    <clipPath id="clip0_417_2898">
      <rect width="20" height="20" fill="white" />
    </clipPath>
  </defs>
</svg>
export default AddIcon