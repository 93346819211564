import { useEffect, useState } from "react";
import styles from "./Companies.module.scss";
import { useTranslation } from "react-i18next";
import {
  NotificationMenu,
  SearchInput,
} from "../../../Components";
import CompanyItem from "../../../Components/CompanyItem";
import { useGetCompaniesForAccountantQuery, usePrefetch } from "../../../store/services/companies";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { ICompanyItem, IMe } from "../../../types";

export default function Companies() {

  const [activePage, setActivePage] = useState<number>(1);
  const { t } = useTranslation();
  const { data, isLoading } = useGetCompaniesForAccountantQuery({ limit: 10, offset: 0, page: activePage });
  const prefetchPage = usePrefetch('getCompaniesForAccountant');
  useEffect(() => {
    if (data?.count) {
      prefetchPage({ limit: 12, offset: (activePage - 1) * 12, page: activePage }, { force: true })
    }
  }, []);
  
  return (
    <div className={`w-100`}>
      <div className={`w-100 d-none d-lg-flex ${styles.searchNotification}`}>
        <SearchInput onClick={() => console.log("dskfj")} />
        {/* <NotificationMenu /> */}
      </div>
      <div className="d-flex w-100 justify-content-between">
        <h1 className={styles.pageHeading}>{t("companies")}</h1>
      </div>
      <div className={`${styles.employeesArray}`}>
        {data?.results?.map((item: ICompanyItem, ind: number) => {
          return (
            <CompanyItem
              to={`/profile/companies/${item?.id}`}
              id={item?.id}
              key={ind}
              img={
                item?.image
                  ? `${process.env.REACT_APP_API_KEY}${item?.image}`
                  : ""
              }
              tarif={item?.tarif}
              name={item?.name}
              profession={item?.managers
                ?.map((el: IMe) => `${el?.user?.first_name} ${el?.user?.last_name}`)
                .join(",")}
              managers={item?.managers}
              accountant_worker={item?.accountant_worker}
              is_active={item?.is_active}
            />
          );
        })}
        
      </div> 
      {data?.count > 10 && (
          <Stack spacing={2} marginTop={10}>
            <Pagination
              count={Math.ceil(data?.count / 10)}
              page={activePage}
              showFirstButton
              showLastButton
              onChange={(_, page) => {
                setActivePage(page);
              }}
            />
          </Stack>
        )}
      {!isLoading && data?.results.length === 0 && <div className={styles.noDataAvailableCont}>
        <img src={"/noDataAvailable.png"} />
      </div>}
    </div>
  );
}
