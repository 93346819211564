import style from './ImageText.module.scss';
export default function ImageText() {
    return (
        <div className={style.container}>
            <div className='container d-flex align-items-center'>
            <img className={style.appImage} src='/appImage.png' />
            <div className='d-flex flex-column'>
                <div className={style.heading}>Create Virtual Cards To Make Online Transactions </div>
                <p  className={style.title}>
                    Scandapay allows users to create virtual cards. These virtual cards can be used to pay for goods and services online. Users can create a Naira virtual card (NGN Card), or a Dollar virtual card (USD Card).

                    The experience of the virtual dollar card was also improved by redesigning the confirmation screens, this is to ensure that user confirm a transaction before it goes through.
                </p>
            </div>
            </div>
        </div>
    )
}