const Company = () => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <g clip-path="url(#clip0_654_3155)">
    <path d="M11 0H3C2.20435 0 1.44129 0.31607 0.87868 0.87868C0.31607 1.44129 0 2.20435 0 3L0 24H14V3C14 2.20435 13.6839 1.44129 13.1213 0.87868C12.5587 0.31607 11.7956 0 11 0ZM6 19H3V17H6V19ZM6 15H3V13H6V15ZM6 11H3V9H6V11ZM6 7H3V5H6V7ZM11 19H8V17H11V19ZM11 15H8V13H11V15ZM11 11H8V9H11V11ZM11 7H8V5H11V7ZM21 5H16V24H24V8C24 7.20435 23.6839 6.44129 23.1213 5.87868C22.5587 5.31607 21.7956 5 21 5ZM21 19H19V17H21V19ZM21 15H19V13H21V15ZM21 11H19V9H21V11Z" fill="#939FA8" />
  </g>
  <defs>
    <clipPath id="clip0_654_3155">
      <rect width="24" height="24" fill="white" />
    </clipPath>
  </defs>
</svg>
export default Company