import { useEffect, useState } from 'react';
import { Pagination, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styles from './Applications.module.scss';
import Activity from '../Menu/Activity';

export default function ActivityPage() {

    return (
        <div className={`w-100`}>
            <Activity/>
        </div>
    );
}


