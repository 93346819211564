import FormControl from '@mui/material/FormControl';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import styles from './BankScore.module.scss';
import { useField } from 'formik';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

interface IProps {
  multiple?: boolean,
  title: string,
  list: any,
  onChange: (val: string) => void,
  value?: any,
  readonly?: boolean,
  showValue?: boolean,
  className?: string
}

const BankScore = ({ readonly = false, title, list, className, multiple, onChange, value, showValue, values, second_name, ...props }: any) => {
  const [field, meta, helpers] = useField(props);
  const [bankField, bankMeta] = useField({ ...props, name: second_name });
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    '& .Mui-selected': {
      backgroundColor: 'rgba(25, 118, 210, 0.08)'
    },
    '& .MuiInputBase-input': {
      height: 30,
      borderRadius: 12,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: 'none',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        borderRadius: 12,
        borderColor: '#none',
        boxShadow: 'none',
      },
    },
  }));
  const { t } = useTranslation();
  const handleChange = (val: SelectChangeEvent) => {
    onChange(val.target.value as any)
  }
  
  return (
    <label className={`${styles.cont} d-flex flex-column ${className}`}>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='d-flex'>
          <span className={styles.title}>{title} </span>
          {meta.touched && bankMeta.error && <span className='error'>*</span>}
        </div>
      </div>
      <div className={`${styles.selectCont} d-flex align-items-center`}>
        <FormControl sx={{ maxWidth: 82, width: '100%', height: 35 }} variant="standard">
          <Select
            disabled={readonly}
            multiple={multiple}
            // value={props.value ? props.value : list ? list[0]?.id : []}
            input={<BootstrapInput />}
            // onChange={handleChange}
            {...bankField}
            {...props}
            name={second_name}
            renderValue={(p: any) => values?.bank !== 0
              ?<img className={styles.bankImg} src={list?.filter((el: any) => el?.id == p)[0]?.src} />
              :<div className={styles.bankImgNotAvailable}></div>}
            sx={{ maxWidth: 501, width: '100%', marginBottom: 24, height: 67 }}
          >
            <MenuItem value={0}>
              <div className='d-flex flex'>
                <div className={styles.bankImgNotAvailable} />
                <span>{t('notAvailable')}</span>
              </div>
            </MenuItem>
            {list?.map((el: { id: number, src: string, name: string }) => {
              return <MenuItem key={el.id} value={el.id}>
                <div className='d-flex flex'>
                  <img className={styles.bankImg} src={el?.src} />
                  <span>{el?.name}</span>
                </div>
              </MenuItem>
            })}
          </Select>
        </FormControl>
        <div className={styles.line}></div>
        {values?.bank === 0 ? <div  className={styles.bankScoreInputNotAvailable}>{t('notAvailable')}</div> : <input
          {...field}
          {...props}
          className={styles.bankScoreInput}
        />}
      </div>
      {meta.touched && bankMeta.error && (
        <div className="error error-text">{meta.error}</div>
      )}
    </label>
  );
};
export default BankScore;