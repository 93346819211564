import React, { useCallback, useEffect, useState } from 'react';
import style from './MenuItem.module.scss';
import { useNavigate } from 'react-router-dom';

interface SubMenuItem {
  name: string,
  id: number,
  count: number
}
interface Props {
  icon: React.ReactNode,
  title: string,
  active: boolean,
  withSubMenu?: boolean,
  count?: number,
  array?: void | never[],
  showOnlyIcon?: boolean,
  status?: string
  onClick: () => void,
};

const MenuItem = ({ icon, title, active, array, withSubMenu = false, count, status, showOnlyIcon, onClick }: Props) => {
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [filter, setFilter] = useState<any>();
  const navigate = useNavigate();
  const handleShowSubMenu = useCallback(() => {
    setShowSubMenu(!showSubMenu);
  }, [showSubMenu]);
  useEffect(() => {
    const myParams: any = {}
    const queryParams: any = new URLSearchParams(window.location.search)
    for (const [key, value] of queryParams) {
      myParams[key] = value
    }
    Object.keys(myParams).length > 0 ? setFilter({ ...filter, ...myParams }) : setFilter({})

  }, [window.location.search]);
  
  return (<>
    <div onClick={() => {
      onClick();
      if (withSubMenu) {
        handleShowSubMenu()
      }
    }}
      className={`${style.menuItem} ${active ? style.menuItemActive : ''} ${showOnlyIcon? style.showOnlyIcon :''}`}>
      <div className={`d-flex ${style.iconTitle}`} >
        {icon}
        <div className={style.title}>{title}</div>
        {withSubMenu && count !==0 && <div className={`d-flex justify-content-center align-items-center ${style.badge}`}>{count && count >= 100 ? '99+' : count}</div>}
      </div>
    </div>
    {withSubMenu && showSubMenu && !showOnlyIcon && <div>
      {array?.map((el: SubMenuItem, ind: number) => {
        return <div className={`d-flex justify-content-between align-items-center ${style.subMenu} 
        ${+filter.company_id === el.id
            ? style.subMenuActive
            : ''}`}
          key={ind}
          onClick={() => navigate(`/profile/dashboard/?company_id=${el?.id}&status=${status}`)}
        ><span className={style.companyName}>{el?.name}</span>
          <div className={style.roundBadge}>{ el?.count && el?.count >= 100 ? '99+' : el?.count}</div>
        </div>
      })}
    </div>}
  </>
  )
}
export default MenuItem;