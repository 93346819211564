import React from 'react';
import style from './ComponentWithBorder.module.scss'
import { fileIconSrc } from '../../Utils';

type Props = {
  img?: string,
  transform?: boolean,
  onClick?: any,
};

const ComponentWithBorder = ({ img = '', transform, onClick }: Props) => {
  return (<div className={`${style.borderCont} ${transform ? style.borderContTransform : ""}`}>
    <div className={style.borderItemTopLeft} />
    <div className={style.borderItemTopRight} />
    <div className={style.borderItemBottomLeft} />
    <div className={style.borderItemBottomRight} />
    <img src={(img?.includes('png') || img?.includes('jpg') || img?.includes('webp') || img?.includes('svg') || img?.includes('jpeg')) 
      ? img
      : fileIconSrc(img)}
      className={style.img}
      style={{ objectFit: (img?.includes('png') || img?.includes('jpg') || img?.includes('webp') || img?.includes('svg') || img?.includes('jpeg')) ? 'cover' : 'contain' }}
    />
    <div onClick={(e) => {
      e.preventDefault()
      onClick()
    }} className={style.close}>x</div>
  </div>
  )
}
export default ComponentWithBorder;