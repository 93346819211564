import { createApi } from '@reduxjs/toolkit/query/react'
import queryString from 'query-string';
import axiosBaseQuery from './API'


// Define a service using a base URL and expected endpoints
export const managersApi = createApi({
  reducerPath: 'managersApi',
  tagTypes: ['ManagersApi'],
  baseQuery: axiosBaseQuery({ baseUrl: process.env.REACT_APP_API_KEY }),
  endpoints: (builder) => ({
    getClients: builder.query<any, {}>({
      query: (data:any) => {
        const stringified = queryString.stringify(data);
        return  {url:`/get-my-clients/?${stringified}`, method:'GET'}
      },
      providesTags: [{ type: 'ManagersApi' }],
    }),
    getManagers: builder.query<any, {}>({
      query: (data:any) => {
        const stringified = queryString.stringify(data);
        return  {url:`/get-my-workers/?${stringified}`, method:'GET'}
      },
      providesTags: [{ type: 'ManagersApi' }],
    }),
    addManagers: builder.mutation<any, any>({
      query(body:any) {  
        const {client_id, ...rest} = body; 
        const stringified = queryString.stringify({client_id});
        return {
          url: `/register-worker/?${stringified}`,
          method: 'POST',
          data: rest,
        }
      },
      invalidatesTags: [{ type: 'ManagersApi'}],
    }),
    getManager: builder.query<any, {}>({
      query: (data:any) => {        
        return  {url:`/user-detail/${data.id}/`, method:'GET'}
      },
      providesTags: ['ManagersApi'],
    }),  
    deleteManagers: builder.mutation<any, {id:number | null}>({
      query({id}) {        
        return {
          url: `/managers/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: [{ type: 'ManagersApi'}],
    }), 
    editManager: builder.mutation<any, Partial<any>>({
      query(data) {    
        const {id, ...rest} = data    
        return {
          url: `/edit-user/${id}/`,
          method: 'PUT',
          data: rest
        }
      },
      invalidatesTags: ['ManagersApi'],
    }), 
  }),
})

export const { 
  useGetManagersQuery,  
  useGetManagerQuery, 
  useDeleteManagersMutation, 
  useAddManagersMutation, 
  useEditManagerMutation,
  useGetClientsQuery,
  usePrefetch
} = managersApi
