import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import * as Yup from "yup";
import DeleteIcon from '@mui/icons-material/Delete';
import { Form, Formik, FormikHelpers } from 'formik';
import styles from './TaskPage.module.scss';
import { CustomButtosWithIcon, CustomInput, CustomizedTables, DetailItem, FileDownload } from '../../../Components';
import DocumentCard from '../../../Components/SvgCompoents/DocumentCard';
import ComponentWithBorder from '../../../Components/ComponentWithBorder';
import Recording from '../../../Components/Recording';
import { useEditTasksInvoiceByIdMutation } from '../../../store/services/tasks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAddImagesMutation } from '../../../store/services/photo';
import { Collapse } from '@mui/material';
import { getFileName } from '../../../Utils';
import { IFiles, ITaskPage } from '../../../types';
import DeleteTaskDialog from '../../../Dialogs/DeleteTaskDialog';
import { toast } from 'react-toastify';
interface DataTask {
    data: ITaskPage
}
export default function InvoicePage({ data }: DataTask) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const params = useParams();
    const [files, setFiles] = useState<IFiles>({});
    const [submit, setSubmit] = useState<boolean>(false);
    const [status, setStatus] = useState<string>('done');
    const [complain, setComplain] = useState<string>('');
    const [savedFiles, setSavedFiles] = useState<IFiles>({});
    const navigate = useNavigate();
    const [editTasksInvoiceById] = useEditTasksInvoiceByIdMutation();
    // const { data, isLoading } = useGetTasksByIdQuery({ id: params.id });
    const xml = useMemo(() => {
        return getFileName(data?.invoice?.xml)
    }, [data?.invoice?.xml]);
    const property = useMemo(() => {
        return data?.invoice?.with_vat
            ? ["name", "measurement", "count", "price", "discount", "vat", "sum"]
            : ["name", "measurement", "count", "price", "discount", "sum"]
    }, [])
    const invoice = useMemo(() => {
        return getFileName(data?.invoice?.invoice)
    }, [data?.invoice]);
    const [addImages] = useAddImagesMutation();
    const handleSetFile = useCallback((data: IFiles) => {
        setFiles({ ...files, ...data })
    }, [files])
    const handleDeleteFile = useCallback((name: keyof typeof savedFiles) => {
        if (savedFiles[name]) {
            setSavedFiles((prev: IFiles) => {
                const prevFiles = { ...prev };
                delete prevFiles[name];
                return prevFiles
            })
        } else {
            setFiles((prev: IFiles) => {
                const prevFiles = { ...prev };
                delete prevFiles[name];
                return prevFiles
            })
        }

    }, [savedFiles])

    useEffect(() => {
        if (data?.invoice) {
            const { files, invoice } = data?.invoice
            setSavedFiles({ files: files.map((el: any) => el.url), invoice })
        }
    }, [data])
    const SignupSchema = Yup.object().shape({
        comment: Yup.string(),
    });
    interface Values {
        comment: string
    }
    const handleOpenDeleteTask = useCallback(() => { setOpen(true) }, []);
    const handleCloseDeleteTask = useCallback(() => { setOpen(false) }, []);
    const deleteTask = () => {
        editTasksInvoiceById({ id: data?.invoice?.id, status: 'deleted' })
        handleCloseDeleteTask()
        navigate('/profile/dashboard')
    }
    console.log(data, 'dataaaaa78999999');

    return (
        <div className={`w-100`}>
            <DeleteTaskDialog
                open={open}
                handleClose={handleCloseDeleteTask}
                handleDelete={deleteTask}
            />
            <div className='d-flex justify-content-between flex-nowrap'>
                <div className={`${styles.taskTypeHeading}`}>{t(`${data?.task_type?.name}`)} </div>
                <div className='d-flex align-items-center gap-2'>
                    <div className={`${styles.taskStatus} ${styles[data?.status]}`}>{t(`${data?.status}`)}</div>
                    {data?.status === 'created' && <div onClick={handleOpenDeleteTask} className={`${styles.taskStatus} ${styles.deleteTask}`}>{t('delete')} <DeleteIcon /></div>}
                </div>
            </div>
            <div className='d-flex justify-content-between flex-wrap'>
                <div className='d-flex justify-content-between flex-wrap'>
                    <div>
                        <div className={styles.heading}>{t('detail')} </div>
                        <div className={styles.detailCont}>
                            {data?.task_name && <DetailItem name={t('taskName')} value={data?.task_name} />}
                            {data?.invoice?.address_from && <DetailItem name={t('addressFrom')} value={data?.invoice?.address_from} />}
                            {data?.invoice?.buyer_address && <DetailItem name={t('buyerAddress')} value={data?.invoice?.buyer_address} />}
                            {data?.invoice?.buyer_name && <DetailItem
                                name={t(data?.invoice?.buyer_tin ? 'companyName' : 'buyerName')}
                                value={data?.invoice?.buyer_name} />}
                            {data?.invoice?.car_number && <DetailItem name={t('carNumber')} value={data?.invoice?.car_number} />}
                            {data?.invoice?.buyer_tin && <DetailItem name={t('buyerTin')} value={data?.invoice?.buyer_tin} />}
                            {data?.invoice?.shipping_address && <DetailItem name={t('shippingAddress')} value={data?.invoice?.shipping_address} />}
                            {data?.invoice?.shipping_method && <DetailItem name={t('shippingMethod')} value={t(`${data?.invoice?.shipping_method}`)} />}
                            {data?.invoice?.shipping_date && !data?.invoice?.xml && <DetailItem name={t('shipping_date')} value={moment(data?.invoice?.shipping_date).format('DD-MMMM-YYYY')} />}
                            {data?.created_at && <DetailItem name={t('createdAt')} value={moment(data?.created_at).format('DD-MMMM-YYYY')} />}
                            {data?.recording && <div className={styles.application}>
                                <h4 className={styles.subHeading}>{t('voiceMessage')}</h4>
                                <Recording src={data?.recording} />
                            </div>}

                        </div>

                    </div>

                </div>
                <div className={styles.filesCont}>
                    {data?.invoice?.xml && <div className={styles.heading}> {t('attachedFiles')} </div>}
                    {data?.invoice?.xml && <div className={styles.application}>
                        <h4 className={styles.subHeading}>{t('xmlFile')}</h4>
                        <div className={`d-flex ${styles.cardCont}`}>
                            <FileDownload
                                url={`${process.env.REACT_APP_API_KEY}/${data?.invoice.xml}`}
                                status={data?.status}
                                title={xml}
                            />
                        </div>
                    </div>}
                    {savedFiles?.files?.map((el: any, ind: number) => <FileDownload
                        url={`${process.env.REACT_APP_API_KEY}/${el}`}
                        status={data?.status}
                        title={getFileName(el)}
                        key={ind}
                    />
                    )}

                    {/* <ComponentWithBorder img='/passport.png' /> */}
                </div>
            </div>
            {!data?.invoice?.xml &&
                <CustomizedTables
                    withDiscount={true}
                    withDelete={false}
                    rows={data?.invoice?.values}
                    name={t('product')}
                    property={property}
                    excel={data?.excel}
                />}
            <hr />
            <div className={`d-flex justify-content-between align-items-center ${styles.heading}`}>
                <span> {t('accountantAttachedFiles')} </span>
                {(data?.status === 'created' || data?.status === 'dispute') && <CustomButtosWithIcon
                    title={t("answer")}
                    icon={
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                        >
                            <path
                                d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z"
                                fill="#FEFEFE"
                            />
                        </svg>
                    }
                    type="submit"
                    onClick={() => {
                        setSubmit(true)
                        const savedData = { ...files }
                        if (savedData && savedData.invoice) {
                            editTasksInvoiceById({ ...savedData, id: data?.invoice?.id, status: 'answeredByAccountant' })
                                .then((res: any) => {
                                    if (res?.data?.id) {
                                        toast.success('Գործընթացը հաջողությամբ կատարվել է');
                                        navigate('/profile/dashboard')
                                    }
                                })
                        }
                    }}
                />}
            </div>
            <div className={`d-flex flex-wrap ${styles.cardsCont}`}>

                <label className={`${styles.cardUploadItem} d-flex flex-column position-relative`}>
                    <input type='file' style={{ display: 'none' }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const file: File | null = (e?.target?.files && e?.target?.files?.length > 0) ? e?.target?.files[0] : null;
                            if (file) {
                                const formdata = new FormData();
                                formdata.append('file_url', file)
                                addImages(formdata)
                                    .then((res: any) => {
                                        if (res.data) {
                                            handleSetFile({ invoice: res.data })
                                        }
                                    })
                            }
                        }} />
                    <span className={styles.cardLabel}>{t('Invoice')}</span>
                    {files?.invoice
                        ? <ComponentWithBorder onClick={() => handleDeleteFile('invoice')}
                            img={`${process.env.REACT_APP_API_KEY}${files.invoice}`} />
                        : (savedFiles.invoice
                            ? <FileDownload url={`${process.env.REACT_APP_API_KEY}/${savedFiles.invoice}`}
                                status={data?.status}
                                title={invoice}
                                deleteItem={() => handleDeleteFile('invoice')}
                            />
                            : <div className='d-flex flex-column'>
                                <DocumentCard />
                                <span className={styles.cardTitle}>{t('attachFilesHere')}</span>
                                {submit && !files?.invoice && <div className='error error-text'>{t('required')}</div>}
                            </div>)}
                </label>

            </div>
            <hr />
            {data?.status === "dispute" && <div className='d-flex flex-column mt-3'>
                <div className={styles.heading}>{t('userComplain')} </div>
                <span className={styles.complain}>{data?.invoice?.user_complain}</span>
            </div>}
        </div>
    );
}


