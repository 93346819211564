import React from 'react';
import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../../../App.module.scss';
import RegisSvg from '../../../Components/SvgCompoents/RegisSvg';
import { useTranslation } from 'react-i18next';
import Physical from './Physical';
import Legal from './Legal';
export default function AsBroker() {

  const [active, setAtcive] = useState<string>('physical');
  const handleClick = useCallback((name: string) => {
    setAtcive(name)
  }, [])
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className={`d-flex justify-content-center align-items-center ${styles.cont}`}>
      <div className={styles.RegisSvg}>
        <RegisSvg />
      </div>
      <div className={styles.formCont}>
        <div className={styles.heading}>{t("signUpAsBroker")}</div>
        <div className={`d-flex ${styles.tubButtons}`}>
          <div className={`d-flex justify-content-center align-items-center ${styles.buttons} ${active === 'physical' ? styles.active : ""}`}
            onClick={() => handleClick('physical')}>
            {t('physicalPerson')}
          </div>
          <div className={`d-flex justify-content-center align-items-center ${styles.buttons} ${active === 'legal' ? styles.active : ""}`}
            onClick={() => handleClick('legal')}>
            {t('legalPerson')}
          </div>
        </div>
        {active === 'physical' ? <Physical /> : <Legal />}
      </div>

    </div>

  );
}


