import { useCallback } from 'react';
import { useField } from 'formik';
import FormControl from '@mui/material/FormControl';
import { Box, Chip, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import styles from './CustomSelectFormik.module.scss';

interface IProps {
  label: string,
  list: any,
}

const CustomSelectFormik = ({ label, list, readonly = false,className, ...props }: any) => {
  const [field, meta, helpers] = useField(props);
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    '& .Mui-selected': {
      // backgroundColor: 'rgba(25, 118, 210, 0.08)'
    },
    '& .MuiInputBase-input': {
      height: 25,
      borderRadius: 12,
      position: 'relative',
      // backgroundColor: theme.palette.background.paper,
      border: '2px solid #97BCC7',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        borderRadius: 12,
        // borderColor: '#006884',
        boxShadow: 'none',
      },
    },
  }));

  return (
    <label className={`${styles.cont} d-flex flex-column ${className} position-relative`}>
      <span className={styles.label}>{label}</span>
      {meta.error ? (
      <div className="error error-text">{meta.error}</div>
    ) : null}
      <FormControl sx={{ maxWidth: 501, width: '100%', height: 45 }} variant="standard">
        <Select
          {...field}
          {...props}
          disabled={readonly}
          // labelId="demo-customized-select-label"
          // id="demo-customized-select"
          input={<BootstrapInput />}
          renderValue={(p: any) => list?.filter((el: { id: number, name: string }) => p === el?.id)[0]?.name}
          sx={{ maxWidth: 501, width: '100%', marginBottom: 24, height: 45 }}
        >
          {list?.map((el: { id: number, name: string }) => {
            return <MenuItem key={el.id} value={el.id}>{el?.name}</MenuItem>
          })}
        </Select>
      </FormControl>
    
    </label>
  );
};
export default CustomSelectFormik;