import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import style from './Questions.module.scss';
export default function Questions() {
    return (
        <div className='container'>
            <div className={style.headingCont}>
                <h2 className={style.heading}>Frequently Asked </h2>
                <div className={style.headingNext}></div>
            </div>
            <p className={style.sectionTitle}>Here are some frequently asked questions users ask when using Scandapay</p>
            <div className={style.array}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<svg xmlns="http://www.w3.org/2000/svg" width="28" height="15" viewBox="0 0 28 15" fill="none">
                        <path d="M0.25 0.371094L14 14.1211L27.75 0.371094H0.25Z" fill="#006884"/>
                        </svg>}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        Accordion 1
                    </AccordionSummary>
                    <AccordionDetails>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                        malesuada lacus ex, sit amet blandit leo lobortis eget.
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<svg xmlns="http://www.w3.org/2000/svg" width="28" height="15" viewBox="0 0 28 15" fill="none">
                        <path d="M0.25 0.371094L14 14.1211L27.75 0.371094H0.25Z" fill="#006884"/>
                        </svg>}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        Accordion 2
                    </AccordionSummary>
                    <AccordionDetails>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                        malesuada lacus ex, sit amet blandit leo lobortis eget.
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<svg xmlns="http://www.w3.org/2000/svg" width="28" height="15" viewBox="0 0 28 15" fill="none">
                        <path d="M0.25 0.371094L14 14.1211L27.75 0.371094H0.25Z" fill="#006884"/>
                        </svg>}
                        aria-controls="panel3-content"
                        id="panel3-header"
                    >
                        Accordion Actions
                    </AccordionSummary>
                    <AccordionDetails>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                        malesuada lacus ex, sit amet blandit leo lobortis eget.
                    </AccordionDetails>
                   
                </Accordion>

            </div>
        </div>
    )
}