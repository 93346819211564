import style from './Interface.module.scss';
export default function Interface() {
    return (
        <div className='container'>
            <div className={style.headingCont}>
                <h2 className={style.heading}>Explore Scandapay’s Features</h2>
                <div className={style.headingNext}></div>
            </div>
            <p className={style.sectionTitle}>Scandapay is a fintech solution that allows users make payment for  goods purchased by scanning a QR Code. Scandapay also offers other amazing features.</p>
           <img src='/interface.png' className={style.image}/>
        </div>
    )
}