import styles from './CustomInput.module.scss';
import { useState, useCallback, useEffect } from 'react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import SeeEye from '../SvgCompoents/SeeEye';
import HideEye from '../SvgCompoents/HideEye';

const CustomInput = ({ title, readonly = false, withLabel = true, type, className, ...props }: any) => {
  const [field, meta, helpers] = useField(props);
  const [inputType, setInputType] = useState<string>(type);
  const { t } = useTranslation();
  const handleChangeType = useCallback(() => {
    const newType = inputType === 'password' ? 'text' : 'password';
    setInputType(newType)
  }, [inputType])
  useEffect(() => {
    document.addEventListener("wheel", function (event) {
      // @ts-ignore
      if (document?.activeElement?.type === "number" &&
        document?.activeElement.classList.contains("noscroll")) {
        // @ts-ignore
        document?.activeElement?.blur();
      }
    });
  }, [])
  
  return <label className={`${styles.cont} d-flex flex-column ${className}`}>
    <div className='d-flex justify-content-between align-items-center'>
      {withLabel && <div className='d-flex'>
        <span className={styles.title}>{title} </span>
        {meta.touched && meta.error && <span className='error'>*</span>}
      </div>}
      {type === 'password' && (inputType === 'password'
        ? <div onClick={handleChangeType} className={styles.hideText}>
          <SeeEye />
          <span>{t("show")}</span>
        </div>
        : <div onClick={handleChangeType} className={styles.hideText}>
          <HideEye />
          <span>{t("hide")}</span>
        </div>)}
    </div>
    <input
      {...field}
      {...props}
      type={type === 'password' ? inputType : type}
      className={`${styles.input} noscroll`}
      disabled={readonly}
      autoComplete='off'
    />
    {meta.error ? (
      <div className="error error-text">{meta.error}</div>
    ) : null}
  </label>
}
export default CustomInput;