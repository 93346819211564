import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios from 'axios';
import type { AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import i18n from '../../i18n';

const errorToast = (text: string) => toast.error(text);
const urlsToNotUse = ['assets']
function createLoaderElement(): void {
  document.body.classList.add('loading-indicator');
}
function removeLoaderElement(): void {
  document.body.classList.remove('loading-indicator');
}
function isValidRequestForInterceptor(url: string): boolean {
  for (const address of urlsToNotUse) {
    if (new RegExp(address).test(url)) {
      return false;
    }
  }
  return true;
}
export const API = axios.create({
  // baseURL: process.env.NEXT_PUBLIC_BASE_URL,
  baseURL: process.env.REACT_APP_API_KEY,
  responseType: 'json',
});
export const setApiAuthorizationHeader = (token: string | null) => {
  API.defaults.headers.common.Authorization = token ? `Bearer ${token}` : null;
};
API.interceptors.response.use(
  (res) => {
    removeLoaderElement();
    if (res?.data?.error) {
      return Promise.reject(res?.data?.error)
    }
    return res;
  },
  async (err) => {
    removeLoaderElement();
    if (err?.response?.status === 403) {
      window.location.replace('/')
      localStorage.removeItem('rbc-access-token');
      localStorage.removeItem('rbcId');
      localStorage.removeItem('rbcRole');
    }
    return Promise.reject(err)
  }
)

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string | undefined } = { baseUrl: '' }
  ): BaseQueryFn<
    {
      url: string
      method: AxiosRequestConfig['method']
      data?: AxiosRequestConfig['data']
      params?: AxiosRequestConfig['params']
    },
    unknown,
    unknown
  > =>
    async ({ url, method, data, params }) => {
      API.interceptors.request.use(
        (config) => {
          if (isValidRequestForInterceptor(config.url!)) {
            if (!config.url?.includes('/tasks/?limit=10000000') && !config.url?.includes('unseen-activities-count')) {
              createLoaderElement();
            }
          }
          const token = localStorage.getItem('rbc-access-token');
          if (config.url !== 'auth/login/role' && !!config?.headers && token) {
            config.headers.Authorization = `Bearer ${token}`
          }
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      try {
        const result = await API({ url: baseUrl + url, method, data, params })
        return { data: result.data }
      } catch (axiosError) {
        let err = axiosError as any
        console.log(err, 'errrrrrrrrrrrrrrrrrrrrrr');

        errorToast(i18n.t(err?.response?.data?.message));
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        }
      }
    }


export default axiosBaseQuery;