import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import styles from "../App.module.scss";
import { useTranslation } from "react-i18next";
import RegisSvg from "../Components/SvgCompoents/RegisSvg";
import { CustomButton, CustomInput } from "../Components";
import Finished from "../Components/SvgCompoents/Finished";
import {
  useChangePasswordMutation,
  useSendForgetCodeMutation,
} from "../store/services/auth";

export default function ForgetPassword() {
  const [active, setAtcive] = useState<{ step: number }>({ step: 1 });
  const [email, setEmail] = useState<string>("");
  const [unEqualPasswords, setUnEqualPasswords] = useState<boolean>(false)
  const [sendForgetCodeRequest] = useSendForgetCodeMutation();
  const [changePassword] = useChangePasswordMutation();

  const navigate = useNavigate();
  const { t } = useTranslation();
  interface Values {
    email: string;
  }
  const SignupSchema = Yup.object().shape({
    email: Yup.string().trim().email(t("emailError")).required(t("required")),
  });
  interface ValuesCode {
    code: string;
  }
  const SignupSchemaCode = Yup.object().shape({
    code: Yup.string().required(t("required")),
  });

  interface ValuesPassword {
    password: string;
    // repeat_password: string;
    code: string;
  }

  const SignupSchemaPassword = Yup.object().shape({
    password: Yup.string().required(t("required")),
    // repeat_password: Yup.string().required(t("required")),
  });

  return (
    <div
      className={`d-flex justify-content-center align-items-center ${styles.cont}`}
    >
      <div className={styles.RegisSvg}>
        <RegisSvg />
      </div>
      <div className={styles.formCont}>
        {active.step === 1 && (
          <div className="d-flex flex-column">
            <h3 className={styles.heading}>{t("resetPassword")}</h3>
            <p className={styles.description}>{t("enterEmail")}</p>
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={SignupSchema}
              onSubmit={(
                values: Values,
                { setSubmitting }: FormikHelpers<Values>
              ) => {
                setEmail(values.email);
                sendForgetCodeRequest({ email: values.email.trim() })
                  .then((res: any) => {
                    if (res.data) {
                      setAtcive({ step: 2 });
                    }
                  })
              }}
            >
              {({ errors, touched }: any) => (
                <Form>
                  <CustomInput
                    name="email"
                    withLabel={false}
                    title={t("emailAdress")}
                  />
                  <CustomButton
                    title={t("send")}
                    type="submit"
                    onClick={() => console.log("dbgfdjg")}
                  />
                </Form>
              )}
            </Formik>
          </div>
        )}
        {active.step === 2 && (
          <div className="d-flex flex-column">
            <h3 className={styles.heading}>{t("resetPassword")}</h3>
            {/* <p className={styles.description}>{t("createNewPassword")}</p> */}
            <Formik
              initialValues={{
                password: "",
                // repeat_password: "",
                code: "",
              }}
              validationSchema={SignupSchemaPassword}
              onSubmit={(
                values: ValuesPassword,
                { setSubmitting }: FormikHelpers<ValuesPassword>
              ) => {

                // if(values.password === values.repeat_password){
                changePassword({
                  email: email.trim(),
                  confirm_code: values.code.trim(),
                  new_password: values.password.trim()
                })
                  .then((res: any) => {
                    if (!res.error) {
                      setAtcive({ step: 3 });
                    }
                  })
                  ;
                // } else {
                //   setUnEqualPasswords(true)
                // }
              }}
            >
              {({ errors, touched }: any) => (
                <Form>
                  <CustomInput name="code" title={t("code")} />
                  <CustomInput
                    name="password"
                    type="password"
                    title={t("password")}
                  />
                  {/* <CustomInput
                    name="repeat_password"
                    type="password"
                    title={t("repeatPassword")}
                  /> */}
                  {/* {unEqualPasswords && <p className="error">Գաղտնաբառն ու կրկնե գաղտնաբառը նույնը չեն</p>} */}
                  <CustomButton
                    title={t("send")}
                    type="submit"
                    onClick={() => console.log("dbgfdjg")}
                  />
                </Form>
              )}
            </Formik>
          </div>
        )}
        {active.step === 3 && (
          <div
            className={`d-flex flex-column align-items-center ${styles.congratulations}`}
          >
            <Finished />
            <h3 className={`d-flex flex-column ${styles.text}`}>
              {t("congratulations")}
            </h3>
            <p className={`d-flex flex-column ${styles.desciption}`}>
              {t("passwordSuccessfullyChanged")}
            </p>
            <CustomButton
              title={t("goToLogin")}
              type="button"
              onClick={() => {
                navigate("/");
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
