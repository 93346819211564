import React from "react";
import { Route } from "react-router-dom";
import {
  AccountantCompanies,
  Companies,
  Menu,
  PrivatePage,
  Profile,
} from "../Pages";

export default function BrokerRoutes() {
  return (
    <React.Fragment>
      <Route
        path="/profile"
        element={
          <PrivatePage>
            <Menu>
              <Profile />
            </Menu>
          </PrivatePage>
        }
      ></Route>
      <Route
        path="/profile/accountant-companies"
        element={
          <PrivatePage>
            <Menu>
              <AccountantCompanies />
            </Menu>
          </PrivatePage>
        }
      ></Route>
      <Route
        path="/profile/companies"
        element={
          <PrivatePage>
            <Menu>
              <Companies />
            </Menu>
          </PrivatePage>
        }
      ></Route>
    </React.Fragment>
  );
}
