
import styles from './ChangeLanguage.module.scss';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
import { useEditMeMutation } from '../../store/services/auth';
import { useAppSelector } from '../../store';
import { useState } from 'react';
interface CountryType {
  code: string;
  label: string;
  title: string
}
const countries: readonly CountryType[] = [
  {
    code: 'US',
    label: 'English',
    title: 'en'
  },
  {
    code: 'AM',
    label: 'Armenia',
    title: 'hy'
  },
  {
    code: 'RU',
    label: 'Russia',
    title: 'ru'
  }
]
const ChangeLanguage = () => {
  const { t, i18n } = useTranslation();
  const [editMeRequest] = useEditMeMutation();
  const auth: any = useAppSelector((state) => state.auth);

  const localStoragelocale=localStorage.getItem('rbc-locale')==='en'?'us':localStorage.getItem('rbc-locale');
  return <div className={`d-flex align-items-center languageStyle ${styles.languageCont}`}>
    <img
      className={styles.languageContImg}
      loading="lazy"
      width="20"
      height="15"
      src={`https://flagcdn.com/w20/${auth?.me?.language
        ? (auth?.me?.language === 'hy' ? 'am' : (auth?.me?.language === 'en' ? 'us' : auth?.me?.language))
        : localStoragelocale}.png`}
      srcSet={`https://flagcdn.com/w40/${auth?.me?.language
        ? auth?.me?.language === 'hy' ? 'am' : (auth?.me?.language === 'en' ? 'us' : auth?.me?.language)
        : localStoragelocale}.png 2x`}
      alt=""
    />
    <Autocomplete
      // readOnly={true}
      autoComplete
      disableClearable
      blurOnSelect
      disablePortal
      id="country-select-demo"
      sx={{ width: 150 }}
      options={countries}
      autoHighlight
      className='languageLabel'
      getOptionLabel={(option) => ""}
      onChange={(event: any, newValue: CountryType | null) => {
        localStorage.setItem('rbc-locale', `${newValue?.code === 'US' ? 'en' : newValue?.code.toLowerCase()}`);
        i18n.changeLanguage(newValue?.code === 'US' ? 'en' : newValue?.code.toLowerCase());
        if (localStorage.getItem('rbc-access-token')) {
          editMeRequest({ language: i18n.language === 'am' ? 'hy' : (i18n.language === 'ru' ? 'ru' : 'en'), id: auth?.me?.id })
        }
      }}
      renderOption={(props, option) => {
        return <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.label}
        </Box>
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          // label="Choose a language"
          disabled
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
            className: "customAutocomplete"
          }}
        />
      )}
    />
  </div>
}
export default ChangeLanguage;
