import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import CssBaseline from "@mui/material/CssBaseline";
import { useParams } from "react-router-dom";
import { MenuHooks } from "./Menu.hook";
import style from "./Menu.module.scss";
import Activity from "./Activity";
import NotificationSection from "./Notification";
import Logout from "../../Components/SvgCompoents/Logout";
import { setLogOut } from "../../store/slice/authSlice";
import { RootState, useAppSelector } from "../../store";
import { useGetMeQuery } from "../../store/services/auth";
import { usePrefetchImmediately } from '../../hooks/usePrefetchImmediately';
import Notification from "../../Components/SvgCompoents/Notification";
import { ChangeLanguage, MenuItem } from "../../Components";
import LeftMenuIcon from "../../Components/LeftMenuIcon";
import NotificationWithRound from "../../Components/NotificationWithRound/intex";
import { deleteToken } from "../../firebaseNotifications/firebase";
import { useEffect } from "react";

interface Props {
  children: any;
}

export default function ResponsiveDrawer(props: Props) {
  const {
    drawerWidth,
    currentMenu,
    drawerStyle,
    appBarStyle,
    mobileOpen,
    tasks,
    companies,
    status,
    showExtendedMenu,
    showNotification,
    handleShowNotification,
    t,
    dispatch,
    navigate,
    container,
    handleDrawerToggle,
    handleChangeMenuSize
  } = MenuHooks(props);
  const { data } = useGetMeQuery({ id: localStorage.getItem("rbcId") });
  const auth: any = useAppSelector((state: RootState) => state.auth);
  const { id } = useParams<Record<string, string | undefined>>();
  
  const drawer = (
    <div className={`${!showExtendedMenu ? style.menuLeftSmall : style.menuLeft} ${style.menuLeft}`}>
      <div onClick={handleChangeMenuSize}>
        <LeftMenuIcon showOnlyIcon={(window.location.href.includes('/profile/dashboard/') && id) ? true : !showExtendedMenu} />
      </div>
      <Toolbar />
      {currentMenu?.map((el, ind) => {
        return (
          <>
            <MenuItem
              showOnlyIcon={(window.location.href.includes('/profile/dashboard/') && id) ? true : !showExtendedMenu}
              key={ind}
              active={
                window.location.pathname === el.url ||
                (el.url.split("/").length > 2 &&
                  window.location.pathname.includes(el.url))
              }
              status={status}
              withSubMenu={el.url.includes('dashboard')}
              count={tasks?.count}
              icon={el.icon}
              title={t(`${el.title}`)}
              array={el.url.includes('dashboard') ? companies : []}
              onClick={() => {
                navigate(el.url);
                handleDrawerToggle();
              }}
            />
          </>
        );
      })}
      {window.innerWidth < 1024 && (
        <MenuItem
          showOnlyIcon={(window.location.href.includes('/profile/dashboard/') && id) ? true : !showExtendedMenu}
          active={window.location.href.includes('/profile/dashboard/') && id ? window.location.pathname === "/activity" : window.location.pathname === "/notification"}
          icon={<Notification />}
          title={window.location.href.includes('/profile/dashboard/') && id ? t("activity") : t("notification")}
          onClick={() => navigate(window.location.href.includes('/profile/dashboard/') && id ? `/profile/activity/${id}` : "/profile/notification", { replace: true })}
        />
      )}
      <MenuItem
        showOnlyIcon={(window.location.href.includes('/profile/dashboard/') && id) ? true : !showExtendedMenu}
        active={window.location.pathname === "/notification"}
        icon={<Logout />}
        title={t("logout")}
        onClick={() => {
          // navigate("/", { replace: true });
          // window.history.replaceState(null, '', "/");
          deleteToken()
          window.location.pathname = '/';
          localStorage.removeItem("rbc-access-token");
          localStorage.removeItem("firebaseToken");
          localStorage.removeItem("rbcRole");
          localStorage.removeItem("rbcId");
          dispatch(setLogOut());
        }}
      />
    </div>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" sx={appBarStyle}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon className="menuIcon" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={drawerStyle}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          // sx={drawerStyle}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, width: { md: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        <div className="d-flex">
          <div className={window.location.href.includes('/profile/dashboard/') && id ? `${style.main} ${style.mainWithActivityCont}` : style.main}>{props.children}</div>
          <div className={window.location.href.includes('/profile/dashboard/') && id ? `${style.notification} ${style.activityCont}` : style.notification}>
            <div
              className={`d-flex justify-content-center align-items-center ${style.myInfo}`}
            >
              <img
                className={
                  auth.me && auth.me.avatar_image
                    ? style.myAvatar
                    : style.myAvatarDefault
                }
                src={
                  auth.me && auth.me.avatar_image
                    ? `${process.env.REACT_APP_API_KEY}${auth.me.avatar_image}`
                    : `/managerDefaultImg.svg`
                }
              />
              <div className={`w-100 ${style.nameProfession}`}>
                {auth?.me?.user_role === 1 ? (
                  <span className={style.name}>{auth?.me?.company_name}</span>
                ) : (
                  <span className={style.name}>
                    {auth?.me?.user?.first_name} {auth?.me?.user?.last_name}
                  </span>
                )}
              </div>
              <div>
                <ChangeLanguage />
              </div>
              <NotificationWithRound  handleShowNotification={handleShowNotification} />
            </div>
            {window.location.href.includes('/profile/dashboard/') && id
              ? <Activity />
              : showNotification ? <NotificationSection /> : <></>}
          </div>
        </div>
      </Box>
    </Box>
  );
}
