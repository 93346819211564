import OurTimeItem from './OurTimeItem';
import style from './OurTime.module.scss';
export default function OurTime() {
    return (
        <div className='container'>
            <div className={style.headingCont}>
                <h2 className={style.heading}>Testimonials</h2>
                <div className={style.headingNext}></div>
            </div>
            <p className={style.sectionTitle}>What users are saying about scandapay</p>
            <div className={style.array}>
                <OurTimeItem
                    img="/timeItem.png"
                    name="Seyi Ajibona"
                    city="Lagos, Nigeria"
                    description="The platform is very friendly and easy to use, I can easly perform any transaction easily!"
                />
                <OurTimeItem
                    img="/timeItem.png"
                    name="Seyi Ajibona"
                    city="Lagos, Nigeria"
                    description="The platform is very friendly and easy to use, I can easly perform any transaction easily!"
                />
                <OurTimeItem
                    img="/timeItem.png"
                    name="Seyi Ajibona"
                    city="Lagos, Nigeria"
                    description="The platform is very friendly and easy to use, I can easly perform any transaction easily!"
                />
                
            </div>
        </div>
    )
}