import { useField } from 'formik';
import style from './CustomRadio.module.scss'
type RadioItem = {
  label: string,
  value: string,
}
type Props = {
  radioArray: RadioItem[],
  label: string,
  name: string,
  onClick: (val: string) => void,
};

const CustomRadio = ({ radioArray,onChangeSecond, label, ...props }: any) => {
  const [field, meta, helpers] = useField(props);
  const { value, ...rest } = field;

  return (<div className={style.customRadio}>
    <span className={style.label}>{label}</span>
    <div className={`d-flex flex-wrap`}>
      {radioArray?.map((el: any, ind: number) => <label className={`${style.customRadioItem} d-flex`} key={ind}>
        <input type="radio" id={el.value} value={el.value} checked={el.value === field.value}
        {...rest}
        onChange={(e)=>{
          rest.onChange(e)
          if (onChangeSecond) {
            onChangeSecond()
          }
        }}
         className="form-check-input" />
        <span className={style.customRadioItemLabel}>{el?.label}</span>
      </label>)}
    </div>
  </div>)
}
export default CustomRadio;