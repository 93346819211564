import { useState, useCallback } from 'react';
import styles from './AddInput.module.scss';
import { useTranslation } from 'react-i18next';
import AddIcon from '../SvgCompoents/AddIcon';

const AddInput = ({ label, type, addAddress, readonly=false }: any) => {
  const { t } = useTranslation();
  const [value, setValule] = useState('');
  const [focus, setFocus] = useState(false);
  const handleChange = useCallback((e: any) => {
    setValule(e.target.value)
  }, []);
  const handleAddAddress = useCallback(() => {
    if (value) {
      addAddress(value)
    setValule('')
    }
    
  }, [value]);
  const handleFocus = useCallback(() => {
    setFocus(true)
  }, []);
  const handleBlur = useCallback(() => {
    setFocus(false)
  }, []);

  return <label className={`${styles.cont} d-flex flex-column`}>
    <div className='d-flex justify-content-between align-items-center'>
      <span className={styles.title}>{label} </span>
    </div>
    <div className={`w-100 d-flex align-items-center ${styles.input} ${focus ? styles.focusedInput : ''}`}>
      <input disabled={readonly} value={value} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} type={type} style={{paddingLeft: "10px"}}/>
      <div className='d-flex' onClick={handleAddAddress}>
        <AddIcon /> 
        <span className={styles.addLabel}></span>
      </div>
    </div>
  </label>
}
export default AddInput;