import { useState, useCallback, useMemo, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Form, Formik, FormikHelpers } from "formik";
import { Params, useNavigate, useParams } from "react-router-dom";
import styleProfile from "./EditCompany.module.scss";
import {
  AddInput,
  CustomButtosWithIcon,
  CustomInput,
  CustomSelect,
  ProfileFileUpload,
} from "../../../../Components";
import Close from "../../../../Components/SvgCompoents/Close";
import { useGetManagersQuery } from "../../../../store/services/managers";
import { useEditCompaniesMutation, useGetCompanyByIdQuery } from "../../../../store/services/companies";
import { useAddImagesMutation } from "../../../../store/services/photo";
import { toast } from "react-toastify";
import EditUser from "../../../../Components/SvgCompoents/EditUser";
import { AddressItem, IMe } from "../../../../types";
import TarifItem from "../../../../Components/TarifItem";
import { useGetTasksTypeQuery } from "../../../../store/services/tasks";
import DeleteAccountDialog from "../../../../Dialogs/DeleteAccountDialog";
import Help from "../../../../Components/SvgCompoents/Help";

export const EditCompany = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams<Record<string, string | undefined>>();
  const { data: companyData, isLoading } = useGetCompanyByIdQuery(params?.companyid ? +params?.companyid : '');
  const [disable, setDisable] = useState(true);
  const [open, setOpen] = useState(false);
  const [help, setHelp] = useState<boolean>(false);
  const [addressList, setAddressList] = useState<any>(companyData?.address);
  const [managerList, setManagerList] = useState<string[]>(companyData?.managers ? companyData?.managers?.map((el: IMe) => el?.id) : []);
  const [accountantList, setAccountantList] = useState<string[]>(companyData?.accountant_worker ? companyData?.accountant_worker?.map((el: IMe) => el?.id) : []);
  const [selectedTarif, setSelectedTarif] = useState<number>(1)
  const { data: managers } = useGetManagersQuery({ limit: 100000, offset: 0 });
  const { data: clinetsManagers } = useGetManagersQuery({ limit: 100000, offset: 0, client_id: params?.id });
  const { data: task_types } = useGetTasksTypeQuery({
    limit: 100000,
    offset: 0,
  });
  useEffect(() => {
    setAddressList(companyData?.address)
  }, [companyData?.address])
  useEffect(() => {
    setSelectedTarif(companyData?.tarif-1)
  }, [companyData?.tarif])
  useEffect(() => {
    setManagerList(companyData?.managers.map((el: IMe) => el?.id))
  }, [companyData?.managers])
  useEffect(() => {
    setAccountantList(companyData?.accountant_worker.map((el: IMe) => el?.id))
  }, [companyData?.accountant_worker])
  const SignupSchema = Yup.object().shape({
    name: Yup.string().required(t("required")),
    hvhh: Yup.string().required(t("required")),
    // addresses: Yup.array().required(t("required")),
  });
  interface Values {
    name: string;
    hvhh: string;
    managers: number[];
  }
  const handleEdit = useCallback(
    () =>
      setTimeout(() => {
        setDisable(false);
      }, 1000),
    []
  );
  const handleAddAddress = useCallback(
    (address: string) => {
      const newArray = address
        ? [...addressList, { name: address }]
        : [...addressList];
      setAddressList(newArray);
    },
    [addressList]
  );
  const handleOpen = useCallback(() => {
    setOpen(true)
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false)
  }, []);
  const handleDeleteAddress = useCallback(
    (ind: number) => {
      const newArray = [...addressList];
      newArray.splice(ind, 1);
      setAddressList(newArray);
    },
    [addressList]
  );

  const handleAddManager = useCallback(
    (managerId: any) => {
      setManagerList(managerId);
    },
    [managerList]
  );

  const handleDeleteManager = useCallback(
    (ind: number) => {
      const newArray = [...managerList];
      newArray.splice(ind, 1);
      setManagerList(newArray);
    },
    [managerList]
  );

  const selectedManagers = useMemo(() => {
    let newArray: number[] = [];
    for (let index = 0; index < managerList?.length; index++) {
      const element = managerList[index];
      for (let ind = 0; ind < clinetsManagers?.length; ind++) {
        const el = clinetsManagers[ind];
        if (el?.id === +element) {
          newArray.push(el);
        }
      }
    }
    return newArray;
  }, [managerList, clinetsManagers]);


  const handleAddAccountant = useCallback(
    (managerId: any) => {
      setAccountantList(managerId);
    },
    [accountantList]
  );

  const handleDeleteAccountant = useCallback(
    (ind: number) => {
      const newArray = [...accountantList];
      newArray.splice(ind, 1);
      setAccountantList(newArray);
    },
    [accountantList]
  );

  const selectedAccountant = useMemo(() => {
    let newArray: number[] = [];

    for (let index = 0; index < accountantList?.length; index++) {
      const element = accountantList[index];
      for (let ind = 0; ind < managers?.length; ind++) {
        const el = managers[ind];
        if (el?.id === +element) {
          newArray.push(el);
        }
      }
    }
    return newArray;
  }, [accountantList, managers]);

  const [addImages, { data }] = useAddImagesMutation();
  const [editCompany, { data: editedCompanyData }] = useEditCompaniesMutation()
  const handleSelectTarif = useCallback((event: React.SyntheticEvent, newValue: number) => { setSelectedTarif(newValue) }, [])
  const handleSetHelp = useCallback(() => {
    setHelp(!help)
  }, [help])
  return (isLoading ? <div></div> : <div className={`w-100`}>
    <Formik
      initialValues={{
        name: companyData?.name,
        hvhh: companyData?.hvhh,
        managers: [],
        accountant_worker: companyData?.accountant_worker?.id
      }}
      validationSchema={SignupSchema}
      onSubmit={(
        values: Values,
        { setSubmitting }: FormikHelpers<Values>
      ) => {

        editCompany({
          ...values,
          id: params?.companyid,
          managers: managerList.length > 0 ? managerList : [],
          accountant_worker: accountantList.length > 0 ? accountantList : [],
          address: addressList,
          image: companyData?.image,
          tarif: selectedTarif+1
        })
          .then((res: any) => {
            if (res.data) {
              toast.success(t('editProfileSuccess'));
              setDisable(true)
            }
          })
      }}
    >
      {({ errors, touched }: any) => (
        <Form>
          <DeleteAccountDialog
            title={companyData?.is_active ?  t('doYouWantDeactivate') : t('doYouWantActivate')}
            open={open}
            handleClose={handleClose}
            handleDelete={() => {
              editCompany({
                id: params?.companyid,
                is_active: !companyData?.is_active
              })
                .then((res: any) => {
                  if (res.data) {
                    toast.success(t('editProfileSuccess'));
                    setOpen(false)
                  }
                })
            }}
          />
          <div className={styleProfile.profilePhotos}>
            <div
              className={`w-100 d-flex justify-content-between ${styleProfile.nameImages}`}
            >
              <div className="d-flex flex-wrap justify-content-between align-items-center w-100">
                <ProfileFileUpload
                  forCompany={true}
                  avatar_image={companyData?.image
                    ? `${process.env.REACT_APP_API_KEY}${companyData?.image}`
                    : ''}
                  request={(img: string) => {
                    editCompany({
                      image: img,
                      id: params?.companyid ? +params?.companyid : ''
                    });
                  }} />
                <div className={`d-flex flex-wrap justify-content-center gap-2 justify-md-content-between ${styleProfile.buttonsCont}`}>
                  <CustomButtosWithIcon
                    color='red'
                    title={companyData?.is_active ? t('deactivate') : t('activate')}
                    type={'button'}
                    onClick={handleOpen}
                    // icon={< />}
                  />
                  {companyData?.is_active && (disable ? (
                    <div>
                      <CustomButtosWithIcon
                        title={t("editProfile")}
                        type={"button"}
                        onClick={handleEdit}
                        icon={<EditUser />}
                      />
                    </div>
                  ) : (
                    <div>
                      <CustomButtosWithIcon
                        title={t("save")}
                        type="submit"
                        onClick={console.log("ggg")}
                        icon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z"
                              fill="#FEFEFE"
                            />
                          </svg>
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {/* <div className={`d-flex ${styleProfile.tarifContainer}`}>
            <TarifItem
              active={selectedTarif === 1}
              type='basic'
              title="Basic"
              onClick={() => handleSelectTarif(1)}
              array={task_types}
            />
            <TarifItem
              active={selectedTarif === 2}
              type='premium'
              title="Premium"
              onClick={() => handleSelectTarif(2)}
              array={task_types}
            />
          </div> */}
           <div className={`d-flex ${styleProfile.tarifContainer}`}>
            <div className={styleProfile.selectTarif}>{t('selectTarif')}</div>
            <div className='d-flex align-items-center'>
              <div className="tabs-cont">
                <Tabs
                  textColor="secondary"
                  value={selectedTarif}
                  onChange={handleSelectTarif}
                  aria-label="basic tabs example"
                >
                  <Tab label="Basic" className="basic" />
                  <Tab label="Premium" className="premium" />
                </Tabs>
              </div>
              <div onClick={handleSetHelp} className={styleProfile.helpStyle}>
                <Help />
              </div>
            </div>
            <Dialog
              open={help}
              onClose={handleSetHelp}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={true}
              maxWidth='md'
              className="tarifModal"
            >
              <div className="d-flex flex-column flex-md-row justify-content-center">
                <TarifItem
                  price='5,000'
                  type='basic'
                  title="Basic"
                  array={task_types}
                />
                <TarifItem
                  price='7,000'
                  type='premium'
                  title="Premium"
                  array={task_types}
                />
              </div>
            </Dialog>

          </div>
          <div className={styleProfile.formsCont}>
            <div
              className={`d-flex flex-wrap justify-content-start ${styleProfile.inputs}`}
            >
              <CustomInput name="name" readonly={disable} title={t("companyName")} />
              <CustomInput name="hvhh" readonly={disable} title={t("HVHH")} />
              {managers?.length > 0 && <div className={`${styleProfile.withSelected} ${styleProfile.withSelectedAccountant}`}>
                <CustomSelect
                  readonly={disable}
                  label={t("accountants")}
                  list={managers?.map((el: IMe) => ({
                    name: `${el.user.first_name} ${el.user.last_name}`,
                    id: el?.id,
                  }))}
                  value={accountantList}
                  multiple={true}
                  onChange={handleAddAccountant}
                />
                {selectedAccountant?.map((el: any, ind: number) => {
                  return (
                    <div key={ind} className={styleProfile.selected}>
                      {el?.avatar_image
                        ? <img
                          src={`${process.env.REACT_APP_API_KEY}${el?.avatar_image}`}
                          className={styleProfile.image}
                        />
                        : <div><img
                          src={"/managerDefaultImg.svg"}
                          className={styleProfile.imageDefault}
                        /></div>}
                      <span className={styleProfile.title}>
                        {el?.user?.first_name} {el?.user?.last_name}
                      </span>
                      {!disable && <span onClick={() => handleDeleteAccountant(ind)}>
                        <Close />
                      </span>}
                    </div>
                  );
                })}
              </div>
              }
              <div className={styleProfile.withSelected}>
                <AddInput
                  readonly={disable}
                  label={t("addresses")}
                  addAddress={(val: string) => handleAddAddress(val)}
                />
                {addressList?.map((el: AddressItem, ind: number) => (
                  <div key={ind} className={`${styleProfile.selected} ${styleProfile.selectedAddress}`}>
                    <span className={styleProfile.title}>{el.name}</span>
                    {!disable && <span onClick={() => handleDeleteAddress(ind)}>
                      <Close />
                    </span>}
                  </div>
                ))}
              </div>
              {clinetsManagers?.length > 0 && <div className={styleProfile.withSelected}>
                <CustomSelect
                  readonly={disable}
                  label={t("managers")}
                  list={clinetsManagers?.map((el: any) => ({
                    name: `${el.user.first_name} ${el.user.last_name}`,
                    id: el?.id,
                  }))}
                  value={managerList}
                  multiple={true}
                  onChange={handleAddManager}
                />
                {selectedManagers?.map((el: any, ind: number) => {
                  return (
                    <div key={ind} className={styleProfile.selected}>
                      {el?.avatar_image
                        ? <img
                          src={`${process.env.REACT_APP_API_KEY}${el?.avatar_image}`}
                          className={styleProfile.image}
                        />
                        : <div><img
                          src={"/managerDefaultImg.svg"}
                          className={styleProfile.imageDefault}
                        /></div>}
                      <span className={styleProfile.title}>
                        {el?.user?.first_name} {el?.user?.last_name}
                      </span>
                      {!disable && <span onClick={() => handleDeleteManager(ind)}>
                        <Close />
                      </span>}
                    </div>
                  );
                })}
              </div>
              }
            </div>
          </div>
        </Form>
      )}
    </Formik>
  </div>
  );
};
export default EditCompany;
