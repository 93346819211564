import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from "yup";
import styles from '../DeleteDialog.module.scss';
import CustomButtosWithIcon from '../../Components/CustomButtosWithIcon';
import { CustomInput, CustomSelectFormik } from '../../Components';
import { useState } from 'react';
import { useGetMeasurementQuery } from '../../store/services/tasks';
interface IProduct {
    name: string
    price?: number
    count?: number
    discount?:number
}
interface IProps {
    type?: string,
    open?: any,
    handleClose?: () => void,
    handleAdd: (arg: IProduct) => void
}
const ProductDialog = ({ open, type, handleClose, handleAdd }: IProps) => {
    const { t } = useTranslation();
    const { data } = useGetMeasurementQuery({
        limit: 100000,
        offset: 0,
    });

    const SignupSchema = Yup.object().shape({
        name: Yup.string()
            .required(t('required')),
        price: Yup.number()
            .required(t('required'))
            .min(0, t('required')),
        count: Yup.number()
            .required(t('required'))
            .min(0, t('required')),
        measurement: Yup.string()
        .test({
            test: (value:any) => {
                if (!value && (type==='ladingBill' || type==='invoice' || type==='estimate')) {
                    return false
                }else{
                    return true
                }
            },
            message:t('required')
          }),
          discount: Yup.number()
          .min(0, t("minValue"))
          .max(100, t("maxValue"))
          .test(
             {test: (value:any) => {
                  if (!value  && (type==='invoice' || type==='estimate')) {
                      return false
                  }else{
                      return true
                  }
              },
            message:t('required')
            }
            ),

    });
    return <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <div className={styles["dialogCont"]}>
            <DialogContent>
                <div className={`d-flex flex-column ${styles.heading}`}>
                    {t("addProduct")}
                </div>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'center', gap: '2' }}>
                <Formik
                    initialValues={{
                        name: '',
                    }}
                    validationSchema={SignupSchema}
                    onSubmit={(
                        values: IProduct,
                        { setSubmitting }: FormikHelpers<IProduct>
                    ) => {
                        handleAdd(values)
                    }}
                >
                    {({ errors, touched, values }: any) => (
                        <Form className='w-100' >
                            <CustomInput name='name' title={t('name')} className='taskInput' />
                            <CustomInput name='price' title={t('price')} type='number' className='taskInput' />
                            <CustomInput name='count' title={t('count')} type='number' className='taskInput' />
                            {(type==='invoice'|| type==='estimate') && <CustomInput 
                            name='discount' 
                            title={t('discount')} 
                            type='number' 
                            className='taskInput'
                             />}
                            {(type === 'ladingBill' || type==='invoice' || type==='estimate') && <CustomSelectFormik
                                name='measurement'
                                className='taskInput'
                                label={t("measurement")}
                                list={data.map((el: any) => ({ name: t(`${el?.name}`), id: el.name }))}
                            />}
                            <CustomButtosWithIcon
                                title={t('add')}
                                type={'submit'}
                                // onClick={handleAdd}
                                icon=''
                            />

                        </Form>
                    )}
                </Formik>
            </DialogActions>
        </div>
    </Dialog>
}
export default ProductDialog;