import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import * as Yup from "yup";
import DeleteIcon from '@mui/icons-material/Delete';
import styles from './TaskPage.module.scss';
import { CustomButtosWithIcon, CustomizedTables, DetailItem, FileDownload } from '../../../Components';
import DocumentCard from '../../../Components/SvgCompoents/DocumentCard';
import ComponentWithBorder from '../../../Components/ComponentWithBorder';
import Recording from '../../../Components/Recording';
import { useEditTasksLadingBillByIdMutation } from '../../../store/services/tasks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAddImagesMutation } from '../../../store/services/photo';
import { Collapse } from '@mui/material';
import { getFileName } from '../../../Utils';
import { IFiles, ITaskPage } from '../../../types';
import DeleteTaskDialog from '../../../Dialogs/DeleteTaskDialog';
import { toast } from 'react-toastify';
interface DataTask {
    data: ITaskPage
}
export default function LadingBill({ data }: DataTask) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const params = useParams();
    const [files, setFiles] = useState<IFiles>({});
    const [submit, setSubmit] = useState<boolean>(false);
    const [status, setStatus] = useState<string>('done');
    const [complain, setComplain] = useState<string>('');
    const [savedFiles, setSavedFiles] = useState<IFiles>({});
    const navigate = useNavigate();
    const [editTasksLadingBillById, { isSuccess }] = useEditTasksLadingBillByIdMutation();
    // const { data, isLoading } = useGetTasksByIdQuery({ id: params.id });
    const xml = useMemo(() => {
        return getFileName(data?.lading_bill?.xml)
    }, [data?.lading_bill?.xml]);
    const property = useMemo(() => {
        return ["name", "measurement", "count", "price", "sum",]
    }, [])
    const lading_bill = useMemo(() => {
        return getFileName(data?.lading_bill?.lading_bill)
    }, [data?.lading_bill?.lading_bill]);
    const [addImages] = useAddImagesMutation();
    const handleSetFile = useCallback((data: IFiles) => {
        setFiles({ ...files, ...data })
    }, [files])
    const handleChangeStatus = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setStatus(e.target.value)
    }, [status])
    const handleComplain = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComplain(e.target.value)
    }, [])

    const handleDeleteFile = useCallback((name: string, id?: number) => {
        setFiles((prev: any) => {
            const prevFiles = { ...prev };
            delete prevFiles[name];
            return prevFiles
        })
    }, [])
    useEffect(() => {
        if (data?.lading_bill) {
            const { files } = data?.lading_bill
            setSavedFiles({ files: files.map((el: any) => el.url) })
        }
    }, [data])

    const handleOpenDeleteTask = useCallback(() => { setOpen(true) }, []);
    const handleCloseDeleteTask = useCallback(() => { setOpen(false) }, []);
    const deleteTask = () => {
        editTasksLadingBillById({ id: data?.lading_bill?.id, status: 'deleted' })
    }
    useEffect(() => {
        if (isSuccess) {
            toast.success(t('taskHasBeenDeleted'))
            handleCloseDeleteTask()
            navigate('/profile/dashboard')
        }
    }, [isSuccess])
    return (
        <div className={`w-100`}>
            <DeleteTaskDialog
                open={open}
                handleClose={handleCloseDeleteTask}
                handleDelete={deleteTask}
            />
            <div className='d-flex justify-content-between flex-nowrap'>
                <div className={`${styles.taskTypeHeading}`}>{t(`${data?.task_type?.name}`)} </div>
                <div className='d-flex align-items-center gap-2'>
                    <div className={`${styles.taskStatus} ${styles[data?.status]}`}>{t(`${data?.status}`)}</div>
                    {data?.status === 'created' && <div
                        onClick={handleOpenDeleteTask}
                        className={`${styles.taskStatus} ${styles.deleteTask}`}>
                        {t('delete')} <DeleteIcon />
                    </div>}
                </div>
            </div>
            <div className='d-flex justify-content-between flex-wrap'>
                <div className='d-flex justify-content-between flex-wrap'>
                    <div>
                        <div className={styles.heading}>{t('detail')} </div>
                        <div className={styles.detailCont}>
                            {data?.task_name && <DetailItem name={t('taskName')} value={data?.task_name} />}
                            {data?.lading_bill?.from_address && <DetailItem name={t('addressFrom')} value={data?.lading_bill?.from_address} />}
                            {data?.lading_bill?.to_address && <DetailItem name={t('buyerAddress')} value={data?.lading_bill?.to_address} />}
                            {data?.lading_bill?.car_number && <DetailItem name={t('carNumber')} value={data?.lading_bill?.car_number} />}
                            {data?.lading_bill?.shipping_method && <DetailItem name={t('shippingMethod')} value={t(`${data?.lading_bill?.shipping_method}`)} />}
                            {data?.created_at && <DetailItem name={t('createdAt')} value={moment(data?.created_at).format('DD-MMMM-YYYY')} />}
                            {data?.recording && <div className={styles.application}>
                                <h4 className={styles.subHeading}>{t('voiceMessage')}</h4>
                                <Recording src={data?.recording} />
                            </div>}

                        </div>

                    </div>

                </div>
                <div className={styles.filesCont}>
                    {data?.lading_bill?.xml && <div className={styles.application}>
                        <h4 className={styles.subHeading}>{t('xmlFile')}</h4>
                        <div className={`d-flex ${styles.cardCont}`}>
                            <FileDownload
                                url={`${process.env.REACT_APP_API_KEY}/${data?.lading_bill.xml}`}
                                status={data?.status}
                                title={xml}
                            />
                        </div>
                    </div>}
                    {savedFiles?.files && savedFiles?.files?.length > 0 && <>
                        <div className={styles.heading}> {t('attachedFiles')} </div>
                        {savedFiles?.files?.map((el: any, ind: number) => <FileDownload
                            url={`${process.env.REACT_APP_API_KEY}/${el}`}
                            status={data?.status}
                            title={getFileName(el)}
                            key={ind}
                        />
                        )}</>}
                </div>
            </div>
            {!data?.lading_bill?.xml && <CustomizedTables
                withDelete={false}
                sum={data?.lading_bill?.sum}
                rows={data?.lading_bill?.values}
                name={t('product')}
                property={property}
                excel={data?.excel}
            />}
            {data.status !== "created" && <>
                <hr />
                <div className={styles.heading}> {t('accountantAttachedFiles')} </div>
                <div className={`d-flex flex-wrap ${styles.cardsCont}`}>
                    <label className={`${styles.cardUploadItem} d-flex flex-column position-relative`}>
                        <input type='file' style={{ display: 'none' }}
                            onChange={(e: any) => {
                                const file: any = e?.target?.files[0];
                                if (file) {
                                    const formdata = new FormData();
                                    formdata.append('file_url', file)
                                    addImages(formdata)
                                        .then((res: any) => {
                                            if (res.data) {
                                                handleSetFile({ lading_bill: res.data })
                                            }
                                        })
                                }
                            }} />
                        <span className={styles.cardLabel}>{t('LadingBill')}</span>
                        {files?.lading_bill
                            ? <ComponentWithBorder
                                onClick={() => handleDeleteFile('lading_bill')}
                                img={`${process.env.REACT_APP_API_KEY}${files.lading_bill}`}
                            />
                            : (lading_bill
                                ? <FileDownload url={`${process.env.REACT_APP_API_KEY}/${data?.lading_bill?.lading_bill}`}
                                    status={data?.status}
                                    title={lading_bill}
                                    deleteItem={() => { console.log('dgf') }}
                                />
                                : <div className='d-flex flex-column align-items-center'>
                                    <DocumentCard />
                                    <span className={styles.cardTitle}>{t('attachFilesHere')}</span>
                                </div>)}
                    </label>

                </div>
            </>}
            <hr />
            {data.status === "answeredByAccountant" && <div className='d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <div className={styles.heading}> {t('acceptTask')} </div>
                    {(data?.status === 'answeredByAccountant') && <CustomButtosWithIcon
                        title={t("answer")}
                        icon={
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                            >
                                <path
                                    d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z"
                                    fill="#FEFEFE"
                                />
                            </svg>
                        }
                        type="submit"
                        onClick={() => {
                            setSubmit(true)
                            const edtData = data?.status === 'created'
                                ? { ...files?.files?.map((el) => ({ url: el })), status: 'answeredByAccountant', id: data?.employee_registration?.id, }
                                : { status, user_complain: complain, id: data?.lading_bill?.id, }
                            if (!complain && status === 'dispute') return
                            editTasksLadingBillById(edtData)
                        }}
                    />}
                </div>
                <div className='d-flex flex-column align-items-start gap-2'>
                    <label className={styles.customRadioItem}>
                        <input className="form-check-input"
                            type="radio"
                            id="contactChoice1"
                            name="status"
                            value="done"
                            onChange={handleChangeStatus}
                            checked={status === 'done'}
                        />
                        <div className={styles.customRadioItemLabel}>{t('finishTask')}</div></label>
                    <label className={styles.customRadioItem}>
                        <input className="form-check-input"
                            type="radio"
                            id="contactChoice2"
                            name="status"
                            value="dispute"
                            checked={status === 'dispute'}
                            onChange={handleChangeStatus}
                        />
                        <div className={styles.customRadioItemLabel}> {t('disputeTask')} </div></label>
                    <Collapse in={status === 'dispute'} timeout="auto">
                        <div className='mt-2'>
                            <textarea value={complain} className={styles['complain-textarea']} onChange={handleComplain} />
                            {submit && !complain && <div className='error'>{t("required")}</div>}
                        </div>
                    </Collapse>
                </div>
            </div>}
            {data.status === "dispute" && <div className='d-flex flex-column'>
                <div className={styles.heading}> {t('userComplain')} </div>
                <span className={styles.complain}>{data?.lading_bill?.user_complain}</span>
            </div>}
        </div>
    );
}


