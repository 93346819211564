import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import * as Yup from "yup";
import styles from './TaskPage.module.scss';
import { CustomButtosWithIcon, CustomInput, DetailItem, FileDownload } from '../../../Components';
import DocumentCard from '../../../Components/SvgCompoents/DocumentCard';
import ComponentWithBorder from '../../../Components/ComponentWithBorder';
import Recording from '../../../Components/Recording';
import { useEditDismissalTasksByIdMutation } from '../../../store/services/tasks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAddImagesMutation } from '../../../store/services/photo';
import { toast } from 'react-toastify';
import { getFileName } from '../../../Utils';
import { IFiles, ITaskPage } from '../../../types';
import { Form, Formik, FormikHelpers } from 'formik';
interface DataTask {
  data: ITaskPage
}
export default function Registration({ data }: DataTask) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [submit, setSubmit] = useState<boolean>(false);
  const [files, setFiles] = useState<IFiles>({});
  const [savedFiles, setSavedFiles] = useState<IFiles>({});
  const [addImages] = useAddImagesMutation();
  const [editDismissalTasksById] = useEditDismissalTasksByIdMutation();
  const description = t('salaryDescription');
  useEffect(() => {
    if (data?.employee_dismissal) {
      const { dismiss_order, request } = data?.employee_dismissal
      setSavedFiles({ dismiss_order, request })
    }
  }, [data])
  const handleSetFile = useCallback((data: IFiles) => {
    setFiles({ ...files, ...data })
  }, [files])

  const handleDeleteFile = useCallback((name: keyof typeof savedFiles) => {
    if (savedFiles[name]) {
      setSavedFiles((prev: IFiles) => {
        const prevFiles = { ...prev };
        delete prevFiles[name];
        return prevFiles
      })
    } else {
      setFiles((prev: IFiles) => {
        const prevFiles = { ...prev };
        delete prevFiles[name];
        return prevFiles
      })
    }

  }, [savedFiles])
  const application = useMemo(() => {
    return getFileName(data?.employee_dismissal?.application)
  }, [data?.employee_dismissal?.application]);
  const SignupSchema = Yup.object().shape({
    salary: Yup.number()
      .required(t('required'))
      .min(0, t('required')),
  });
  interface Values {
    salary?: number
  }
  const dismiss_order = useMemo(() => {
    return getFileName(savedFiles?.dismiss_order)
  }, [savedFiles?.dismiss_order]);


  const request = useMemo(() => {
    return getFileName(savedFiles?.request)
  }, [savedFiles?.request]);

  return (
    <div className={`w-100`}>
      <div className='d-flex justify-content-between flex-nowrap'>
        <div className={`${styles.taskTypeHeading}`}>{t(`${data?.task_type?.name}`)} </div>
        <div className={`${styles.taskStatus} ${styles[data?.status]}`}>{t(`${data?.status}`)}</div>
      </div>
      <div className='d-flex justify-content-between flex-wrap'>
        <div>
          <div className={styles.heading}>{t('detail')} </div>
          <div className={styles.detailCont}>
            {data?.task_name && <DetailItem name={t('taskName')} value={data?.task_name} />}
            {data?.employee_dismissal && data?.employee_dismissal?.salary !== 0 && <DetailItem
              name={t('salary')}
              value={new Intl.NumberFormat(
                'en-US',
              ).format(data?.employee_dismissal?.salary)} />}
            <DetailItem
              name={t('dismissalType')}
              value={data?.employee_dismissal?.type === 0
                ? t(`accordingOwnApplication`)
                : (data?.employee_dismissal?.type === 1
                  ? t(`dueLossConfidence`)
                  : t(`dueNonTaxableAbsences`))} />
            {data?.employee_dismissal?.dismiss_date && <DetailItem name={t('createdAt')} value={moment(data?.employee_dismissal?.dismiss_date).format('DD-MMMM-YYYY')} />}
            {data?.employee_dismissal?.explanation && <DetailItem name={t('explanation')} value={data?.employee_dismissal?.explanation} />}
          </div>

        </div>
        <div className={styles.filesCont}>
          <div className={styles.heading}> {t('attachedFiles')} </div>

          {data?.employee_dismissal?.application && <div className={styles.application}>
            <h4 className={styles.subHeading}>{t('accost')}</h4>
            <div className={`d-flex ${styles.cardCont}`}>
              <FileDownload
                url={`${process.env.REACT_APP_API_KEY}/${data?.employee_dismissal.application}`}
                status={data?.status}
                title={application}
              // deleteItem={() => { console.log('dgf') }}
              />
            </div>
          </div>}

          {data?.employee_dismissal?.recording && <div className={styles.application}>
            <h4 className={styles.subHeading}>{t('voiceMessage')}</h4>
            <Recording src={data?.employee_dismissal?.recording} />
          </div>}
          {/* <ComponentWithBorder img='/passport.png' /> */}
        </div>
      </div>
      <hr />
      {data?.status === "answeredByAccountant" &&
        <><div className={styles.heading}> {t('accountantSalary')} </div>
          {data?.employee_dismissal && <div>{description.replace('<<X<<', new Intl.NumberFormat(
            'en-US',
          ).format(
            data?.employee_dismissal?.salary))}</div>}
        </>}
      <hr />
      <Formik
        initialValues={{
        }}
        validationSchema={SignupSchema}
        onSubmit={(
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {
          const savedData = { ...savedFiles, ...files }
          if (savedData && savedData.dismiss_order && savedData.request) {
            editDismissalTasksById({ ...savedData, ...values, id: data?.employee_dismissal?.id, status: 'answeredByAccountant' })
              .then((res: any) => {
                if (res?.data?.id) {
                  toast.success('Գործընթացը հաջողությամբ կատարվել է');
                  navigate('/profile/dashboard')
                }
              })
          }
        }}
      >
        {({ errors, touched, values }: any) => (
          <Form >
            <div className={`d-flex justify-content-between align-items-center ${styles.heading}`}>
              <span> {t('accountantAttachedFiles')} </span>
              {(data?.status === 'created' || data?.status === 'dispute') && <CustomButtosWithIcon
                title={t("answer")}
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z"
                      fill="#FEFEFE"
                    />
                  </svg>
                }
                type="submit"
                onClick={() => {
                  setSubmit(true)

                }}
              />}
            </div>

            {data?.status !== "answeredByAccountant" && <CustomInput name='salary' title={t('salary')} type='number' className='taskInput' />}
            <div className={`d-flex flex-wrap ${styles.cardsCont}`}>
              <label className={`${styles.cardUploadItem} d-flex flex-column position-relative`}>
                <input type='file' style={{ display: 'none' }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const file: File | null = (e?.target?.files && e?.target?.files?.length > 0) ? e?.target?.files[0] : null;
                    if (file) {
                      const formdata = new FormData();
                      formdata.append('file_url', file)
                      addImages(formdata)
                        .then((res: any) => {
                          if (res.data) {
                            handleSetFile({ dismiss_order: res.data })
                          }
                        })
                    }
                  }} />
                <span className={styles.cardLabel}>{t('employmentContract')}</span>
                {files?.dismiss_order
                  ? <ComponentWithBorder
                    onClick={() => handleDeleteFile('dismiss_order')}
                    img={`${process.env.REACT_APP_API_KEY}${files.dismiss_order}`} />
                  : (dismiss_order
                    ? <FileDownload url={`${process.env.REACT_APP_API_KEY}/${savedFiles?.dismiss_order}`}
                      status={data?.status}
                      title={dismiss_order}
                      deleteItem={() => handleDeleteFile('dismiss_order')}
                    />
                    : <div className='d-flex flex-column'>
                      <DocumentCard />
                      <span className={styles.cardTitle}>{t('attachFilesHere')}</span>
                      {submit && !files?.dismiss_order && <div className='error error-text'>{t('required')}</div>}
                    </div>)}
              </label>
              <label className={`${styles.cardUploadItem} d-flex flex-column position-relative`}>
                <input type='file' style={{ display: 'none' }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const file: File | null = (e?.target?.files && e?.target?.files?.length > 0) ? e?.target?.files[0] : null;
                    if (file) {
                      const formdata = new FormData();
                      formdata.append('file_url', file)
                      addImages(formdata)
                        .then((res: any) => {
                          if (res.data) {
                            handleSetFile({ request: res.data })
                          }
                        })
                    }
                  }} />
                <span className={styles.cardLabel}>{t('employmentOrder')}</span>
                {files?.request
                  ? <ComponentWithBorder
                    onClick={() => handleDeleteFile('request')}
                    img={`${process.env.REACT_APP_API_KEY}${files.request}`} />
                  : (request
                    ? <FileDownload url={`${process.env.REACT_APP_API_KEY}/${savedFiles?.request}`}
                      status={data?.status}
                      title={request}
                      deleteItem={() => handleDeleteFile('request')}
                    />
                    : <div className='d-flex flex-column'>
                      <DocumentCard />
                      <span className={styles.cardTitle}>{t('attachFilesHere')}</span>
                      {submit && !files?.request && <div className='error error-text'>{t('required')}</div>}
                    </div>)}
              </label>

            </div>
            <hr />
            {data?.status === "dispute" && <div className='d-flex flex-column mt-3'>
              <div className={styles.heading}>{t('userComplain')} </div>
              <span className={styles.complain}>{data?.employee_dismissal?.user_complain}</span>
            </div>}
          </Form>)}
      </Formik>
    </div>
  );
}


