
const Lawyer = () => <svg xmlns="http://www.w3.org/2000/svg" width="74" height="74" viewBox="0 0 74 74" fill="none">
  <g clip-path="url(#clip0_42_3929)">
    <path d="M46.6118 50.0866L43.9085 48.6646C40.3192 51.7412 36.3684 53.5826 33.2103 53.5826C30.0522 53.5826 26.1016 51.7414 22.5122 48.6646L19.8087 50.0866C8.22172 58.3709 0 55.9133 0 66.4015V73.3919H66.4207V66.4015C66.4207 55.9133 58.1988 58.3709 46.6118 50.0866Z" fill="#545C66" />
    <path d="M33.2125 51.3182L20.9763 44.3261V47.8232L23.735 57.7553L33.2114 53.6499L42.7498 57.6394L45.4476 47.8232V44.3259L33.2125 51.3182Z" fill="#F5F7FA" />
    <path d="M33.2102 53.5408L42.619 57.5853L37.7026 73.3921H28.7189L23.8374 57.7117L33.2102 53.5408Z" fill="#E6E9ED" />
    <path d="M34.9574 59.3675H31.4635L28.3914 55.7383L33.2104 53.5408L38.03 55.6649L34.9574 59.3675Z" fill="#ED5565" />
    <path d="M37.2896 73.3923H29.1321V65.1944L31.464 59.3677H34.9578L37.2896 65.1944V73.3923Z" fill="#DA4453" />
    <path d="M40.2018 73.3919H37.7021L45.4471 47.823L51.873 53.3226L45.4449 61.698L47.7766 64.0275L40.2018 73.3919ZM26.2176 73.3919H28.7184L20.9724 47.823L14.5464 53.3226L20.9748 61.698L18.6428 64.0275L26.2176 73.3919Z" fill="#656D78" />
    <path d="M53.4881 25.5721V27.9028C53.4881 41.2458 40.8556 52.3753 33.0944 52.3753C32.6049 52.3753 32.1037 52.3287 31.5898 52.2472C23.8052 50.9766 12.7007 40.4082 12.7007 27.9028V25.5721L17.572 17.2046C17.572 17.2046 25.5907 20.5506 32.9311 20.5506C40.2623 20.5506 48.372 17.2046 48.372 17.2046L53.4881 25.5721Z" fill="#F0C7B7" />
    <path d="M43.851 8.82691C43.851 8.82691 56.3672 7.09082 53.4885 25.572L48.3714 17.2045C48.3714 17.2045 40.2617 20.5505 32.9304 20.5505C25.59 20.5505 17.5714 17.2045 17.5714 17.2045L12.7 25.572C9.78665 -5.50797 47.206 0.459377 47.206 0.459377C41.7303 2.7088 43.851 8.82691 43.851 8.82691Z" fill="#965353" />
    <path d="M66.4252 61.1537C66.3676 61.1537 66.3104 61.1454 66.2551 61.1292C66.1073 61.0838 65.9835 60.9816 65.911 60.8451C65.8385 60.7085 65.8231 60.5488 65.8682 60.4009L69.0729 49.9103C69.0952 49.837 69.1317 49.7688 69.1804 49.7096C69.2291 49.6505 69.289 49.6015 69.3567 49.5656C69.4244 49.5296 69.4985 49.5074 69.5748 49.5002C69.6511 49.4929 69.728 49.5008 69.8013 49.5234C69.949 49.5689 70.0727 49.6711 70.1453 49.8077C70.2178 49.9442 70.2332 50.1039 70.1882 50.2518L66.9834 60.7422C66.9463 60.8613 66.8721 60.9654 66.7718 61.0394C66.6714 61.1135 66.55 61.1535 66.4252 61.1538V61.1537Z" fill="#EDAB37" />
    <path d="M73.4177 61.1532C73.2928 61.1529 73.1713 61.1127 73.0709 61.0385C72.9705 60.9643 72.8964 60.8599 72.8595 60.7407L69.6547 50.2501C69.6097 50.1022 69.6251 49.9425 69.6976 49.806C69.7702 49.6695 69.8939 49.5673 70.0416 49.5218C70.1895 49.4769 70.3492 49.4924 70.4857 49.5649C70.6222 49.6374 70.7244 49.761 70.77 49.9087L73.9747 60.3992C74.0013 60.4864 74.0071 60.5785 73.9917 60.6683C73.9762 60.7581 73.94 60.8431 73.8859 60.9163C73.8317 60.9896 73.7612 61.0492 73.6799 61.0903C73.5986 61.1315 73.5088 61.153 73.4177 61.1532Z" fill="#EDAB37" />
    <path d="M73.4178 59.9868H66.4257C66.1042 59.9868 65.843 60.2481 65.843 60.5695C65.843 62.8189 67.6725 64.6482 69.9218 64.6482C72.171 64.6482 74.0005 62.8189 74.0005 60.5695C74.0005 60.2481 73.7393 59.9868 73.4178 59.9868Z" fill="#FFCE54" />
    <path d="M45.7369 61.1537C45.6793 61.1537 45.622 61.1454 45.5667 61.1292C45.4189 61.0838 45.2951 60.9817 45.2226 60.8451C45.15 60.7085 45.1346 60.5488 45.1797 60.4009L48.3844 49.9103C48.4067 49.837 48.4432 49.7688 48.4919 49.7096C48.5406 49.6505 48.6005 49.6015 48.6682 49.5656C48.7359 49.5296 48.81 49.5074 48.8863 49.5002C48.9626 49.4929 49.0396 49.5008 49.1128 49.5234C49.2606 49.5689 49.3843 49.6711 49.4569 49.8076C49.5294 49.9441 49.5449 50.1039 49.4998 50.2518L46.2951 60.7422C46.2579 60.8612 46.1838 60.9653 46.0834 61.0393C45.983 61.1133 45.8616 61.1534 45.7369 61.1537Z" fill="#EDAB37" />
    <path d="M52.729 61.1532C52.6041 61.1529 52.4826 61.1127 52.3822 61.0385C52.2818 60.9643 52.2077 60.8599 52.1707 60.7407L48.966 50.2501C48.921 50.1022 48.9364 49.9425 49.0089 49.806C49.0815 49.6695 49.2052 49.5673 49.3529 49.5218C49.5008 49.4769 49.6605 49.4924 49.797 49.5649C49.9334 49.6374 50.0357 49.761 50.0813 49.9087L53.286 60.3992C53.3126 60.4864 53.3184 60.5785 53.303 60.6683C53.2875 60.7581 53.2513 60.8431 53.1972 60.9163C53.143 60.9896 53.0725 61.0492 52.9912 61.0903C52.9099 61.1315 52.8201 61.153 52.729 61.1532Z" fill="#EDAB37" />
  </g>
</svg>

export default Lawyer

