import FeaturesItem from './FeaturesItem';
import style from './SectionFeatures.module.scss';
export default function SectionFeatures() {
    return (
        <div className='container'>
            <div className={style.headingCont}>
                <h2 className={style.heading}>Explore Scandapay’s Features</h2>
                <div className={style.headingNext}></div>
            </div>
            <p className={style.sectionTitle}>Scandapay is a fintech solution that allows users make payment for  goods purchased by scanning a QR Code. Scandapay also offers other amazing features.</p>
            <div className={style.features}>
                <FeaturesItem
                    title='Send, and Receive Money'
                    description='Our platform offers different ways for you to send and receive money money conveniently without any hassle'
                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="56" height="40" viewBox="0 0 56 40" fill="none">
                        <path d="M44.5 14.5C44.5 13.7707 44.2103 13.0712 43.6946 12.5555C43.1788 12.0398 42.4794 11.75 41.75 11.75H9.87752L16.2025 5.45252C16.7204 4.93469 17.0113 4.23235 17.0113 3.50002C17.0113 2.76769 16.7204 2.06536 16.2025 1.54752C15.6847 1.02969 14.9823 0.73877 14.25 0.73877C13.5177 0.73877 12.8154 1.02969 12.2975 1.54752L1.29752 12.5475C0.915934 12.9342 0.657444 13.4253 0.554665 13.9588C0.451887 14.4923 0.509424 15.0442 0.720017 15.545C0.926323 16.0472 1.27667 16.4771 1.7269 16.7805C2.17713 17.084 2.7071 17.2473 3.25002 17.25H41.75C42.4794 17.25 43.1788 16.9603 43.6946 16.4446C44.2103 15.9288 44.5 15.2294 44.5 14.5ZM55.28 24.455C55.0737 23.9528 54.7234 23.5229 54.2731 23.2195C53.8229 22.9161 53.2929 22.7527 52.75 22.75H14.25C13.5207 22.75 12.8212 23.0398 12.3055 23.5555C11.7898 24.0712 11.5 24.7707 11.5 25.5C11.5 26.2294 11.7898 26.9288 12.3055 27.4446C12.8212 27.9603 13.5207 28.25 14.25 28.25H46.1225L39.7975 34.5475C39.5398 34.8032 39.3352 35.1073 39.1956 35.4424C39.056 35.7775 38.9841 36.137 38.9841 36.5C38.9841 36.8631 39.056 37.2225 39.1956 37.5576C39.3352 37.8927 39.5398 38.1969 39.7975 38.4525C40.0532 38.7103 40.3573 38.9149 40.6924 39.0545C41.0275 39.1941 41.387 39.266 41.75 39.266C42.113 39.266 42.4725 39.1941 42.8076 39.0545C43.1427 38.9149 43.4469 38.7103 43.7025 38.4525L54.7025 27.4525C55.0841 27.0658 55.3426 26.5747 55.4454 26.0413C55.5481 25.5078 55.4906 24.9558 55.28 24.455Z" fill="#27004E" />
                    </svg>} />
                <FeaturesItem
                    title='Send, and Receive Money'
                    description='Our platform offers different ways for you to send and receive money money conveniently without any hassle'
                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="56" height="40" viewBox="0 0 56 40" fill="none">
                        <path d="M44.5 14.5C44.5 13.7707 44.2103 13.0712 43.6946 12.5555C43.1788 12.0398 42.4794 11.75 41.75 11.75H9.87752L16.2025 5.45252C16.7204 4.93469 17.0113 4.23235 17.0113 3.50002C17.0113 2.76769 16.7204 2.06536 16.2025 1.54752C15.6847 1.02969 14.9823 0.73877 14.25 0.73877C13.5177 0.73877 12.8154 1.02969 12.2975 1.54752L1.29752 12.5475C0.915934 12.9342 0.657444 13.4253 0.554665 13.9588C0.451887 14.4923 0.509424 15.0442 0.720017 15.545C0.926323 16.0472 1.27667 16.4771 1.7269 16.7805C2.17713 17.084 2.7071 17.2473 3.25002 17.25H41.75C42.4794 17.25 43.1788 16.9603 43.6946 16.4446C44.2103 15.9288 44.5 15.2294 44.5 14.5ZM55.28 24.455C55.0737 23.9528 54.7234 23.5229 54.2731 23.2195C53.8229 22.9161 53.2929 22.7527 52.75 22.75H14.25C13.5207 22.75 12.8212 23.0398 12.3055 23.5555C11.7898 24.0712 11.5 24.7707 11.5 25.5C11.5 26.2294 11.7898 26.9288 12.3055 27.4446C12.8212 27.9603 13.5207 28.25 14.25 28.25H46.1225L39.7975 34.5475C39.5398 34.8032 39.3352 35.1073 39.1956 35.4424C39.056 35.7775 38.9841 36.137 38.9841 36.5C38.9841 36.8631 39.056 37.2225 39.1956 37.5576C39.3352 37.8927 39.5398 38.1969 39.7975 38.4525C40.0532 38.7103 40.3573 38.9149 40.6924 39.0545C41.0275 39.1941 41.387 39.266 41.75 39.266C42.113 39.266 42.4725 39.1941 42.8076 39.0545C43.1427 38.9149 43.4469 38.7103 43.7025 38.4525L54.7025 27.4525C55.0841 27.0658 55.3426 26.5747 55.4454 26.0413C55.5481 25.5078 55.4906 24.9558 55.28 24.455Z" fill="#27004E" />
                    </svg>} />
                <FeaturesItem
                    title='Send, and Receive Money'
                    description='Our platform offers different ways for you to send and receive money money conveniently without any hassle'
                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="56" height="40" viewBox="0 0 56 40" fill="none">
                        <path d="M44.5 14.5C44.5 13.7707 44.2103 13.0712 43.6946 12.5555C43.1788 12.0398 42.4794 11.75 41.75 11.75H9.87752L16.2025 5.45252C16.7204 4.93469 17.0113 4.23235 17.0113 3.50002C17.0113 2.76769 16.7204 2.06536 16.2025 1.54752C15.6847 1.02969 14.9823 0.73877 14.25 0.73877C13.5177 0.73877 12.8154 1.02969 12.2975 1.54752L1.29752 12.5475C0.915934 12.9342 0.657444 13.4253 0.554665 13.9588C0.451887 14.4923 0.509424 15.0442 0.720017 15.545C0.926323 16.0472 1.27667 16.4771 1.7269 16.7805C2.17713 17.084 2.7071 17.2473 3.25002 17.25H41.75C42.4794 17.25 43.1788 16.9603 43.6946 16.4446C44.2103 15.9288 44.5 15.2294 44.5 14.5ZM55.28 24.455C55.0737 23.9528 54.7234 23.5229 54.2731 23.2195C53.8229 22.9161 53.2929 22.7527 52.75 22.75H14.25C13.5207 22.75 12.8212 23.0398 12.3055 23.5555C11.7898 24.0712 11.5 24.7707 11.5 25.5C11.5 26.2294 11.7898 26.9288 12.3055 27.4446C12.8212 27.9603 13.5207 28.25 14.25 28.25H46.1225L39.7975 34.5475C39.5398 34.8032 39.3352 35.1073 39.1956 35.4424C39.056 35.7775 38.9841 36.137 38.9841 36.5C38.9841 36.8631 39.056 37.2225 39.1956 37.5576C39.3352 37.8927 39.5398 38.1969 39.7975 38.4525C40.0532 38.7103 40.3573 38.9149 40.6924 39.0545C41.0275 39.1941 41.387 39.266 41.75 39.266C42.113 39.266 42.4725 39.1941 42.8076 39.0545C43.1427 38.9149 43.4469 38.7103 43.7025 38.4525L54.7025 27.4525C55.0841 27.0658 55.3426 26.5747 55.4454 26.0413C55.5481 25.5078 55.4906 24.9558 55.28 24.455Z" fill="#27004E" />
                    </svg>} />
                <FeaturesItem
                    title='Send, and Receive Money'
                    description='Our platform offers different ways for you to send and receive money money conveniently without any hassle'
                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="56" height="40" viewBox="0 0 56 40" fill="none">
                        <path d="M44.5 14.5C44.5 13.7707 44.2103 13.0712 43.6946 12.5555C43.1788 12.0398 42.4794 11.75 41.75 11.75H9.87752L16.2025 5.45252C16.7204 4.93469 17.0113 4.23235 17.0113 3.50002C17.0113 2.76769 16.7204 2.06536 16.2025 1.54752C15.6847 1.02969 14.9823 0.73877 14.25 0.73877C13.5177 0.73877 12.8154 1.02969 12.2975 1.54752L1.29752 12.5475C0.915934 12.9342 0.657444 13.4253 0.554665 13.9588C0.451887 14.4923 0.509424 15.0442 0.720017 15.545C0.926323 16.0472 1.27667 16.4771 1.7269 16.7805C2.17713 17.084 2.7071 17.2473 3.25002 17.25H41.75C42.4794 17.25 43.1788 16.9603 43.6946 16.4446C44.2103 15.9288 44.5 15.2294 44.5 14.5ZM55.28 24.455C55.0737 23.9528 54.7234 23.5229 54.2731 23.2195C53.8229 22.9161 53.2929 22.7527 52.75 22.75H14.25C13.5207 22.75 12.8212 23.0398 12.3055 23.5555C11.7898 24.0712 11.5 24.7707 11.5 25.5C11.5 26.2294 11.7898 26.9288 12.3055 27.4446C12.8212 27.9603 13.5207 28.25 14.25 28.25H46.1225L39.7975 34.5475C39.5398 34.8032 39.3352 35.1073 39.1956 35.4424C39.056 35.7775 38.9841 36.137 38.9841 36.5C38.9841 36.8631 39.056 37.2225 39.1956 37.5576C39.3352 37.8927 39.5398 38.1969 39.7975 38.4525C40.0532 38.7103 40.3573 38.9149 40.6924 39.0545C41.0275 39.1941 41.387 39.266 41.75 39.266C42.113 39.266 42.4725 39.1941 42.8076 39.0545C43.1427 38.9149 43.4469 38.7103 43.7025 38.4525L54.7025 27.4525C55.0841 27.0658 55.3426 26.5747 55.4454 26.0413C55.5481 25.5078 55.4906 24.9558 55.28 24.455Z" fill="#27004E" />
                    </svg>} />
                <FeaturesItem
                    title='Send, and Receive Money'
                    description='Our platform offers different ways for you to send and receive money money conveniently without any hassle'
                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="56" height="40" viewBox="0 0 56 40" fill="none">
                        <path d="M44.5 14.5C44.5 13.7707 44.2103 13.0712 43.6946 12.5555C43.1788 12.0398 42.4794 11.75 41.75 11.75H9.87752L16.2025 5.45252C16.7204 4.93469 17.0113 4.23235 17.0113 3.50002C17.0113 2.76769 16.7204 2.06536 16.2025 1.54752C15.6847 1.02969 14.9823 0.73877 14.25 0.73877C13.5177 0.73877 12.8154 1.02969 12.2975 1.54752L1.29752 12.5475C0.915934 12.9342 0.657444 13.4253 0.554665 13.9588C0.451887 14.4923 0.509424 15.0442 0.720017 15.545C0.926323 16.0472 1.27667 16.4771 1.7269 16.7805C2.17713 17.084 2.7071 17.2473 3.25002 17.25H41.75C42.4794 17.25 43.1788 16.9603 43.6946 16.4446C44.2103 15.9288 44.5 15.2294 44.5 14.5ZM55.28 24.455C55.0737 23.9528 54.7234 23.5229 54.2731 23.2195C53.8229 22.9161 53.2929 22.7527 52.75 22.75H14.25C13.5207 22.75 12.8212 23.0398 12.3055 23.5555C11.7898 24.0712 11.5 24.7707 11.5 25.5C11.5 26.2294 11.7898 26.9288 12.3055 27.4446C12.8212 27.9603 13.5207 28.25 14.25 28.25H46.1225L39.7975 34.5475C39.5398 34.8032 39.3352 35.1073 39.1956 35.4424C39.056 35.7775 38.9841 36.137 38.9841 36.5C38.9841 36.8631 39.056 37.2225 39.1956 37.5576C39.3352 37.8927 39.5398 38.1969 39.7975 38.4525C40.0532 38.7103 40.3573 38.9149 40.6924 39.0545C41.0275 39.1941 41.387 39.266 41.75 39.266C42.113 39.266 42.4725 39.1941 42.8076 39.0545C43.1427 38.9149 43.4469 38.7103 43.7025 38.4525L54.7025 27.4525C55.0841 27.0658 55.3426 26.5747 55.4454 26.0413C55.5481 25.5078 55.4906 24.9558 55.28 24.455Z" fill="#27004E" />
                    </svg>} />
                <FeaturesItem
                    title='Send, and Receive Money'
                    description='Our platform offers different ways for you to send and receive money money conveniently without any hassle'
                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="56" height="40" viewBox="0 0 56 40" fill="none">
                        <path d="M44.5 14.5C44.5 13.7707 44.2103 13.0712 43.6946 12.5555C43.1788 12.0398 42.4794 11.75 41.75 11.75H9.87752L16.2025 5.45252C16.7204 4.93469 17.0113 4.23235 17.0113 3.50002C17.0113 2.76769 16.7204 2.06536 16.2025 1.54752C15.6847 1.02969 14.9823 0.73877 14.25 0.73877C13.5177 0.73877 12.8154 1.02969 12.2975 1.54752L1.29752 12.5475C0.915934 12.9342 0.657444 13.4253 0.554665 13.9588C0.451887 14.4923 0.509424 15.0442 0.720017 15.545C0.926323 16.0472 1.27667 16.4771 1.7269 16.7805C2.17713 17.084 2.7071 17.2473 3.25002 17.25H41.75C42.4794 17.25 43.1788 16.9603 43.6946 16.4446C44.2103 15.9288 44.5 15.2294 44.5 14.5ZM55.28 24.455C55.0737 23.9528 54.7234 23.5229 54.2731 23.2195C53.8229 22.9161 53.2929 22.7527 52.75 22.75H14.25C13.5207 22.75 12.8212 23.0398 12.3055 23.5555C11.7898 24.0712 11.5 24.7707 11.5 25.5C11.5 26.2294 11.7898 26.9288 12.3055 27.4446C12.8212 27.9603 13.5207 28.25 14.25 28.25H46.1225L39.7975 34.5475C39.5398 34.8032 39.3352 35.1073 39.1956 35.4424C39.056 35.7775 38.9841 36.137 38.9841 36.5C38.9841 36.8631 39.056 37.2225 39.1956 37.5576C39.3352 37.8927 39.5398 38.1969 39.7975 38.4525C40.0532 38.7103 40.3573 38.9149 40.6924 39.0545C41.0275 39.1941 41.387 39.266 41.75 39.266C42.113 39.266 42.4725 39.1941 42.8076 39.0545C43.1427 38.9149 43.4469 38.7103 43.7025 38.4525L54.7025 27.4525C55.0841 27.0658 55.3426 26.5747 55.4454 26.0413C55.5481 25.5078 55.4906 24.9558 55.28 24.455Z" fill="#27004E" />
                    </svg>} />
            </div>
        </div>
    )
}