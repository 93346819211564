const getFileName = (string: string | null | undefined) => {
  const stringList = string ? string?.split("/") : '';
  return stringList ? stringList[stringList?.length - 1] : ''
}
const fileIcon = (title:string, className:string) => {
  if (title.includes('png') || title.includes('jpg') || title.includes('webp') || title.includes('svg') || title.includes('jpeg')) {
    return <img className={className} src='/photo.jpg' />
  }
  if (title.includes('pdf')) {
    return <img className={className} src='/pdf.jpg' />
  }
  return <img className={className} src='/file.jpg' />
}
const fileIconSrc = (title:string) => {
  if (title.includes('png') || title.includes('jpg') || title.includes('webp') || title.includes('svg') || title.includes('jpeg')) {
    return '/photo.jpg'
  }
  if (title.includes('pdf')) {
    return '/pdf.jpg'
  }
  return '/file.jpg'
}
export { getFileName, fileIcon, fileIconSrc };