import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist';
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from '@reduxjs/toolkit/query';
import storage from 'redux-persist/lib/storage';
import { authApiSignIn } from './services/auth';
import { userApi } from './services/user';
import { managersApi } from './services/managers';
import { notificationsApi } from './services/notifications';
import { companiesApi } from './services/companies';
import { accountantCompaniesApi } from './services/accountantCompanies';
import { accountantsApi } from './services/accountants';
import { photoApi } from './services/photo';
import { tasksApi } from './services/tasks';
import createTaskSlice from './slice/createtask';
import notificationSlice from './slice/notificationSlice';
import activitySlice from './slice/activitySlice';
import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector
} from 'react-redux'
import authSlice from './slice/authSlice';
import { activityApi } from './services/activity';
const persistConfig = {
  key: 'root',
  storage,
  blacklist: [tasksApi.reducerPath],
}
const reducers = combineReducers({
  // Add the generated reducer as a specific top-level slice
  [authApiSignIn.reducerPath]: authApiSignIn.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [managersApi.reducerPath]: managersApi.reducer,
  [companiesApi.reducerPath]: companiesApi.reducer,
  [accountantCompaniesApi.reducerPath]: accountantCompaniesApi.reducer,
  [photoApi.reducerPath]: photoApi.reducer,
  [tasksApi.reducerPath]: tasksApi.reducer,
  [notificationsApi.reducerPath]: notificationsApi.reducer,
  [accountantsApi.reducerPath]: accountantsApi.reducer,
  [activityApi.reducerPath]: activityApi.reducer,
  createTask: createTaskSlice,
  auth: authSlice,
  notificationsContainer: notificationSlice,
  activitySlice

})
export const store = configureStore({
  reducer: reducers,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware(
    //   {
    //   serializableCheck: {
    //     ignoredPaths: ['tasksApi'],
    //     ignoredActions: [FLUSH,
    //       REHYDRATE,
    //       PAUSE,
    //       PERSIST,
    //       PURGE,
    //       REGISTER,
    //       'tasksApi/executeQuery/fulfilled'
    //     ],
    //   }
    // }
  ).concat([
      authApiSignIn.middleware,
      userApi.middleware,
      managersApi.middleware,
      companiesApi.middleware,
      accountantCompaniesApi.middleware,
      tasksApi.middleware,
      photoApi.middleware,
      notificationsApi.middleware,
      accountantsApi.middleware,
      activityApi.middleware
    ]),
})
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
// export const persistor = persistStore(store);
// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)
