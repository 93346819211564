import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  paper: {
    border: "none",
    color: '#021620',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 24,
    letterSpacing: -0.084,
  }
});
export default function CustomAutocomplete({ options, label }: any) {
  const classes = useStyles();
  return (
    <Autocomplete
      id="combo-box-demo"
      options={options}
      getOptionLabel={(option: any) => option?.title}
      style={{ minWidth: 130 }}
      renderInput={(params: any) => (
        <TextField {...params} className={classes.paper} label={label} variant="outlined" />
      )}
    />
  );
}