import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import styleProfile from './profile.module.scss'
import * as Yup from "yup";
import { Form, Formik, FormikHelpers } from 'formik';
import { CustomButtosWithIcon, CustomInput } from '../../../Components';
import { useAddImagesMutation } from '../../../store/services/photo';
import EditUser from '../../../Components/SvgCompoents/EditUser';
import Changepassword from '../../../Components/SvgCompoents/Changepassword';
import ChangePhoto from '../../../Components/SvgCompoents/ChangePhoto';
import { useAppDispatch, useAppSelector } from '../../../store';
import ResetPassword from '../../ResetPassword';
import { useEditMeMutation } from '../../../store/services/auth';
import { deleteUser } from '../../../store/slice/authSlice';
import DeleteAccountDialog from '../../../Dialogs/DeleteAccountDialog';

export default function AddEmployess() {
  const [open, setOpen] = useState(false);
  const [disable, setDisable] = useState(true);
  const [openResetPassword, setOpenResetPassword] = useState(false);
  const [editMeRequest] = useEditMeMutation();
  const [addImagesRequest] = useAddImagesMutation();
  const { t } = useTranslation();
  const auth: any = useAppSelector((state) => state.auth);

  const SignupSchema = Yup.object().shape({
    email: Yup.string().email(t('emailError'))
      .required(t('required')),
    first_name: Yup.string()
      .required(t('required')),
    last_name: Yup.string()
      .required(t('required')),
      phone_number: Yup.string()
      .required(t("required"))
      .test(
        'unique',
        t("phoneNumberError").toString(),
        (value:any) => /^[0-9\b]+$/.test(value)
      ),
    // password: Yup.string()
    //   .required(t('required')),
  });

  interface Values {
    email: string;
    first_name: string,
    last_name: string,
    phone_number: string,
    // password: string
  }
  const dispatch = useAppDispatch();
  const handleEdit = useCallback(() =>
    setTimeout(() => {
      setDisable(false)
    }, 1000)
    , [])
  const handleOpenResetPassword = useCallback(() => { setOpenResetPassword(true) }, []);
  const handleCloseResetPassword = useCallback(() => { setOpenResetPassword(false) }, []);
  const handleOpenDeleteAccount = useCallback(() => { setOpen(true) }, []);
  const handleCloseDeleteAccount = useCallback(() => { setOpen(false) }, []);
  const handleDelete = useCallback(() => { dispatch(deleteUser()); setOpen(false) }, []);
  const [addImages, { data }] = useAddImagesMutation();
  const avatar_image = useMemo(() => {
    return data ? `${process.env.REACT_APP_API_KEY}${data}` : `${process.env.REACT_APP_API_KEY}${auth?.me?.avatar_image}`
  }, [data, auth.me])

  return (
    auth?.me?.user && <div className={`w-100`}>
      <Formik
        initialValues={{
          email: auth?.me?.user?.email,
          first_name: auth?.me?.user?.first_name,
          last_name: auth?.me?.user?.last_name,
          phone_number: auth?.me?.phone_number,
          id: auth?.me?.id
          // password: ''
        }}
        validationSchema={SignupSchema}
        onSubmit={(
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {
          if (!disable) {
            editMeRequest(values)
              .then((res: any) => {
                if (res?.data?.user_detail) {
                  toast.success(t('editProfileSuccess'))
                  setDisable(true)
                } 
              })
          }
        }}
      >
        {({ values }: any) => (
          <Form >
            <DeleteAccountDialog
              open={open}
              handleClose={handleCloseDeleteAccount}
              handleDelete={handleDelete}
            />
            <div className={styleProfile.profilePhotos}>
              {/* <h1 className={styleProfile.myProfile}>My Profile</h1> */}
              <div className={`w-100 d-flex justify-content-between ${styleProfile.nameImages}`}>
                <div className='d-flex flex-wrap justify-content-between align-items-center w-100'>
                  <div className={styleProfile.profilePhotoEdit}>
                  {auth?.me?.avatar_image ? (
                        <img
                          src={`${process.env.REACT_APP_API_KEY}${auth?.me?.avatar_image}`}
                          className={styleProfile.profilePhoto}
                        />
                      ) : data ? (
                        <img
                          src={`${process.env.REACT_APP_API_KEY}${data}`}
                          className={styleProfile.profilePhoto}
                        />
                      ) : (
                        <div className={styleProfile.imgContainer}>
                          <img src={"/managerDefaultImg.svg"} />
                        </div>
                      )}
                    <label>
                      <input
                        type='file'
                        style={{ display: 'none' }}
                        onChange={(e: any) => {
                          const file: any = e?.target?.files[0];
                          if (file) {
                            const formdata = new FormData();
                            formdata.append('file_url', file)
                            addImages(formdata)
                              .then((res: any) => {
                                editMeRequest({ ...values, avatar_image: `${res.data}` })
                              });
                          }
                        }}
                      />
                      <ChangePhoto />
                    </label>
                  </div>
                  <div className='d-flex gap-2'>
                  <CustomButtosWithIcon
                    color='red'
                    title={t('settingsDelete')}
                    type={'button'}
                    onClick={handleOpenDeleteAccount}
                    icon={<DeleteIcon />}
                  />
                    {disable ? <div><CustomButtosWithIcon
                      title={t('editProfile')}
                      type={'button'}
                      onClick={handleEdit}
                      icon={<EditUser />}
                    />
                    </div>
                      : <div>
                        <CustomButtosWithIcon
                          title={t('save')}
                          type='submit'
                          onClick={console.log('ggg')}
                          icon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z" fill="#FEFEFE" />
                          </svg>}
                        />
                      </div>}
                    <CustomButtosWithIcon
                      title={t('changePassword')}
                      type='button'
                      onClick={handleOpenResetPassword}
                      icon={<Changepassword />}
                    />
                  </div>
                </div>
              </div>
            </div>
            <p style={{ marginTop: "20px" }}>{auth?.me?.user?.email}</p>
            <div className={styleProfile.formsCont}>
              <div className={`d-flex flex-wrap justify-content-between ${styleProfile.inputs}`}>
                <CustomInput name='first_name' readonly={disable} title={t('firstName')} />
                <CustomInput name='last_name' readonly={disable} title={t('lastName')} />
                <CustomInput name='phone_number' readonly={disable} title={t('phoneNumber')} />
                {/* <CustomInput name='email' readonly={disable} title={t('emailAdress')} /> */}
                {/* <CustomInput name='password' title={t('password')} />
                <CustomInput name='repeat_password' title={t('repeatPassword')} /> */}
              </div>

            </div>
          </Form>
        )}
      </Formik>
      <ResetPassword open={openResetPassword} handleClose={handleCloseResetPassword} handleOpen={handleOpenResetPassword} />
      {/*<CompanyItem img='/manager.png' name='Western Auto' profession='Stephanie Nicol' /> */}
    </div>

  );
}


