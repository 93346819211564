import {  createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { IImages } from '../../types';
export interface ITask{
  company_id:string,
  task_type_id:string
}

const createTaskSlice = createSlice({
  name: 'createTask',
  initialState:{taskData:{company_id:'', task_type_id:''}},
  reducers: {
    createTask(state, action: PayloadAction<ITask>) {      
        state.taskData=action.payload
    },
  },
})
export const { createTask } = createTaskSlice.actions
export default createTaskSlice.reducer