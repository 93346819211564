import React, { useEffect } from 'react';
// import { getMe } from '../../store/Slices/authSlice';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../store';
import { useGetMeQuery } from '../store/services/auth';

type Props = {
  children: React.ReactNode
};
const Layout = ({ children }: Props) => {
  const access = localStorage.getItem('rbc-access-token')
  const navigate = useNavigate()
  // const { data } = useGetMeQuery({ id: localStorage.getItem('rbcId') });
  const auth: any = useAppSelector((state) => state.auth)
  const location = useLocation()
  // useEffect(() => {
  //   if (auth?.isLogged) {
  //     navigate('/profile', { replace: true })
  //   }

  // }, [auth]);

  return (auth?.isLogged ? <Navigate to='/profile/dashboard' state={{ from: location }} replace /> : <div>{children}</div>)
}
export default Layout;