import style from './SectionOne.module.scss';
export default function SectionOne() {
    return (
        <div className={style.conainer}>
            <img className={style.ellipse} src='/Ellipse.png' />
            <div className={`${style.cnt} container d-flex justify-content-between align-items-center`}>
                <div className={style.textImages}>
                    <h1 className={style.heading}>Create QR Code to Send and Receive Money Easily</h1>
                    <p className={style.decription}>Send and receive money easily, pay bills, create QR Codes to receive payments, and also reward other users.</p>
                    <img src='/appstore-icon-mobile-retina 2.png' />
                    <img src='/appstore-icon-mobile-retina 3.png' />

                </div>
                <div className={style.imagesCont}>
                    <img className={style.image1} src='/Android Large - 129.png' />
                    <img className={style.image2} src='/Android Large - 108.png' />
                </div>
            </div>
        </div>
    )
}