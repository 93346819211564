
const LoginSvg = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 537 543" fill="none">
  <g clip-path="url(#clip0_83_4961)">
    <path d="M413.354 411.47C430.973 421.658 431.096 438.156 413.559 448.344L263.969 535.366C246.459 545.54 218.004 545.54 200.386 535.366L49.704 448.33C32.0852 438.156 31.9623 421.658 49.4856 411.456L199.144 324.421C216.653 314.246 245.122 314.246 262.727 324.421L413.354 411.47Z" fill="#EEEFFF" />
    <path d="M431.833 392.514L267.203 488.249C247.947 499.448 216.708 499.434 197.274 488.249L31.4983 392.514C21.754 386.874 16.9501 379.485 16.9501 372.097V387.12C16.9501 394.508 21.8086 401.896 31.5529 407.537L197.26 503.272C216.626 514.457 247.933 514.471 267.19 503.272L431.819 407.537C441.372 401.978 446.163 394.699 446.19 387.406V372.384C446.122 379.663 441.386 386.942 431.833 392.514Z" fill="url(#paint0_linear_83_4961)" />
    <path d="M431.532 351.967C450.912 363.165 451.021 381.315 431.764 392.514L267.135 488.249C247.879 499.448 216.64 499.434 197.206 488.249L31.4983 392.514C12.1326 381.315 12.0098 363.165 31.2663 351.967L195.896 256.232C215.152 245.033 246.391 245.033 265.839 256.232L431.532 351.967Z" fill="url(#paint1_linear_83_4961)" />
    <g opacity="0.32">
      <path d="M292.601 319.832L294.239 318.876L323.363 335.701L321.711 336.657L292.601 319.832Z" fill="url(#paint2_radial_83_4961)" />
      <path d="M139.668 408.766L141.319 407.81L170.429 424.635L168.778 425.591L139.668 408.766Z" fill="url(#paint3_radial_83_4961)" />
      <path d="M47.6706 355.627L49.3219 354.657L78.0907 371.278L76.4394 372.234L47.6706 355.627Z" fill="url(#paint4_radial_83_4961)" />
      <path d="M200.604 266.693L202.255 265.723L231.024 282.344L229.373 283.3L200.604 266.693Z" fill="url(#paint5_radial_83_4961)" />
      <path d="M170.02 284.474L171.671 283.505L200.44 300.125L198.789 301.081L170.02 284.474Z" fill="url(#paint6_radial_83_4961)" />
      <path d="M261.813 302.051L263.464 301.081L292.588 317.906L290.936 318.876L261.813 302.051Z" fill="url(#paint7_radial_83_4961)" />
      <path d="M139.436 302.255L141.101 301.299L169.856 317.906L168.205 318.876L139.436 302.255Z" fill="url(#paint8_radial_83_4961)" />
      <path d="M231.229 319.832L232.88 318.876L262.004 335.701L260.352 336.657L231.229 319.832Z" fill="url(#paint9_radial_83_4961)" />
      <path d="M169.87 319.832L171.521 318.876L200.631 335.701L198.98 336.657L169.87 319.832Z" fill="url(#paint10_radial_83_4961)" />
      <path d="M108.838 320.05L110.517 319.081L139.272 335.701L137.607 336.671L108.838 320.05Z" fill="url(#paint11_radial_83_4961)" />
      <path d="M323.376 337.613L325.028 336.657L354.151 353.483L352.5 354.439L323.376 337.613Z" fill="url(#paint12_radial_83_4961)" />
      <path d="M262.017 337.613L263.669 336.657L292.779 353.483L291.127 354.439L262.017 337.613Z" fill="url(#paint13_radial_83_4961)" />
      <path d="M200.645 337.613L202.296 336.657L231.42 353.483L229.769 354.439L200.645 337.613Z" fill="url(#paint14_radial_83_4961)" />
      <path d="M139.259 337.627L140.937 336.657L170.061 353.483L168.382 354.452L139.259 337.627Z" fill="url(#paint15_radial_83_4961)" />
      <path d="M78.2545 337.832L79.9332 336.862L108.688 353.483L107.023 354.452L78.2545 337.832Z" fill="url(#paint16_radial_83_4961)" />
      <path d="M292.792 355.408L294.444 354.439L323.567 371.264L321.916 372.22L292.792 355.408Z" fill="url(#paint17_radial_83_4961)" />
      <path d="M231.434 355.408L233.085 354.439L262.209 371.264L260.544 372.22L231.434 355.408Z" fill="url(#paint18_radial_83_4961)" />
      <path d="M170.047 355.408L171.712 354.439L200.836 371.264L199.171 372.234L170.047 355.408Z" fill="url(#paint19_radial_83_4961)" />
      <path d="M108.675 355.408L110.353 354.439L139.477 371.264L137.798 372.234L108.675 355.408Z" fill="url(#paint20_radial_83_4961)" />
      <path d="M262.209 373.19L263.86 372.22L292.984 389.045L291.332 390.015L262.209 373.19Z" fill="url(#paint21_radial_83_4961)" />
      <path d="M200.822 373.203L202.501 372.22L231.625 389.045L229.946 390.015L200.822 373.203Z" fill="url(#paint22_radial_83_4961)" />
      <path d="M139.463 373.203L141.128 372.22L170.252 389.045L168.587 390.015L139.463 373.203Z" fill="url(#paint23_radial_83_4961)" />
      <path d="M261.253 266.693L232.675 283.3L231.024 284.269L202.105 301.081L200.454 302.051L171.521 318.876L169.87 319.832L140.937 336.657L139.259 337.627L110.353 354.439L108.675 355.408L79.7557 372.234L78.1044 373.203L49.5266 389.81L47.8616 388.854L76.4394 372.234L78.0907 371.278L107.023 354.452L108.688 353.483L137.607 336.671L139.272 335.701L168.205 318.876L169.856 317.906L198.789 301.081L200.44 300.125L229.373 283.3L231.024 282.344L259.588 265.723L261.253 266.693Z" fill="url(#paint24_radial_83_4961)" />
      <path d="M293.011 390.985L294.662 390.015L323.772 406.84L322.121 407.796L293.011 390.985Z" fill="url(#paint25_radial_83_4961)" />
      <path d="M231.611 390.985L233.276 390.015L262.4 406.84L260.735 407.81L231.611 390.985Z" fill="url(#paint26_radial_83_4961)" />
      <path d="M170.252 390.985L171.917 390.015L201.041 406.84L199.362 407.81L170.252 390.985Z" fill="url(#paint27_radial_83_4961)" />
      <path d="M292.042 284.474L263.464 301.081L261.813 302.051L232.88 318.876L231.229 319.832L202.296 336.657L200.645 337.613L171.712 354.439L170.047 355.408L141.128 372.234L139.463 373.203L110.531 390.029L108.879 390.985L80.3016 407.605L78.6503 406.636L107.214 390.029L108.866 389.059L137.798 372.234L139.477 371.264L168.382 354.452L170.061 353.483L198.98 336.657L200.631 335.701L229.564 318.876L231.215 317.906L260.148 301.081L261.799 300.125L290.377 283.505L292.042 284.474Z" fill="url(#paint28_radial_83_4961)" />
      <path d="M108.879 390.985L110.531 390.029L139.654 406.84L138.003 407.81L108.879 390.985Z" fill="url(#paint29_radial_83_4961)" />
      <path d="M262.413 408.78L264.078 407.81L293.188 424.622L291.51 425.591L262.413 408.78Z" fill="url(#paint30_radial_83_4961)" />
      <path d="M201.027 408.766L202.706 407.796L231.816 424.622L230.151 425.591L201.027 408.766Z" fill="url(#paint31_radial_83_4961)" />
      <path d="M322.817 302.255L294.239 318.876L292.601 319.832L263.669 336.657L262.017 337.613L233.085 354.439L231.434 355.408L202.501 372.22L200.822 373.203L171.917 390.015L170.252 390.985L141.319 407.81L139.668 408.766L111.09 425.386L109.425 424.417L138.003 407.81L139.654 406.84L168.587 390.015L170.252 389.045L199.171 372.234L200.836 371.264L229.769 354.439L231.42 353.483L260.352 336.657L262.004 335.701L290.936 318.876L292.588 317.906L321.152 301.299L322.817 302.255Z" fill="url(#paint32_radial_83_4961)" />
      <path d="M353.606 320.037L325.028 336.657L323.376 337.613L294.444 354.439L292.792 355.408L263.86 372.22L262.209 373.19L233.276 390.015L231.611 390.985L202.706 407.796L201.027 408.766L172.094 425.591L170.443 426.547L141.865 443.168L140.214 442.212L168.778 425.591L170.429 424.635L199.362 407.81L201.041 406.84L229.946 390.015L231.625 389.045L260.544 372.22L262.209 371.264L291.128 354.439L292.779 353.483L321.711 336.657L323.363 335.701L351.941 319.081L353.606 320.037Z" fill="url(#paint33_radial_83_4961)" />
      <path d="M170.443 426.547L172.094 425.591L201.218 442.417L199.567 443.373L170.443 426.547Z" fill="url(#paint34_radial_83_4961)" />
      <path d="M384.408 337.832L355.83 354.452L354.179 355.408L325.246 372.234L323.595 373.203L294.662 390.015L293.011 390.985L264.078 407.81L262.413 408.78L233.494 425.591L231.829 426.561L202.897 443.386L201.245 444.342L172.681 460.963L170.989 459.993L199.567 443.373L201.218 442.417L230.151 425.591L231.816 424.622L260.735 407.81L262.4 406.84L291.332 390.015L292.984 389.045L321.916 372.22L323.567 371.264L352.5 354.439L354.151 353.483L382.716 336.862L384.408 337.832Z" fill="url(#paint35_radial_83_4961)" />
      <path d="M354.179 355.408L355.83 354.452L384.926 371.264L383.275 372.22L354.179 355.408Z" fill="url(#paint36_radial_83_4961)" />
      <path d="M323.595 373.203L325.246 372.234L354.342 389.045L352.691 390.015L323.595 373.203Z" fill="url(#paint37_radial_83_4961)" />
      <path d="M231.829 426.561L233.494 425.591L262.604 442.403L260.926 443.373L231.829 426.561Z" fill="url(#paint38_radial_83_4961)" />
      <path d="M201.245 444.342L202.897 443.386L231.993 460.198L230.342 461.168L201.245 444.342Z" fill="url(#paint39_radial_83_4961)" />
      <path d="M415.183 355.613L386.619 372.234L384.967 373.19L356.035 390.015L354.383 390.985L325.451 407.81L323.799 408.766L294.867 425.591L293.202 426.561L264.283 443.373L262.618 444.342L233.685 461.168L232.034 462.137L203.456 478.744L201.778 477.774L230.342 461.168L231.993 460.198L260.926 443.373L262.604 442.403L291.51 425.591L293.188 424.622L322.121 407.796L323.772 406.84L352.691 390.015L354.342 389.045L383.275 372.22L384.926 371.264L413.504 354.643L415.183 355.613Z" fill="url(#paint40_radial_83_4961)" />
      <path d="M386.619 372.234L415.347 388.84L413.695 389.796L384.967 373.19L386.619 372.234Z" fill="url(#paint41_radial_83_4961)" />
      <path d="M231.024 284.269L232.675 283.3L261.799 300.125L260.148 301.081L231.024 284.269Z" fill="url(#paint42_radial_83_4961)" />
      <path d="M356.035 390.015L384.776 406.622L383.125 407.592L354.383 390.985L356.035 390.015Z" fill="url(#paint43_radial_83_4961)" />
      <path d="M200.454 302.051L202.105 301.081L231.215 317.906L229.564 318.876L200.454 302.051Z" fill="url(#paint44_radial_83_4961)" />
      <path d="M325.451 407.81L354.192 424.403L352.541 425.373L323.799 408.766L325.451 407.81Z" fill="url(#paint45_radial_83_4961)" />
      <path d="M294.867 425.591L323.608 442.198L321.93 443.168L293.202 426.561L294.867 425.591Z" fill="url(#paint46_radial_83_4961)" />
      <path d="M264.283 443.373L293.025 459.98L291.346 460.949L262.618 444.342L264.283 443.373Z" fill="url(#paint47_radial_83_4961)" />
      <path d="M233.685 461.168L262.427 477.774L260.776 478.744L232.034 462.137L233.685 461.168Z" fill="url(#paint48_radial_83_4961)" />
      <path d="M78.1044 373.203L79.7557 372.234L108.866 389.059L107.214 390.029L78.1044 373.203Z" fill="url(#paint49_radial_83_4961)" />
      <path d="M236.265 280.227C239.199 281.92 239.185 284.665 236.265 286.331C234.629 287.166 232.819 287.601 230.983 287.601C229.147 287.601 227.337 287.166 225.702 286.331C222.795 284.665 222.781 281.907 225.702 280.213C227.339 279.383 229.149 278.951 230.985 278.954C232.82 278.956 234.629 279.392 236.265 280.227Z" fill="url(#paint50_radial_83_4961)" />
      <path d="M267.053 298.008C269.974 299.702 269.974 302.447 267.053 304.127C265.418 304.955 263.611 305.386 261.779 305.386C259.946 305.386 258.139 304.955 256.504 304.127C253.597 302.447 253.583 299.688 256.504 297.995C258.141 297.173 259.948 296.746 261.78 296.748C263.612 296.751 265.418 297.182 267.053 298.008Z" fill="url(#paint51_radial_83_4961)" />
      <path d="M297.856 315.803C300.763 317.483 300.776 320.242 297.856 321.921C296.221 322.753 294.414 323.186 292.581 323.186C290.748 323.186 288.94 322.753 287.306 321.921C284.372 320.228 284.386 317.483 287.306 315.789C288.943 314.965 290.75 314.536 292.583 314.538C294.415 314.541 296.221 314.974 297.856 315.803Z" fill="url(#paint52_radial_83_4961)" />
      <path d="M328.644 333.598C331.565 335.278 331.551 338.023 328.644 339.703C327.009 340.537 325.199 340.972 323.363 340.972C321.527 340.972 319.717 340.537 318.081 339.703C315.161 338.009 315.161 335.264 318.081 333.584C319.718 332.753 321.529 332.321 323.364 332.323C325.2 332.326 327.009 332.763 328.644 333.598Z" fill="url(#paint53_radial_83_4961)" />
      <path d="M359.419 351.379C362.34 353.073 362.34 355.804 359.419 357.484C357.784 358.319 355.974 358.754 354.138 358.754C352.302 358.754 350.492 358.319 348.856 357.484C345.936 355.791 345.936 353.059 348.856 351.366C350.493 350.532 352.303 350.099 354.139 350.101C355.976 350.104 357.785 350.542 359.419 351.379Z" fill="url(#paint54_radial_83_4961)" />
      <path d="M390.208 369.161C393.129 370.854 393.129 373.599 390.208 375.279C388.571 376.11 386.762 376.544 384.926 376.544C383.091 376.544 381.282 376.11 379.645 375.279C376.724 373.599 376.724 370.854 379.645 369.161C381.281 368.327 383.091 367.893 384.926 367.893C386.762 367.893 388.572 368.327 390.208 369.161Z" fill="url(#paint55_radial_83_4961)" />
      <path d="M205.667 298.022C208.588 299.715 208.601 302.447 205.667 304.14C204.032 304.968 202.225 305.4 200.392 305.4C198.56 305.4 196.753 304.968 195.118 304.14C192.211 302.46 192.183 299.715 195.118 298.022C196.752 297.192 198.559 296.759 200.392 296.759C202.225 296.759 204.033 297.192 205.667 298.022Z" fill="url(#paint56_radial_83_4961)" />
      <path d="M236.456 315.803C239.376 317.497 239.39 320.228 236.456 321.921C234.825 322.756 233.019 323.191 231.188 323.191C229.356 323.191 227.551 322.756 225.92 321.921C223.013 320.242 222.972 317.497 225.92 315.803C227.554 314.98 229.358 314.552 231.188 314.552C233.017 314.552 234.821 314.98 236.456 315.803Z" fill="url(#paint57_radial_83_4961)" />
      <path d="M267.258 333.612C270.151 335.278 270.192 338.023 267.258 339.716C265.626 340.548 263.821 340.981 261.99 340.981C260.159 340.981 258.354 340.548 256.722 339.716C253.802 338.023 253.774 335.292 256.722 333.612C258.354 332.78 260.159 332.346 261.99 332.346C263.821 332.346 265.627 332.78 267.258 333.612Z" fill="url(#paint58_radial_83_4961)" />
      <path d="M298.088 351.393C301.008 353.087 301.035 355.804 298.088 357.498C296.454 358.332 294.647 358.767 292.813 358.767C290.979 358.767 289.171 358.332 287.538 357.498C284.618 355.804 284.604 353.087 287.538 351.393C289.171 350.557 290.979 350.12 292.813 350.12C294.647 350.12 296.455 350.557 298.088 351.393Z" fill="url(#paint59_radial_83_4961)" />
      <path d="M328.822 369.174C331.742 370.868 331.756 373.599 328.822 375.279C327.191 376.113 325.385 376.548 323.554 376.548C321.722 376.548 319.917 376.113 318.286 375.279C315.365 373.599 315.352 370.868 318.286 369.174C319.917 368.342 321.723 367.908 323.554 367.908C325.385 367.908 327.19 368.342 328.822 369.174Z" fill="url(#paint60_radial_83_4961)" />
      <path d="M359.597 386.956C362.531 388.649 362.545 391.381 359.597 393.074C357.962 393.902 356.155 394.334 354.322 394.334C352.489 394.334 350.682 393.902 349.047 393.074C346.127 391.381 346.113 388.649 349.047 386.956C350.682 386.126 352.489 385.693 354.322 385.693C356.155 385.693 357.962 386.126 359.597 386.956Z" fill="url(#paint61_radial_83_4961)" />
      <path d="M83.345 369.161C86.2656 370.854 86.2519 373.599 83.345 375.279C81.7122 376.107 79.9075 376.538 78.0771 376.538C76.2467 376.538 74.442 376.107 72.8092 375.279C69.9023 373.599 69.8613 370.854 72.8092 369.161C74.4426 368.335 76.247 367.905 78.0771 367.905C79.9071 367.905 81.7116 368.335 83.345 369.161Z" fill="url(#paint62_radial_83_4961)" />
      <path d="M114.12 386.956C117.041 388.636 117.041 391.381 114.12 393.06C112.487 393.895 110.679 394.33 108.845 394.33C107.011 394.33 105.204 393.895 103.571 393.06C100.664 391.381 100.636 388.636 103.571 386.956C105.205 386.126 107.012 385.693 108.845 385.693C110.678 385.693 112.485 386.126 114.12 386.956Z" fill="url(#paint63_radial_83_4961)" />
      <path d="M144.922 404.751C147.829 406.417 147.843 409.176 144.922 410.855C143.288 411.687 141.481 412.12 139.648 412.12C137.814 412.12 136.007 411.687 134.373 410.855C131.452 409.162 131.439 406.431 134.373 404.751C136.006 403.917 137.814 403.483 139.648 403.483C141.481 403.483 143.289 403.917 144.922 404.751Z" fill="url(#paint64_radial_83_4961)" />
      <path d="M175.711 422.532C178.632 424.226 178.632 426.957 175.711 428.637C174.08 429.471 172.275 429.906 170.443 429.906C168.611 429.906 166.806 429.471 165.175 428.637C162.255 426.957 162.227 424.226 165.175 422.532C166.808 421.703 168.612 421.271 170.443 421.271C172.274 421.271 174.079 421.703 175.711 422.532Z" fill="url(#paint65_radial_83_4961)" />
      <path d="M206.486 440.313C209.407 442.007 209.407 444.752 206.486 446.418C204.853 447.253 203.045 447.688 201.211 447.688C199.377 447.688 197.57 447.253 195.937 446.418C193.016 444.738 193.002 442.007 195.937 440.313C197.57 439.48 199.378 439.046 201.211 439.046C203.045 439.046 204.852 439.48 206.486 440.313Z" fill="url(#paint66_radial_83_4961)" />
      <path d="M237.275 458.095C240.195 459.788 240.195 462.533 237.275 464.213C235.642 465.041 233.837 465.472 232.007 465.472C230.176 465.472 228.372 465.041 226.739 464.213C223.818 462.52 223.805 459.788 226.739 458.095C228.372 457.269 230.177 456.839 232.007 456.839C233.837 456.839 235.641 457.269 237.275 458.095Z" fill="url(#paint67_radial_83_4961)" />
      <path d="M113.915 351.379C116.849 353.073 116.863 355.804 113.915 357.484C112.284 358.318 110.479 358.753 108.647 358.753C106.816 358.753 105.01 358.318 103.379 357.484C100.473 355.818 100.432 353.073 103.379 351.379C105.011 350.548 106.816 350.115 108.647 350.115C110.478 350.115 112.283 350.548 113.915 351.379Z" fill="url(#paint68_radial_83_4961)" />
      <path d="M144.704 369.161C147.625 370.854 147.638 373.586 144.704 375.279C143.071 376.107 141.266 376.539 139.436 376.539C137.606 376.539 135.801 376.107 134.168 375.279C131.261 373.599 131.234 370.854 134.168 369.161C135.801 368.333 137.606 367.901 139.436 367.901C141.266 367.901 143.071 368.333 144.704 369.161Z" fill="url(#paint69_radial_83_4961)" />
      <path d="M175.506 386.956C178.413 388.636 178.44 391.381 175.506 393.074C173.873 393.902 172.069 394.333 170.238 394.333C168.408 394.333 166.603 393.902 164.97 393.074C162.05 391.381 162.036 388.649 164.97 386.956C166.603 386.128 168.408 385.696 170.238 385.696C172.069 385.696 173.873 386.128 175.506 386.956Z" fill="url(#paint70_radial_83_4961)" />
      <path d="M206.295 404.751C209.215 406.431 209.229 409.162 206.295 410.856C204.664 411.69 202.859 412.125 201.027 412.125C199.195 412.125 197.39 411.69 195.759 410.856C192.825 409.162 192.811 406.431 195.759 404.751C197.391 403.919 199.196 403.485 201.027 403.485C202.858 403.485 204.663 403.919 206.295 404.751Z" fill="url(#paint71_radial_83_4961)" />
      <path d="M237.07 422.532C239.99 424.226 240.004 426.943 237.07 428.637C235.437 429.471 233.629 429.906 231.795 429.906C229.961 429.906 228.154 429.471 226.52 428.637C223.6 426.957 223.586 424.226 226.52 422.532C228.153 421.696 229.961 421.259 231.795 421.259C233.629 421.259 235.437 421.696 237.07 422.532Z" fill="url(#paint72_radial_83_4961)" />
      <path d="M267.859 440.313C270.779 442.007 270.793 444.738 267.859 446.418C266.228 447.252 264.422 447.687 262.591 447.687C260.759 447.687 258.954 447.252 257.323 446.418C254.402 444.738 254.375 442.007 257.323 440.313C258.954 439.481 260.759 439.047 262.591 439.047C264.422 439.047 266.227 439.481 267.859 440.313Z" fill="url(#paint73_radial_83_4961)" />
      <path d="M146.687 336.658C146.694 334.274 143.363 332.333 139.248 332.321C135.133 332.31 131.791 334.233 131.784 336.616C131.778 338.999 135.108 340.941 139.224 340.952C143.339 340.964 146.681 339.041 146.687 336.658Z" fill="url(#paint74_radial_83_4961)" />
      <path d="M175.261 351.379C178.181 353.073 178.195 355.804 175.261 357.484C173.63 358.318 171.824 358.753 169.993 358.753C168.161 358.753 166.356 358.318 164.725 357.484C161.818 355.818 161.791 353.073 164.725 351.379C166.357 350.548 168.162 350.115 169.993 350.115C171.824 350.115 173.629 350.548 175.261 351.379Z" fill="url(#paint75_radial_83_4961)" />
      <path d="M206.09 369.174C208.997 370.854 209.024 373.599 206.09 375.279C204.458 376.11 202.653 376.543 200.822 376.543C198.991 376.543 197.186 376.11 195.554 375.279C192.634 373.599 192.62 370.868 195.554 369.174C197.185 368.34 198.991 367.905 200.822 367.905C202.654 367.905 204.459 368.34 206.09 369.174Z" fill="url(#paint76_radial_83_4961)" />
      <path d="M236.865 386.956C239.799 388.649 239.813 391.381 236.865 393.074C235.232 393.902 233.428 394.333 231.597 394.333C229.767 394.333 227.962 393.902 226.329 393.074C223.395 391.381 223.382 388.649 226.329 386.956C227.962 386.128 229.767 385.696 231.597 385.696C233.428 385.696 235.232 386.128 236.865 386.956Z" fill="url(#paint77_radial_83_4961)" />
      <path d="M267.654 404.751C270.574 406.431 270.588 409.162 267.654 410.855C266.023 411.69 264.218 412.125 262.386 412.125C260.554 412.125 258.749 411.69 257.118 410.855C254.197 409.162 254.184 406.431 257.118 404.751C258.749 403.916 260.554 403.481 262.386 403.481C264.218 403.481 266.023 403.916 267.654 404.751Z" fill="url(#paint78_radial_83_4961)" />
      <path d="M298.429 422.532C301.363 424.226 301.377 426.943 298.429 428.637C296.798 429.471 294.993 429.906 293.161 429.906C291.329 429.906 289.524 429.471 287.893 428.637C284.959 426.943 284.945 424.226 287.893 422.532C289.524 421.698 291.329 421.263 293.161 421.263C294.993 421.263 296.798 421.698 298.429 422.532Z" fill="url(#paint79_radial_83_4961)" />
      <path d="M175.083 315.817C178.004 317.497 178.031 320.228 175.083 321.921C173.45 322.756 171.642 323.191 169.808 323.191C167.975 323.191 166.167 322.756 164.534 321.921C161.64 320.242 161.599 317.497 164.534 315.817C166.167 314.983 167.975 314.549 169.808 314.549C171.642 314.549 173.45 314.983 175.083 315.817Z" fill="url(#paint80_radial_83_4961)" />
      <path d="M205.872 333.598C208.792 335.278 208.806 338.009 205.872 339.703C204.241 340.537 202.436 340.972 200.604 340.972C198.772 340.972 196.967 340.537 195.336 339.703C192.429 338.023 192.402 335.278 195.336 333.598C196.968 332.769 198.773 332.337 200.604 332.337C202.435 332.337 204.239 332.769 205.872 333.598Z" fill="url(#paint81_radial_83_4961)" />
      <path d="M236.674 351.393C239.581 353.073 239.608 355.804 236.674 357.498C235.042 358.329 233.237 358.762 231.406 358.762C229.575 358.762 227.77 358.329 226.138 357.498C223.218 355.818 223.204 353.087 226.138 351.393C227.77 350.561 229.575 350.127 231.406 350.127C233.237 350.127 235.043 350.561 236.674 351.393Z" fill="url(#paint82_radial_83_4961)" />
      <path d="M267.449 369.174C270.383 370.868 270.397 373.599 267.449 375.279C265.816 376.114 264.008 376.549 262.174 376.549C260.341 376.549 258.533 376.114 256.9 375.279C253.979 373.599 253.965 370.868 256.9 369.174C258.532 368.338 260.34 367.902 262.174 367.902C264.009 367.902 265.817 368.338 267.449 369.174Z" fill="url(#paint83_radial_83_4961)" />
      <path d="M298.238 386.956C301.158 388.649 301.172 391.381 298.238 393.074C296.605 393.902 294.8 394.333 292.97 394.333C291.139 394.333 289.335 393.902 287.702 393.074C284.781 391.381 284.768 388.649 287.702 386.956C289.335 386.128 291.139 385.696 292.97 385.696C294.8 385.696 296.605 386.128 298.238 386.956Z" fill="url(#paint84_radial_83_4961)" />
      <path d="M329.013 404.751C331.947 406.431 331.961 409.162 329.013 410.855C327.382 411.69 325.576 412.125 323.745 412.125C321.913 412.125 320.108 411.69 318.477 410.855C315.543 409.162 315.529 406.431 318.477 404.751C320.108 403.916 321.913 403.481 323.745 403.481C325.576 403.481 327.382 403.916 329.013 404.751Z" fill="url(#paint85_radial_83_4961)" />
    </g>
    <g opacity="0.56">
      <path d="M266.153 346.818C273.741 351.202 278.299 356.624 279.8 362.086C282.169 362.887 284.454 363.916 286.624 365.159C299.766 372.753 300.408 384.73 287.989 391.913C275.569 399.097 254.962 398.742 241.819 391.176C239.887 390.086 238.096 388.766 236.483 387.243C227.223 386.555 218.22 383.876 210.089 379.39C192.729 369.352 191.214 353.933 206.704 344.92C222.194 335.906 248.793 336.794 266.153 346.818Z" fill="#ACACE9" />
    </g>
    <path d="M305.812 179.275L306.645 177.349C306.645 177.349 306.999 171.217 306.945 170.357C306.89 169.496 309.374 167.311 309.674 167.052C309.975 166.792 311.558 165.003 311.558 165.003C312.988 162.879 314.575 160.866 316.307 158.98C317.358 158.243 317.74 159.595 316.839 161.712C316.149 163.498 315.561 165.321 315.079 167.175C315.079 167.175 319.269 169.373 319.76 170.343C320.043 172.556 319.833 174.804 319.146 176.926C318.695 177.349 318.218 176.557 318.218 176.557C318.093 177.247 317.789 177.891 317.336 178.426C316.883 178.961 316.298 179.366 315.638 179.602L313.864 183.549L305.812 179.275Z" fill="url(#paint86_linear_83_4961)" />
    <path d="M214.142 224.943L218.687 230.283L226.166 223.769L219.164 217.35L214.142 224.943Z" fill="url(#paint87_linear_83_4961)" />
    <path d="M218.441 160.141C218.441 160.141 214.088 161.671 207.359 169.838C200.631 178.005 188.144 192.29 188.458 199.023C188.772 205.756 213.528 227.293 215.466 227.607C217.404 227.921 222.631 221.202 221.949 218.047C221.266 214.892 207.851 199.555 207.359 198.927C206.868 198.299 222.904 184.86 222.904 184.86L218.441 160.141Z" fill="url(#paint88_linear_83_4961)" />
    <path d="M243.839 376.795C243.117 380.145 242.719 383.557 242.652 386.983C242.775 391.08 246.596 393.812 251.045 394.139C255.494 394.467 258.96 390.438 269.032 385.399C279.554 380.127 283.267 376.727 282.052 369.734C281.097 364.203 268.404 366.853 258.947 369.133C253.446 370.642 248.307 373.248 243.839 376.795Z" fill="url(#paint89_linear_83_4961)" />
    <path d="M217.145 357.539C217.145 357.539 214.852 364.791 214.988 368.847C215.125 372.903 217.404 377.041 221.812 377.382C226.22 377.724 231.57 376.454 235.187 365.788C237.165 359.956 244.426 350.615 236.442 345.794C231.638 342.899 230.983 352.622 221.635 354.944C213.282 356.965 217.145 357.539 217.145 357.539Z" fill="url(#paint90_linear_83_4961)" />
    <path d="M245.19 291.589C244.248 294.02 237.288 339.921 234.504 359.355C233.003 366.743 215.698 367.945 214.934 361.882C214.934 361.882 213.419 317.251 217.349 297.694L217.431 267.28C216.011 257.773 216.011 248.107 217.431 238.6L254.866 250.004L245.19 291.589Z" fill="url(#paint91_linear_83_4961)" />
    <path d="M230.751 252.093C231.666 256.191 232.239 263.989 235.773 269.383C236.333 270.216 236.838 271.077 237.316 271.923C237.411 272.319 238.83 277.618 240.577 284.734C242.324 291.849 240.878 306.503 242.392 314.096C241.56 323.123 242.392 373.299 242.392 377.478C243.921 383.159 263.096 384.088 265.061 374.473C265.061 374.473 265.184 326.537 267.668 313.959C268.12 311.56 268.412 309.132 268.541 306.694C269.005 298.978 269.906 284.925 270.097 279.052C270.602 267.621 270.929 253.104 270.533 248.788L246.582 254.046L230.751 252.093Z" fill="url(#paint92_linear_83_4961)" />
    <path d="M225.51 251.82C230.502 256.316 237.063 258.661 243.771 258.348C255.139 257.857 231.597 232.906 231.597 232.906L225.51 251.82Z" fill="url(#paint93_linear_83_4961)" />
    <path d="M271.489 248.775C271.489 251.984 270.124 262.036 257.5 264.275C244.876 266.515 233.685 259.796 233.685 259.796L232.075 239.311L228.486 256.395C228.486 256.395 217.65 249.212 216.462 245.989C215.275 242.766 215.493 236.579 217.963 223.96C220.434 211.341 218.496 199.077 214.756 183.577C211.017 168.076 215.07 160.92 218.919 159.991C222.767 159.062 231.365 161.889 231.365 161.889C231.365 161.889 245.627 164.197 248.425 166.847C251.222 169.496 263.437 178.551 263.437 178.551L269.796 192.29L268.978 202.655L268.404 210.003C267.931 215.913 267.977 221.854 268.541 227.757C268.746 229.887 269.005 232.004 269.332 234.121C270.397 240.84 271.489 247.983 271.489 248.775Z" fill="url(#paint94_linear_83_4961)" />
    <path d="M262.331 136.788C262.509 142.866 262.322 148.95 261.772 155.006C260.912 161.343 253.583 160.619 253.583 160.619L252.942 171.121C252.942 171.121 241.137 172.009 236.811 164.716L237.561 157.219C237.561 157.219 228.24 147.563 231.12 137.239C233.999 126.914 242.652 124.046 249.052 124.101C255.453 124.155 262.086 127.925 262.331 136.788Z" fill="url(#paint95_linear_83_4961)" />
    <path d="M262.454 137.826C262.454 137.826 262.154 141.144 259.957 141.923C257.759 142.701 257.787 142.524 257.787 142.524V146.853L255.467 147.645C255.467 147.645 255.221 143.889 253.542 143.548C251.864 143.207 251.263 148.56 252.464 150.008C252.723 150.403 253.093 150.713 253.528 150.898C253.963 151.082 254.443 151.134 254.907 151.046C254.907 151.046 252.628 159.759 248.083 161.302C243.539 162.845 238.285 160.319 236.074 158.448C234.709 157.273 234.095 155.935 232.43 152.48C231.447 150.418 229.059 147.017 228.69 144.081C227.721 136.091 231.051 135.313 231.051 135.313C231.051 135.313 229.509 130.724 234.682 125.384C239.854 120.044 248.82 119.334 253.952 122.284C259.083 125.234 263.3 126.381 265.17 125.862C267.04 125.343 266.931 129.714 264.747 130.943C264.747 130.943 268.527 130.943 268.213 132.172C267.899 133.401 266.835 138.304 262.454 137.826Z" fill="#965353" />
    <path d="M256.04 172.911C256.04 172.911 254.948 167.379 254.552 166.601C254.157 165.823 252.628 165.399 245.641 164.197C238.653 162.996 236.66 159.882 235.378 159.445C234.095 159.008 231.379 161.889 231.379 161.889C231.379 161.889 234.299 168.718 243.662 170.589C253.024 172.46 256.04 172.911 256.04 172.911Z" fill="url(#paint96_linear_83_4961)" />
    <g opacity="0.29">
      <path d="M232.771 235.282C232.726 235.278 232.682 235.266 232.642 235.246C232.602 235.225 232.567 235.197 232.537 235.163C232.508 235.128 232.486 235.089 232.472 235.046C232.459 235.003 232.453 234.958 232.457 234.913C233.518 221.865 233.645 208.757 232.839 195.69C231.474 177.076 234.381 169.25 234.504 168.936C234.515 168.888 234.536 168.843 234.566 168.804C234.595 168.764 234.633 168.732 234.677 168.709C234.72 168.686 234.768 168.672 234.817 168.669C234.867 168.666 234.916 168.674 234.962 168.692C235.008 168.71 235.049 168.738 235.083 168.773C235.117 168.809 235.143 168.851 235.16 168.898C235.176 168.944 235.182 168.994 235.177 169.043C235.172 169.092 235.157 169.14 235.132 169.182C235.132 169.25 232.102 177.199 233.522 195.636C234.32 208.744 234.192 221.891 233.139 234.981C233.129 235.07 233.085 235.15 233.016 235.206C232.947 235.262 232.859 235.289 232.771 235.282Z" fill="#3D41C1" />
    </g>
    <g opacity="0.29">
      <path d="M268.541 227.798L268.309 227.62C252.805 215.821 254.266 193.478 254.266 193.478L268.978 202.683L268.404 210.03C267.93 215.945 267.976 221.891 268.541 227.798Z" fill="#3D41C1" />
    </g>
    <path d="M304.775 180.367C305.175 179.606 305.631 178.875 306.14 178.182C308.149 178.075 310.141 178.606 311.831 179.698C314.465 181.255 314.97 182.211 315.051 182.429C314.875 183.477 314.634 184.512 314.328 185.53L304.775 180.367Z" fill="url(#paint97_linear_83_4961)" />
    <path d="M289.599 201.85C289.599 201.85 267.34 178.879 261.772 177.827C256.204 176.775 252.219 184.546 254.266 193.437C256.313 202.328 267.913 209.921 275.119 215.807C282.325 221.693 288.575 224.465 293.912 223.824C299.248 223.182 315.256 187.96 314.642 185.311C313.796 181.665 304.72 177.786 303.56 180.599L289.599 201.85Z" fill="url(#paint98_linear_83_4961)" />
    <path d="M313.1 185.953C313.1 186.097 313.057 186.237 312.977 186.356C312.897 186.476 312.783 186.569 312.65 186.623C312.517 186.677 312.371 186.691 312.23 186.662C312.09 186.633 311.961 186.562 311.86 186.46C311.76 186.357 311.692 186.227 311.665 186.086C311.639 185.944 311.655 185.798 311.712 185.666C311.769 185.534 311.864 185.422 311.985 185.344C312.105 185.267 312.247 185.226 312.39 185.229C312.485 185.229 312.578 185.248 312.665 185.284C312.752 185.321 312.831 185.375 312.897 185.442C312.963 185.509 313.015 185.589 313.05 185.677C313.085 185.765 313.102 185.859 313.1 185.953Z" fill="url(#paint99_linear_83_4961)" />
    <path d="M312.39 188.466C312.39 188.609 312.348 188.749 312.268 188.868C312.189 188.987 312.076 189.08 311.944 189.134C311.812 189.189 311.666 189.204 311.526 189.176C311.385 189.148 311.257 189.079 311.155 188.978C311.054 188.876 310.985 188.747 310.957 188.607C310.93 188.467 310.944 188.321 310.999 188.189C311.053 188.057 311.146 187.943 311.265 187.864C311.384 187.784 311.524 187.742 311.667 187.742C311.859 187.742 312.043 187.818 312.178 187.954C312.314 188.09 312.39 188.274 312.39 188.466Z" fill="url(#paint100_linear_83_4961)" />
    <g opacity="0.35">
      <path d="M423.303 7.53864L430.195 3.52349C436.459 -0.122908 445.207 0.396061 454.76 5.94078L447.868 9.94226C438.261 4.4112 429.567 3.89224 423.303 7.53864Z" fill="#C0C0ED" />
      <path d="M479.053 87.6639L472.161 91.6791C478.357 88.0736 482.205 80.4121 482.233 69.5275C482.233 47.6764 466.907 20.9498 447.855 9.94228L454.747 5.9408C473.853 16.9483 489.179 43.6203 489.125 65.526C489.152 76.4106 485.249 84.0722 479.053 87.6639Z" fill="#C0C0ED" />
      <path d="M447.855 9.94228C466.961 20.9498 482.287 47.6218 482.233 69.5275C482.178 91.4332 466.675 100.283 447.623 89.2755C428.571 78.268 413.177 51.6233 413.245 29.6766C413.313 7.72986 428.789 -1.06521 447.855 9.94228Z" fill="#C0C0ED" />
      <path d="M475.505 65.6216C475.505 74.8537 472.065 81.2042 466.606 83.8946C461.639 86.3255 454.979 85.7383 447.691 81.5046C432.351 72.6549 419.973 51.1999 420.014 33.5688C420.014 23.0666 424.477 16.2791 431.341 14.4491C435.954 13.1926 441.659 14.1759 447.841 17.7404C463.222 26.5628 475.505 48.0178 475.505 65.6216Z" fill="#C0C0ED" />
      <path d="M475.505 65.6216C475.505 74.8537 472.065 81.2042 466.606 83.8946C461.98 85.2603 456.221 84.2497 449.997 80.6442C434.644 71.7945 422.28 50.2575 422.28 32.7084C422.28 23.4216 425.76 17.0712 431.273 14.4217C435.886 13.1653 441.591 14.1486 447.773 17.713C463.222 26.5627 475.505 48.0178 475.505 65.6216Z" fill="#C0C0ED" />
      <path d="M448.974 19.748C449.128 19.8539 449.254 19.9943 449.344 20.1578C449.434 20.3214 449.485 20.5037 449.493 20.6903V25.4156C449.493 25.757 449.261 25.8936 448.974 25.7297C448.822 25.6223 448.696 25.4816 448.606 25.3184C448.516 25.1551 448.465 24.9735 448.455 24.7874V20.0621C448.455 19.707 448.687 19.5704 448.974 19.748Z" fill="#C0C0ED" />
      <path d="M436.896 16.3201C437.097 16.462 437.249 16.6622 437.333 16.8937L439.271 22.1789C439.421 22.575 439.271 22.8891 439.08 22.93C438.991 22.9282 438.906 22.8996 438.834 22.8481C438.629 22.7075 438.471 22.5073 438.384 22.2745L436.446 16.9893C436.295 16.6069 436.446 16.2791 436.637 16.2518C436.728 16.2437 436.82 16.2678 436.896 16.3201Z" fill="#C0C0ED" />
      <path d="M428.025 21.6462C428.123 21.7103 428.207 21.794 428.271 21.8921L431.655 26.3032C431.77 26.4335 431.847 26.5926 431.879 26.7634C431.91 26.9343 431.894 27.1104 431.833 27.2729C431.737 27.4231 431.573 27.4231 431.396 27.2729C431.297 27.2109 431.209 27.1323 431.137 27.0407L427.739 22.6568C427.621 22.5282 427.542 22.369 427.511 22.1975C427.479 22.026 427.497 21.8492 427.561 21.6872C427.738 21.5779 427.848 21.5779 428.025 21.6462Z" fill="#C0C0ED" />
      <path d="M428.653 36.6689C428.803 36.777 428.927 36.9181 429.014 37.0815C429.102 37.2448 429.151 37.426 429.158 37.6112C429.158 37.9389 428.926 38.0892 428.653 37.9116L424.736 35.5353C424.584 35.4324 424.458 35.2943 424.37 35.1327C424.282 34.971 424.235 34.7906 424.231 34.6067C424.231 34.2516 424.463 34.115 424.75 34.2925L428.653 36.6689Z" fill="#C0C0ED" />
      <path d="M431.328 50.5717C431.536 50.7122 431.69 50.9193 431.765 51.1589C431.915 51.5413 431.833 51.8691 431.587 51.8964L428.189 52.1968C428.11 52.2274 428.022 52.2274 427.943 52.1968C427.734 52.0541 427.577 51.8483 427.493 51.6096C427.343 51.2135 427.493 50.8858 427.684 50.8721L431.068 50.5717C431.152 50.5402 431.244 50.5402 431.328 50.5717Z" fill="#C0C0ED" />
      <path d="M438.656 64.051C438.758 64.1089 438.847 64.1881 438.916 64.2832C439.034 64.4113 439.115 64.5697 439.148 64.7409C439.182 64.9122 439.168 65.0894 439.107 65.2529L437.142 68.1618C437.112 68.1919 437.077 68.2159 437.039 68.2323C437 68.2486 436.958 68.2571 436.916 68.2571C436.874 68.2571 436.833 68.2486 436.794 68.2323C436.755 68.2159 436.72 68.1919 436.691 68.1618C436.592 68.0948 436.504 68.0118 436.432 67.916C436.314 67.7873 436.235 67.6281 436.204 67.4566C436.173 67.2852 436.19 67.1083 436.254 66.9463L438.22 64.051C438.37 63.9145 438.534 63.9145 438.656 64.051Z" fill="#C0C0ED" />
      <path d="M448.824 73.4744C448.976 73.579 449.101 73.7171 449.191 73.8782C449.281 74.0392 449.333 74.2188 449.342 74.403V79.1283C449.342 79.4834 449.11 79.62 448.824 79.4425C448.673 79.3343 448.55 79.1932 448.462 79.0298C448.375 78.8665 448.326 78.6853 448.319 78.5001V73.7748C448.319 73.4744 448.551 73.3105 448.824 73.4744Z" fill="#C0C0ED" />
      <path d="M458.964 76.356C459.173 76.4987 459.33 76.7045 459.414 76.9432L461.352 82.2148C461.489 82.5972 461.352 82.9386 461.161 82.9659C461.069 82.9643 460.978 82.9358 460.902 82.884C460.693 82.7412 460.535 82.5355 460.451 82.2967L458.513 77.0251C458.377 76.6291 458.514 76.315 458.718 76.2877C458.806 76.2792 458.893 76.3035 458.964 76.356Z" fill="#C0C0ED" />
      <path d="M466.402 71.8902C466.501 71.9571 466.589 72.0402 466.661 72.136L470.032 76.5609C470.148 76.6876 470.227 76.8437 470.261 77.0122C470.294 77.1807 470.281 77.3552 470.223 77.5169C470.128 77.6671 469.95 77.6671 469.773 77.5169C469.673 77.4553 469.586 77.3767 469.513 77.2847L466.142 72.8598C466.027 72.7302 465.948 72.5718 465.914 72.4012C465.881 72.2306 465.893 72.0541 465.951 71.8902C465.981 71.86 466.016 71.8361 466.054 71.8197C466.093 71.8034 466.135 71.7949 466.177 71.7949C466.219 71.7949 466.26 71.8034 466.299 71.8197C466.337 71.8361 466.372 71.86 466.402 71.8902Z" fill="#C0C0ED" />
      <path d="M473.048 63.6687C473.202 63.7747 473.329 63.915 473.419 64.0786C473.509 64.2421 473.559 64.4245 473.567 64.611C473.567 64.9525 473.335 65.089 473.048 64.9115L469.145 62.5488C468.994 62.4443 468.87 62.3059 468.782 62.1446C468.694 61.9833 468.646 61.8037 468.64 61.6202C468.64 61.2787 468.872 61.1285 469.145 61.3061L473.048 63.6687Z" fill="#C0C0ED" />
      <path d="M469.855 47.0891C470.066 47.2289 470.225 47.4357 470.305 47.6764C470.441 48.0588 470.305 48.3866 470.114 48.4139L466.716 48.7143C466.628 48.7242 466.54 48.6997 466.47 48.646C466.261 48.5033 466.103 48.2975 466.02 48.0588C465.869 47.6764 466.02 47.335 466.211 47.3076L469.595 47.0072C469.688 47.0088 469.778 47.0373 469.855 47.0891Z" fill="#C0C0ED" />
      <path d="M461.038 31.0013C461.141 31.0644 461.229 31.1481 461.298 31.2471C461.413 31.3767 461.492 31.5351 461.526 31.7057C461.559 31.8763 461.547 32.0528 461.489 32.2167L459.523 35.1256C459.494 35.1558 459.459 35.1798 459.42 35.1961C459.382 35.2125 459.34 35.2209 459.298 35.2209C459.256 35.2209 459.215 35.2125 459.176 35.1961C459.137 35.1798 459.102 35.1558 459.073 35.1256C458.978 35.063 458.895 34.9845 458.827 34.8935C458.709 34.7625 458.629 34.6017 458.595 34.4284C458.561 34.2551 458.576 34.076 458.636 33.9102L460.588 31.0013C460.649 30.9436 460.729 30.9115 460.813 30.9115C460.897 30.9115 460.977 30.9436 461.038 31.0013Z" fill="#C0C0ED" />
      <path d="M434.712 38.1575L437.715 36.4094C437.762 36.3804 437.816 36.3651 437.872 36.3651C437.927 36.3651 437.981 36.3804 438.029 36.4094C438.103 36.4509 438.167 36.5066 438.22 36.5733L435.217 38.3214C435.163 38.2509 435.093 38.1947 435.013 38.1575C434.968 38.1281 434.916 38.1125 434.862 38.1125C434.809 38.1125 434.757 38.1281 434.712 38.1575Z" fill="#C0C0ED" />
      <path d="M447.418 49.1514C447.437 49.4631 447.528 49.7661 447.684 50.0364C447.841 50.3067 448.058 50.5367 448.319 50.7082L445.316 52.4563C445.056 52.2848 444.838 52.0548 444.682 51.7845C444.526 51.5142 444.434 51.2112 444.416 50.8994C444.408 50.7884 444.428 50.6771 444.476 50.5765C444.524 50.4759 444.597 50.3895 444.689 50.3259L447.691 48.5778C447.6 48.6414 447.527 48.7278 447.479 48.8284C447.431 48.929 447.41 49.0403 447.418 49.1514Z" fill="#C0C0ED" />
      <path d="M448.319 50.7083C448.417 50.7633 448.527 50.7922 448.64 50.7922C448.752 50.7922 448.862 50.7633 448.96 50.7083L445.958 52.4427C445.861 52.5018 445.75 52.5331 445.637 52.5331C445.524 52.5331 445.413 52.5018 445.316 52.4427L448.319 50.7083Z" fill="#C0C0ED" />
      <path d="M444.689 50.3259L447.691 48.5778C447.786 48.5198 447.894 48.4891 448.005 48.4891C448.116 48.4891 448.224 48.5198 448.319 48.5778L445.316 50.3259C445.222 50.2679 445.113 50.2372 445.003 50.2372C444.892 50.2372 444.783 50.2679 444.689 50.3259Z" fill="#C0C0ED" />
      <path d="M448.96 50.7765L445.958 52.5109C446.05 52.4487 446.123 52.3621 446.169 52.2608C446.214 52.1596 446.231 52.0476 446.217 51.9373C446.202 51.6267 446.112 51.3243 445.955 51.0557C445.798 50.7871 445.579 50.5602 445.316 50.3941L448.319 48.646C448.582 48.8121 448.801 49.039 448.957 49.3076C449.114 49.5762 449.204 49.8786 449.22 50.1892C449.232 50.3013 449.214 50.4145 449.169 50.5176C449.123 50.6207 449.051 50.71 448.96 50.7765Z" fill="#C0C0ED" />
      <path d="M449.697 52.0603L446.695 53.8084C446.868 53.6962 447.009 53.5423 447.107 53.3609C447.205 53.1796 447.255 52.9765 447.254 52.7704L450.257 51.0223C450.258 51.2284 450.207 51.4315 450.11 51.6128C450.012 51.7942 449.87 51.9481 449.697 52.0603Z" fill="#C0C0ED" />
      <path d="M464.314 48.7826L461.311 50.5307C461.516 50.4078 461.311 49.9025 461.079 49.7522L464.082 48.0042C464.355 48.1544 464.587 48.6597 464.314 48.7826Z" fill="#C0C0ED" />
      <path d="M461.079 49.7522C461.366 49.9161 461.543 50.5034 461.243 50.558L447.254 52.7704C447.159 53.8493 446.326 54.259 445.316 53.6718C444.387 53.0601 443.723 52.1181 443.46 51.036L434.767 38.9906C434.385 38.4443 434.63 37.9527 435.013 38.1848C435.093 38.222 435.163 38.2782 435.217 38.3487L444.88 48.9738C445.038 49.0164 445.189 49.0808 445.33 49.165C445.646 49.3632 445.931 49.6071 446.176 49.8888L460.915 49.6976C460.973 49.7031 461.029 49.7218 461.079 49.7522ZM445.316 52.4836C445.808 52.7704 446.217 52.4836 446.217 51.9647C446.202 51.654 446.112 51.3516 445.955 51.083C445.798 50.8144 445.579 50.5875 445.316 50.4214C444.825 50.1347 444.416 50.4214 444.416 50.9267C444.434 51.2385 444.526 51.5415 444.682 51.8118C444.838 52.0821 445.056 52.3121 445.316 52.4836Z" fill="#C0C0ED" />
      <path d="M445.316 50.3941C445.579 50.5602 445.798 50.7871 445.955 51.0557C446.112 51.3243 446.202 51.6266 446.217 51.9373C446.217 52.5109 445.808 52.7431 445.316 52.4563C445.056 52.2847 444.838 52.0547 444.682 51.7844C444.526 51.5142 444.434 51.2111 444.416 50.8994C444.416 50.3258 444.825 50.1073 445.316 50.3941Z" fill="#C0C0ED" />
      <path d="M435.217 38.3487L438.22 36.6006L447.882 47.2257L444.88 48.9738L435.217 38.3487Z" fill="#C0C0ED" />
      <path d="M444.88 48.9738L447.882 47.2257C448.04 47.2683 448.192 47.3327 448.332 47.4169C448.651 47.6116 448.937 47.8559 449.179 48.1407L446.176 49.8888C445.931 49.6071 445.646 49.3632 445.33 49.165C445.19 49.0808 445.038 49.0164 444.88 48.9738Z" fill="#C0C0ED" />
      <path d="M446.176 49.8889L449.179 48.1408L463.918 47.9496L460.915 49.6977L446.176 49.8889Z" fill="#C0C0ED" />
      <path d="M460.915 49.6976L463.918 47.9632C463.972 47.9509 464.028 47.9509 464.082 47.9632L461.079 49.7113C461.026 49.6949 460.97 49.6902 460.915 49.6976Z" fill="#C0C0ED" />
    </g>
    <path d="M412.385 13.0014L419.277 8.98626C425.541 5.33986 434.289 5.85883 443.843 11.4035L436.951 15.405C427.343 9.87397 418.649 9.355 412.385 13.0014Z" fill="url(#paint101_linear_83_4961)" />
    <path d="M468.135 93.1267L461.243 97.1418C467.439 93.5364 471.288 85.8749 471.315 74.9903C471.315 53.1392 455.989 26.4125 436.937 15.405L443.829 11.4036C462.935 22.4111 478.261 49.0831 478.207 70.9888C478.234 81.8734 474.331 89.5349 468.135 93.1267Z" fill="url(#paint102_linear_83_4961)" />
    <path d="M436.937 15.4051C456.043 26.4125 471.369 53.0845 471.315 74.9903C471.26 96.896 455.757 105.746 436.705 94.7382C417.653 83.7307 402.259 57.086 402.327 35.1393C402.395 13.1926 417.871 4.39756 436.937 15.4051Z" fill="url(#paint103_linear_83_4961)" />
    <path d="M464.587 71.0844C464.587 80.3165 461.148 86.667 455.689 89.3574C450.721 91.7883 444.061 91.2011 436.773 86.9674C421.433 78.1177 409.055 56.6627 409.096 39.0315C409.096 28.5294 413.559 21.7419 420.424 19.9118C425.036 18.6554 430.741 19.6387 436.923 23.2032C452.304 32.0255 464.587 53.4806 464.587 71.0844Z" fill="#9CA1E2" />
    <path d="M464.587 71.0844C464.587 80.3164 461.147 86.6669 455.689 89.3574C451.062 90.723 445.303 89.7124 439.08 86.107C423.726 77.2573 411.362 55.7203 411.362 38.1711C411.362 28.8844 414.842 22.5339 420.355 19.8845C424.968 18.628 430.673 19.6113 436.855 23.1758C452.304 32.0255 464.587 53.4806 464.587 71.0844Z" fill="#C1C5F4" />
    <path d="M438.056 25.2108C438.21 25.3167 438.336 25.457 438.427 25.6206C438.517 25.7842 438.567 25.9665 438.575 26.1531V30.8784C438.575 31.2198 438.343 31.3564 438.056 31.1925C437.904 31.085 437.778 30.9444 437.688 30.7811C437.598 30.6179 437.547 30.4363 437.537 30.2502V25.5249C437.537 25.1698 437.769 25.0332 438.056 25.2108Z" fill="#9CA1E2" />
    <path d="M425.978 21.7828C426.179 21.9248 426.331 22.125 426.415 22.3564L428.353 27.6417C428.503 28.0377 428.353 28.3518 428.162 28.3928C428.073 28.391 427.988 28.3624 427.916 28.3109C427.711 28.1703 427.553 27.9701 427.466 27.7373L425.528 22.452C425.378 22.0696 425.528 21.7419 425.719 21.7146C425.81 21.7064 425.902 21.7306 425.978 21.7828Z" fill="#9CA1E2" />
    <path d="M417.107 27.109C417.205 27.1731 417.289 27.2568 417.353 27.3548L420.737 31.766C420.852 31.8963 420.929 32.0553 420.961 32.2262C420.992 32.397 420.976 32.5731 420.915 32.7357C420.819 32.8859 420.655 32.8859 420.478 32.7357C420.379 32.6736 420.291 32.5951 420.219 32.5035L416.82 28.1196C416.703 27.9909 416.624 27.8318 416.593 27.6603C416.561 27.4888 416.579 27.312 416.643 27.15C416.82 27.0407 416.93 27.0407 417.107 27.109Z" fill="#9CA1E2" />
    <path d="M417.735 42.1316C417.885 42.2398 418.009 42.3809 418.096 42.5442C418.184 42.7076 418.233 42.8887 418.24 43.0739C418.24 43.4017 418.008 43.5519 417.735 43.3744L413.818 40.9981C413.666 40.8952 413.54 40.7571 413.452 40.5954C413.364 40.4338 413.317 40.2534 413.313 40.0694C413.313 39.7143 413.545 39.5778 413.832 39.7553L417.735 42.1316Z" fill="#9CA1E2" />
    <path d="M420.41 56.0344C420.618 56.1749 420.772 56.382 420.847 56.6217C420.997 57.0041 420.915 57.3318 420.669 57.3592L417.271 57.6596C417.192 57.6901 417.104 57.6901 417.025 57.6596C416.816 57.5168 416.659 57.3111 416.575 57.0724C416.425 56.6763 416.575 56.3485 416.766 56.3349L420.15 56.0344C420.234 56.003 420.326 56.003 420.41 56.0344Z" fill="#9CA1E2" />
    <path d="M427.738 69.5138C427.841 69.5717 427.929 69.6509 427.998 69.746C428.116 69.874 428.197 70.0325 428.23 70.2037C428.264 70.3749 428.25 70.5521 428.189 70.7156L426.224 73.6245C426.194 73.6547 426.159 73.6787 426.121 73.695C426.082 73.7114 426.04 73.7198 425.998 73.7198C425.956 73.7198 425.915 73.7114 425.876 73.695C425.837 73.6787 425.802 73.6547 425.773 73.6245C425.674 73.5576 425.586 73.4745 425.514 73.3787C425.396 73.2501 425.317 73.0909 425.286 72.9194C425.255 72.7479 425.272 72.5711 425.336 72.4091L427.302 69.5138C427.452 69.3772 427.616 69.3772 427.738 69.5138Z" fill="#9CA1E2" />
    <path d="M437.906 78.9371C438.058 79.0417 438.183 79.1799 438.273 79.3409C438.363 79.502 438.415 79.6816 438.424 79.8658V84.5911C438.424 84.9462 438.192 85.0828 437.906 84.9052C437.755 84.7971 437.632 84.6559 437.544 84.4926C437.457 84.3293 437.408 84.1481 437.401 83.9629V79.2376C437.401 78.9371 437.633 78.7733 437.906 78.9371Z" fill="#9CA1E2" />
    <path d="M448.046 81.8187C448.255 81.9615 448.412 82.1672 448.496 82.406L450.434 87.6775C450.571 88.0599 450.434 88.4014 450.243 88.4287C450.151 88.4271 450.06 88.3986 449.984 88.3467C449.775 88.204 449.617 87.9982 449.533 87.7595L447.596 82.4879C447.459 82.0919 447.596 81.7777 447.8 81.7504C447.888 81.742 447.975 81.7663 448.046 81.8187Z" fill="#9CA1E2" />
    <path d="M455.484 77.353C455.583 77.4199 455.671 77.503 455.743 77.5988L459.114 82.0236C459.23 82.1504 459.309 82.3065 459.343 82.475C459.376 82.6435 459.363 82.818 459.305 82.9796C459.21 83.1299 459.032 83.1299 458.855 82.9796C458.755 82.9181 458.668 82.8395 458.595 82.7475L455.224 78.3226C455.109 78.193 455.03 78.0346 454.996 77.864C454.963 77.6934 454.975 77.5169 455.033 77.353C455.063 77.3228 455.098 77.2989 455.136 77.2825C455.175 77.2661 455.217 77.2577 455.259 77.2577C455.301 77.2577 455.342 77.2661 455.381 77.2825C455.42 77.2989 455.454 77.3228 455.484 77.353Z" fill="#9CA1E2" />
    <path d="M462.13 69.1315C462.284 69.2374 462.411 69.3778 462.501 69.5413C462.591 69.7049 462.641 69.8872 462.649 70.0738C462.649 70.4152 462.417 70.5518 462.13 70.3743L458.227 68.0116C458.076 67.9071 457.952 67.7686 457.864 67.6073C457.776 67.4461 457.728 67.2665 457.722 67.0829C457.722 66.7415 457.954 66.5913 458.227 66.7688L462.13 69.1315Z" fill="#9CA1E2" />
    <path d="M458.937 52.5519C459.148 52.6917 459.307 52.8985 459.387 53.1392C459.523 53.5216 459.387 53.8493 459.196 53.8766L455.798 54.1771C455.71 54.1869 455.622 54.1625 455.552 54.1088C455.343 53.966 455.185 53.7603 455.102 53.5216C454.951 53.1392 455.102 52.7977 455.293 52.7704L458.677 52.47C458.77 52.4716 458.86 52.5 458.937 52.5519Z" fill="#9CA1E2" />
    <path d="M450.12 36.464C450.223 36.5272 450.311 36.6109 450.38 36.7098C450.495 36.8395 450.574 36.9979 450.608 37.1685C450.641 37.3391 450.629 37.5156 450.571 37.6795L448.605 40.5884C448.576 40.6186 448.541 40.6425 448.502 40.6589C448.464 40.6753 448.422 40.6837 448.38 40.6837C448.338 40.6837 448.297 40.6753 448.258 40.6589C448.219 40.6425 448.184 40.6186 448.155 40.5884C448.06 40.5258 447.977 40.4472 447.909 40.3562C447.791 40.2253 447.711 40.0645 447.677 39.8912C447.644 39.7179 447.658 39.5387 447.718 39.3729L449.67 36.464C449.731 36.4064 449.811 36.3743 449.895 36.3743C449.979 36.3743 450.059 36.4064 450.12 36.464Z" fill="#9CA1E2" />
    <path d="M423.794 43.6203L426.797 41.8722C426.844 41.8432 426.898 41.8279 426.954 41.8279C427.009 41.8279 427.064 41.8432 427.111 41.8722C427.185 41.9137 427.25 41.9694 427.302 42.0361L424.299 43.7842C424.245 43.7137 424.175 43.6575 424.095 43.6203C424.05 43.5909 423.998 43.5753 423.945 43.5753C423.891 43.5753 423.839 43.5909 423.794 43.6203Z" fill="#2F97ED" />
    <path d="M436.5 54.6141C436.519 54.9259 436.61 55.2289 436.767 55.4992C436.923 55.7695 437.14 55.9995 437.401 56.171L434.399 57.9191C434.138 57.7476 433.92 57.5175 433.764 57.2473C433.608 56.977 433.517 56.6739 433.498 56.3622C433.49 56.2511 433.511 56.1398 433.558 56.0393C433.606 55.9387 433.679 55.8523 433.771 55.7886L436.773 54.0405C436.682 54.1042 436.609 54.1906 436.561 54.2912C436.513 54.3917 436.492 54.503 436.5 54.6141Z" fill="#2F97ED" />
    <path d="M437.401 56.171C437.499 56.2261 437.609 56.255 437.722 56.255C437.834 56.255 437.944 56.2261 438.042 56.171L435.04 57.9054C434.943 57.9646 434.832 57.9959 434.719 57.9959C434.606 57.9959 434.495 57.9646 434.398 57.9054L437.401 56.171Z" fill="#2F97ED" />
    <path d="M433.771 55.7886L436.773 54.0405C436.868 53.9826 436.976 53.9519 437.087 53.9519C437.198 53.9519 437.306 53.9826 437.401 54.0405L434.398 55.7886C434.304 55.7307 434.195 55.7 434.085 55.7C433.974 55.7 433.865 55.7307 433.771 55.7886Z" fill="white" />
    <path d="M438.042 56.2392L435.04 57.9737C435.132 57.9114 435.205 57.8248 435.251 57.7236C435.296 57.6223 435.313 57.5103 435.299 57.4001C435.284 57.0894 435.194 56.787 435.037 56.5185C434.88 56.2499 434.661 56.0229 434.398 55.8569L437.401 54.1088C437.664 54.2749 437.883 54.5018 438.04 54.7704C438.196 55.039 438.286 55.3413 438.302 55.652C438.314 55.764 438.296 55.8773 438.251 55.9804C438.205 56.0835 438.133 56.1728 438.042 56.2392Z" fill="white" />
    <path d="M438.779 57.523L435.777 59.2711C435.95 59.1589 436.091 59.0051 436.189 58.8237C436.287 58.6423 436.338 58.4393 436.336 58.2332L439.339 56.4851C439.34 56.6912 439.289 56.8942 439.192 57.0756C439.094 57.257 438.952 57.4108 438.779 57.523Z" fill="#2F97ED" />
    <path d="M453.396 54.2454L450.393 55.9935C450.598 55.8705 450.393 55.3652 450.161 55.215L453.164 53.4669C453.437 53.6171 453.669 54.1225 453.396 54.2454Z" fill="#2F97ED" />
    <path d="M450.161 55.215C450.448 55.3789 450.625 55.9662 450.325 56.0208L436.336 58.2332C436.241 59.3121 435.408 59.7218 434.398 59.1346C433.469 58.5228 432.805 57.5808 432.542 56.4988L423.849 44.4534C423.467 43.9071 423.712 43.4154 424.095 43.6476C424.175 43.6848 424.245 43.741 424.299 43.8115L433.962 54.4366C434.12 54.4792 434.272 54.5435 434.412 54.6278C434.728 54.8259 435.013 55.0699 435.258 55.3516L449.997 55.1604C450.056 55.1659 450.112 55.1845 450.161 55.215ZM434.398 57.9464C434.89 58.2332 435.299 57.9464 435.299 57.4274C435.284 57.1168 435.194 56.8144 435.037 56.5458C434.88 56.2772 434.661 56.0503 434.398 55.8842C433.907 55.5974 433.498 55.8842 433.498 56.3895C433.517 56.7013 433.608 57.0043 433.764 57.2746C433.92 57.5449 434.138 57.7749 434.398 57.9464Z" fill="url(#paint104_linear_83_4961)" />
    <path d="M434.398 55.857C434.661 56.023 434.88 56.25 435.037 56.5186C435.194 56.7871 435.284 57.0895 435.299 57.4002C435.299 57.9738 434.89 58.206 434.398 57.9192C434.138 57.7476 433.92 57.5176 433.764 57.2473C433.608 56.977 433.517 56.674 433.498 56.3623C433.498 55.7887 433.907 55.5702 434.398 55.857Z" fill="white" />
    <path d="M424.299 43.8114L427.302 42.0634L436.964 52.6885L433.962 54.4365L424.299 43.8114Z" fill="#2F97ED" />
    <path d="M433.962 54.4366L436.964 52.6885C437.122 52.7311 437.274 52.7954 437.415 52.8797C437.733 53.0743 438.019 53.3187 438.261 53.6035L435.258 55.3516C435.013 55.0699 434.728 54.8259 434.412 54.6278C434.272 54.5435 434.12 54.4792 433.962 54.4366Z" fill="#2F97ED" />
    <path d="M435.258 55.3516L438.261 53.6035L453 53.4124L449.998 55.1604L435.258 55.3516Z" fill="#2F97ED" />
    <path d="M449.998 55.1604L453 53.426C453.054 53.4137 453.11 53.4137 453.164 53.426L450.161 55.1741C450.108 55.1577 450.052 55.153 449.998 55.1604Z" fill="#2F97ED" />
    <g opacity="0.35">
      <path d="M364.291 50.954C365.85 51.9651 367.148 53.3313 368.078 54.9407C369.008 56.5501 369.543 58.357 369.641 60.2134L369.464 124.401C369.464 127.802 367.035 129.167 364.073 127.447L277.166 77.2437C275.61 76.2303 274.316 74.8633 273.389 73.2541C272.461 71.645 271.927 69.8392 271.83 67.9843L272.007 3.79663C272.007 0.396056 274.423 -0.969646 277.384 0.737472L364.291 50.954Z" fill="#C0C0ED" />
      <path d="M295.263 21.9467V27.3003C290.814 24.7874 287.238 26.8906 287.224 32.0256C287.38 35.6417 288.653 39.1204 290.868 41.9815L288.371 45.0406C284.852 40.5368 282.824 35.0467 282.571 29.3352C282.584 21.2502 288.261 17.9862 295.263 21.9467Z" fill="#C0C0ED" />
      <path d="M295.358 22.0149C302.428 26.112 308.132 35.986 308.119 44.1255C308.105 52.2651 302.346 55.5154 295.276 51.432C292.56 49.7842 290.198 47.6114 288.33 45.0405L290.827 41.9814C292.026 43.6318 293.544 45.0249 295.29 46.0785C299.794 48.687 303.478 46.5838 303.478 41.4215C303.329 38.604 302.516 35.8619 301.104 33.4194C299.693 30.977 297.724 28.9035 295.358 27.3685H295.235V21.9467L295.358 22.0149Z" fill="#C0C0ED" />
      <path d="M316.73 75.5638C317.391 75.99 317.94 76.5682 318.331 77.2501C318.723 77.9319 318.946 78.6976 318.982 79.4833C318.982 80.849 317.972 81.5046 316.716 80.7808C316.058 80.3512 315.51 79.7725 315.116 79.0916C314.723 78.4107 314.495 77.6466 314.451 76.8612C314.465 75.4272 315.475 74.84 316.73 75.5638Z" fill="#C0C0ED" />
      <path d="M328.794 41.3259V46.6658C324.291 44.0573 320.606 46.1468 320.606 51.3228C320.754 54.1423 321.567 56.8867 322.979 59.3316C324.39 61.7764 326.36 63.8523 328.726 65.3894C331.879 67.2195 334.608 66.7551 335.973 64.5017L340.067 69.3363C337.925 72.8325 333.626 73.5836 328.685 70.702C321.63 66.6049 315.911 56.7173 315.939 48.5914C315.966 40.4655 321.739 37.2425 328.794 41.3259Z" fill="#C0C0ED" />
      <path d="M328.794 41.3259C335.85 45.423 341.568 55.2833 341.541 63.4229C341.61 65.4895 341.11 67.5354 340.095 69.3363L336 64.5018C336.659 63.3582 336.981 62.0513 336.928 60.7324C336.781 57.9126 335.969 55.1678 334.557 52.7228C333.146 50.2778 331.176 48.2021 328.808 46.6658L328.794 41.3259Z" fill="#C0C0ED" />
      <path d="M364.005 87.8005L363.991 90.8323L350.999 83.321V80.2892L364.005 87.8005Z" fill="#C0C0ED" />
      <path d="M364.032 79.1966L364.019 82.2148L351.013 74.7035L351.026 71.6853L364.032 79.1966Z" fill="#C0C0ED" />
      <path d="M364.046 70.5927V73.6246L351.04 66.1133L351.053 63.0814L364.046 70.5927Z" fill="#C0C0ED" />
      <path d="M364.073 61.9889L364.059 65.0207L351.067 57.5094L351.081 54.4775L364.073 61.9889Z" fill="#C0C0ED" />
      <path d="M363.978 96.4044L363.964 99.4362L350.972 91.9249V88.8931L363.978 96.4044Z" fill="#C0C0ED" />
      <path d="M279.705 54.1634C280.36 54.5953 280.905 55.1748 281.296 55.8555C281.687 56.5362 281.913 57.299 281.956 58.083C281.956 59.4487 280.933 60.1042 279.691 59.4487C279.03 59.0185 278.48 58.4379 278.086 57.7543C277.692 57.0707 277.466 56.3035 277.425 55.5155C277.425 54.0269 278.449 53.4396 279.705 54.1634Z" fill="#C0C0ED" />
      <path d="M308.883 72.3409L308.869 74.7035L285.559 61.2378L285.573 58.8615L308.883 72.3409Z" fill="#C0C0ED" />
      <path d="M279.664 66.0177C280.323 66.4417 280.872 67.0176 281.263 67.697C281.655 68.3765 281.879 69.14 281.915 69.9236C281.915 71.3712 280.906 71.9585 279.65 71.2893C278.991 70.8597 278.443 70.281 278.05 69.6001C277.656 68.9192 277.428 68.1551 277.384 67.3697C277.398 65.8811 278.408 65.2802 279.664 66.0177Z" fill="#C0C0ED" />
      <path d="M308.856 84.1814L308.842 86.5578L285.532 73.0783V70.7157L308.856 84.1814Z" fill="#C0C0ED" />
      <path d="M345.867 105.568V107.944L322.558 94.4787L322.571 92.1024L345.867 105.568Z" fill="#C0C0ED" />
      <path d="M345.908 93.7276L345.895 96.0902L322.599 82.6381V80.2618L345.908 93.7276Z" fill="#C0C0ED" />
      <path d="M316.703 87.4044C317.358 87.8363 317.903 88.4158 318.294 89.0965C318.685 89.7771 318.911 90.54 318.955 91.3239C318.955 92.7579 317.931 93.3452 316.689 92.6896C316.028 92.2595 315.478 91.6789 315.084 90.9953C314.691 90.3116 314.464 89.5444 314.424 88.7564C314.465 87.2678 315.434 86.6806 316.703 87.4044Z" fill="#C0C0ED" />
    </g>
    <path d="M358.832 56.4168C360.391 57.4279 361.689 58.794 362.619 60.4034C363.549 62.0128 364.084 63.8197 364.182 65.6762L364.005 129.864C364.005 133.264 361.576 134.63 358.614 132.909L271.707 82.7064C270.151 81.693 268.857 80.326 267.93 78.7169C267.002 77.1077 266.468 75.302 266.371 73.447L266.548 9.2594C266.548 5.85882 268.964 4.49312 271.925 6.20024L358.832 56.4168Z" fill="url(#paint105_linear_83_4961)" />
    <path d="M289.804 27.4095V32.763C285.355 30.2502 281.779 32.3533 281.765 37.4883C281.921 41.1044 283.194 44.5831 285.409 47.4443L282.912 50.5034C279.393 45.9995 277.365 40.5094 277.112 34.7979C277.125 26.713 282.803 23.449 289.804 27.4095Z" fill="#C1C5F4" />
    <path d="M289.899 27.4777C296.969 31.5748 302.673 41.4488 302.66 49.5883C302.646 57.7278 296.887 60.9782 289.817 56.8948C287.101 55.2469 284.739 53.0742 282.871 50.5033L285.368 47.4442C286.567 49.0945 288.085 50.4877 289.831 51.5412C294.335 54.1497 298.019 52.0465 298.019 46.8842C297.87 44.0668 297.057 41.3247 295.645 38.8822C294.234 36.4397 292.265 34.3662 289.899 32.8312H289.776V27.4094L289.899 27.4777Z" fill="url(#paint106_linear_83_4961)" />
    <path d="M311.271 81.0266C311.932 81.4528 312.481 82.031 312.872 82.7128C313.264 83.3947 313.487 84.1604 313.523 84.9461C313.523 86.3118 312.513 86.9674 311.257 86.2435C310.599 85.8139 310.051 85.2353 309.657 84.5544C309.264 83.8734 309.036 83.1094 308.992 82.324C309.006 80.89 310.016 80.3028 311.271 81.0266Z" fill="#C1C5F4" />
    <path d="M323.336 46.7887V52.1285C318.832 49.5201 315.147 51.6096 315.147 56.7856C315.295 59.6051 316.108 62.3495 317.52 64.7944C318.931 67.2392 320.901 69.3151 323.267 70.8522C326.42 72.6822 329.149 72.2179 330.514 69.9645L334.608 74.7991C332.466 78.2952 328.167 79.0464 323.226 76.1648C316.171 72.0677 310.452 62.18 310.48 54.0542C310.507 45.9283 316.28 42.7052 323.336 46.7887Z" fill="#C1C5F4" />
    <path d="M323.335 46.7887C330.391 50.8858 336.109 60.7461 336.082 68.8856C336.151 70.9523 335.651 72.9981 334.636 74.7991L330.541 69.9645C331.2 68.8209 331.522 67.5141 331.469 66.1952C331.322 63.3754 330.51 60.6306 329.098 58.1856C327.687 55.7406 325.717 53.6649 323.349 52.1286L323.335 46.7887Z" fill="url(#paint107_linear_83_4961)" />
    <path d="M358.546 93.2633L358.532 96.2951L345.54 88.7838V85.752L358.546 93.2633Z" fill="#C1C5F4" />
    <path d="M358.573 84.6594L358.56 87.6776L345.554 80.1663L345.567 77.1481L358.573 84.6594Z" fill="#C1C5F4" />
    <path d="M358.587 76.0555V79.0873L345.581 71.576L345.594 68.5442L358.587 76.0555Z" fill="#C1C5F4" />
    <path d="M358.614 67.4516L358.601 70.4835L345.608 62.9722L345.622 59.9403L358.614 67.4516Z" fill="#C1C5F4" />
    <path d="M358.519 101.867L358.505 104.899L345.513 97.3877V94.3558L358.519 101.867Z" fill="#C1C5F4" />
    <path d="M274.246 59.6262C274.901 60.0581 275.446 60.6376 275.837 61.3183C276.228 61.9989 276.454 62.7618 276.497 63.5457C276.497 64.9114 275.474 65.567 274.232 64.9114C273.571 64.4813 273.021 63.9007 272.627 63.2171C272.233 62.5334 272.007 61.7662 271.966 60.9782C271.966 59.4896 272.99 58.9024 274.246 59.6262Z" fill="#C1C5F4" />
    <path d="M303.424 77.8036L303.41 80.1663L280.1 66.7005L280.114 64.3242L303.424 77.8036Z" fill="#C1C5F4" />
    <path d="M274.205 71.4804C274.864 71.9045 275.413 72.4803 275.804 73.1598C276.196 73.8393 276.42 74.6027 276.456 75.3863C276.456 76.834 275.447 77.4212 274.191 76.752C273.533 76.3224 272.984 75.7438 272.591 75.0629C272.197 74.3819 271.969 73.6179 271.926 72.8325C271.939 71.3439 272.949 70.743 274.205 71.4804Z" fill="#C1C5F4" />
    <path d="M303.396 89.6442L303.383 92.0205L280.073 78.5411V76.1785L303.396 89.6442Z" fill="#C1C5F4" />
    <path d="M340.408 111.031V113.407L317.099 99.9415L317.112 97.5652L340.408 111.031Z" fill="#C1C5F4" />
    <path d="M340.449 99.1903L340.436 101.553L317.14 88.0873V85.7246L340.449 99.1903Z" fill="#C1C5F4" />
    <path d="M311.244 92.8672C311.899 93.2991 312.444 93.8786 312.835 94.5592C313.226 95.2399 313.452 96.0028 313.496 96.7867C313.496 98.2207 312.472 98.8079 311.23 98.1524C310.569 97.7223 310.019 97.1416 309.625 96.458C309.232 95.7744 309.005 95.0072 308.965 94.2192C309.006 92.7306 309.975 92.1433 311.244 92.8672Z" fill="#C1C5F4" />
    <g opacity="0.35">
      <path d="M407.267 76.2057C408.03 76.6976 408.664 77.3642 409.117 78.1504C409.57 78.9366 409.83 79.8196 409.874 80.7262L409.656 159.363C409.656 161.029 408.482 161.698 407.035 160.865L386.073 148.751C385.311 148.26 384.676 147.593 384.223 146.807C383.77 146.021 383.51 145.138 383.466 144.231L383.685 65.5943C383.685 63.9281 384.872 63.259 386.318 64.1057L407.267 76.2057Z" fill="#C0C0ED" />
      <path d="M396.677 84.3999C397.201 84.7406 397.637 85.2001 397.95 85.7411C398.263 86.2822 398.444 86.8895 398.478 87.5137C398.478 88.6609 397.66 89.1116 396.663 88.538C396.137 88.2008 395.7 87.7417 395.389 87.1998C395.078 86.6578 394.902 86.0487 394.875 85.4242C394.875 84.277 395.681 83.799 396.677 84.3999Z" fill="#C0C0ED" />
      <path d="M396.609 110.048C397.133 110.388 397.569 110.848 397.882 111.389C398.195 111.93 398.376 112.537 398.41 113.161C398.41 114.309 397.591 114.759 396.595 114.186C396.071 113.845 395.635 113.386 395.322 112.844C395.009 112.303 394.828 111.696 394.794 111.072C394.807 109.925 395.612 109.46 396.609 110.048Z" fill="#C0C0ED" />
      <path d="M396.54 135.695C397.064 136.039 397.498 136.501 397.809 137.045C398.12 137.588 398.298 138.197 398.328 138.823C398.328 139.97 397.523 140.421 396.527 139.847C396.001 139.509 395.563 139.05 395.249 138.508C394.936 137.967 394.756 137.358 394.725 136.733C394.725 135.586 395.531 135.122 396.54 135.695Z" fill="#C0C0ED" />
      <path d="M396.554 128.444C398.915 129.976 400.88 132.045 402.289 134.483C403.698 136.921 404.511 139.657 404.661 142.469C404.661 147.645 400.99 149.721 396.472 147.14C394.108 145.602 392.142 143.526 390.736 141.08C389.329 138.635 388.521 135.891 388.379 133.073C388.42 127.925 392.064 125.849 396.554 128.444ZM396.554 143.972C399.529 145.692 402.013 144.327 402.013 140.885C401.912 139.02 401.375 137.205 400.443 135.587C399.511 133.969 398.212 132.593 396.65 131.571C393.661 129.85 391.191 131.243 391.191 134.657C391.287 136.525 391.825 138.343 392.76 139.962C393.695 141.581 395 142.955 396.568 143.972" fill="#C0C0ED" />
      <path d="M396.622 102.782C398.983 104.314 400.949 106.384 402.358 108.822C403.767 111.259 404.579 113.996 404.729 116.808C404.729 121.984 401.058 124.06 396.54 121.479C394.176 119.94 392.209 117.863 390.8 115.419C389.392 112.974 388.581 110.23 388.434 107.412C388.488 102.263 392.146 100.187 396.622 102.782ZM396.622 118.324C399.597 120.031 402.081 118.652 402.081 115.237C401.98 113.369 401.441 111.551 400.507 109.931C399.572 108.31 398.27 106.932 396.704 105.91C393.715 104.189 391.245 105.582 391.245 108.996C391.347 110.864 391.887 112.682 392.821 114.302C393.756 115.922 395.058 117.3 396.622 118.324Z" fill="#C0C0ED" />
      <path d="M396.704 77.1208C399.065 78.6538 401.029 80.7235 402.438 83.161C403.847 85.5985 404.66 88.3347 404.811 91.1465C404.811 96.3225 401.14 98.412 396.622 95.8172C394.26 94.2798 392.294 92.2055 390.885 89.7632C389.477 87.321 388.665 84.5802 388.516 81.7642C388.557 76.6155 392.255 74.526 396.704 77.1208ZM396.704 92.6624C399.666 94.3695 402.095 93.0038 402.163 89.5759C402.063 87.7075 401.524 85.8891 400.59 84.2683C399.656 82.6476 398.352 81.2703 396.786 80.2483C393.811 78.5275 391.327 79.9205 391.327 83.3347C391.422 85.2044 391.958 87.0249 392.893 88.6465C393.828 90.2681 395.134 91.6445 396.704 92.6624Z" fill="#C0C0ED" />
    </g>
    <path d="M401.808 81.6685C402.571 82.1603 403.205 82.827 403.658 83.6132C404.111 84.3993 404.371 85.2824 404.415 86.1889L404.197 164.826C404.197 166.492 403.023 167.161 401.576 166.328L380.614 154.214C379.852 153.722 379.217 153.056 378.764 152.27C378.311 151.483 378.051 150.6 378.007 149.694L378.226 71.0571C378.226 69.3909 379.413 68.7217 380.86 69.5685L401.808 81.6685Z" fill="url(#paint108_linear_83_4961)" />
    <path d="M391.218 89.8627C391.742 90.2033 392.178 90.6629 392.491 91.2039C392.804 91.7449 392.985 92.3522 393.019 92.9765C393.019 94.1237 392.201 94.5743 391.204 94.0007C390.678 93.6635 390.241 93.2045 389.93 92.6625C389.619 92.1205 389.443 91.5115 389.416 90.887C389.416 89.7398 390.222 89.2618 391.218 89.8627Z" fill="#C1C5F4" />
    <path d="M391.15 115.51C391.673 115.851 392.11 116.311 392.423 116.852C392.736 117.393 392.917 118 392.951 118.624C392.951 119.771 392.132 120.222 391.136 119.648C390.612 119.308 390.176 118.848 389.863 118.307C389.55 117.766 389.369 117.159 389.334 116.535C389.348 115.387 390.153 114.923 391.15 115.51Z" fill="#C1C5F4" />
    <path d="M391.081 141.158C391.605 141.502 392.039 141.964 392.35 142.508C392.661 143.051 392.839 143.66 392.869 144.286C392.869 145.433 392.064 145.883 391.068 145.31C390.542 144.972 390.104 144.513 389.79 143.971C389.477 143.43 389.297 142.821 389.266 142.196C389.266 141.049 390.072 140.585 391.081 141.158Z" fill="#C1C5F4" />
    <path d="M391.095 133.906C393.456 135.439 395.421 137.508 396.83 139.946C398.239 142.383 399.052 145.12 399.202 147.932C399.202 153.108 395.531 155.184 391.013 152.603C388.649 151.065 386.683 148.988 385.277 146.543C383.87 144.098 383.062 141.354 382.92 138.536C382.961 133.387 386.605 131.312 391.095 133.906ZM391.095 149.434C394.07 151.155 396.554 149.789 396.554 146.348C396.453 144.483 395.916 142.668 394.984 141.05C394.052 139.432 392.753 138.056 391.191 137.034C388.202 135.313 385.732 136.706 385.732 140.12C385.828 141.988 386.366 143.805 387.301 145.424C388.236 147.043 389.541 148.418 391.109 149.434" fill="url(#paint109_linear_83_4961)" />
    <path d="M391.163 108.245C393.524 109.777 395.49 111.847 396.899 114.284C398.307 116.722 399.12 119.459 399.27 122.271C399.27 127.447 395.599 129.522 391.081 126.941C388.717 125.403 386.75 123.326 385.341 120.881C383.933 118.437 383.122 115.693 382.975 112.875C383.029 107.726 386.687 105.65 391.163 108.245ZM391.163 123.787C394.138 125.494 396.622 124.114 396.622 120.7C396.521 118.832 395.982 117.014 395.047 115.393C394.113 113.773 392.811 112.395 391.245 111.372C388.256 109.652 385.786 111.045 385.786 114.459C385.888 116.327 386.428 118.144 387.362 119.765C388.297 121.385 389.599 122.763 391.163 123.787Z" fill="url(#paint110_linear_83_4961)" />
    <path d="M391.245 82.5836C393.606 84.1166 395.57 86.1863 396.979 88.6237C398.388 91.0612 399.201 93.7975 399.352 96.6093C399.352 101.785 395.681 103.875 391.163 101.28C388.801 99.7426 386.835 97.6682 385.426 95.226C384.018 92.7838 383.206 90.0429 383.057 87.2269C383.098 82.0783 386.796 79.9888 391.245 82.5836ZM391.245 98.1252C394.207 99.8323 396.636 98.4666 396.704 95.0387C396.604 93.1703 396.065 91.3518 395.131 89.7311C394.197 88.1104 392.893 86.733 391.327 85.711C388.352 83.9902 385.868 85.3832 385.868 88.7975C385.963 90.6672 386.499 92.4877 387.434 94.1093C388.369 95.7309 389.675 97.1072 391.245 98.1252Z" fill="url(#paint111_linear_83_4961)" />
    <g opacity="0.35">
      <path d="M344.011 136.665C345.592 137.682 346.911 139.058 347.86 140.681C348.808 142.304 349.36 144.129 349.47 146.006L349.402 167.857C349.402 171.285 346.973 172.678 343.943 170.958L275.87 131.626C274.302 130.602 272.997 129.223 272.063 127.599C271.129 125.976 270.59 124.155 270.493 122.284V100.433C270.493 97.0053 272.935 95.6259 275.952 97.3467L344.011 136.665Z" fill="#C0C0ED" />
      <path d="M292.533 117.586C292.979 118.33 293.224 119.177 293.243 120.045V128.908C293.273 129.218 293.222 129.531 293.095 129.816C292.969 130.101 292.77 130.348 292.52 130.533C292.254 130.691 291.947 130.767 291.638 130.75C291.33 130.733 291.033 130.624 290.786 130.437L283.076 125.958C282.334 125.498 281.734 124.841 281.342 124.06C280.903 123.313 280.659 122.468 280.633 121.601V112.738C280.597 112.427 280.645 112.112 280.772 111.826C280.899 111.541 281.101 111.294 281.356 111.113C281.631 110.974 281.94 110.916 282.246 110.945C282.553 110.974 282.845 111.089 283.089 111.277L290.855 115.756C291.566 116.207 292.145 116.838 292.533 117.586ZM282.898 120.577L289.858 117.941L283.54 114.281C283.212 114.09 283.021 114.008 282.98 114.035C282.939 114.063 282.98 114.268 282.98 114.65V120.577M290.977 126.982V121.041L284.031 123.677L290.35 127.324C290.677 127.515 290.855 127.61 290.909 127.583C290.944 127.384 290.944 127.181 290.909 126.982" fill="#C0C0ED" />
      <path d="M309.361 132.199C309.357 132.43 309.324 132.659 309.265 132.882C309.209 133.09 309.111 133.286 308.978 133.456C309.22 133.987 309.346 134.565 309.347 135.149V138.345C309.4 138.649 309.357 138.963 309.224 139.242C309.091 139.521 308.874 139.751 308.604 139.902C308.334 140.052 308.024 140.114 307.717 140.08C307.41 140.046 307.121 139.916 306.89 139.71L299.125 135.217C298.399 134.778 297.805 134.15 297.405 133.401C296.952 132.68 296.707 131.849 296.696 130.997V127.802C296.712 127.357 296.844 126.924 297.078 126.545C296.975 126.259 296.888 125.967 296.819 125.671C296.75 125.399 296.714 125.119 296.709 124.838V121.943C296.656 121.638 296.699 121.325 296.832 121.046C296.966 120.767 297.182 120.536 297.452 120.386C297.722 120.236 298.033 120.174 298.34 120.208C298.647 120.242 298.935 120.371 299.166 120.577L306.931 125.057C307.578 125.45 308.121 125.994 308.514 126.641C308.946 127.283 309.239 128.009 309.374 128.771L309.361 132.199ZM307.013 136.433V134.398C307.038 134.168 307.015 133.935 306.945 133.715C306.785 133.559 306.601 133.43 306.399 133.333L299.575 129.427C299.248 129.222 299.057 129.14 299.016 129.167C298.981 129.366 298.981 129.569 299.016 129.768V131.803C298.987 132.03 298.987 132.259 299.016 132.486C299.183 132.643 299.371 132.777 299.575 132.882L306.399 136.788C306.727 136.979 306.904 137.061 306.959 137.034C307.013 137.006 306.959 136.815 306.959 136.433M306.959 130.492V128.539C306.995 128.313 306.995 128.082 306.959 127.856C306.791 127.699 306.603 127.565 306.399 127.46L299.575 123.554C299.41 123.431 299.218 123.347 299.016 123.308C298.981 123.507 298.981 123.711 299.016 123.909V125.876C298.979 125.987 298.966 126.105 298.978 126.222C298.99 126.338 299.028 126.451 299.087 126.552C299.147 126.653 299.227 126.74 299.323 126.807C299.419 126.874 299.528 126.92 299.644 126.941L306.467 130.847C306.795 131.038 306.986 131.12 307.027 131.093C307.068 131.066 307.027 130.874 307.095 130.492" fill="#C0C0ED" />
      <path d="M312.786 131.257L315.12 132.609L315.106 135.286L312.786 133.934V131.257Z" fill="#C0C0ED" />
      <path d="M312.759 143.138V140.475L315.092 141.814L315.079 144.477L312.759 143.138Z" fill="#C0C0ED" />
      <path d="M323.813 134.821L323.772 149.503L321.425 148.151L321.452 137.321L320.442 138.167L317.372 136.392L321.234 133.333L323.813 134.821Z" fill="#C0C0ED" />
      <path d="M339.303 143.767V146.43L329.6 140.83C329.438 140.709 329.252 140.625 329.054 140.585C329.054 140.585 328.986 140.817 328.986 141.199V142.947C328.942 143.173 328.942 143.405 328.986 143.63C329.153 143.784 329.341 143.913 329.545 144.012L336.806 148.205C337.529 148.654 338.122 149.285 338.525 150.035C338.972 150.758 339.217 151.589 339.235 152.439V155.635C339.274 155.94 339.226 156.25 339.099 156.53C338.971 156.81 338.767 157.048 338.511 157.219C338.236 157.348 337.93 157.4 337.627 157.369C337.324 157.337 337.035 157.224 336.792 157.041L329.04 152.562C328.328 152.099 327.735 151.474 327.31 150.739C326.886 150.003 326.641 149.177 326.597 148.328V147.372L328.917 148.738V149.12C328.893 149.346 328.916 149.574 328.986 149.789C329.137 149.962 329.323 150.102 329.531 150.199L336.355 154.105C336.683 154.296 336.874 154.364 336.915 154.337C336.952 154.139 336.952 153.935 336.915 153.736V151.701C336.932 151.471 336.909 151.24 336.846 151.019C336.686 150.862 336.502 150.733 336.301 150.636L326.584 145.023V136.474L339.303 143.767Z" fill="#C0C0ED" />
    </g>
    <path d="M339.917 140.762C341.498 141.779 342.817 143.155 343.765 144.778C344.714 146.401 345.266 148.226 345.376 150.103L345.308 171.955C345.308 175.382 342.879 176.775 339.849 175.055L271.775 135.723C270.207 134.699 268.903 133.32 267.969 131.697C267.034 130.073 266.496 128.252 266.398 126.381V104.53C266.398 101.102 268.841 99.7229 271.857 101.444L339.917 140.762Z" fill="url(#paint112_linear_83_4961)" />
    <path d="M288.439 121.683C288.885 122.427 289.13 123.274 289.149 124.142V133.005C289.179 133.315 289.128 133.628 289.001 133.913C288.874 134.198 288.676 134.445 288.425 134.63C288.16 134.788 287.853 134.864 287.544 134.847C287.235 134.83 286.939 134.721 286.692 134.534L278.981 130.055C278.239 129.595 277.639 128.938 277.248 128.157C276.809 127.41 276.564 126.565 276.538 125.698V116.835C276.503 116.524 276.551 116.209 276.678 115.924C276.805 115.638 277.007 115.391 277.262 115.21C277.537 115.071 277.845 115.013 278.152 115.042C278.458 115.071 278.751 115.186 278.995 115.374L286.76 119.853C287.472 120.304 288.051 120.936 288.439 121.683ZM278.804 124.674L285.764 122.038L279.445 118.378C279.118 118.187 278.927 118.105 278.886 118.132C278.845 118.16 278.886 118.365 278.886 118.747V124.674M286.883 131.079V125.139L279.937 127.774L286.255 131.421C286.583 131.612 286.76 131.707 286.815 131.68C286.85 131.481 286.85 131.278 286.815 131.079" fill="#C1C5F4" />
    <path d="M305.266 136.296C305.262 136.527 305.23 136.756 305.171 136.979C305.115 137.188 305.017 137.383 304.884 137.553C305.126 138.084 305.252 138.662 305.253 139.246V142.442C305.306 142.747 305.263 143.06 305.129 143.339C304.996 143.618 304.78 143.849 304.51 143.999C304.239 144.149 303.929 144.211 303.622 144.177C303.315 144.143 303.026 144.014 302.796 143.808L295.031 139.315C294.305 138.875 293.711 138.247 293.311 137.498C292.858 136.777 292.613 135.946 292.601 135.095V131.899C292.618 131.454 292.749 131.021 292.984 130.642C292.88 130.356 292.794 130.064 292.724 129.768C292.656 129.496 292.619 129.216 292.615 128.935V126.04C292.562 125.735 292.605 125.422 292.738 125.143C292.871 124.864 293.088 124.633 293.358 124.483C293.628 124.333 293.938 124.271 294.245 124.305C294.552 124.339 294.841 124.468 295.072 124.674L302.837 129.154C303.484 129.548 304.027 130.091 304.42 130.738C304.852 131.38 305.145 132.106 305.28 132.868L305.266 136.296ZM302.919 140.53V138.495C302.944 138.265 302.921 138.033 302.851 137.812C302.691 137.656 302.506 137.527 302.305 137.43L295.481 133.524C295.153 133.319 294.962 133.237 294.921 133.264C294.887 133.463 294.887 133.667 294.921 133.865V135.9C294.893 136.127 294.893 136.356 294.921 136.583C295.089 136.741 295.277 136.874 295.481 136.979L302.305 140.885C302.632 141.076 302.81 141.158 302.864 141.131C302.919 141.104 302.864 140.912 302.864 140.53M302.864 134.589V132.636C302.9 132.41 302.9 132.18 302.864 131.953C302.697 131.796 302.509 131.663 302.305 131.557L295.481 127.651C295.315 127.528 295.124 127.444 294.921 127.406C294.887 127.604 294.887 127.808 294.921 128.007V129.973C294.884 130.084 294.872 130.202 294.884 130.319C294.896 130.435 294.933 130.548 294.993 130.649C295.052 130.75 295.133 130.837 295.229 130.904C295.325 130.971 295.434 131.017 295.549 131.038L302.373 134.944C302.701 135.135 302.892 135.217 302.933 135.19C302.973 135.163 302.933 134.972 303.001 134.589" fill="#C1C5F4" />
    <path d="M308.692 135.354L311.025 136.706L311.012 139.383L308.692 138.031V135.354Z" fill="#C1C5F4" />
    <path d="M308.664 147.235V144.572L310.998 145.911L310.984 148.574L308.664 147.235Z" fill="#C1C5F4" />
    <path d="M319.719 138.918L319.678 153.6L317.331 152.248L317.358 141.418L316.348 142.264L313.277 140.489L317.14 137.43L319.719 138.918Z" fill="#C1C5F4" />
    <path d="M335.209 147.864V150.527L325.505 144.927C325.344 144.806 325.157 144.722 324.96 144.682C324.96 144.682 324.891 144.914 324.891 145.296V147.044C324.848 147.27 324.848 147.502 324.891 147.727C325.059 147.881 325.247 148.01 325.451 148.11L332.711 152.302C333.435 152.751 334.028 153.382 334.431 154.132C334.878 154.855 335.123 155.686 335.141 156.536V159.732C335.179 160.037 335.132 160.347 335.004 160.627C334.877 160.907 334.673 161.145 334.417 161.316C334.141 161.445 333.836 161.497 333.533 161.466C333.23 161.434 332.941 161.321 332.698 161.138L324.946 156.659C324.234 156.196 323.641 155.571 323.216 154.836C322.792 154.1 322.547 153.274 322.503 152.425V151.469L324.823 152.835V153.217C324.799 153.443 324.822 153.671 324.891 153.886C325.043 154.059 325.229 154.199 325.437 154.296L332.261 158.202C332.589 158.393 332.78 158.462 332.821 158.434C332.858 158.236 332.858 158.032 332.821 157.833V155.798C332.838 155.569 332.815 155.337 332.752 155.116C332.592 154.959 332.408 154.83 332.206 154.733L322.489 149.12V140.571L335.209 147.864Z" fill="#C1C5F4" />
    <g opacity="0.35">
      <path d="M405.684 178.469C406.873 179.222 407.864 180.248 408.577 181.462C409.289 182.676 409.701 184.042 409.778 185.448V203.502C409.778 206.07 407.95 207.094 405.684 205.81L361.835 180.476C360.664 179.71 359.69 178.679 358.991 177.467C358.291 176.255 357.887 174.895 357.809 173.498V155.443C357.809 152.876 359.624 151.838 361.903 153.122L405.684 178.469Z" fill="#58E0AB" />
      <path d="M375.155 168.964C375.714 169.329 376.18 169.821 376.514 170.4C376.849 170.979 377.042 171.628 377.079 172.296V173.238L375.114 172.105V171.695C375.132 171.527 375.132 171.358 375.114 171.19C374.981 171.076 374.834 170.98 374.677 170.903L369.805 168.09C369.677 167.995 369.534 167.921 369.382 167.871C369.382 167.871 369.382 168.035 369.382 168.349V169.524C369.365 169.692 369.365 169.861 369.382 170.029C369.51 170.142 369.653 170.238 369.805 170.316L375.169 173.402C375.737 173.757 376.207 174.25 376.533 174.836C376.887 175.407 377.081 176.063 377.093 176.735V178.974C377.125 179.216 377.086 179.462 376.982 179.682C376.878 179.902 376.713 180.088 376.506 180.217C376.287 180.317 376.045 180.357 375.805 180.333C375.566 180.309 375.336 180.222 375.141 180.08L369.327 176.721C368.766 176.358 368.298 175.866 367.964 175.287C367.629 174.708 367.437 174.057 367.403 173.389V172.433L369.327 173.552V173.962C369.311 174.135 369.311 174.309 369.327 174.481C369.458 174.587 369.6 174.679 369.751 174.754L374.636 177.581C374.768 177.678 374.916 177.752 375.073 177.8C375.086 177.636 375.086 177.472 375.073 177.308V176.134C375.091 175.97 375.091 175.805 375.073 175.642C375.073 175.642 374.923 175.519 374.636 175.355L369.314 172.269C368.754 171.907 368.287 171.418 367.953 170.841C367.618 170.264 367.425 169.616 367.389 168.95V166.71C367.334 166.468 367.358 166.214 367.457 165.986C367.557 165.758 367.727 165.568 367.942 165.444C368.158 165.32 368.407 165.269 368.654 165.298C368.901 165.327 369.132 165.434 369.314 165.604L375.155 168.964Z" fill="#58E0AB" />
      <path d="M388.516 176.68L388.502 178.906L381.528 174.877V177.103L387.137 180.34V182.566L381.515 179.316V181.542L388.488 185.57L388.475 187.797L379.549 182.648L379.59 171.517L388.516 176.68Z" fill="#58E0AB" />
      <path d="M400.048 183.344L400.034 185.57L396.172 183.331L396.145 192.221L394.207 191.115L394.234 182.211L390.372 179.971V177.759L400.048 183.344Z" fill="#58E0AB" />
    </g>
    <path d="M401.59 182.566C402.779 183.319 403.77 184.345 404.482 185.559C405.195 186.773 405.607 188.139 405.684 189.545V207.599C405.684 210.167 403.855 211.191 401.59 209.907L357.741 184.574C356.57 183.807 355.596 182.777 354.897 181.564C354.197 180.352 353.792 178.992 353.715 177.595V159.54C353.715 156.973 355.53 155.935 357.809 157.219L401.59 182.566Z" fill="url(#paint113_linear_83_4961)" />
    <path d="M371.061 173.061C371.62 173.426 372.086 173.918 372.42 174.497C372.754 175.076 372.948 175.725 372.985 176.393V177.335L371.02 176.202V175.792C371.037 175.624 371.037 175.455 371.02 175.287C370.887 175.173 370.74 175.077 370.583 175L365.711 172.187C365.582 172.092 365.44 172.018 365.288 171.968C365.288 171.968 365.288 172.132 365.288 172.446V173.621C365.271 173.789 365.271 173.958 365.288 174.126C365.416 174.239 365.558 174.335 365.711 174.413L371.074 177.499C371.643 177.854 372.113 178.347 372.439 178.933C372.793 179.504 372.986 180.16 372.999 180.832V183.071C373.03 183.313 372.992 183.559 372.888 183.779C372.784 183.999 372.618 184.185 372.412 184.314C372.192 184.414 371.951 184.454 371.711 184.43C371.471 184.406 371.242 184.319 371.047 184.178L365.233 180.818C364.671 180.455 364.204 179.963 363.869 179.384C363.535 178.805 363.343 178.154 363.309 177.486V176.53L365.233 177.649V178.059C365.217 178.232 365.217 178.406 365.233 178.578C365.364 178.684 365.506 178.776 365.656 178.851L370.542 181.678C370.674 181.775 370.822 181.849 370.979 181.897C370.992 181.733 370.992 181.569 370.979 181.405V180.231C370.997 180.067 370.997 179.902 370.979 179.739C370.979 179.739 370.829 179.616 370.542 179.452L365.22 176.366C364.66 176.004 364.193 175.515 363.859 174.938C363.524 174.361 363.331 173.713 363.295 173.047V170.807C363.24 170.565 363.263 170.311 363.363 170.083C363.462 169.855 363.633 169.665 363.848 169.541C364.064 169.417 364.313 169.366 364.56 169.395C364.807 169.424 365.038 169.531 365.22 169.701L371.061 173.061Z" fill="white" />
    <path d="M384.421 180.777L384.408 183.003L377.434 178.974V181.2L383.043 184.437V186.663L377.42 183.413V185.639L384.394 189.668L384.381 191.894L375.455 186.745L375.496 175.615L384.421 180.777Z" fill="white" />
    <path d="M395.954 187.442L395.94 189.668L392.078 187.428L392.05 196.319L390.112 195.212L390.14 186.308L386.277 184.068V181.856L395.954 187.442Z" fill="white" />
    <g opacity="0.35">
      <g filter="url(#filter0_d_83_4961)">
        <path d="M199.369 28.7979C202.918 26.763 205.797 28.4018 205.811 32.4716L205.975 87.632C205.857 89.8544 205.215 92.0173 204.103 93.9445C202.991 95.8717 201.439 97.5085 199.574 98.7214L110.606 150.126C107.058 152.161 104.179 150.536 104.165 146.452L104.001 91.292C104.121 89.0702 104.764 86.908 105.876 84.9812C106.988 83.0543 108.539 81.4172 110.402 80.2026L199.369 28.7979Z" fill="#A6A6E7" />
      </g>
      <path d="M124.377 103.761L124.459 130.843L114.319 136.701L114.237 109.633L124.377 103.761Z" fill="#C0C0ED" />
      <path d="M140.085 82.374L140.194 121.747L130.054 127.606L129.931 88.2329L140.085 82.374Z" fill="#C0C0ED" />
      <path d="M158.018 65.6306L158.154 111.381L148 117.24L147.864 71.5031L158.018 65.6306Z" fill="#C0C0ED" />
      <path d="M177.506 103.788L177.52 106.205L132.265 132.345L132.251 129.941L177.506 103.788Z" fill="#C0C0ED" />
      <path d="M175.91 61.6702L176.019 101.057L165.865 106.916L165.756 67.5426L175.91 61.6702Z" fill="#C0C0ED" />
      <path d="M195.303 40.8433L195.439 89.8307L185.285 95.7032L185.149 46.7157L195.303 40.8433Z" fill="#C0C0ED" />
      <path d="M124.322 82.9203L124.336 85.1054L113.309 91.4695V89.2844L124.322 82.9203Z" fill="#C0C0ED" />
      <path d="M133.084 72.3909V74.5896L113.295 86.0205V83.8354L133.084 72.3909Z" fill="#C0C0ED" />
    </g>
    <path d="M203.464 34.2608C207.012 32.2259 209.892 33.8647 209.905 37.9345L210.069 93.0949C209.951 95.3173 209.31 97.4802 208.197 99.4074C207.085 101.335 205.533 102.971 203.668 104.184L114.701 155.589C111.152 157.624 108.273 155.999 108.259 151.915L108.095 96.7549C108.216 94.5331 108.858 92.3709 109.97 90.4441C111.082 88.5172 112.633 86.88 114.496 85.6655L203.464 34.2608Z" fill="url(#paint114_linear_83_4961)" />
    <path d="M128.471 109.224L128.553 136.305L118.413 142.164L118.331 115.096L128.471 109.224Z" fill="url(#paint115_linear_83_4961)" />
    <path d="M144.179 87.8369L144.288 127.21L134.148 133.069L134.025 93.6957L144.179 87.8369Z" fill="url(#paint116_linear_83_4961)" />
    <path d="M162.112 71.0935L162.248 116.844L152.095 122.703L151.958 76.966L162.112 71.0935Z" fill="url(#paint117_linear_83_4961)" />
    <path d="M181.601 109.251L181.614 111.668L136.359 137.808L136.346 135.404L181.601 109.251Z" fill="#C1C5F4" />
    <path d="M180.004 83.9999L180.113 106.52L169.959 112.378L169.85 89.8724L180.004 83.9999Z" fill="url(#paint118_linear_83_4961)" />
    <path d="M199.397 46.3062L199.533 95.2936L189.38 101.166L189.243 52.1786L199.397 46.3062Z" fill="url(#paint119_linear_83_4961)" />
    <path d="M128.416 88.3831L128.43 90.5545L117.403 96.9323V94.7472L128.416 88.3831Z" fill="#C1C5F4" />
    <path d="M137.178 77.8536V80.0524L117.389 91.4833V89.2982L137.178 77.8536Z" fill="#C1C5F4" />
    <g opacity="0.35">
      <path d="M151.951 138.823C154.858 137.143 157.219 138.495 157.219 141.827L157.437 215.848C157.34 217.668 156.815 219.439 155.904 221.017C154.994 222.596 153.723 223.936 152.196 224.93L107.487 250.755C104.58 252.435 102.219 251.096 102.219 247.751L102.001 173.73C102.102 171.911 102.629 170.141 103.54 168.564C104.45 166.986 105.718 165.645 107.242 164.648L151.951 138.823Z" fill="#C0C0ED" />
      <path d="M110.408 176.953C111.568 176.284 112.51 176.83 112.523 178.155C112.484 178.883 112.273 179.592 111.907 180.223C111.542 180.854 111.033 181.39 110.422 181.787C109.262 182.457 108.32 181.924 108.32 180.586C108.359 179.859 108.568 179.151 108.931 178.52C109.294 177.889 109.8 177.352 110.408 176.953Z" fill="#C0C0ED" />
      <path d="M140.501 160.305V162.873L115.526 177.308V174.727L140.501 160.305Z" fill="#C0C0ED" />
      <path d="M154.626 145.487L155.172 146.375C154.039 147.784 153.073 149.319 152.292 150.95C152.019 151.496 151.76 152.043 151.541 152.562C150.931 153.942 150.397 155.355 149.945 156.795L149.672 157.71L146.833 157.205L147.474 155.703L149.153 155.989C149.602 154.638 150.121 153.312 150.709 152.015C150.914 151.565 151.118 151.114 151.35 150.65C152.237 148.807 153.336 147.074 154.626 145.487Z" fill="#C0C0ED" />
      <path d="M151.146 166.86C151.225 166.806 151.312 166.764 151.405 166.738C151.173 167.202 150.954 167.666 150.75 168.103L146.314 170.671C146.19 170.754 146.087 170.865 146.013 170.996C145.94 171.126 145.898 171.272 145.891 171.422V176.967C145.891 177.24 146.082 177.349 146.328 177.212L151.146 174.481C151.274 174.401 151.381 174.291 151.457 174.16C151.533 174.029 151.576 173.881 151.582 173.73V168.704C151.814 168.185 152.06 167.653 152.333 167.093C152.421 167.275 152.463 167.477 152.456 167.68V173.225C152.422 173.683 152.282 174.128 152.044 174.522C151.807 174.916 151.481 175.248 151.091 175.492L146.26 178.223C145.523 178.646 144.895 178.319 144.895 177.486V171.927C144.928 171.467 145.069 171.02 145.306 170.624C145.543 170.228 145.87 169.893 146.26 169.646L151.146 166.86Z" fill="#C0C0ED" />
      <path d="M154.68 161.575L155.212 162.477C154.08 163.881 153.114 165.412 152.333 167.038C152.06 167.598 151.814 168.131 151.582 168.65C150.973 170.035 150.439 171.453 149.985 172.897L149.713 173.812L146.874 173.293L147.529 171.791L149.194 172.091C149.494 171.203 150.013 169.742 150.75 168.103C150.954 167.666 151.173 167.202 151.405 166.738C152.288 164.893 153.387 163.16 154.68 161.575Z" fill="#C0C0ED" />
      <path d="M151.091 150.759L151.35 150.65C151.118 151.114 150.914 151.565 150.709 152.015L146.273 154.569C146.149 154.65 146.045 154.759 145.969 154.887C145.893 155.015 145.848 155.158 145.837 155.307V160.879C145.837 161.138 146.028 161.261 146.26 161.125L151.091 158.393C151.216 158.309 151.32 158.198 151.395 158.068C151.471 157.938 151.516 157.792 151.528 157.642V152.616C151.746 152.097 152.005 151.551 152.278 151.005C152.365 151.178 152.407 151.371 152.401 151.565V157.123C152.369 157.584 152.229 158.031 151.991 158.427C151.754 158.823 151.427 159.158 151.036 159.404L146.219 162.135C145.482 162.559 144.854 162.217 144.854 161.384V155.826C144.884 155.364 145.024 154.917 145.261 154.52C145.498 154.124 145.827 153.789 146.219 153.545L151.091 150.759Z" fill="#C0C0ED" />
      <path d="M110.462 193.054C111.609 192.385 112.564 192.918 112.564 194.256C112.524 194.981 112.314 195.686 111.951 196.315C111.589 196.944 111.083 197.478 110.476 197.875C109.302 198.544 108.374 198.012 108.361 196.674C108.399 195.947 108.61 195.239 108.975 194.61C109.341 193.981 109.85 193.448 110.462 193.054Z" fill="#C0C0ED" />
      <path d="M140.541 176.393L140.555 178.96L115.58 193.396L115.567 190.828L140.541 176.393Z" fill="#C0C0ED" />
      <path d="M110.503 208.419C111.65 207.749 112.605 208.282 112.605 209.62C112.565 210.347 112.355 211.054 111.993 211.685C111.63 212.316 111.125 212.853 110.517 213.253C109.343 213.922 108.415 213.376 108.402 212.051C108.44 211.323 108.651 210.613 109.016 209.982C109.381 209.351 109.891 208.815 110.503 208.419Z" fill="#C0C0ED" />
      <path d="M142.998 217.746C144.663 216.777 146.014 217.555 146.028 219.467C145.97 220.507 145.669 221.52 145.147 222.421C144.625 223.323 143.898 224.089 143.025 224.657L120.671 237.603C119.006 238.573 117.655 237.795 117.655 235.896C117.71 234.854 118.011 233.839 118.532 232.934C119.054 232.03 119.782 231.262 120.657 230.693L142.998 217.746Z" fill="#C0C0ED" />
      <path d="M140.637 207.135V209.702L115.662 224.138L115.648 221.556L140.637 207.135Z" fill="#C0C0ED" />
      <path d="M140.596 191.757V194.325L115.621 208.76L115.608 206.192L140.596 191.757Z" fill="#C0C0ED" />
      <path d="M151.186 182.224L151.446 182.115C151.214 182.566 150.995 183.03 150.804 183.481L146.369 186.048C146.241 186.129 146.135 186.24 146.059 186.371C145.983 186.501 145.939 186.648 145.932 186.8V192.29C145.932 192.563 146.123 192.672 146.369 192.535L151.2 189.804C151.324 189.72 151.426 189.608 151.5 189.478C151.573 189.348 151.615 189.202 151.623 189.053V184.095C151.855 183.563 152.101 183.03 152.374 182.484C152.465 182.656 152.512 182.849 152.51 183.044V188.602C152.478 189.063 152.338 189.51 152.101 189.906C151.863 190.302 151.536 190.637 151.146 190.883L146.314 193.614C145.591 194.038 144.95 193.696 144.95 192.85V187.305C144.98 186.844 145.119 186.396 145.356 185.999C145.594 185.603 145.922 185.268 146.314 185.024L151.186 182.224Z" fill="#C0C0ED" />
      <path d="M154.789 176.953L155.335 177.841C154.191 179.244 153.219 180.78 152.442 182.416C152.169 182.962 151.923 183.495 151.691 184.027C151.081 185.406 150.553 186.82 150.108 188.261L149.835 189.176L146.997 188.671L147.638 187.168L149.303 187.455C149.603 186.567 150.136 185.106 150.873 183.467C151.064 183.03 151.282 182.566 151.514 182.102C152.392 180.258 153.492 178.529 154.789 176.953Z" fill="#C0C0ED" />
      <path d="M110.544 223.783C111.704 223.113 112.646 223.66 112.646 224.984C112.611 225.712 112.404 226.421 112.041 227.053C111.678 227.685 111.169 228.221 110.558 228.617C109.384 229.286 108.456 228.754 108.456 227.415C108.488 226.687 108.694 225.976 109.057 225.344C109.421 224.712 109.931 224.176 110.544 223.783Z" fill="#C0C0ED" />
      <path d="M151.227 197.589C151.951 197.179 152.592 197.589 152.592 198.34V203.898C152.56 204.359 152.42 204.806 152.182 205.202C151.945 205.598 151.618 205.933 151.227 206.179L146.396 208.91C145.673 209.334 145.031 208.992 145.031 208.159V202.601C145.062 202.139 145.201 201.692 145.438 201.295C145.676 200.899 146.004 200.564 146.396 200.32L151.227 197.589ZM151.678 204.417V198.845C151.678 198.586 151.487 198.476 151.241 198.613L146.424 201.344C146.299 201.425 146.195 201.534 146.119 201.662C146.044 201.79 145.998 201.934 145.987 202.082V207.64C145.987 207.913 146.178 208.036 146.424 207.9L151.255 205.168C151.38 205.086 151.484 204.975 151.557 204.844C151.631 204.713 151.672 204.567 151.678 204.417Z" fill="#C0C0ED" />
    </g>
    <path d="M156.045 144.286C158.952 142.606 161.313 143.958 161.313 147.29L161.531 221.311C161.434 223.131 160.909 224.902 159.999 226.48C159.088 228.058 157.817 229.399 156.291 230.393L111.582 256.218C108.675 257.898 106.314 256.559 106.314 253.213L106.095 179.193C106.196 177.374 106.723 175.604 107.634 174.027C108.544 172.449 109.812 171.108 111.336 170.111L156.045 144.286Z" fill="url(#paint120_linear_83_4961)" />
    <path d="M114.502 182.416C115.662 181.747 116.604 182.293 116.617 183.618C116.578 184.346 116.367 185.055 116.002 185.686C115.636 186.317 115.127 186.853 114.516 187.25C113.356 187.92 112.414 187.387 112.414 186.049C112.453 185.322 112.663 184.614 113.025 183.983C113.388 183.352 113.894 182.815 114.502 182.416Z" fill="#C1C5F4" />
    <path d="M144.595 165.768V168.335L119.62 182.771V180.19L144.595 165.768Z" fill="#C1C5F4" />
    <path d="M158.72 150.95L159.266 151.838C158.133 153.247 157.167 154.782 156.386 156.413C156.113 156.959 155.854 157.506 155.636 158.025C155.025 159.405 154.492 160.818 154.039 162.258L153.766 163.173L150.927 162.668L151.569 161.166L153.247 161.452C153.696 160.101 154.215 158.775 154.803 157.478C155.008 157.028 155.212 156.577 155.444 156.113C156.331 154.27 157.43 152.537 158.72 150.95Z" fill="url(#paint121_linear_83_4961)" />
    <path d="M155.24 172.323C155.319 172.269 155.407 172.227 155.499 172.2C155.267 172.665 155.049 173.129 154.844 173.566L150.409 176.134C150.284 176.217 150.181 176.328 150.108 176.459C150.034 176.589 149.992 176.735 149.986 176.885V182.429C149.986 182.703 150.177 182.812 150.422 182.675L155.24 179.944C155.368 179.864 155.475 179.754 155.551 179.623C155.627 179.492 155.67 179.344 155.677 179.193V174.167C155.909 173.648 156.154 173.115 156.427 172.556C156.515 172.738 156.558 172.94 156.55 173.143V178.687C156.517 179.146 156.376 179.591 156.139 179.984C155.902 180.378 155.575 180.711 155.185 180.955L150.354 183.686C149.617 184.109 148.989 183.782 148.989 182.948V177.39C149.023 176.93 149.163 176.483 149.4 176.087C149.637 175.691 149.964 175.356 150.354 175.109L155.24 172.323Z" fill="#C1C5F4" />
    <path d="M158.774 167.038L159.307 167.939C158.174 169.344 157.208 170.874 156.427 172.501C156.154 173.061 155.909 173.593 155.677 174.112C155.067 175.498 154.534 176.916 154.08 178.36L153.807 179.275L150.968 178.756L151.623 177.253L153.288 177.554C153.588 176.666 154.107 175.205 154.844 173.566C155.049 173.129 155.267 172.665 155.499 172.2C156.382 170.355 157.482 168.622 158.774 167.038Z" fill="url(#paint122_linear_83_4961)" />
    <path d="M155.185 156.222L155.444 156.112C155.212 156.577 155.008 157.027 154.803 157.478L150.368 160.032C150.243 160.113 150.139 160.222 150.063 160.35C149.988 160.478 149.942 160.621 149.931 160.769V166.341C149.931 166.601 150.122 166.724 150.354 166.587L155.185 163.856C155.31 163.772 155.414 163.661 155.49 163.53C155.565 163.4 155.61 163.255 155.622 163.105V158.079C155.84 157.56 156.1 157.014 156.373 156.468C156.459 156.641 156.501 156.834 156.495 157.027V162.586C156.463 163.047 156.323 163.493 156.086 163.89C155.849 164.286 155.521 164.621 155.131 164.867L150.313 167.598C149.576 168.021 148.948 167.68 148.948 166.847V161.288C148.978 160.827 149.118 160.38 149.355 159.983C149.592 159.586 149.921 159.252 150.313 159.008L155.185 156.222Z" fill="#C1C5F4" />
    <path d="M114.557 198.517C115.703 197.848 116.658 198.381 116.658 199.719C116.618 200.444 116.408 201.149 116.046 201.778C115.683 202.406 115.178 202.941 114.57 203.338C113.397 204.007 112.469 203.475 112.455 202.136C112.493 201.41 112.704 200.702 113.069 200.073C113.435 199.444 113.945 198.911 114.557 198.517Z" fill="#C1C5F4" />
    <path d="M144.636 181.856L144.649 184.423L119.675 198.859L119.661 196.291L144.636 181.856Z" fill="#C1C5F4" />
    <path d="M114.598 213.881C115.744 213.212 116.699 213.745 116.699 215.083C116.659 215.81 116.449 216.517 116.087 217.148C115.724 217.779 115.219 218.316 114.611 218.716C113.438 219.385 112.51 218.839 112.496 217.514C112.534 216.786 112.745 216.076 113.11 215.445C113.476 214.813 113.985 214.278 114.598 213.881Z" fill="#C1C5F4" />
    <path d="M147.092 223.209C148.757 222.239 150.108 223.018 150.122 224.93C150.065 225.97 149.763 226.982 149.241 227.884C148.72 228.786 147.993 229.552 147.12 230.119L124.765 243.066C123.1 244.036 121.749 243.257 121.749 241.359C121.804 240.316 122.105 239.301 122.627 238.397C123.148 237.493 123.876 236.725 124.751 236.156L147.092 223.209Z" fill="url(#paint123_linear_83_4961)" />
    <path d="M144.731 212.598V215.165L119.756 229.601L119.743 227.019L144.731 212.598Z" fill="#C1C5F4" />
    <path d="M144.69 197.22V199.787L119.715 214.223L119.702 211.655L144.69 197.22Z" fill="#C1C5F4" />
    <path d="M155.281 187.687L155.54 187.578C155.308 188.029 155.09 188.493 154.899 188.944L150.463 191.511C150.335 191.592 150.229 191.703 150.153 191.833C150.077 191.964 150.034 192.111 150.026 192.262V197.753C150.026 198.026 150.218 198.135 150.463 197.998L155.294 195.267C155.418 195.183 155.521 195.071 155.594 194.941C155.667 194.811 155.71 194.665 155.717 194.516V189.558C155.949 189.026 156.195 188.493 156.468 187.947C156.559 188.119 156.606 188.312 156.605 188.507V194.065C156.572 194.526 156.432 194.973 156.195 195.369C155.958 195.765 155.63 196.1 155.24 196.346L150.409 199.077C149.685 199.501 149.044 199.159 149.044 198.312V192.768C149.074 192.306 149.213 191.859 149.451 191.462C149.688 191.066 150.016 190.731 150.409 190.487L155.281 187.687Z" fill="#C1C5F4" />
    <path d="M158.884 182.416L159.43 183.303C158.285 184.707 157.314 186.243 156.536 187.879C156.263 188.425 156.018 188.957 155.786 189.49C155.175 190.869 154.647 192.283 154.203 193.724L153.93 194.639L151.091 194.133L151.732 192.631L153.397 192.918C153.698 192.03 154.23 190.569 154.967 188.93C155.158 188.493 155.376 188.029 155.608 187.564C156.486 185.721 157.586 183.992 158.884 182.416Z" fill="url(#paint124_linear_83_4961)" />
    <path d="M114.639 229.245C115.799 228.576 116.74 229.123 116.74 230.447C116.706 231.175 116.498 231.884 116.135 232.516C115.772 233.148 115.264 233.684 114.652 234.08C113.479 234.749 112.551 234.217 112.551 232.878C112.582 232.149 112.788 231.439 113.151 230.807C113.515 230.175 114.025 229.639 114.639 229.245Z" fill="#C1C5F4" />
    <path d="M155.322 203.051C156.045 202.642 156.686 203.051 156.686 203.803V209.361C156.654 209.822 156.514 210.268 156.277 210.665C156.04 211.061 155.712 211.396 155.322 211.642L150.49 214.373C149.767 214.796 149.126 214.455 149.126 213.622V208.064C149.156 207.602 149.295 207.155 149.533 206.758C149.77 206.361 150.098 206.027 150.49 205.783L155.322 203.051ZM155.772 209.88V204.308C155.772 204.048 155.581 203.939 155.335 204.076L150.518 206.807C150.393 206.888 150.289 206.997 150.214 207.125C150.138 207.253 150.092 207.396 150.081 207.545V213.103C150.081 213.376 150.272 213.499 150.518 213.362L155.349 210.631C155.474 210.549 155.578 210.437 155.651 210.307C155.725 210.176 155.766 210.03 155.772 209.88Z" fill="#C1C5F4" />
    <g opacity="0.35">
      <path d="M174.51 134.821C175.875 134.07 176.857 134.671 176.871 136.187C176.834 137.008 176.601 137.807 176.193 138.52C175.785 139.233 175.212 139.837 174.524 140.284C173.241 141.035 172.176 140.434 172.176 138.918C172.213 138.099 172.444 137.301 172.85 136.588C173.255 135.876 173.825 135.27 174.51 134.821Z" fill="#C0C0ED" />
      <path d="M183.449 129.686C184.746 128.949 185.796 129.55 185.796 131.052C185.766 131.873 185.538 132.674 185.132 133.387C184.725 134.101 184.153 134.705 183.463 135.149C182.166 135.9 181.129 135.299 181.115 133.783C181.152 132.964 181.383 132.166 181.789 131.454C182.195 130.741 182.764 130.136 183.449 129.686Z" fill="#C0C0ED" />
      <path d="M192.074 124.674C193.439 123.923 194.408 124.538 194.422 126.04C194.387 126.859 194.157 127.659 193.751 128.372C193.345 129.084 192.775 129.689 192.088 130.137C190.723 130.888 189.741 130.287 189.741 128.771C189.774 127.951 190.004 127.152 190.41 126.439C190.816 125.726 191.386 125.121 192.074 124.674Z" fill="#C0C0ED" />
      <path d="M200.959 119.539C202.255 118.802 203.306 119.403 203.306 120.905C203.263 121.713 203.029 122.5 202.623 123.2C202.218 123.9 201.652 124.494 200.972 124.934C199.608 125.685 198.625 125.084 198.625 123.568C198.669 122.76 198.904 121.974 199.309 121.274C199.715 120.574 200.28 119.979 200.959 119.539Z" fill="#C0C0ED" />
      <path d="M207.496 106.715C208.792 105.964 209.857 106.579 209.857 108.081V126.081C209.815 126.9 209.581 127.699 209.173 128.41C208.765 129.122 208.195 129.728 207.51 130.178L169.051 152.398C167.686 153.149 166.69 152.548 166.676 151.032V133.019C166.714 132.198 166.946 131.398 167.354 130.686C167.763 129.973 168.335 129.368 169.024 128.922L207.496 106.715ZM174.524 140.202C175.212 139.755 175.785 139.151 176.193 138.438C176.601 137.725 176.834 136.926 176.871 136.105C176.871 134.616 175.806 134.016 174.51 134.739C173.824 135.189 173.255 135.794 172.85 136.507C172.444 137.219 172.213 138.017 172.176 138.836C172.176 140.325 173.241 140.926 174.524 140.202ZM200.972 124.92C201.652 124.481 202.218 123.887 202.623 123.186C203.029 122.486 203.263 121.7 203.306 120.891C203.306 119.403 202.255 118.802 200.959 119.526C200.271 119.972 199.7 120.577 199.294 121.29C198.888 122.003 198.659 122.803 198.625 123.623C198.625 125.098 199.676 125.699 200.972 124.988M183.449 135.067C184.139 134.623 184.712 134.019 185.118 133.305C185.525 132.592 185.753 131.791 185.783 130.97C185.783 129.495 184.732 128.894 183.435 129.604C182.75 130.054 182.181 130.659 181.775 131.372C181.369 132.084 181.139 132.882 181.102 133.701C181.102 135.19 182.153 135.791 183.449 135.067ZM192.129 130.055C192.816 129.608 193.386 129.002 193.792 128.29C194.198 127.577 194.428 126.778 194.463 125.958C194.463 124.469 193.412 123.855 192.115 124.592C191.427 125.039 190.857 125.644 190.451 126.357C190.044 127.07 189.815 127.87 189.781 128.689C189.781 130.164 190.832 130.765 192.129 130.055Z" fill="#C0C0ED" />
    </g>
    <path d="M178.604 140.284C179.969 139.533 180.952 140.134 180.965 141.65C180.928 142.47 180.695 143.27 180.287 143.983C179.879 144.695 179.307 145.3 178.618 145.747C177.335 146.498 176.271 145.897 176.271 144.381C176.307 143.562 176.538 142.764 176.944 142.051C177.35 141.339 177.919 140.733 178.604 140.284Z" fill="#C1C5F4" />
    <path d="M187.543 135.149C188.84 134.412 189.891 135.012 189.891 136.515C189.86 137.335 189.632 138.136 189.226 138.85C188.82 139.563 188.247 140.168 187.557 140.612C186.26 141.363 185.223 140.762 185.21 139.246C185.247 138.427 185.477 137.628 185.883 136.916C186.289 136.204 186.858 135.598 187.543 135.149Z" fill="#C1C5F4" />
    <path d="M196.168 130.137C197.533 129.386 198.502 130 198.516 131.503C198.481 132.322 198.251 133.122 197.845 133.834C197.439 134.547 196.869 135.152 196.182 135.6C194.817 136.351 193.835 135.75 193.835 134.234C193.868 133.414 194.098 132.614 194.504 131.902C194.91 131.189 195.481 130.584 196.168 130.137Z" fill="#C1C5F4" />
    <path d="M205.053 125.002C206.349 124.265 207.4 124.866 207.4 126.368C207.357 127.176 207.123 127.963 206.718 128.663C206.312 129.363 205.746 129.957 205.067 130.397C203.702 131.148 202.719 130.547 202.719 129.031C202.763 128.223 202.998 127.437 203.403 126.737C203.809 126.036 204.374 125.442 205.053 125.002Z" fill="#C1C5F4" />
    <path d="M211.59 112.178C212.887 111.427 213.951 112.042 213.951 113.544V131.544C213.909 132.363 213.675 133.161 213.267 133.873C212.859 134.585 212.29 135.191 211.604 135.641L173.145 157.861C171.781 158.612 170.784 158.011 170.771 156.495V138.481C170.808 137.661 171.04 136.861 171.449 136.148C171.857 135.436 172.429 134.831 173.118 134.384L211.59 112.178ZM178.618 145.665C179.307 145.218 179.879 144.613 180.287 143.901C180.696 143.188 180.928 142.388 180.965 141.568C180.965 140.079 179.901 139.478 178.604 140.202C177.919 140.651 177.35 141.257 176.944 141.969C176.538 142.682 176.307 143.48 176.271 144.299C176.271 145.788 177.335 146.389 178.618 145.665ZM205.067 130.383C205.746 129.944 206.312 129.35 206.718 128.649C207.123 127.949 207.357 127.162 207.4 126.354C207.4 124.865 206.35 124.265 205.053 124.988C204.365 125.435 203.794 126.04 203.388 126.753C202.982 127.466 202.753 128.266 202.719 129.085C202.719 130.56 203.77 131.161 205.067 130.451M187.543 140.53C188.234 140.086 188.806 139.481 189.213 138.768C189.619 138.055 189.847 137.253 189.877 136.433C189.877 134.958 188.826 134.357 187.53 135.067C186.844 135.516 186.275 136.122 185.869 136.834C185.464 137.547 185.233 138.345 185.196 139.164C185.196 140.653 186.247 141.254 187.543 140.53ZM196.223 135.518C196.91 135.07 197.48 134.465 197.886 133.752C198.292 133.04 198.522 132.24 198.557 131.421C198.557 129.932 197.506 129.318 196.209 130.055C195.522 130.502 194.951 131.107 194.545 131.82C194.139 132.533 193.909 133.332 193.876 134.152C193.876 135.627 194.927 136.228 196.223 135.518Z" fill="#EDF3FF" />
    <path d="M445.539 178.774V232.427L469.126 260.894L493.9 233.445V176.074L445.539 178.774Z" fill="url(#paint125_linear_83_4961)" />
    <path d="M469.861 169.728L499.733 152.357L499.634 186.929L469.762 204.286L469.861 169.728Z" fill="url(#paint126_linear_83_4961)" />
    <path d="M439.805 152.357L469.663 135L499.733 152.357L469.861 169.728L439.805 152.357Z" fill="url(#paint127_linear_83_4961)" />
    <path d="M469.861 169.728L469.762 204.286L439.706 186.915L439.805 152.357L469.861 169.728Z" fill="url(#paint128_linear_83_4961)" />
    <path d="M488.509 400.129V443.188L507.438 466.033L527.319 444.004V397.963L488.509 400.129Z" fill="url(#paint129_linear_83_4961)" />
    <path d="M508.027 392.87L532 378.929L531.921 406.674L507.948 420.604L508.027 392.87Z" fill="url(#paint130_linear_83_4961)" />
    <path d="M483.906 378.929L507.868 365L532 378.929L508.027 392.87L483.906 378.929Z" fill="url(#paint131_linear_83_4961)" />
    <path d="M508.027 392.87L507.948 420.604L483.827 406.663L483.907 378.929L508.027 392.87Z" fill="url(#paint132_linear_83_4961)" />
    <path d="M4.50854 234.129V277.188L23.4376 300.033L43.3188 278.004V231.963L4.50854 234.129Z" fill="url(#paint133_linear_83_4961)" />
    <path d="M24.0269 226.87L48 212.929L47.9206 240.674L23.9476 254.604L24.0269 226.87Z" fill="url(#paint134_linear_83_4961)" />
    <path d="M-0.0936279 212.929L23.8681 199L47.9998 212.929L24.0267 226.87L-0.0936279 212.929Z" fill="url(#paint135_linear_83_4961)" />
    <path d="M24.0269 226.87L23.9476 254.604L-0.172729 240.663L-0.0934125 212.929L24.0269 226.87Z" fill="url(#paint136_linear_83_4961)" />
  </g>
  <defs>
    <filter id="filter0_d_83_4961" x="100.001" y="27.9241" width="109.974" height="131.078" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset dy="4" />
      <feGaussianBlur stdDeviation="2" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_83_4961" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_83_4961" result="shape" />
    </filter>
    <linearGradient id="paint0_linear_83_4961" x1="455.77" y1="446.76" x2="-292.385" y2="416.62" gradientUnits="userSpaceOnUse">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" />
    </linearGradient>
    <linearGradient id="paint1_linear_83_4961" x1="346.7" y1="495.296" x2="174.055" y2="311.088" gradientUnits="userSpaceOnUse">
      <stop stop-color="#E8EDFE" />
      <stop offset="1" stop-color="#EEF4FF" />
    </linearGradient>
    <radialGradient id="paint2_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.357) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint3_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.356) scale(476.733 477.065)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint4_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.357) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint5_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.357) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint6_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.357) scale(476.733 477.065)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint7_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.357) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint8_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.357) scale(476.733 477.065)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint9_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.357) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint10_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.357) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint11_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.357) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint12_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.356) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint13_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.356) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint14_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.356) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint15_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.357) scale(476.733 477.065)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint16_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.357) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint17_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.356) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint18_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.356) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint19_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.357) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint20_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.357) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint21_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.357) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint22_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.357) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint23_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.357) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint24_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.357) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint25_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.356) scale(476.733 477.065)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint26_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.356) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint27_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.356) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint28_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.357) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint29_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.356) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint30_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.356) scale(476.733 477.065)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint31_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.357) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint32_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.357) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint33_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.357) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint34_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.357) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint35_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.357) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint36_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.356) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint37_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.49 372.357) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint38_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.357) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint39_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.357) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint40_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.356) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint41_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.49 372.357) scale(476.733 477.065)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint42_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.357) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint43_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.357) scale(476.733 477.065)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint44_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.357) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint45_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.356) scale(476.733 477.065)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint46_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.357) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint47_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.357) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint48_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.357) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint49_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.49 372.357) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint50_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.37) scale(476.732 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint51_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.37) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint52_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.37) scale(476.732 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint53_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.49 372.37) scale(476.734 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint54_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.37) scale(476.732 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint55_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.37) scale(476.732 477.065)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint56_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.37) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint57_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.37) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint58_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(161.367 276.485) scale(476.733 477.063)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint59_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.49 372.37) scale(476.733 477.063)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint60_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.37) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint61_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.49 372.37) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint62_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.37) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint63_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.49 372.37) scale(476.733 477.065)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint64_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.37) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint65_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.37) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint66_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.37) scale(476.733 477.065)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint67_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.37) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint68_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.37) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint69_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(161.367 276.484) scale(476.733 477.066)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint70_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(161.368 276.484) scale(476.732 477.066)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint71_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.37) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint72_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.37) scale(476.733 477.063)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint73_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(161.368 276.485) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint74_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.459 372.355) scale(476.733 477.065)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint75_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.37) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint76_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.37) scale(476.733 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint77_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(161.367 276.484) scale(476.733 477.066)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint78_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(161.367 276.485) scale(476.733 477.063)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint79_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.37) scale(476.733 477.063)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint80_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.49 372.37) scale(476.733 477.065)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint81_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.49 372.37) scale(476.732 477.064)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint82_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.37) scale(476.733 477.065)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint83_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.37) scale(476.733 477.066)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint84_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.37) scale(476.732 477.066)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="paint85_radial_83_4961" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(234.491 372.37) scale(476.733 477.063)">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </radialGradient>
    <linearGradient id="paint86_linear_83_4961" x1="318.75" y1="177.581" x2="309.134" y2="166.389" gradientUnits="userSpaceOnUse">
      <stop stop-color="#FF8587" />
      <stop offset="1" stop-color="#FFAEAE" />
    </linearGradient>
    <linearGradient id="paint87_linear_83_4961" x1="214.142" y1="223.824" x2="226.152" y2="223.824" gradientUnits="userSpaceOnUse">
      <stop stop-color="#D5E6F9" />
      <stop offset="1" stop-color="#EEEFFF" />
    </linearGradient>
    <linearGradient id="paint88_linear_83_4961" x1="229.455" y1="203.024" x2="185.011" y2="170.919" gradientUnits="userSpaceOnUse">
      <stop stop-color="#2E3BBB" />
      <stop offset="1" stop-color="#7258D8" />
    </linearGradient>
    <linearGradient id="paint89_linear_83_4961" x1="242.638" y1="380.305" x2="282.27" y2="380.305" gradientUnits="userSpaceOnUse">
      <stop stop-color="#2E3BBB" />
      <stop offset="1" stop-color="#7258D8" />
    </linearGradient>
    <linearGradient id="paint90_linear_83_4961" x1="228.308" y1="376.126" x2="224.536" y2="296.165" gradientUnits="userSpaceOnUse">
      <stop stop-color="#2E3BBB" />
      <stop offset="1" stop-color="#7258D8" />
    </linearGradient>
    <linearGradient id="paint91_linear_83_4961" x1="255.289" y1="294.963" x2="191.402" y2="308.41" gradientUnits="userSpaceOnUse">
      <stop stop-color="#2790F9" />
      <stop offset="1" stop-color="#31B0F9" />
    </linearGradient>
    <linearGradient id="paint92_linear_83_4961" x1="230.751" y1="315.27" x2="270.711" y2="315.27" gradientUnits="userSpaceOnUse">
      <stop stop-color="#27A7F9" />
      <stop offset="1" stop-color="#4FCCF9" />
    </linearGradient>
    <linearGradient id="paint93_linear_83_4961" x1="225.51" y1="245.634" x2="246.869" y2="245.634" gradientUnits="userSpaceOnUse">
      <stop stop-color="#D5E6F9" />
      <stop offset="1" stop-color="#EEEFFF" />
    </linearGradient>
    <linearGradient id="paint94_linear_83_4961" x1="250.581" y1="261.23" x2="234.742" y2="172.641" gradientUnits="userSpaceOnUse">
      <stop stop-color="#2E3BBB" />
      <stop offset="1" stop-color="#7258D8" />
    </linearGradient>
    <linearGradient id="paint95_linear_83_4961" x1="230.574" y1="147.632" x2="262.386" y2="147.632" gradientUnits="userSpaceOnUse">
      <stop stop-color="#FF8587" />
      <stop offset="1" stop-color="#FFAEAE" />
    </linearGradient>
    <linearGradient id="paint96_linear_83_4961" x1="251.577" y1="171.504" x2="234.537" y2="158.937" gradientUnits="userSpaceOnUse">
      <stop stop-color="#D5E6F9" />
      <stop offset="1" stop-color="#EEEFFF" />
    </linearGradient>
    <linearGradient id="paint97_linear_83_4961" x1="304.775" y1="181.829" x2="315.038" y2="181.829" gradientUnits="userSpaceOnUse">
      <stop stop-color="#D5E6F9" />
      <stop offset="1" stop-color="#EEEFFF" />
    </linearGradient>
    <linearGradient id="paint98_linear_83_4961" x1="295.809" y1="253.145" x2="279.984" y2="164.556" gradientUnits="userSpaceOnUse">
      <stop stop-color="#2E3BBB" />
      <stop offset="1" stop-color="#7258D8" />
    </linearGradient>
    <linearGradient id="paint99_linear_83_4961" x1="311.667" y1="185.953" x2="313.1" y2="185.953" gradientUnits="userSpaceOnUse">
      <stop stop-color="#D5E6F9" />
      <stop offset="1" stop-color="#EEEFFF" />
    </linearGradient>
    <linearGradient id="paint100_linear_83_4961" x1="310.944" y1="188.466" x2="312.39" y2="188.466" gradientUnits="userSpaceOnUse">
      <stop stop-color="#D5E6F9" />
      <stop offset="1" stop-color="#EEEFFF" />
    </linearGradient>
    <linearGradient id="paint101_linear_83_4961" x1="459.21" y1="117.122" x2="421.549" y2="-9.76409" gradientUnits="userSpaceOnUse">
      <stop stop-color="#2E3BBB" />
      <stop offset="1" stop-color="#7258D8" />
    </linearGradient>
    <linearGradient id="paint102_linear_83_4961" x1="478.93" y1="111.263" x2="441.27" y2="-15.6093" gradientUnits="userSpaceOnUse">
      <stop stop-color="#2E3BBB" />
      <stop offset="1" stop-color="#7258D8" />
    </linearGradient>
    <linearGradient id="paint103_linear_83_4961" x1="402.327" y1="55.0648" x2="471.315" y2="55.0648" gradientUnits="userSpaceOnUse">
      <stop stop-color="#E8EDFE" />
      <stop offset="1" stop-color="#EEF4FF" />
    </linearGradient>
    <linearGradient id="paint104_linear_83_4961" x1="423.658" y1="51.5003" x2="450.489" y2="51.5003" gradientUnits="userSpaceOnUse">
      <stop stop-color="#27A7F9" />
      <stop offset="1" stop-color="#4FCCF9" />
    </linearGradient>
    <linearGradient id="paint105_linear_83_4961" x1="315.12" y1="115.592" x2="315.366" y2="42.8145" gradientUnits="userSpaceOnUse">
      <stop stop-color="#E8EDFE" />
      <stop offset="1" stop-color="#EEF4FF" />
    </linearGradient>
    <linearGradient id="paint106_linear_83_4961" x1="282.898" y1="43.033" x2="302.687" y2="43.033" gradientUnits="userSpaceOnUse">
      <stop stop-color="#27A7F9" />
      <stop offset="1" stop-color="#4FCCF9" />
    </linearGradient>
    <linearGradient id="paint107_linear_83_4961" x1="323.322" y1="60.7871" x2="336.082" y2="60.7871" gradientUnits="userSpaceOnUse">
      <stop stop-color="#AB58D8" />
      <stop offset="1" stop-color="#FF86D8" />
    </linearGradient>
    <linearGradient id="paint108_linear_83_4961" x1="377.939" y1="117.914" x2="404.347" y2="117.914" gradientUnits="userSpaceOnUse">
      <stop stop-color="#E8EDFE" />
      <stop offset="1" stop-color="#EEF4FF" />
    </linearGradient>
    <linearGradient id="paint109_linear_83_4961" x1="382.948" y1="143.248" x2="399.202" y2="143.248" gradientUnits="userSpaceOnUse">
      <stop stop-color="#AB58D8" />
      <stop offset="1" stop-color="#FF86D8" />
    </linearGradient>
    <linearGradient id="paint110_linear_83_4961" x1="383.016" y1="117.586" x2="399.27" y2="117.586" gradientUnits="userSpaceOnUse">
      <stop stop-color="#27A7F9" />
      <stop offset="1" stop-color="#4FCCF9" />
    </linearGradient>
    <linearGradient id="paint111_linear_83_4961" x1="383.084" y1="91.9249" x2="399.352" y2="91.9249" gradientUnits="userSpaceOnUse">
      <stop stop-color="#17D6A6" />
      <stop offset="1" stop-color="#17ECC7" />
    </linearGradient>
    <linearGradient id="paint112_linear_83_4961" x1="306.344" y1="173.839" x2="305.702" y2="129.058" gradientUnits="userSpaceOnUse">
      <stop stop-color="#E8EDFE" />
      <stop offset="1" stop-color="#EEF4FF" />
    </linearGradient>
    <linearGradient id="paint113_linear_83_4961" x1="353.633" y1="183.563" x2="405.616" y2="183.563" gradientUnits="userSpaceOnUse">
      <stop stop-color="#17D6A6" />
      <stop offset="1" stop-color="#17ECC7" />
    </linearGradient>
    <linearGradient id="paint114_linear_83_4961" x1="108.095" y1="94.9249" x2="210.069" y2="94.9249" gradientUnits="userSpaceOnUse">
      <stop stop-color="#E8EDFE" />
      <stop offset="1" stop-color="#EEF4FF" />
    </linearGradient>
    <linearGradient id="paint115_linear_83_4961" x1="123.872" y1="142.396" x2="123.093" y2="111.696" gradientUnits="userSpaceOnUse">
      <stop stop-color="#AB58D8" />
      <stop offset="1" stop-color="#FF86D8" />
    </linearGradient>
    <linearGradient id="paint116_linear_83_4961" x1="139.921" y1="131.17" x2="138.486" y2="92.2755" gradientUnits="userSpaceOnUse">
      <stop stop-color="#27A7F9" />
      <stop offset="1" stop-color="#4FCCF9" />
    </linearGradient>
    <linearGradient id="paint117_linear_83_4961" x1="159.096" y1="119.043" x2="154.928" y2="72.7465" gradientUnits="userSpaceOnUse">
      <stop stop-color="#AB58D8" />
      <stop offset="1" stop-color="#FF86D8" />
    </linearGradient>
    <linearGradient id="paint118_linear_83_4961" x1="174.859" y1="108.759" x2="175.118" y2="70.7795" gradientUnits="userSpaceOnUse">
      <stop stop-color="#27A7F9" />
      <stop offset="1" stop-color="#4FCCF9" />
    </linearGradient>
    <linearGradient id="paint119_linear_83_4961" x1="194.252" y1="98.2298" x2="194.511" y2="50.7583" gradientUnits="userSpaceOnUse">
      <stop stop-color="#AB58D8" />
      <stop offset="1" stop-color="#FF86D8" />
    </linearGradient>
    <linearGradient id="paint120_linear_83_4961" x1="106.095" y1="200.252" x2="161.531" y2="200.252" gradientUnits="userSpaceOnUse">
      <stop stop-color="#E8EDFE" />
      <stop offset="1" stop-color="#EEF4FF" />
    </linearGradient>
    <linearGradient id="paint121_linear_83_4961" x1="158.488" y1="193.246" x2="154.648" y2="152.59" gradientUnits="userSpaceOnUse">
      <stop stop-color="#17D6A6" />
      <stop offset="1" stop-color="#17ECC7" />
    </linearGradient>
    <linearGradient id="paint122_linear_83_4961" x1="157.028" y1="193.396" x2="153.201" y2="152.726" gradientUnits="userSpaceOnUse">
      <stop stop-color="#17D6A6" />
      <stop offset="1" stop-color="#17ECC7" />
    </linearGradient>
    <linearGradient id="paint123_linear_83_4961" x1="136.338" y1="253.65" x2="135.791" y2="227.784" gradientUnits="userSpaceOnUse">
      <stop stop-color="#2E3BBB" />
      <stop offset="1" stop-color="#7258D8" />
    </linearGradient>
    <linearGradient id="paint124_linear_83_4961" x1="155.636" y1="193.519" x2="151.809" y2="152.863" gradientUnits="userSpaceOnUse">
      <stop stop-color="#17D6A6" />
      <stop offset="1" stop-color="#17ECC7" />
    </linearGradient>
    <linearGradient id="paint125_linear_83_4961" x1="469.833" y1="180.258" x2="469.592" y2="233.657" gradientUnits="userSpaceOnUse">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </linearGradient>
    <linearGradient id="paint126_linear_83_4961" x1="473.364" y1="167.17" x2="522.07" y2="214.823" gradientUnits="userSpaceOnUse">
      <stop stop-color="#C1C5F4" />
      <stop offset="1" stop-color="#A5AAE3" />
    </linearGradient>
    <linearGradient id="paint127_linear_83_4961" x1="447.006" y1="144.906" x2="466.95" y2="203.248" gradientUnits="userSpaceOnUse">
      <stop stop-color="#C1C5F4" />
      <stop offset="1" stop-color="#A5AAE3" />
    </linearGradient>
    <linearGradient id="paint128_linear_83_4961" x1="475.058" y1="226.477" x2="449.858" y2="166.716" gradientUnits="userSpaceOnUse">
      <stop stop-color="#CFD0F5" />
      <stop offset="1" stop-color="#EEF4FF" />
    </linearGradient>
    <linearGradient id="paint129_linear_83_4961" x1="508.004" y1="401.32" x2="507.811" y2="444.174" gradientUnits="userSpaceOnUse">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </linearGradient>
    <linearGradient id="paint130_linear_83_4961" x1="510.838" y1="390.817" x2="549.926" y2="429.06" gradientUnits="userSpaceOnUse">
      <stop stop-color="#AB58D8" />
      <stop offset="1" stop-color="#FF86D8" />
    </linearGradient>
    <linearGradient id="paint131_linear_83_4961" x1="535.185" y1="411.79" x2="498.519" y2="367.613" gradientUnits="userSpaceOnUse">
      <stop stop-color="#AB58D8" />
      <stop offset="1" stop-color="#FF86D8" />
    </linearGradient>
    <linearGradient id="paint132_linear_83_4961" x1="512.198" y1="438.412" x2="491.974" y2="390.453" gradientUnits="userSpaceOnUse">
      <stop stop-color="#CFD0F5" />
      <stop offset="1" stop-color="#EEF4FF" />
    </linearGradient>
    <linearGradient id="paint133_linear_83_4961" x1="24.0043" y1="235.32" x2="23.8114" y2="278.174" gradientUnits="userSpaceOnUse">
      <stop stop-color="#A6A6E7" />
      <stop offset="1" stop-color="#EEEFFF" stop-opacity="0" />
    </linearGradient>
    <linearGradient id="paint134_linear_83_4961" x1="49.5929" y1="282.912" x2="10.907" y2="267.319" gradientUnits="userSpaceOnUse">
      <stop stop-color="#053D57" />
      <stop offset="1" stop-color="#0C7CB0" />
    </linearGradient>
    <linearGradient id="paint135_linear_83_4961" x1="51.1848" y1="245.801" x2="14.5192" y2="201.613" gradientUnits="userSpaceOnUse">
      <stop stop-color="#053D57" />
      <stop offset="1" stop-color="#0C7CB0" />
    </linearGradient>
    <linearGradient id="paint136_linear_83_4961" x1="28.1981" y1="272.412" x2="7.97446" y2="224.453" gradientUnits="userSpaceOnUse">
      <stop stop-color="#CFD0F5" />
      <stop offset="1" stop-color="#EEF4FF" />
    </linearGradient>
    <clipPath id="clip0_83_4961">
      <rect width="537" height="543" fill="white" />
    </clipPath>
  </defs>
</svg>

export default LoginSvg

