import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { CustomButton, CustomSelect } from "../Components";
import { useGetCompaniesQuery } from "../store/services/companies";
import { useAppDispatch, useAppSelector } from "../store";
import { createTask } from "../store/slice/createtask";
import { Button } from "@mui/material";
import { useGetTasksTypeQuery } from "../store/services/tasks";
import styles from "./createTask.module.scss";
import "../App.module.scss";
import { ICompanyItem } from "../types";

interface IProps {
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
}
export default function CreateTask({ open, handleOpen, handleClose }: IProps) {
  const { t } = useTranslation();
  const myTaskTypes = useAppSelector((state: any) => state?.auth?.me?.task_types?.map((el: any) => el?.task_type))
  const { data: companies } = useGetCompaniesQuery({
    limit: 100000,
    offset: 0,
  });
  const { data: task_types } = useGetTasksTypeQuery({
    limit: 100000,
    offset: 0,
  });
  const taskData = useAppSelector((state: any) => state.createTask.taskData);
  const dispatch = useAppDispatch();
  const handleSelectCompany = useCallback(
    (data: any) => {
      localStorage.setItem('cmpId', data);
      dispatch(createTask({ ...taskData, company_id: data }));
    },
    [taskData]
  );
  const handleSelectTaskType = useCallback(
    (data: any) => {
      localStorage.setItem('taskTypeId', data);
      const company_id = taskData?.company_id ? taskData?.company_id : (companies?.length === 1 ? companies[0]?.id : '')
      dispatch(createTask({ ...taskData, task_type_id: data, company_id }));
      if (!taskData?.company_id && companies?.length === 1) {
        localStorage.setItem('cmpId', companies[0]?.id);
      }
    },
    [taskData, companies]
  );
  const userRole = localStorage.getItem("rbcRole");
  useEffect(() => {
    dispatch(createTask({ task_type_id: '', company_id: '' }))
  }, [])
  
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={styles["dialog-content"]}>
          <DialogTitle className={styles["heading"]} id="alert-dialog-title">{t("createTasks")}</DialogTitle>
          <DialogContent>
            <div className="d-flex flex-column">
              <CustomSelect
                label={t("companies")}
                list={companies?.filter((el:ICompanyItem)=>el.is_active)}
                value={taskData?.company_id ? taskData?.company_id : (companies?.length === 1 ? companies[0]?.id : '')}
                showValue={true}
                onChange={(val: string) => handleSelectCompany(val)}
              />
              <CustomSelect
                label={t("taskTypes")}
                list={userRole === '4'
                  ? myTaskTypes?.map((el: any) => ({ id: el.id, name: t(`${el.name}`) }))
                  : task_types?.map((el: any) => ({ id: el.id, name: t(`${el.name}`) }))}
                value={taskData?.task_type_id}
                showValue={true}
                onChange={(val: string) => handleSelectTaskType(val)}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <CustomButton onClick={handleClose} title={t("create")} type="button" />
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
}
