import { useEffect, useState } from 'react';
import styles from './Accountants.module.scss';
import { useTranslation } from 'react-i18next';
import { AccountantItem, CustomButtosWithIcon, NotificationMenu, SearchInput } from '../../../Components';
import EmployeeItem from '../../../Components/EmployeeItem';
import { useNavigate } from 'react-router-dom';
import { usePrefetch } from '../../../store/services/managers';
import { useGetAccountantsQuery } from '../../../store/services/accountants';
import { Pagination, Stack } from '@mui/material';

export default function Accountants() {
  const [page, setPage] = useState<number>(1);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data, isLoading } = useGetAccountantsQuery({ limit: 10, page});
  const prefetchPage = usePrefetch('getManagers');
  useEffect(() => {
    prefetchPage({ limit: 10, page }, { force: true })
  }, []);
  return (
    <div className={`w-100`}>
      <div className={`w-100 d-none d-lg-flex ${styles.searchNotification}`}>
        <SearchInput onClick={() => console.log('dskfj')} />
        {/* <NotificationMenu /> */}
      </div>
      <div className='d-flex flex-wrap w-100 justify-content-between'>
        <h1 className={styles.pageHeading}>{t("accountants")}</h1>
      </div>
      <div className={`${styles.employeesArray}`}>
        {data?.results?.map((el: any, ind: number) => <AccountantItem
          // to={`/profile/accountants/${el.id}`}
          is_write={el.is_write}
          id={el.id}
          key={ind}
          img={el.avatar_image}
          cover_image={el?.cover_image}
          name={`${el?.company_name}`}
          profession={`${el?.phone_number}`}
        />)}
      </div>
      {data?.count > 10 && (
          <Stack spacing={2} marginTop={10}>
            <Pagination
              count={Math.ceil(data?.count / 10)}
              page={page}
              showFirstButton
              showLastButton
              onChange={(_, page) => {
                setPage(page);
              }}
            />
          </Stack>
        )}
      {!isLoading && data?.length === 0 && <div className={styles.noDataAvailableCont}>
        <img src={"/noDataAvailable.png"} />
      </div>}
    </div>
  );
}


