import React from 'react';
import { Route } from 'react-router-dom';
import {
  PrivatePage,
  UserProfile,
  Menu,
  Managers,
  Companies,
  AccountantCompanies,
  AddCompany,
  CreateTask,
  CreateVacation,
  ProvisionAllowance,
  Dismissal,
  ChangeSalaryPosition,
  Dashboard,
  TaskPage,
  CompaniesUser,
  AddEmployeesUser,
  AddManagerUser,
  Bonus,
  Invoice,
  EstimateTask,
  LadingBill
} from '../Pages';
import { EditCompany } from '../Pages/User/EditCompany';
import { EditManager } from '../Pages/User/EditManager';
import Accountants from '../Pages/User/Accountants';
import AddApplication from '../Pages/User/AddApplication';
import Applications from '../Pages/User/Applications';
import ActivityPage from '../Pages/ActivityPage';
import NotificationPage from '../Pages/NotificationPage';
import Free from '../Pages/User/CreateTask/Free';

export const UserRoutes = () => {

  return (
    <>
      <Route path='/profile' element={<PrivatePage><Menu><UserProfile /></Menu></PrivatePage>}></Route>
      <Route path='/profile/dashboard/:id' element={<PrivatePage><Menu><TaskPage /></Menu></PrivatePage>}></Route>
      <Route path='/profile/dashboard' element={<PrivatePage><Menu><Dashboard /></Menu></PrivatePage>}></Route>
      <Route path='/profile/managers/:id' element={<PrivatePage><Menu><EditManager /></Menu></PrivatePage>}></Route>
      <Route path='/profile/managers/add-employee' element={<PrivatePage><Menu><AddManagerUser /></Menu></PrivatePage>}></Route>
      <Route path='/profile/managers' element={<PrivatePage><Menu><Managers /></Menu></PrivatePage>}></Route>
      <Route path='/profile/companies/add-companies' element={<PrivatePage><Menu><AddCompany /></Menu></PrivatePage>}></Route>
      <Route path='/profile/companies/create-task' element={<PrivatePage><Menu><CreateTask /></Menu></PrivatePage>}></Route>
      <Route path='/profile/companies/create-vacation' element={<PrivatePage><Menu><CreateVacation /></Menu></PrivatePage>}></Route>
      <Route path='/profile/companies/free' element={<PrivatePage><Menu><Free /></Menu></PrivatePage>}></Route>
      <Route path='/profile/companies/create-provision-allowance' element={<PrivatePage><Menu><ProvisionAllowance /></Menu></PrivatePage>}></Route>
      <Route path='/profile/companies/create-dismissal' element={<PrivatePage><Menu><Dismissal /></Menu></PrivatePage>}></Route>
      <Route path='/profile/companies/create-bonus' element={<PrivatePage><Menu><Bonus /></Menu></PrivatePage>}></Route>
      <Route path='/profile/companies/create-invoice' element={<PrivatePage><Menu><Invoice /></Menu></PrivatePage>}></Route>
      <Route path='/profile/companies/estimate-task' element={<PrivatePage><Menu><EstimateTask /></Menu></PrivatePage>}></Route>
      <Route path='/profile/companies/landing-bill' element={<PrivatePage><Menu><LadingBill /></Menu></PrivatePage>}></Route>
      <Route path='/profile/companies/change-salary-position' element={<PrivatePage><Menu><ChangeSalaryPosition /></Menu></PrivatePage>}></Route>
      <Route path='/profile/accountant-companies' element={<PrivatePage><Menu><AccountantCompanies /></Menu></PrivatePage>}></Route>
      <Route path='/profile/companies/:id' element={<PrivatePage><Menu><EditCompany /></Menu></PrivatePage>}></Route>
      <Route path='/profile/companies' element={<PrivatePage><Menu><CompaniesUser /></Menu></PrivatePage>}></Route>
      <Route path='/profile/accountants' element={<PrivatePage><Menu><Accountants /></Menu></PrivatePage>}></Route>
      <Route path='/profile/accountants/:id' element={<PrivatePage><Menu><AddApplication /></Menu></PrivatePage>}></Route>
      <Route path='/profile/applications' element={<PrivatePage><Menu><Applications /></Menu></PrivatePage>}></Route>
      <Route path='/profile/activity/:id' element={<PrivatePage><Menu><ActivityPage /></Menu></PrivatePage>}></Route>
      <Route path='/profile/notification' element={<PrivatePage><Menu><NotificationPage /></Menu></PrivatePage>}></Route>
      {/* <Route path='/profile/companies/task-page' element={<PrivatePage><Menu><TaskPage /></Menu></PrivatePage>}></Route> */}
    </>
  )
}