import { useTranslation } from 'react-i18next';
import { ITaskTypes } from '../../types';
import CustomButton from '../CustomButton';
import style from './TarifItem.module.scss'

interface Props {
    title: string,
    type: string,
    array: ITaskTypes[],
    active?: boolean,
    price?: string,
    onClick?: () => void,
};
const TarifItem = ({ title, onClick, type, array, active, price }: Props) => {
    const { t } = useTranslation();
    return (<div className={`${style.tarifItem} ${style[type]} ${active ? style[`acive${type}`] : ''}`}>
        <span className={style.title}>{title}</span>
        <div className={style.priceMonth}>
            <div className={style.price}>{price} ֏</div>
            <div className={style.month}> /{t('month')}</div>
        </div>
        <div className={style.typesCont}>
            {array?.map((el: ITaskTypes, ind: number) => {
                if (ind < 5) return
                if (type === 'basic' && ind === array.length - 1) return
                if (ind === 5) return (<div key={ind} className={style.typesItem}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="12" viewBox="0 0 17 12" fill="none">
                        <path d="M14.7628 1.64844L5.97563 10.4356L1.98145 6.44146" stroke="white" stroke-width="2.55628" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>{t('workWithEmployee')}</span>
                </div>)
                return <div key={ind} className={style.typesItem}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="12" viewBox="0 0 17 12" fill="none">
                        <path d="M14.7628 1.64844L5.97563 10.4356L1.98145 6.44146" stroke="white" stroke-width="2.55628" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>{t(`${el.name}`)}</span>
                </div>
            })}
        </div>
    </div>)
}
export default TarifItem;