import style from './Footer.module.scss';
export default function Footer() {
    return (
        <div className={`${style.container} d-flex flex-wrap`}>
            <div className='container d-flex flex-column flex-lg-row justify-content-between'>
            <div>
                <div className={style.description}>Send and receive money easily, pay bills, create QR Codes to receive payments, and also reward other users.</div>
                <div>
                    <h3 className={style.footerHeading}>Download</h3>
                    <div className='d-flex'>
                        <img src='/googleplay.png' />
                        <img src='/appstore.png' />
                    </div>
                </div>
            </div>
            <div className=''>
                <div>
                    <h3 className={style.footerHeading}>Quick Links</h3>
                    <div className={`d-flex ${style.linkCont}`}>
                        <a className={style.link}>Feature </a>
                        <a className={style.link}>FAQs </a>
                        <a className={style.link}>Partner </a>
                    </div>
                </div>
                {/* <div className={style.socials}>
                    <h3 className={style.footerHeading}>Socials</h3>
                    <div className={`d-flex ${style.socialCont}`}>
                        <img className={style.socialIcon} src='/fb.png'/>
                        <img className={style.socialIcon} src='/inst.png'/>
                    </div>
                </div> */}
            </div>
            </div>
        </div>
    )
}