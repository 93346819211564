import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import styleProfile from './EditClient.module.scss'
import * as Yup from "yup";
import { Form, Formik, FormikHelpers } from 'formik';
import { CustomButtosWithIcon, CustomInput, ProfileFileUpload } from '../../../Components';
import { useAddImagesMutation } from '../../../store/services/photo';
import EditUser from '../../../Components/SvgCompoents/EditUser';
import { useAppSelector } from '../../../store';
import ResetPassword from '../../ResetPassword';
import { useNavigate, useParams } from 'react-router-dom';
import Company from '../../../Components/SvgCompoents/Company';
import Employees from '../../../Components/SvgCompoents/Employees';
import { useEditManagerMutation, useGetManagerQuery } from '../../../store/services/managers';
import { IAuth } from '../../../types';

export default function EditClient() {
  const params = useParams<Record<string, string | undefined>>();
  const navigate = useNavigate();
  const [disable, setDisable] = useState(true);
  const [openResetPassword, setOpenResetPassword] = useState(false);
  const [editManagerRequest] = useEditManagerMutation({});
  const [addImagesRequest] = useAddImagesMutation();
  const { t } = useTranslation();
  const { data: clientData } = useGetManagerQuery({ id: params?.id?+params?.id:'' });

  const auth: IAuth = useAppSelector((state) => state.auth);

  const SignupSchema = Yup.object().shape({
    email: Yup.string().email(t('emailError'))
      .required(t('required')),
    first_name: Yup.string()
      .required(t('required')),
    last_name: Yup.string()
      .required(t('required')),
      phone_number: Yup.string()
      .required(t("required"))
      .test(
        'unique',
        t("phoneNumberError").toString(),
        (value:any) => /^[0-9\b]+$/.test(value)
      ),
  });

  interface Values {
    email: string;
    first_name: string,
    last_name: string,
    phone_number: string,
  }
  const handleEdit = useCallback(() =>
    setTimeout(() => {
      setDisable(false)
    }, 1000)
    , [])
  const handleOpenResetPassword = useCallback(() => { setOpenResetPassword(true) }, []);
  const handleCloseResetPassword = useCallback(() => { setOpenResetPassword(false) }, []);
  const [addImages, { data }] = useAddImagesMutation();
  const avatar_image = useMemo(() => {
    return data
      ? `${process.env.REACT_APP_API_KEY}${data}`
      : clientData?.avatar_image
        ? `${process.env.REACT_APP_API_KEY}${clientData?.avatar_image}`
        : ''
  }, [data, clientData])

  return (
    clientData?.user && <div className={`w-100`}>
      <Formik
        initialValues={{
          email: clientData?.user?.email,
          first_name: clientData?.user?.first_name,
          last_name: clientData?.user?.last_name,
          phone_number: clientData?.phone_number,
          id: clientData?.id
          // password: ''
        }}
        validationSchema={SignupSchema}
        onSubmit={(
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {
          if (!disable) {
            editManagerRequest(values)
              .then((res: any) => {
                if (res?.data?.user_detail) {
                  toast.success(t('editProfileSuccess'))
                  setDisable(true)
                }
              })
          }
        }}
      >
          <Form >
            <div className={styleProfile.profilePhotos}>
              {/* <h1 className={styleProfile.myProfile}>My Profile</h1> */}
              <div className={`w-100 d-flex justify-content-between ${styleProfile.nameImages}`}>
                <div className='d-flex gap-3 flex-wrap justify-content-between align-items-center w-100'>
                  <ProfileFileUpload avatar_image={avatar_image}
                  edit={false}
                    request={(img: string) => {
                      console.log('edit');
                      
                      // editManagerRequest({ avatar_image: img, id: clientData?.id })
                    }} />
                  <div className='d-flex flex-wrap gap-2'>
                    {/* {disable ? <CustomButtosWithIcon
                      title={t('editProfile')}
                      type={'button'}
                      onClick={handleEdit}
                      icon={<EditUser />}
                    />
                      : <CustomButtosWithIcon
                        title={t('save')}
                        type='submit'
                        onClick={console.log('ggg')}
                        icon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z" fill="#FEFEFE" />
                        </svg>}
                      />} */}
                    <CustomButtosWithIcon
                      title={t('managers')}
                      type='button'
                      onClick={() => navigate(`/profile/clients/${params?.id}/managers`)}
                      icon={<Employees />}
                    />
                    <CustomButtosWithIcon
                      title={t('companies')}
                      type='button'
                      onClick={() => navigate(`/profile/clients/${params?.id}/companies`)}
                      icon={<Company />}
                    />
                  </div>
                </div>
              </div>
            </div>
            <p style={{ marginTop: "20px" }} className={styleProfile?.email}>{clientData?.user?.email}</p>
            <div className={styleProfile.formsCont}>
              <div className={`d-flex flex-wrap justify-content-between ${styleProfile.inputs}`}>
                <CustomInput name='first_name' readonly={disable} title={t('firstName')} />
                <CustomInput name='last_name' readonly={disable} title={t('lastName')} />
                <CustomInput name='phone_number' readonly={disable} title={t('phoneNumber')} />
              </div>

            </div>
          </Form>
      </Formik>
      <ResetPassword open={openResetPassword} handleClose={handleCloseResetPassword} handleOpen={handleOpenResetPassword} />
    </div>

  );
}


