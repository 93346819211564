import { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from "yup";
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import { Form, Formik, FormikHelpers } from 'formik';
import styleProfile from './Profile.module.scss'
import { CustomButton, CustomButtosWithIcon, CustomInput, ProfileFileUpload } from '../../../Components';
import { RootState, useAppDispatch, useAppSelector } from '../../../store';
import EditUser from '../../../Components/SvgCompoents/EditUser';
import ResetPassword from '../../ResetPassword';
import { useAddImagesMutation } from '../../../store/services/photo';
import Camera from '../../../Components/SvgCompoents/Camera';
import { useEditMeMutation } from '../../../store/services/auth';
import { IAuth } from '../../../types';
import { deleteUser } from '../../../store/slice/authSlice';
import DeleteAccountDialog from '../../../Dialogs/DeleteAccountDialog';

export default function Profile() {

  const { t } = useTranslation();
  const [openResetPassword, setOpenResetPassword] = useState(false);
  const [editMeRequest] = useEditMeMutation();
  // const [deleteMeRequest] = useDeleteMeQuery();
  const [disable, setDisable] = useState(true);
  const [open, setOpen] = useState(false);
  const auth: IAuth = useAppSelector((state: RootState) => state.auth);
  const [addImages, { data }] = useAddImagesMutation();
  const SignupSchema = Yup.object().shape({
    email: Yup.string().trim().email(t('emailError'))
      .required(t('required')),
    company_name: Yup.string()
      .required(t('required')),
    tin: Yup.string()
      .required(t('required')),
    address: Yup.string()
      .required(t('required')),
    phone_number: Yup.string()
      .required(t("required"))
      .test(
        'unique',
        t("phoneNumberError").toString(),
        (value:any) => /^[0-9\b]+$/.test(value)
      ),
    // password: Yup.string().trim()
    //   .required(t('required')),
  });

  interface Values {
    email: string;
    company_name: string,
    tin: string,
    address: string,
    phone_number: string,
    // password: string
  }
  const dispatch = useAppDispatch();
  const handleEdit = useCallback(() =>
    setTimeout(() => {
      setDisable(false)
    }, 1000)
    , [])
  const handleOpenResetPassword = useCallback(() => { setOpenResetPassword(true) }, []);
  const handleCloseResetPassword = useCallback(() => { setOpenResetPassword(false) }, []);
  const handleOpenDeleteAccount = useCallback(() => { setOpen(true) }, []);
  const handleCloseDeleteAccount = useCallback(() => { setOpen(false) }, []);
  const handleDelete = useCallback(() => { dispatch(deleteUser()); setOpen(false) }, []);
  const avatar_image = useMemo(() => {
    return auth?.me?.avatar_image
      ? `${process.env.REACT_APP_API_KEY}${auth?.me?.avatar_image}`
      : ''
  }, [data, auth.me]);
  const cover_image = useMemo(() => {
    return auth?.me?.cover_image
      ? `${process.env.REACT_APP_API_KEY}${auth?.me?.cover_image}`
      : '/cover.png'
  }, [data, auth.me]);

  return (
    auth?.me && <Formik
      initialValues={{
        email: auth?.me?.user?.email,
        company_name: auth?.me?.company_name,
        tin: auth?.me?.tin,
        address: auth?.me?.address,
        phone_number: auth?.me?.phone_number ? auth?.me?.phone_number : '',
        // password: ''
      }}
      validationSchema={SignupSchema}
      onSubmit={(
        values: Values,
        { setSubmitting }: FormikHelpers<Values>
      ) => {
        if (!disable) {
          editMeRequest({ ...values, id: auth?.me?.id })
            .then((res: any) => {
              if (res.data.user_detail) {
                toast.success(t('editProfileSuccess'))
                setDisable(true)
              }
            })
        }
      }}
    >
      {({ errors, touched, values }: any) => (
        <Form >
          <DeleteAccountDialog
            open={open}
            handleClose={handleCloseDeleteAccount}
            handleDelete={handleDelete}
          />
          <div className={`w-100`}>
            <div className={styleProfile.profilePhotos} style={{ backgroundImage: 'url(' + cover_image + ')' }}>
              <label>
                <input
                  type='file'
                  style={{ display: 'none' }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const file: File | null = (e?.target?.files && e?.target?.files?.length > 0) ? e?.target?.files[0] : null;
                    if (file) {
                      const formdata = new FormData();
                      formdata.append('file_url', file)
                      addImages(formdata)
                        .then((res: any) => {
                          editMeRequest({ ...values, id: auth?.me?.id, cover_image: `${res.data}`, avatar_image: auth?.me?.avatar_image })
                        });
                    }
                  }}
                />
                <div className={styleProfile.editCover}>
                  <Camera />
                </div>
              </label>
              <div className={`w-100 d-flex gap-2 flex-wrap justify-content-between ${styleProfile.nameImages}`}>
                <ProfileFileUpload avatar_image={avatar_image} request={(img: string) => editMeRequest({
                  ...values,
                  id: auth?.me?.id,
                  avatar_image: img,
                  cover_image: auth?.me?.cover_image
                })} />
                <div className={`d-flex flex-wrap justify-content-center gap-2 justify-md-content-between ${styleProfile.buttonsCont}`}>
                  <CustomButtosWithIcon
                    color='red'
                    title={t('settingsDelete')}
                    type={'button'}
                    onClick={handleOpenDeleteAccount}
                    icon={<DeleteIcon />}
                  />
                  {disable
                    ? <CustomButtosWithIcon
                      title={t('editProfile')}
                      type={'button'}
                      onClick={handleEdit}
                      icon={<EditUser />}
                    />
                    : <CustomButtosWithIcon
                      title={t('save')}
                      type='submit'
                      onClick={console.log('ggg')}
                      icon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z" fill="#FEFEFE" />
                      </svg>}
                    />
                  }
                  <CustomButtosWithIcon
                    title={t('changePassword')}
                    type='button'
                    onClick={handleOpenResetPassword}
                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <g clip-path="url(#clip0_369_5179)">
                        <path d="M17.3606 2.48305C16.9739 2.44779 16.6319 2.73273 16.5966 3.11945L16.5034 4.14141C14.9228 1.65916 12.1791 0 8.99996 0C5.57993 0 2.56595 1.95195 1.06977 4.88159C0.893141 5.22742 1.03032 5.65095 1.37615 5.82757C1.72206 6.0042 2.14551 5.86705 2.32214 5.52118C3.58836 3.04182 6.13111 1.40625 8.99996 1.40625C11.6148 1.40625 13.9745 2.76789 15.3264 4.90106L14.3239 4.18504C14.0079 3.95937 13.5687 4.03256 13.3431 4.34855C13.1174 4.66457 13.1906 5.10367 13.5066 5.32937L16.5467 7.50066C16.9923 7.81829 17.6068 7.52752 17.6556 6.99233L17.997 3.24714C18.0323 2.86038 17.7474 2.51831 17.3606 2.48305ZM16.6238 12.1724C16.2779 11.9958 15.8544 12.133 15.6778 12.4788C14.4116 14.9582 11.8688 16.5938 8.99996 16.5938C6.38515 16.5938 4.02539 15.2321 2.67349 13.0989L3.67604 13.815C3.99206 14.0406 4.43116 13.9674 4.65686 13.6515C4.88253 13.3354 4.80934 12.8963 4.49335 12.6706L1.45322 10.4993C1.01309 10.1849 0.393571 10.467 0.344352 11.0077L0.00294988 14.7529C-0.0323118 15.1396 0.25263 15.4817 0.639313 15.5169C1.02652 15.5521 1.36814 15.2669 1.40336 14.8805L1.49653 13.8586C3.07708 16.3408 5.82082 18 8.99996 18C12.42 18 15.434 16.0481 16.9302 13.1184C17.1068 12.7726 16.9696 12.3491 16.6238 12.1724Z" fill="#FEFEFE" />
                      </g>
                      <defs>
                        <clipPath id="clip0_369_5179">
                          <rect width="18" height="18" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>}
                  />
                </div>
              </div>
            </div>
            <ResetPassword open={openResetPassword} handleClose={handleCloseResetPassword} handleOpen={handleOpenResetPassword} />
            <div className={styleProfile.formsCont}>
              <div className={`d-flex flex-wrap justify-content-between ${styleProfile.inputs}`}>
                <CustomInput name='company_name' readonly={disable} title={t('companyName')} />
                <CustomInput name='tin' readonly={disable} title={t('HVHH')} />
                <CustomInput name='email' readonly={true} title={t('emailAdress')} />
                <CustomInput name='address' readonly={disable} title={t('address')} />
                <CustomInput name='phone_number' readonly={disable} title={t('phoneNumber')} />
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}


