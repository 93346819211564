import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import * as Yup from "yup";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Collapse } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import styles from './TaskPage.module.scss';
import { CustomButtosWithIcon, CustomInput, DetailItem, FileDownload } from '../../../Components';
import DocumentCard from '../../../Components/SvgCompoents/DocumentCard';
import ComponentWithBorder from '../../../Components/ComponentWithBorder';
import Recording from '../../../Components/Recording';
import { useEditTasksByIdMutation, useGetTasksByIdQuery } from '../../../store/services/tasks';
import { useAddImagesMutation } from '../../../store/services/photo';
import { fileIcon, fileIconSrc, getFileName } from '../../../Utils';
import { Form, Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { IDocument, IFiles, ITaskPage } from '../../../types';
import DeleteTaskDialog from '../../../Dialogs/DeleteTaskDialog';
interface DataTask {
  data: ITaskPage
}
export default function Registration({ data }: DataTask) {
  const { t } = useTranslation();
  const params = useParams();
  const [files, setFiles] = useState<IFiles>({});
  const [open, setOpen] = useState(false);
  const [submit, setSubmit] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('done');
  const [complain, setComplain] = useState<string>('');
  const [savedFiles, setSavedFiles] = useState<IFiles>({});
  const navigate = useNavigate();
  // const { data, isLoading } = useGetTasksByIdQuery({ id: params.id });
  const [addImages] = useAddImagesMutation();
  const [editTasksById, {isSuccess}] = useEditTasksByIdMutation();
  const handleSetFile = useCallback((data: IFiles) => {
    setFiles({ ...files, ...data })
  }, [files])
  const handleChangeStatus = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(e.target.value)
  }, [status])
  const handleComplain = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComplain(e.target.value)
  }, [])
  useEffect(() => {
    if (data?.employee_registration) {
      const { any_document } = data
      setSavedFiles({ any_document: any_document.map((el: IDocument) => el.document) })
    }
  }, [data])

  const handleDeleteAnyFile = useCallback((name: keyof typeof savedFiles, ind?: number) => {
    if (savedFiles[name]) {
      setSavedFiles((prev: any) => {
        const prevFiles = { ...prev };
        const newArray = prevFiles[name].filter((el: any, index: number) => {
          return index !== ind
        })
        return { ...prevFiles, [name]: newArray }
      })
    }
  }, [savedFiles])
  const handleDeleteAnyFileSelected = useCallback((name: string, ind?: number) => {
    setFiles((prev: any) => {
      const prevFiles = { ...prev };
      const newArray = prevFiles[name].splice(ind, 1);
      return newArray
    })
  }, [])
  const handleOpenDeleteTask = useCallback(() => { setOpen(true) }, []);
  const handleCloseDeleteTask = useCallback(() => { setOpen(false) }, []);
  const handleDeleteFile = useCallback((name: string, id?: number) => {
    setFiles((prev: any) => {
      const prevFiles = { ...prev };
      delete prevFiles[name];
      return prevFiles
    })
  }, [])
  const deleteTask = () => {
    editTasksById({ status: 'deleted', id: data?.employee_registration?.id })
    
  }
  useEffect(()=>{
    if (isSuccess) {
      toast.success(t('taskHasBeenDeleted'))
      handleCloseDeleteTask()
      navigate('/profile/dashboard')
    }
},[isSuccess])
  const SignupSchema = Yup.object().shape({
    comment: Yup.string(),
  });
  interface Values {
    comment: string
  }
  const employment_contract = useMemo(() => {
    return getFileName(data?.employee_registration?.employment_contract)
  }, [data?.employee_registration?.employment_contract]);

  const employment_order = useMemo(() => {
    return getFileName(data?.employee_registration?.employment_order)
  }, [data?.employee_registration?.employment_order]);

  const application_from_tax = useMemo(() => {
    return getFileName(data?.employee_registration?.application_from_tax)
  }, [data?.employee_registration?.application_from_tax]);

  const application = useMemo(() => {
    return getFileName(data?.employee_registration?.application)
  }, [data?.employee_registration?.application]);

  const aplication_file = useMemo(() => {
    return getFileName(data?.employee_registration?.aplication_file)
  }, [data?.employee_registration?.aplication_file]);
  const id_back_name = useMemo(() => {
    return getFileName(data?.employee_registration?.id_back)
  }, [data?.employee_registration?.id_back]);

  const id_front_name = useMemo(() => {
    return getFileName(data?.employee_registration?.id_front)
  }, [data?.employee_registration?.id_front]);

  const passport_back_name = useMemo(() => {
    return getFileName(data?.employee_registration?.passport_back)
  }, [data?.employee_registration?.passport_back]);

  const passport_front_name = useMemo(() => {
    return getFileName(data?.employee_registration?.passport_front)
  }, [data?.employee_registration?.passport_front]);

  const passport_other_name = useMemo(() => {
    return getFileName(data?.employee_registration?.passport_other)
  }, [data?.employee_registration?.passport_other]);

  const address_other_name = useMemo(() => {
    return getFileName(data?.employee_registration?.address_other)
  }, [data?.employee_registration?.address_other]);

  const application_name = useMemo(() => {
    return getFileName(data?.employee_registration?.application)
  }, [data?.employee_registration?.application]);

  const residence_other_name = useMemo(() => {
    return getFileName(data?.employee_registration?.residence_other)
  }, [data?.employee_registration?.residence_other]);
  const social_other_name = useMemo(() => {
    return getFileName(data?.employee_registration?.social_other)
  }, [data?.employee_registration?.social_other]);

  return (
    <div className={`w-100`}>
      <DeleteTaskDialog
        open={open}
        handleClose={handleCloseDeleteTask}
        handleDelete={deleteTask}
      />
      <div className='d-flex justify-content-between flex-nowrap'>
        <div className={`${styles.taskTypeHeading}`}>{t(`${data?.task_type?.name}`)} </div>
        <div className='d-flex align-items-center gap-2'>
          <div className={`${styles.taskStatus} ${styles[data?.status]}`}>{t(`${data?.status}`)}</div>
          {data?.status === 'created' && <div onClick={handleOpenDeleteTask} className={`${styles.taskStatus} ${styles.deleteTask}`}>{t('delete')} <DeleteIcon /></div>}
        </div>
      </div>
      <div className='d-flex justify-content-between flex-wrap'>
        <div>
          <div className={styles.heading}>{t('detail')} </div>
          <div className={styles.detailCont}>
            {data?.task_name && <DetailItem name={t('taskName')} value={data?.task_name} />}
            {data?.employee_registration?.address && <DetailItem name={t('address')} value={data?.employee_registration?.address} />}
            {data?.employee_registration?.bank_score && <DetailItem
              name={t('bankData')}
              value={`${data?.employee_registration?.bank_score}/${data?.employee_registration?.bank?.name}`}
            />}
            {data?.employee_registration?.social && <DetailItem name={t('socialOther')} value={data?.employee_registration?.social} />}
            {data?.employee_registration?.salary && <DetailItem
              name={t('salary')}
              value={new Intl.NumberFormat(
                'en-US',
              ).format(data?.employee_registration?.salary)}
            />}
            {data?.employee_registration?.role && <DetailItem name={t('role')} value={data?.employee_registration?.role} />}
            {/* {data?.created_at && <DetailItem name={t('createdAt')} value={moment(data?.created_at).format('DD-MM-YY')} />} */}
            {data?.employee_registration?.date_contract && <DetailItem
              name={t('dataContract')}
              value={moment(data?.employee_registration?.date_contract).format('DD MMMM YYYY')}
            />}
            {data?.employee_registration?.date_starting && <DetailItem
              name={t('dataStarting')}
              value={moment(data?.employee_registration?.date_starting).format('DD MMMM YYYY')}
            />}
            {data?.employee_registration?.bank_score && <DetailItem
              name={t('bankScore')}
              value={data?.employee_registration?.bank_score}
            />}
          </div>
          {data?.employee_registration?.comment && <><div className={styles.heading}>{t('description')} </div>
            <p className={styles.detailCont}>
              {data?.employee_registration?.comment}
            </p></>}
        </div>
        <div className={styles.filesCont}>
          <div className={styles.heading}> {t('attachedFiles')} </div>
          <div className={styles.passport}>
            <h4 className={styles.subHeading}>
              {!data?.employee_registration?.passport_other
                ? (data?.employee_registration?.id_back && data?.employee_registration.id_front
                  ? t('IdCard') : t('passport'))
                : t('citizenAnotherCountry')}</h4>
            <div className={`d-flex flex-wrap ${styles.cardCont}`}>
              {data?.employee_registration?.id_back && <FileDownload
                url={`${process.env.REACT_APP_API_KEY}/${data?.employee_registration.id_back}`}
                status={data?.status}
                title={id_back_name}

              />}
              {data?.employee_registration?.id_front && <FileDownload
                url={`${process.env.REACT_APP_API_KEY}/${data?.employee_registration.id_front}`}
                status={data?.status}
                title={id_front_name}

              />}

              {data?.employee_registration?.passport_back && <FileDownload
                url={`${process.env.REACT_APP_API_KEY}/${data?.employee_registration.passport_back}`}
                status={data?.status}
                title={passport_back_name}

              />}
              {data?.employee_registration?.passport_front && <FileDownload
                url={`${process.env.REACT_APP_API_KEY}/${data?.employee_registration.passport_front}`}
                status={data?.status}
                title={passport_front_name}

              />}
              {data?.employee_registration?.passport_other && <FileDownload
                url={`${process.env.REACT_APP_API_KEY}/${data?.employee_registration.passport_other}`}
                status={data?.status}
                title={passport_other_name}
              />}
              {data?.employee_registration?.address_other && <FileDownload
                url={`${process.env.REACT_APP_API_KEY}/${data?.employee_registration.address_other}`}
                status={data?.status}
                title={address_other_name}

              />}
              {data?.employee_registration?.residence_other && <FileDownload
                url={`${process.env.REACT_APP_API_KEY}/${data?.employee_registration.residence_other}`}
                status={data?.status}
                title={residence_other_name}
              />}
              {data?.employee_registration?.social_other && <FileDownload
                url={`${process.env.REACT_APP_API_KEY}/${data?.employee_registration.social_other}`}
                status={data?.status}
                title={social_other_name}
              />}
            </div>

          </div >
          {data?.employee_registration?.application && <div className={styles.passport}>
            <h4 className={styles.subHeading}>
              {t('accost')}</h4>
            <div className={`d-flex flex-wrap ${styles.cardCont}`}>
              <FileDownload
                url={`${process.env.REACT_APP_API_KEY}/${data?.employee_registration.application}`}
                status={data?.status}
                title={application_name}
              />
            </div>
          </div>}

          {/* {data?.any_document?.length > 0 && <div className={styles.application}>
            <h4 className={styles.subHeading}>{t('anyDocuments')}</h4>
            <div className={`d-flex flex-wrap ${styles.cardCont}`}>
              {data?.any_document?.map((el: any, ind: number) => <ComponentWithBorder
                key={ind}
                img={`${process.env.REACT_APP_API_KEY}${el?.employee_registration?.document}`}
                transform={true} />)}
            </div>
          </div>} */}
          {data?.employee_registration?.recording && <div className={styles.application}>
            <h4 className={styles.subHeading}>{t('voiceMessage')}</h4>
            <Recording src={data?.employee_registration?.recording} />
          </div>}
          {/* <ComponentWithBorder img='/passport.png' /> */}
        </div>
      </div>
      {data.status !== "created" && <>
        <hr />
        <div className={styles.heading}> {t('accountantAttachedFiles')} </div>
        <div className={`d-flex flex-wrap ${styles.cardsCont}`}>
          <label className={`${styles.cardUploadItem} d-flex flex-column position-relative`}>
            <input type='file' style={{ display: 'none' }}
              onChange={(e: any) => {
                const file: any = e?.target?.files[0];
                if (file) {
                  const formdata = new FormData();
                  formdata.append('file_url', file)
                  addImages(formdata)
                    .then((res: any) => {
                      if (res.data) {
                        handleSetFile({ employment_contract: res.data })
                      }
                    })
                }
              }} />
            <span className={styles.cardLabel}>{t('employmentContract')}</span>
            {files?.employment_contract
              ? <ComponentWithBorder
                onClick={() => handleDeleteFile('employment_contract')}
                img={`${process.env.REACT_APP_API_KEY}${files.employment_contract}`}
              />
              : (employment_contract
                ? <FileDownload url={`${process.env.REACT_APP_API_KEY}/${data?.employee_registration?.employment_contract}`}
                  status={data?.status}
                  title={employment_contract}
                  deleteItem={() => { console.log('dgf') }}
                />
                : <div className='d-flex flex-column align-items-center'>
                  <DocumentCard />
                  <span className={styles.cardTitle}>{t('attachFilesHere')}</span>
                </div>)}
          </label>
          <label className={`${styles.cardUploadItem} d-flex flex-column position-relative`}>
            <input type='file' style={{ display: 'none' }}
              onChange={(e: any) => {
                const file: any = e?.target?.files[0];
                if (file) {
                  const formdata = new FormData();
                  formdata.append('file_url', file)
                  addImages(formdata)
                    .then((res: any) => {
                      if (res.data) {
                        handleSetFile({ employment_order: res.data })
                      }
                    })
                }
              }} />
            <span className={styles.cardLabel}>{t('employmentOrder')}</span>
            {files?.employment_order
              ? <ComponentWithBorder
                onClick={() => handleDeleteFile('employment_order')}
                img={`${process.env.REACT_APP_API_KEY}${files.employment_order}`}
              />
              : (employment_order
                ? <FileDownload url={`${process.env.REACT_APP_API_KEY}/${data?.employee_registration?.employment_order}`} status={data?.status} title={employment_order} deleteItem={() => { console.log('dgf') }} />
                : <div className='d-flex flex-column align-items-center'>
                  <DocumentCard />
                  <span className={styles.cardTitle}>{t('attachFilesHere')}</span>
                </div>)}
          </label>
          <label className={`${styles.cardUploadItem} d-flex flex-column position-relative`}>
            <input type='file' style={{ display: 'none' }} onChange={(e: any) => {
              const file: any = e?.target?.files[0];
              if (file) {
                const formdata = new FormData();
                formdata.append('file_url', file)
                addImages(formdata)
                  .then((res: any) => {
                    if (res.data) {
                      handleSetFile({ application_from_tax: res.data })
                    }
                  })
              }
            }} />
            <span className={styles.cardLabel}>{t('applicationFromTax')}</span>
            {files?.application_from_tax
              ? <ComponentWithBorder
                onClick={() => handleDeleteFile('application_from_tax')}
                img={`${process.env.REACT_APP_API_KEY}${files.application_from_tax}`}
              />
              : (application_from_tax
                ? <FileDownload url={`${process.env.REACT_APP_API_KEY}/${data?.employee_registration?.application_from_tax}`}
                  status={data?.status}
                  title={application_from_tax}
                  deleteItem={() => { console.log('dgf') }}
                />
                : <div className='d-flex flex-column align-items-center'>
                  <DocumentCard />
                  <span className={styles.cardTitle}>{t('attachFilesHere')}</span>
                </div>)}
          </label>
          {data.employee_registration?.aplication_file && <label className={`${styles.cardUploadItem} d-flex flex-column position-relative`}>
            <input type='file' style={{ display: 'none' }} onChange={(e: any) => {
              const file: any = e?.target?.files[0];
              if (file) {
                const formdata = new FormData();
                formdata.append('file_url', file)
                addImages(formdata)
                  .then((res: any) => {
                    if (res.data) {
                      handleSetFile({ aplication_file: res.data })
                    }
                  })
              }
            }} />
            <span className={styles.cardLabel}>{t('accost')}</span>
            {files?.aplication_file
              ? <ComponentWithBorder
                onClick={() => handleDeleteFile('aplication_file')}
                img={`${process.env.REACT_APP_API_KEY}${files.aplication_file}`}
              />
              : (aplication_file
                ? <FileDownload url={`${process.env.REACT_APP_API_KEY}/${data?.employee_registration.aplication_file}`}
                  status={data?.status}
                  title={aplication_file}
                  deleteItem={() => { console.log('dgf') }}
                />
                : <div className='d-flex flex-column align-items-center'>
                  <DocumentCard />
                  <span className={styles.cardTitle}>{t('attachFilesHere')}</span>
                </div>)}
          </label>}
        </div>
      </>}
      {(data?.status === 'created' || data?.status === 'dispute') && <><hr />
        <Formik
          initialValues={{
            comment: data.employee_registration?.comment ? data.employee_registration?.comment : ''
          }}
          validationSchema={SignupSchema}
          onSubmit={(
            values: Values,
            { setSubmitting }: FormikHelpers<Values>
          ) => {
            const saved = savedFiles?.any_document ? savedFiles?.any_document : [];
            const newFiles = files?.any_document ? files?.any_document : []
            const sendFiles = (saved && newFiles) ? [...saved, ...newFiles] : [];

            editTasksById({ ...values, id: data?.employee_registration?.id, any_document: sendFiles })
              .then((res: any) => {
                if (res?.data?.id) {
                  toast.success('Գործընթացը հաջողությամբ կատարվել է');
                  setFiles({})
                }
              })
          }}
        >
          {({ errors, touched, values }: any) => (
            <Form >
              <div className='d-flex justify-content-between'>
                <div className={styles.heading}> {t('makeChanges')} </div>
                {(data?.status === 'created' || data?.status === 'dispute') && <CustomButtosWithIcon
                  title={t("save")}
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z"
                        fill="#FEFEFE"
                      />
                    </svg>
                  }
                  type="submit"
                />}
              </div>
              <CustomInput readonly={data?.status === 'answeredByAccountant' || data?.status === 'done'}
                name='comment'
                title={t('description')}
                type='string'
                className='taskInput'
              />
              <div>
                <div className={`${styles.sectionHeading} ${styles.cardContHeading}`}>{t('attachYourDocument')}</div>
                <div className={`d-flex align-items-center ${styles.documentLabel}`}>
                  <label className={`${styles.cardUploadItem} d-flex flex-column position-relative`}>
                    <input type='file' style={{ display: 'none' }}
                      disabled={data?.status === 'answeredByAccountant' || data?.status === 'done'}
                      onChange={(e: any) => {
                        const file: any = e?.target?.files[0];
                        if (file) {
                          const formdata = new FormData();
                          formdata.append('file_url', file)
                          addImages(formdata)
                            .then((res: any) => {
                              if (res.data) {
                                let any_document = files && files.any_document ? [...files.any_document] : [];
                                any_document.push(res.data)
                                handleSetFile({ any_document })
                              }
                            })
                        }
                      }}
                    />
                    <DocumentCard />
                  </label>
                </div>
                {savedFiles?.any_document?.map((el: any, ind: number) => <FileDownload
                  url={`${process.env.REACT_APP_API_KEY}/${el}`}
                  status={data?.status}
                  title={getFileName(el)}
                  deleteItem={() => handleDeleteAnyFile('any_document', ind)}
                  key={ind}
                />
                )}
                {files?.any_document && files?.any_document?.length > 0 && <div className='d-flex gap-2'>
                  {files?.any_document.map((el: any, ind: number) => {
                    return <ComponentWithBorder
                      key={ind}
                      img={`${process.env.REACT_APP_API_KEY}${el}`}
                      onClick={() => handleDeleteAnyFileSelected('any_document', el.id)} />
                  })}
                </div>}
              </div>
            </Form>)}
        </Formik>
      </>}
      <hr />
      {data.status === "answeredByAccountant" && <div className='d-flex flex-column'>
        <div className='d-flex justify-content-between'>
          <div className={styles.heading}> {t('acceptTask')} </div>
          {(data?.status === 'answeredByAccountant') && <CustomButtosWithIcon
            title={t("save")}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z"
                  fill="#FEFEFE"
                />
              </svg>
            }
            type="submit"
            onClick={() => {
              setSubmit(true)
              const edtData = data?.status === 'created'
                ? { ...files, status: 'answeredByAccountant', id: data?.employee_registration?.id, }
                : { status, user_complain: complain, id: data?.employee_registration?.id, }
              if (!complain && status === 'dispute') return
              editTasksById(edtData)
            }}
          />}
        </div>
        <div className='d-flex flex-column align-items-start gap-2'>
          <label className={styles.customRadioItem}>
            <input className="form-check-input"
              type="radio"
              id="contactChoice1"
              name="status"
              value="done"
              onChange={handleChangeStatus}
              checked={status === 'done'}
            />
            <div className={styles.customRadioItemLabel}>{t('finishTask')}</div></label>
          <label className={styles.customRadioItem}>
            <input className="form-check-input"
              type="radio"
              id="contactChoice2"
              name="status"
              value="dispute"
              checked={status === 'dispute'}
              onChange={handleChangeStatus}
            />
            <div className={styles.customRadioItemLabel}> {t('disputeTask')} </div></label>
          <Collapse in={status === 'dispute'} timeout="auto">
            <div className='mt-2'>
              <textarea value={complain} className={styles['complain-textarea']} onChange={handleComplain} />
              {submit && !complain && <div className='error'>{t("required")}</div>}
            </div>
          </Collapse>
        </div>
      </div>}
      
      {data.status === "dispute" && <div className='d-flex flex-column'>
        <div className={styles.heading}> {t('userComplain')} </div>
        <span className={styles.complain}>{data?.employee_registration?.user_complain}</span>
      </div>}
    </div>
  );
}


