import { useCallback, useState } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import { CustomButtosWithIcon, CustomDayPicker, CustomInput, CustomRadio } from '../../../Components';
import DocumentCard from '../../../Components/SvgCompoents/DocumentCard';
import styles from './CreateTask.module.scss';
import { useAddTasksDismissalMutation } from '../../../store/services/tasks';
import ComponentWithBorder from '../../../Components/ComponentWithBorder';
import { useAddImagesMutation } from '../../../store/services/photo';
import { useAppSelector } from '../../../store';
import Recording from '../../../Components/Recording';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { IFiles } from '../../../types';

export default function Dismissal() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [submited, setSubmited] = useState(false);
  const [recording, setRecording] = useState<string>();
  const [files, setFiles] = useState<IFiles>({});
  const [dismissDate, setDismissDate] = useState<string>();
  const [addTasksDismissal] = useAddTasksDismissalMutation();
  const [addImages, { data }] = useAddImagesMutation();
  const taskData = useAppSelector((state) => state.createTask.taskData);
  interface Values {
    type: string
    explanation?: string,
    task_name: string
  }
  const SignupSchema = Yup.object().shape({
    type: Yup.string()
      .required(t('required')),
    explanation: Yup.string()
      .test(
        'type',
        t('required'),
        function (value) {
          const options: any = this.options
          const type = options?.parent?.type;

          if (type !== '0' && !value) {
            return false;
          }
          return true;
        }
      ),
    task_name: Yup.string()
      .required(t('required')),
  });
  const handleSetFile = useCallback((data: IFiles) => {
    setFiles({ ...files, ...data })
  }, [files])
  const handleDeleteFile = useCallback((name: keyof typeof files) => {
    setFiles((prev: IFiles) => {
      const prevFiles = { ...prev };
      delete prevFiles[name];
      return prevFiles
    })
  }, [])
  const handleRecording = useCallback((url: string) => {
    setRecording(url)
  }, [])
  const handleSelectDate = useCallback((value: string) => {
    setDismissDate(value)
  }, [])

  return (
    <div className={`w-100`}>

      <Formik
        initialValues={{
          type: '0',
          task_name: ''
        }}
        validationSchema={SignupSchema}
        onSubmit={(
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {
          if (values.type === '0' && !files.application) return
          addTasksDismissal({
            ...values,
            ...files,
            recording: recording ? recording : '',
            dismiss_date: dismissDate,
            status: 'created',
            task_type: taskData?.task_type_id ? taskData?.task_type_id : localStorage.getItem('taskTypeId'),
            company: taskData?.company_id ? taskData?.company_id : localStorage.getItem('cmpId'),
          })
            .then((res: any) => {
              if (res?.data?.id) {
                toast.success('Գործընթացը հաջողությամբ կատարվել է');
                navigate('/profile/dashboard')
              }
            })

        }}
      >
        {({ errors, touched, values }: any) => (
          <Form className='w-100' >
            <div className='d-flex justify-content-between'>
              <h1 className={styles.pageHeading}>{t('EmployeeDismissal')}</h1>
              <div className='d-none d-md-flex'><CustomButtosWithIcon
                title={t('create')}
                icon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z" fill="#FEFEFE" />
                </svg>}
                type='submit'
                onClick={() => setSubmited(true)}
              />
              </div>
            </div>
            <div className='mt-3'>
              <CustomInput
                name='task_name'
                type='text'
                title={t('taskName')}
                className='taskInput' />
            </div>
            <CustomRadio
              radioArray={[
                { label: t("accordingOwnApplication"), value: "0" },
                { label: t('dueLossConfidence'), value: "1" },
                { label: t('dueNonTaxableAbsences'), value: "2" },
              ]}
              label={t('dismissalType')}
              name={'type'}
            />
           { values.type === '0' &&  <div className={`${styles.sectionHeading} ${styles.cardContHeading}`}>{t('accost')}</div>}
            {values.type === '0' && <label className={`${styles.cardUploadItem} ${styles.documentLabel} d-flex flex-column position-relative`}>
              <input
                type='file'
                style={{ display: 'none' }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const file: File | null = (e?.target?.files &&  e?.target?.files?.length>0) ? e?.target?.files[0]:null;
                  if (file) {
                    const formdata = new FormData();
                    formdata.append('file_url', file)
                    addImages(formdata)
                      .then((res: any) => {
                        if (res.data) {
                          handleSetFile({ application: res.data })
                        }
                      })
                  }
                }}
              />
              {files?.application ? <ComponentWithBorder
                onClick={() => handleDeleteFile('application')}
                img={`${process.env.REACT_APP_API_KEY}${files.application}`} /> : <DocumentCard />}

              <span className={styles.cardTitle}>{t('attachFilesHere')}</span>
              {submited && !files?.application && <div className='error error-text'>{t('required')}</div>}
            </label>}
            {(values.type === '1' || values.type === '2') && <>
              <CustomInput name='explanation' title={t('explanation')} className='taskInput' />
              <CustomDayPicker
                mode='single'
                setSelected={handleSelectDate}
                name='dismiss_date'
                title={t('dismissDate')}
                className='taskInput'
                selected={dismissDate}
                submited={submited}
              />
            </>}
            <div>
              <div className={`${styles.sectionHeading} ${styles.cardContHeading}`}>{t('voiceMessage')}</div>
              <Recording handleRecording={handleRecording} />
            </div>
          </Form>
        )}
      </Formik>


    </div>

  );
}


