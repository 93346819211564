import style from './Subscribe.module.scss';
export default function Subscribe() {
    return (
        <div className={style.container}>
            <div className='container'>
            <div className={style.headingCont}>
                <h2 className={style.heading}>Subscribe to get updates</h2>
                <div className={style.headingNext}></div>
            </div>
            <p className={style.sectionTitle}>Would you like us to send you regular updates about product features, promotions, and security updates? input your email address below, and we’ll make sure that we keep you informed</p>
            <div className={style.form}>
               <input placeholder='Enter your email address' className={style.textField}/>
               <button className={style.button}>Subscribe</button>
            </div>
            </div>
        </div>
    )
}