
const Accountant = () => <svg xmlns="http://www.w3.org/2000/svg" width="74" height="74" viewBox="0 0 74 74" fill="none">
  <path d="M1.44531 62.0111V72.5547H56.3672V62.0111C56.3672 56.6245 52.647 51.9518 47.3976 50.7435L41.3547 49.3531L37.766 48.5264L35.4102 47.9844H22.4023L20.0465 48.5264L16.4578 49.3531L10.4149 50.7435C5.16555 51.9518 1.44531 56.6245 1.44531 62.0111Z" fill="#80D6FB" />
  <path d="M45.9609 66.7734H48.8516V72.5547H45.9609V66.7734ZM8.96094 66.7734H11.8516V72.5547H8.96094V66.7734Z" fill="#46BEE8" />
  <path d="M22.4027 47.9844H35.4105L37.7664 48.5264L28.9066 72.5547L20.0469 48.5264L22.4027 47.9844Z" fill="#EDEFF1" />
  <path d="M22.4027 47.9844H35.4105L37.7664 48.5264L33.204 60.9001L28.9066 55.9336L24.6093 60.9001L20.0469 48.5264L22.4027 47.9844Z" fill="#D3D3D3" />
  <path d="M22.4023 47.9844V41.0469H35.4102V47.9844C35.4102 51.5764 32.4983 54.4883 28.9062 54.4883C25.3142 54.4883 22.4023 51.5764 22.4023 47.9844Z" fill="#FFD3B3" />
  <path d="M28.9062 45.6531C26.6708 45.654 24.4583 45.2019 22.4023 44.324V41.0469H35.4102V44.324C33.3542 45.2019 31.1417 45.654 28.9062 45.6531Z" fill="#FCB98B" />
  <path d="M27.4618 59.8862H30.3525L31.5907 65.2767L28.9071 72.5545L26.2236 65.2767L27.4618 59.8862Z" fill="#6E83B7" />
  <path d="M27.4609 59.1636H30.3516V59.8862H27.4609V59.1636Z" fill="#466089" />
  <path d="M28.906 54.4883L31.1381 57.068L30.3513 59.1637H27.4607L26.6738 57.068L28.906 54.4883Z" fill="#6E83B7" />
  <path d="M35.4105 47.9844L28.9066 54.4883L33.5773 59.8864L37.7664 48.5264L35.4105 47.9844ZM20.0469 48.5264L24.236 59.8864L28.9066 54.4883L22.4027 47.9844L20.0469 48.5264Z" fill="#EDEFF1" />
  <path d="M13.7305 21.1016H15.4648V28.6172H13.7305C12.613 28.6172 11.707 27.7113 11.707 26.5937V23.125C11.707 22.0075 12.613 21.1016 13.7305 21.1016ZM44.082 21.1016H42.3477V28.6172H44.082C45.1996 28.6172 46.1055 27.7113 46.1055 26.5937V23.125C46.1055 22.0075 45.1996 21.1016 44.082 21.1016Z" fill="#FFD3B3" />
  <path d="M15.4648 15.1758V29.8457C15.4648 37.2818 21.4831 42.7812 28.9062 42.7812C36.3294 42.7812 42.3477 37.2818 42.3477 29.8457V15.1758H15.4648Z" fill="#FFD3B3" />
  <path d="M28.9063 1.44531C37.2529 1.44531 44.082 8.27441 44.082 16.6211V21.1016H42.3477V28.6172L40.1797 20.957C31.9576 20.7212 25.0291 19.4435 21.5352 15.3203C21.5352 15.3203 20.2756 19.2731 17.4883 20.957L15.4648 28.6172V21.1016H13.7305V16.6211C13.7305 8.27441 20.5596 1.44531 28.9063 1.44531Z" fill="#6E83B7" />
  <path d="M59.2578 37C64.0472 37 67.9297 33.1175 67.9297 28.3281C67.9297 23.5388 64.0472 19.6562 59.2578 19.6562C54.4685 19.6562 50.5859 23.5388 50.5859 28.3281C50.5859 33.1175 54.4685 37 59.2578 37Z" fill="#FFE352" />
  <path d="M59.2578 36.1328C63.5682 36.1328 67.0625 32.6385 67.0625 28.3281C67.0625 24.0177 63.5682 20.5234 59.2578 20.5234C54.9474 20.5234 51.4531 24.0177 51.4531 28.3281C51.4531 32.6385 54.9474 36.1328 59.2578 36.1328Z" fill="#FFB236" />
  <path d="M59.2578 35.2656C63.0893 35.2656 66.1953 32.1596 66.1953 28.3281C66.1953 24.4966 63.0893 21.3906 59.2578 21.3906C55.4263 21.3906 52.3203 24.4966 52.3203 28.3281C52.3203 32.1596 55.4263 35.2656 59.2578 35.2656Z" fill="#FFE352" />
  <path d="M59.2578 27.4613C58.6203 27.4613 58.1016 26.9426 58.1016 26.3051C58.1016 25.6676 58.6203 25.1488 59.2578 25.1488C59.8953 25.1488 60.4141 25.6676 60.4141 26.3051H62.1484C62.1484 25.0133 61.2964 23.917 60.125 23.5477V22.4316H58.3906V23.5477C57.2192 23.917 56.3672 25.0133 56.3672 26.3051C56.3672 27.899 57.6639 29.1957 59.2578 29.1957C59.8953 29.1957 60.4141 29.7144 60.4141 30.352C60.4141 30.9895 59.8953 31.5082 59.2578 31.5082C58.6203 31.5082 58.1016 30.9895 58.1016 30.352H56.3672C56.3672 31.6438 57.2192 32.74 58.3906 33.1093V34.2254H60.125V33.1093C61.2964 32.74 62.1484 31.6438 62.1484 30.352C62.1484 28.7581 60.8517 27.4613 59.2578 27.4613ZM71.4707 72.5551H47.0449C46.7574 72.5551 46.4817 72.4409 46.2784 72.2376C46.0751 72.0343 45.9609 71.7586 45.9609 71.4711V38.0844C45.9609 37.7969 46.0751 37.5212 46.2784 37.3179C46.4817 37.1146 46.7574 37.0004 47.0449 37.0004H71.4707C71.7582 37.0004 72.0339 37.1146 72.2372 37.3179C72.4405 37.5212 72.5547 37.7969 72.5547 38.0844V71.4711C72.5547 71.7586 72.4405 72.0343 72.2372 72.2376C72.0339 72.4409 71.7582 72.5551 71.4707 72.5551Z" fill="#6E83B7" />
  <path d="M45.9609 45.5996H72.5547V46.3223H45.9609V45.5996Z" fill="#466089" />
  <path d="M70.0977 39.168V43.793H48.418V39.168H70.0977Z" fill="#80D6FB" />
  <path d="M70.0977 43.0703V43.793H48.418V43.0703H70.0977Z" fill="#46BEE8" />
  <path d="M58.1016 58.5352H48.8516C48.7366 58.5352 48.6263 58.4895 48.545 58.4082C48.4637 58.3268 48.418 58.2166 48.418 58.1016V48.8516C48.418 48.7366 48.4637 48.6263 48.545 48.545C48.6263 48.4637 48.7366 48.418 48.8516 48.418H58.1016C58.2166 48.418 58.3268 48.4637 58.4082 48.545C58.4895 48.6263 58.5352 48.7366 58.5352 48.8516V58.1016C58.5352 58.2166 58.4895 58.3268 58.4082 58.4082C58.3268 58.4895 58.2166 58.5352 58.1016 58.5352ZM69.6641 58.5352H60.4141C60.2991 58.5352 60.1888 58.4895 60.1075 58.4082C60.0262 58.3268 59.9805 58.2166 59.9805 58.1016V48.8516C59.9805 48.7366 60.0262 48.6263 60.1075 48.545C60.1888 48.4637 60.2991 48.418 60.4141 48.418H69.6641C69.7791 48.418 69.8893 48.4637 69.9707 48.545C70.052 48.6263 70.0977 48.7366 70.0977 48.8516V58.1016C70.0977 58.2166 70.052 58.3268 69.9707 58.4082C69.8893 58.4895 69.7791 58.5352 69.6641 58.5352ZM58.1016 70.0977H48.8516C48.7366 70.0977 48.6263 70.052 48.545 69.9707C48.4637 69.8893 48.418 69.7791 48.418 69.6641V60.4141C48.418 60.2991 48.4637 60.1888 48.545 60.1075C48.6263 60.0262 48.7366 59.9805 48.8516 59.9805H58.1016C58.2166 59.9805 58.3268 60.0262 58.4082 60.1075C58.4895 60.1888 58.5352 60.2991 58.5352 60.4141V69.6641C58.5352 69.7791 58.4895 69.8893 58.4082 69.9707C58.3268 70.052 58.2166 70.0977 58.1016 70.0977ZM69.6641 70.0977H60.4141C60.2991 70.0977 60.1888 70.052 60.1075 69.9707C60.0262 69.8893 59.9805 69.7791 59.9805 69.6641V60.4141C59.9805 60.2991 60.0262 60.1888 60.1075 60.1075C60.1888 60.0262 60.2991 59.9805 60.4141 59.9805H69.6641C69.7791 59.9805 69.8893 60.0262 69.9707 60.1075C70.052 60.1888 70.0977 60.2991 70.0977 60.4141V69.6641C70.0977 69.7791 70.052 69.8893 69.9707 69.9707C69.8893 70.052 69.7791 70.0977 69.6641 70.0977Z" fill="#EDEFF1" />
  <path d="M56.584 52.7539H54.1992V50.3691H52.7539V52.7539H50.3691V54.1992H52.7539V56.584H54.1992V54.1992H56.584V52.7539ZM56.2638 63.2738L55.242 62.2516L53.4766 64.0171L51.7111 62.2516L50.6893 63.2738L52.4546 65.0391L50.6893 66.8044L51.7111 67.8265L53.4766 66.061L55.242 67.8265L56.2638 66.8044L54.4985 65.0391L56.2638 63.2738ZM61.9316 52.7539H68.1465V54.1992H61.9316V52.7539Z" fill="#6E83B7" />
  <path d="M65.0389 63.4573C65.507 63.4573 65.8865 63.0778 65.8865 62.6097C65.8865 62.1417 65.507 61.7622 65.0389 61.7622C64.5709 61.7622 64.1914 62.1417 64.1914 62.6097C64.1914 63.0778 64.5709 63.4573 65.0389 63.4573Z" fill="#6E83B7" />
  <path d="M65.0389 68.3162C65.507 68.3162 65.8865 67.9367 65.8865 67.4686C65.8865 67.0005 65.507 66.6211 65.0389 66.6211C64.5709 66.6211 64.1914 67.0005 64.1914 67.4686C64.1914 67.9367 64.5709 68.3162 65.0389 68.3162Z" fill="#6E83B7" />
  <path d="M61.9316 64.3164H68.1465V65.7617H61.9316V64.3164Z" fill="#6E83B7" />
</svg>

export default Accountant

