import React from 'react';
import { Route } from 'react-router-dom';
import {
  PrivatePage,
  Profile,
  Employee,
  Menu,
  AddEmployees,
  Companies,
  Clients,
  EditEmployee,
  AddClient,
  EditClient,
  ClientCompanies,
  ClientManagers,
  ClientsAddManager,
  ClientsEditManager,
  ClientsAddCompany,
  ClientsEditCompany,
  EditCompany,
  AccountDashboard,
  TaskPageAccount
} from '../Pages';
import Applications from '../Pages/Account/Applications';
import ActivityPage from '../Pages/ActivityPage';
import NotificationPage from '../Pages/NotificationPage';

// EditCompany
export const AccountantRoutes = () => { 
  return (
    <React.Fragment>
      <Route path='/profile' element={<PrivatePage><Menu><Profile /></Menu></PrivatePage>}></Route>
      <Route path='/profile/dashboard/:id' element={<PrivatePage><Menu><TaskPageAccount /></Menu></PrivatePage>}></Route>
      <Route path='/profile/dashboard' element={<PrivatePage><Menu><AccountDashboard /></Menu></PrivatePage>}></Route>
      <Route path='/profile/employees/add-employees' element={<PrivatePage><Menu><AddEmployees /></Menu></PrivatePage>}></Route>
      <Route path='/profile/employees/:id' element={<PrivatePage><Menu><EditEmployee /></Menu></PrivatePage>}></Route>
      <Route path='/profile/employees' element={<PrivatePage><Menu><Employee /></Menu></PrivatePage>}></Route>
      <Route path='/profile/companies/:id' element={<PrivatePage><Menu><EditCompany /></Menu></PrivatePage>}></Route>
      <Route path='/profile/companies' element={<PrivatePage><Menu><Companies /></Menu></PrivatePage>}></Route>
      <Route path='/profile/clients/:id/managers/:id' element={<PrivatePage><Menu><ClientsEditManager /></Menu></PrivatePage>}></Route>
      <Route path='/profile/clients/:id/managers/add-manager' element={<PrivatePage><Menu><ClientsAddManager /></Menu></PrivatePage>}></Route>
      <Route path='/profile/clients/:id/managers' element={<PrivatePage><Menu><ClientManagers /></Menu></PrivatePage>}></Route>
      <Route path='/profile/clients/:id/companies/:companyid' element={<PrivatePage><Menu><ClientsEditCompany /></Menu></PrivatePage>}></Route>
      <Route path='/profile/clients/:id/companies/add-company' element={<PrivatePage><Menu><ClientsAddCompany /></Menu></PrivatePage>}></Route>
      <Route path='/profile/clients/:id/companies' element={<PrivatePage><Menu><ClientCompanies /></Menu></PrivatePage>}></Route>
      <Route path='/profile/clients/:id' element={<PrivatePage><Menu><EditClient /></Menu></PrivatePage>}></Route>
      <Route path='/profile/clients/add-clients' element={<PrivatePage><Menu><AddClient /></Menu></PrivatePage>}></Route>
      <Route path='/profile/clients' element={<PrivatePage><Menu><Clients /></Menu></PrivatePage>}></Route>
      <Route path='/profile/applications' element={<PrivatePage><Menu><Applications /></Menu></PrivatePage>}></Route>
      <Route path='/profile/activity/:id' element={<PrivatePage><Menu><ActivityPage /></Menu></PrivatePage>}></Route>
      <Route path='/profile/notification' element={<PrivatePage><Menu><NotificationPage /></Menu></PrivatePage>}></Route>
    </React.Fragment>
  )
}