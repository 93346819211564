import React from 'react';
import style from './Activity.module.scss'

type Props = {
    data?: string,
    avatar: string,
    name: string,
    date: string,
    description: string
};
const ActivityItem = ({ avatar, name, date, data, description }: Props) => {
    return (<div className={`d-flex ${style.activityItem}`}>
        {avatar
            ? <img className={style.avatar} src={avatar} />
            : <div className={style.avatarCont}><img src={'/managerDefaultImg.svg'} />
            </div>}
        <div className={`d-flex flex-column ${style.info}`}>
            <div className={`d-flex ${style.titleNameDate}`}>
                {/* <div className={style.name}>{name}</div> */}
               { data && <span className={style.title}>{data}</span>}
            </div>
            <div className={style.description}>{description}</div>
            <span className={style.date}>{date}</span>
        </div>
        {/* <img className={style.avatar} src={avatar} /> */}
    </div>)
}
export default ActivityItem;