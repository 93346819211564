import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ru from './locales/ru/ns1.json';
import am from './locales/am/ns1.json';
import en from './locales/en/ns1.json';
export const resources = {
  ru,
  am,
  en
} as const;

i18n.use(initReactI18next).init({
  lng: 'am',
  resources,
});
export default i18n