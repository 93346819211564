
const User = () => <svg xmlns="http://www.w3.org/2000/svg" width="74" height="74" viewBox="0 0 74 74" fill="none">
  <g clip-path="url(#clip0_42_3918)">
    <path d="M73.9076 73.7566H0.0926407C-0.254234 66.9578 0.439516 63.8475 1.3992 60.3556C5.14545 48.4578 14.303 49.8569 23.2639 45.625C23.6223 45.4631 23.9692 45.2897 24.3161 45.1047C24.8248 44.8503 25.3336 44.5497 25.8308 44.2375L25.8655 44.2144C26.062 44.0872 26.247 43.9716 26.4436 43.8444C26.6055 43.7403 26.7558 43.6363 26.9061 43.5322C27.2067 43.3356 27.4726 43.1391 27.7501 42.8963C27.8773 42.8269 27.993 42.7228 28.1086 42.6419L28.1201 42.6303C28.1317 42.6188 28.1433 42.6188 28.1433 42.6188C28.2473 42.5378 28.3398 42.4453 28.4439 42.3644C28.4439 42.3528 28.4555 42.3528 28.4555 42.3528L28.467 42.3413C28.5017 42.3181 28.5364 42.295 28.5826 42.2603C28.6173 42.2488 28.652 42.2256 28.6983 42.191L28.7445 42.1563C28.7676 42.1331 28.7908 42.1216 28.8023 42.0985C28.8139 42.0985 28.8255 42.0753 28.837 42.0638C28.9411 41.9944 29.0336 41.9135 29.1261 41.8325H44.8858C45.013 41.9481 45.1401 42.0638 45.2789 42.1678C45.302 42.191 45.3136 42.2025 45.3367 42.2141C45.3598 42.2488 45.383 42.2603 45.4061 42.2719C45.4408 42.3066 45.487 42.3297 45.5333 42.376C45.5911 42.4338 45.6605 42.4916 45.7183 42.5263C45.7645 42.561 45.8223 42.5956 45.8686 42.6535C45.8801 42.665 45.8917 42.665 45.9033 42.6766C45.938 42.7113 45.9726 42.746 46.0305 42.7806C46.1692 42.9078 46.3195 43.0119 46.4583 43.1275C46.493 43.1506 46.5161 43.1738 46.5623 43.1969C46.7589 43.3356 46.9555 43.486 47.1405 43.6016C47.2792 43.6825 47.418 43.7866 47.5336 43.8791C47.6145 43.9253 47.707 43.9947 47.788 44.041C47.9036 44.1103 48.0192 44.2028 48.1464 44.2722C48.3083 44.3763 48.4701 44.4688 48.632 44.5613C49.002 44.781 49.3605 44.9775 49.7304 45.1741C50.0773 45.3591 50.4242 45.5325 50.7826 45.6944H50.7942C59.7551 49.9147 68.8548 48.5041 72.5895 60.3903C73.5608 63.8475 74.2661 66.9578 73.9076 73.7566Z" fill="#5F5555" />
    <path d="M53.3723 54.8171L43.1741 73.768H37.7398L39.9713 65.2234V65.2118L39.9945 65.1077L43.3591 52.2849L43.6713 51.1287L45.8335 42.9309L45.9029 42.6418L45.9145 42.5724L45.9607 42.3759V42.3643L45.9838 42.2949L46.111 42.3759L46.2845 42.5146L46.2613 42.5377C46.2613 42.5377 46.2498 42.5609 46.2382 42.5609H46.2266C46.1688 42.6187 46.0995 42.688 46.0301 42.7459C46.1688 42.873 46.3191 42.9771 46.4579 43.0927C46.4926 43.1159 46.5157 43.139 46.562 43.1621C46.7585 43.3009 46.9551 43.4512 47.1401 43.5668C47.2788 43.6477 47.4176 43.7518 47.5332 43.8443C47.6141 43.8905 47.7066 43.9599 47.7876 44.0062C47.9032 44.0755 48.0188 44.168 48.146 44.2374C48.3079 44.3415 48.4698 44.434 48.6316 44.5265C49.0016 44.7462 49.3601 44.9427 49.7301 45.1393C50.077 45.3243 50.4238 45.4977 50.7823 45.6596H50.7938L52.8751 52.1693L49.2676 53.314L53.3723 54.8171ZM36.2713 73.7565H30.837L20.6504 54.8055L24.732 53.3255L21.1245 52.1809L23.2751 45.6249C23.6335 45.463 23.9804 45.2896 24.3273 45.1046C24.836 44.8502 25.3448 44.5496 25.842 44.2374L25.8766 44.2143C26.0732 44.0871 26.2582 43.9715 26.4548 43.8443C26.6166 43.7402 26.767 43.6362 26.9173 43.5321C27.2179 43.3355 27.4838 43.139 27.7613 42.8962C27.8885 42.8268 28.0041 42.7227 28.1198 42.6418L30.3513 51.1749L30.6635 52.4121L34.0282 65.1309V65.1424L34.0398 65.2002V65.2118L36.2713 73.7565Z" fill="#3C3C3C" />
    <path d="M48.1582 44.2491L47.9038 45.1509L47.7651 45.6828L46.817 49.1516L46.736 49.4984L40.5616 72.2188L40.5501 72.2534L40.1338 73.7566H33.8901L33.497 72.2997L33.4854 72.265L27.3457 49.5563L27.3341 49.5447V49.5331L25.9004 44.2144C26.097 44.0872 26.282 43.9716 26.4785 43.8444C26.6404 43.7403 26.7907 43.6363 26.941 43.5322C27.2416 43.3356 27.5076 43.1391 27.7851 42.8962C27.9123 42.8269 28.0279 42.7228 28.1435 42.6419L28.132 42.6072C28.0626 42.5378 27.9932 42.48 27.9354 42.4222H27.9238L27.8891 42.3759H27.9007L27.9123 42.3644L27.9238 42.3528L28.0395 42.2719L28.1666 42.1909L28.2129 42.1562H45.6954L46.0191 42.3759V42.3875L46.1001 42.4453L46.2735 42.5725C46.2157 42.6303 46.1463 42.6997 46.077 42.7575C46.2157 42.8847 46.366 42.9887 46.5048 43.1044C46.5395 43.1275 46.5626 43.1506 46.6088 43.1738C46.8054 43.3125 47.002 43.4628 47.187 43.5784C47.3257 43.6594 47.4645 43.7634 47.5801 43.8559C47.661 43.9022 47.7535 43.9716 47.8345 44.0178C47.9154 44.0872 48.031 44.1681 48.1582 44.2491Z" fill="#E5E5E5" />
    <path d="M48.1584 44.249L47.7653 45.6827L46.794 49.1515L46.69 49.4984L43.9034 51.834L43.36 52.2965L41.0243 54.2737L39.9143 52.0537L37.1162 46.4805H37.1278C37.1278 46.4805 37.1278 46.469 37.1393 46.469H37.174C39.9953 45.8215 42.4812 45.6712 44.2156 44.2837C44.7822 43.8559 45.314 43.2315 45.8575 42.6418C45.8806 42.6302 45.8922 42.6071 45.9037 42.5955L46.0425 42.4568C46.0656 42.4452 46.0771 42.4221 46.1003 42.399C46.1003 42.3874 46.1118 42.3874 46.1118 42.3759L46.1465 42.3412C46.1581 42.3412 46.3084 42.4684 46.3547 42.4799C46.3315 42.4915 46.3084 42.503 46.2968 42.5262C46.2853 42.5262 46.2853 42.5377 46.2737 42.5377L46.2506 42.5609C46.2506 42.5609 46.239 42.584 46.2275 42.584H46.2159C46.1581 42.6418 46.0887 42.7112 46.0193 42.769C46.1581 42.8962 46.3084 43.0002 46.4471 43.1159C46.4818 43.139 46.505 43.1621 46.5512 43.1852C46.7478 43.324 46.9443 43.4743 47.1293 43.5899C47.2681 43.6709 47.4068 43.7749 47.5225 43.8674C47.6034 43.9137 47.6959 43.983 47.7768 44.0293C47.9156 44.0871 48.0312 44.168 48.1584 44.249ZM37.0006 46.4574H36.989C33.2428 45.6018 30.4215 44.8965 28.1553 42.6302L28.109 42.584C28.0396 42.5377 27.9703 42.4684 27.9125 42.4105C27.9009 42.3874 27.9009 42.3874 27.8893 42.3759L27.8778 42.3643L27.8084 42.2949L27.7968 42.3065L27.8662 42.3759V42.3874H27.8778L27.9009 42.4337H27.9125C27.9703 42.4915 28.0396 42.5493 28.109 42.6187L28.1206 42.6534C28.005 42.7343 27.8893 42.8384 27.7621 42.9077C27.4846 43.139 27.2187 43.3355 26.9181 43.5437C26.7678 43.6477 26.6175 43.7518 26.4556 43.8559C26.259 43.983 26.074 44.0987 25.8775 44.2259L25.8428 44.249L27.265 49.5215L27.2881 49.533L27.3112 49.5446L27.3228 49.5562L30.0515 51.8918L30.6528 52.4121L32.8496 54.2621L34.0406 52.0652V52.0537L34.0521 52.0305L37.0237 46.469C37.0121 46.469 37.0121 46.469 37.0006 46.4574Z" fill="#5F5555" />
    <path d="M55.4541 27.0558C54.5753 29.2758 53.6735 30.4321 52.3322 31.8311C51.6269 35.0571 50.7019 36.8146 49.6728 38.6877C48.6785 40.3758 47.6378 41.3817 46.3891 42.4571C46.366 42.4686 46.3428 42.4802 46.3313 42.5033C46.3197 42.5033 46.3197 42.5149 46.3081 42.5149L46.285 42.538C46.285 42.538 46.2735 42.5611 46.2619 42.5611H46.2503C46.1925 42.6189 46.1231 42.6883 46.0538 42.7461C45.9844 42.8039 45.915 42.8733 45.8572 42.9311C45.4525 43.278 45.0016 43.648 44.5506 44.0642C44.4813 44.1336 44.3772 44.203 44.2963 44.2608C42.6547 45.6638 40.5703 46.4418 38.411 46.4577H37.8675C37.671 46.4577 37.4513 46.4577 37.2547 46.4461H37.2085C37.1969 46.4461 37.1853 46.4461 37.1738 46.4577H37.1506C37.1275 46.4577 37.1044 46.4461 37.0813 46.4461H37.035C36.4569 46.4461 35.8788 46.423 35.3122 46.4114C32.9291 46.2901 30.6846 45.2547 29.0453 43.5208C28.7331 43.1971 28.4325 42.8849 28.1666 42.6305L28.1435 42.6074C28.0741 42.538 28.0047 42.4802 27.9469 42.4224H27.9353L27.9122 42.3761H27.9006V42.3646L27.8313 42.2952C26.5131 41.0233 25.5072 40.1677 24.6285 38.6992C23.3566 36.5486 22.3506 34.6061 21.761 31.5305C19.7606 29.8424 18.7316 28.108 18.2113 25.2752C17.691 22.7314 18.3963 20.0258 19.8763 19.5864C20.2 19.5171 20.4313 19.4708 20.6625 19.4824H20.6856C21.7263 19.5286 22.0616 20.2686 22.8016 21.2977C22.8594 20.3958 22.9519 19.5633 23.056 18.8117C23.0906 18.5111 23.1253 18.2105 23.1947 17.8867C23.2063 17.7596 23.2294 17.6439 23.2525 17.5167C23.7266 14.9383 24.4781 13.4583 25.3685 13.0767L25.7847 16.8808C27.4035 14.3486 28.8256 12.4177 31.0803 12.7877L38.4688 13.0421C41.4866 12.9264 43.88 11.7239 45.5103 9.18018C49.326 11.7355 50.9447 16.2333 51.176 21.9683C51.5922 21.1474 52.0431 20.6964 52.4594 20.4189H52.471C52.7831 20.2339 53.0722 20.1299 53.396 20.0605C56.541 20.1183 56.356 24.4196 55.4541 27.0558Z" fill="#FFD3B3" />
    <path d="M22.9056 24.1652C18.7662 18.5805 19.8415 11.2383 21.4256 7.91984C23.819 2.77453 32.144 -0.243285 39.0006 0.300152C42.7931 0.878277 46.0884 2.33515 47.8922 6.31265C53.4422 6.86765 54.8759 17.112 51.095 23.5292C51.1528 19.5517 50.4128 17.1467 49.5456 14.5452C47.7187 17.3433 45.6953 17.7942 41.579 18.5111C43.0359 16.823 43.8569 14.973 43.6719 12.8455C38.6769 17.748 32.6644 19.8061 23.2178 17.5283C22.8478 19.7367 22.7206 21.818 22.9056 24.1652Z" fill="#687E87" />
  </g>
  <defs>
    <clipPath id="clip0_42_3918">
      <rect width="74" height="74" fill="white" />
    </clipPath>
  </defs>
</svg>
export default User

